import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Chip,
  Typography,
  CardHeader,
  Stack,
  Avatar
} from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { excerpt } from 'utils/helpers'
import { find, prop, propEq } from 'ramda'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { Link, useLocation } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

const ExploreGoalCard = ({
  goalTitle,
  totalSteps,
  stepDetail = null,
  subdomainId = null,
  id
}) => {
  const { userDomains } = useSelector(prop('user'))
  const { pathname } = useLocation()
  const { sendEvent } = useAnalytics()

  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = find(propEq('id', subdomainId))(subdomains)

  const handleClick = () =>
    sendEvent('block_interactions', {
      block_title: 'Goals Explore',
      click_element: 'Link',
      element_title: goalTitle,
      path: pathname
    })

  return (
    <Card>
      <CardHeader
        disableTypography
        subheader={
          <>
            <Typography mb={1} variant="cardTitle">
              {goalTitle}
            </Typography>
            <Chip
              icon={<CheckRoundedIcon />}
              label={
                <FormattedMessage
                  defaultMessage="{total} Recommended Steps"
                  id="ba3sI4"
                  values={{ total: totalSteps }}
                />
              }
            />
            <Divider sx={{ mb: 0 }} />
          </>
        }
        sx={{ pb: 0 }}
        title={
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ mb: 1.5 }}
          >
            <Avatar
              src={prop('display_image', subdomain)}
              sx={{ height: 54, width: 54 }}
            />
            <Stack spacing={0.5}>
              <Typography color="secondary.dark" variant="overline">
                <FormattedMessage defaultMessage="Suggested Goal" id="NTzXZv" />
              </Typography>
              <Typography color="secondary.dark" variant="h6">
                {prop('title', subdomain)}
              </Typography>
            </Stack>
          </Stack>
        }
      />
      <CardContent>
        <Typography variant="overline">
          <FormattedMessage defaultMessage="First Step" id="qxKWTO" />
        </Typography>
        <Typography mt={1} variant="body2">
          {excerpt(prop(0, stepDetail), 60)}
        </Typography>
      </CardContent>
      <CardActions sx={{ pb: 2, px: 3 }} variant="center">
        <Button
          fullWidth
          LinkComponent={Link}
          onClick={handleClick}
          to={`/goals/create/premade?cardId=${id}`}
        >
          <FormattedMessage defaultMessage="Set this Goal" id="4OUieF" />
        </Button>
      </CardActions>
    </Card>
  )
}

ExploreGoalCard.propTypes = {
  goalTitle: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  stepDetail: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  subdomainId: PropTypes.number,
  totalSteps: PropTypes.number.isRequired
}

export default ExploreGoalCard
