import PropTypes from 'prop-types'
import VideoTranscript from './VideoTranscript'
import parse from 'html-react-parser'
import { Box, Stack, Typography } from '@mui/material'
import { isNil, not } from 'ramda'

const VideoArticle = ({
  video_description,
  video_embed_url,
  video_transcript,
}) => (
  <Stack
    sx={{
      alignItems: 'center',
    }}
  >
    <Box height={310} position="relative" width="100%">
      <Box
        component="iframe"
        src={video_embed_url}
        sx={{
          border: 'none',
          height: '100%',
          width: 1,
        }}
      />
    </Box>
    {not(isNil(video_transcript)) && (
      <VideoTranscript videoTranscript={video_transcript} />
    )}
    <Typography variant="body3">{parse(video_description)}</Typography>
  </Stack>
)

VideoArticle.propTypes = {
  video_description: PropTypes.string.isRequired,
  video_embed_url: PropTypes.string.isRequired,
  video_transcript: PropTypes.string,
}

VideoArticle.defaultProps = {
  video_transcript: null,
}

export default VideoArticle
