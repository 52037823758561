import { Box, Button, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'

const SortMenu = ({ filter, options, setFilter }) => {
  const { sendEvent } = useAnalytics()

  return (
    <Box
      sx={{
        height: 48,
        position: 'relative',
        width: '100%'
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: 'auto',
          pb: 1,
          position: 'absolute',
          pr: 2,
          top: 0,
          width: 'calc(100% + 16px)'
        }}
      >
        {options.map(({ label, value }) => (
          <Button
            key={value}
            onClick={() => {
              setFilter(value)
              sendEvent('YOU_subdomain_filter', {
                filter_selection: label,
                page_title: window.location.pathname
              })
            }}
            selected={equals(filter, value)}
            variant="filterMenu"
          >
            <FormattedMessage
              defaultMessage="{label}"
              id="j8y+qc"
              values={{ label }}
            />
          </Button>
        ))}
      </Stack>
    </Box>
  )
}

SortMenu.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilter: PropTypes.func.isRequired
}

export default SortMenu
