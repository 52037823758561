import { useState } from 'react'
import ArrowAnimationButton from 'components/common/ArrowAnimationButton'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Grow, Stack, Typography } from '@mui/material'
import parse from 'html-react-parser'

const VideoTranscript = ({ videoTranscript }) => {
  const [showTranscript, setShowTranscript] = useState(false)

  return (
    <Stack alignItems="center" justifyContent="center">
      <ArrowAnimationButton
        buttonText={
          showTranscript ? (
            <FormattedMessage
              defaultMessage="Hide Audio Transcript"
              id="SsirJy"
            />
          ) : (
            <FormattedMessage
              defaultMessage="View Audio Transcript"
              id="BPWeoX"
            />
          )
        }
        handleClick={() => setShowTranscript(!showTranscript)}
        showContent={showTranscript}
      />
      <Grow in={showTranscript}>
        <Typography
          sx={{
            bgcolor: 'primary.light',
            height: showTranscript ? 'auto' : 0,
            mt: 2,
            p: showTranscript ? 2 : 0,
          }}
          variant="subtitle1"
        >
          {showTranscript && parse(videoTranscript)}
        </Typography>
      </Grow>
    </Stack>
  )
}

VideoTranscript.propTypes = {
  videoTranscript: PropTypes.string.isRequired,
}

export default VideoTranscript
