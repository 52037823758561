/* eslint-disable sort-keys */
import TopicImage from 'pages/dashboard/components/TopicImage'
import { or, isEmpty, isNil, prop, concat, map } from 'ramda'
import { useGetFeaturedContentQuery } from 'api/schoolApi'
import { Skeleton, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const RegionBlock = () => {
  const { flagImage, secondary_color } = useSelector(prop('school'))
  const { userDomains } = useSelector(prop('user'))

  const { data: featuredContent = {}, isFetching } =
    useGetFeaturedContentQuery()

  const getTopicDataFromSubdomain = ({ id, display_image, link, title }) => ({
    badge: flagImage,
    color: secondary_color,
    id,
    image: display_image,
    internalURL: link, //`/explore/subdomain/${subdomain.id}`,
    title
  })

  const getTopicDataFromGroup = ({
    id,
    frontend_display_image,
    display_text
  }) => ({
    badge: flagImage,
    color: secondary_color,
    id,
    image: frontend_display_image,
    internalURL: `/explore/group/${id}`,
    title: display_text
  })

  const subdomains = useMemo(() => {
    if (or(isEmpty(userDomains), isNil(prop('subdomains', featuredContent))))
      return []
    return userDomains
      .reduce((subdomains, d) => subdomains.concat(d.subdomains), [])
      .filter(({ id }) => prop('subdomains', featuredContent).includes(id))
      .map(getTopicDataFromSubdomain)
  }, [featuredContent, userDomains])

  const groups = useMemo(() => {
    if (isNil(prop('groups', featuredContent))) return []
    return map(getTopicDataFromGroup, prop('groups', featuredContent))
  }, [featuredContent])
  // adding this groupDefault when featuredContent is null it returns undefined from groups
  // breaking the map below, so defaulting to empty array so the topicsList
  // list of topics concated with groups
  const topics = concat(groups, subdomains)

  if (or(isFetching, isEmpty(userDomains)))
    return (
      <Stack
        data-testid="skeleton"
        direction={{ xs: 'column', lg: 'row' }}
        spacing={{ xs: 1, lg: 3 }}
      >
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
      </Stack>
    )

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
      sx={{
        gridGap: { xs: 8, sm: 24 },
        pt: 2,
        px: 0
      }}
    >
      {topics.map(({ id, title, internalURL, image }) => (
        <TopicImage
          image={image}
          internalURL={internalURL}
          key={id}
          title={title}
        />
      ))}
    </Stack>
  )
}

export default RegionBlock
