import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { updateUserData } from 'store/userSlice'
import PropTypes from 'prop-types'

const StaffFacultyAlert = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const [update] = useUpdateProfileMutation()

  const handleClick = () => {
    update({ has_seen_staff_faculty_announcement: true })
    dispatch(
      updateUserData({
        name: 'has_seen_staff_faculty_announcement',
        value: true
      })
    )
    setShow(false)
  }

  return (
    <Slide direction="left" in={show}>
      <Alert
        component="div"
        icon={false}
        role="alert"
        severity="info"
        sx={{
          borderTopColor: 'school.secondary',
          boxShadow: 6
        }}
        variant="outlined"
      >
        <AlertTitle>
          <FormattedMessage
            defaultMessage="Student or Staff/Faculty?"
            id="sT9B/p"
          />
        </AlertTitle>
        <Typography sx={{ display: 'inline' }} variant="body2">
          <FormattedMessage
            defaultMessage="YOU is customized to both students and staff/faculty members.
                Visit your profile settings to ensure you are experiencing the
                correct version of YOU."
            id="eQzYBj"
          />
        </Typography>
        <Stack direction="row" mt={2} spacing={2}>
          <Button
            LinkComponent={Link}
            onClick={handleClick}
            to="/profile?active=settings"
          >
            <FormattedMessage defaultMessage="Visit Profile" id="AZj8qj" />
          </Button>
          <Button onClick={handleClick} variant="outlined">
            <FormattedMessage defaultMessage="No Thanks" id="mgZWr0" />
          </Button>
        </Stack>
      </Alert>
    </Slide>
  )
}

StaffFacultyAlert.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default StaffFacultyAlert
