/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import {
  prop,
  isNil,
  path,
  gt,
  defaultTo,
  not,
  length,
  toLower,
  or,
  equals
} from 'ramda'
import { useGetSubdomainQuery } from 'api/schoolApi'
import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PollOfMonth from 'components/poll-of-month/PollOfMonth'
import PageHeroBanner from 'components/common/PageHeroBanner'
import UserFeedback from 'components/common/UserFeedback'
import SubdomainCalloutBox from './SubdomainCalloutBox'
import LearnNewSkillsBlock from './LearnNewSkillsBlock'
import ExploreResourcesBlock from './ExploreResourcesBlock'
import ConnectedSubdomains from './ConnectedSubdomains'
import PrioritizedIcon from './PrioritizedIcon'

const Subdomain = () => {
  const { subdomainName } = useParams()
  const { userDomains } = useSelector(prop('user'))

  const subdomainId = useMemo(() => {
    if (isNil(userDomains)) return null
    return prop(
      'id',
      subdomainsFromUserDomains(userDomains).find(({ frontend_url_slug }) =>
        equals(frontend_url_slug, subdomainName)
      )
    )
  }, [userDomains, subdomainName])

  const { data, isFetching } = useGetSubdomainQuery(subdomainId, {
    skip: isNil(subdomainId)
  })

  if (or(isFetching, isNil(subdomainId))) return

  const subdomainData = prop('subdomain', data)
  const hasPolling = path(['poll_question', 'allow_as_poll_on_subdomain'], data)
  const hasSkillCards = gt(defaultTo(0, length(prop('skill_cards', data))), 0)

  return (
    <Box>
      <PageHeroBanner imageUrl={prop('hero_image_url', subdomainData)} />
      <Container
        sx={{
          my: { xs: 3, md: 6 }
        }}
      >
        <Stack spacing={{ xs: 3, md: 4 }}>
          <Stack>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
              <PrioritizedIcon
                title={path(['subdomain', 'onboarding_title'], data)}
              />
              <Typography variant="overline">
                {prop('frontend_name', subdomainData)}
              </Typography>
            </Stack>
            <Typography color="primary.main" component="h1" variant="h2">
              {prop('onboarding_title', subdomainData)}
            </Typography>
          </Stack>

          <Box
            sx={{
              display: 'grid',
              gridGap: { xs: 16, md: 32 },
              gridTemplateAreas: {
                xs: `
              'callout'
              'poll'
              'skills'
              `,
                md: `
              'callout poll'
              'skills poll'
              `
              }
            }}
          >
            {not(isNil(prop('context_copy', subdomainData))) && (
              <Box sx={{ gridArea: 'callout' }}>
                <SubdomainCalloutBox
                  crisisSubdomain={prop('negative_content', subdomainData)}
                  topSectionCopy={prop('context_copy', subdomainData)}
                />
              </Box>
            )}

            {hasPolling && (
              <Box
                sx={{
                  gridArea: 'poll',
                  m: '0 auto',
                  maxWidth: 375
                }}
              >
                <PollOfMonth pollingQuestion={prop('poll_question', data)} />
              </Box>
            )}
            {hasSkillCards && (
              <Box sx={{ gridArea: 'skills' }}>
                <LearnNewSkillsBlock
                  containsPolling={hasPolling}
                  skillCards={prop('skill_cards', data)}
                />
              </Box>
            )}
          </Box>
        </Stack>

        <ExploreResourcesBlock subdomain={data} />

        <Divider sx={{ mt: 6 }} />

        <UserFeedback
          hasNeutral
          negativeTitle={
            <FormattedMessage
              defaultMessage="Looks like we missed the mark."
              id="kKAHaA"
            />
          }
          positiveTitle={
            <FormattedMessage
              defaultMessage="Awesome! Keep exploring for more."
              id="xT2bTP"
            />
          }
          subtitle={
            <FormattedMessage
              defaultMessage="Have you learned something new in {domain}?"
              id="TiZl5R"
              values={{
                domain: toLower(prop('onboarding_title', subdomainData))
              }}
            />
          }
        />
      </Container>
      <ConnectedSubdomains
        subdomainData={prop('relatedDomains', subdomainData)}
      />
    </Box>
  )
}

export default Subdomain
