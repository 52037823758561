import WelcomeSectionWrapper from 'pages/welcome/components/WelcomeSectionWrapper'
import WelcomeFooter from 'pages/welcome/components/WelcomeFooter'
import WelcomeSectionHero from 'pages/welcome/components/WelcomeSectionHero'
import WelcomeSectionAbout from 'pages/welcome/components/WelcomeSectionAbout'
import WelcomeSectionCards from 'pages/welcome/components/WelcomeSectionCards'
import WelcomeSectionTools from 'pages/welcome/components/WelcomeSectionTools'
import WelcomeSectionStories from 'pages/welcome/components/WelcomeSectionStories'
import WelcomeSectionQuotes from 'pages/welcome/components/WelcomeSectionQuotes'
import WelcomeSectionResources from 'pages/welcome/components/WelcomeSectionResources'
import { prop } from 'ramda'
import { Box } from '@mui/material'
import CTA from 'pages/welcome/components/CTA'
import { useCurrentAppType } from 'hooks'

const Referral = () => {
  const { isCollective, isWellpower, isNLC } = useCurrentAppType()

  const welcomeWidgets = {
    about: (
      <WelcomeSectionWrapper curveBottom curveTop key="about">
        <WelcomeSectionAbout />
      </WelcomeSectionWrapper>
    ),
    cards: (
      <WelcomeSectionWrapper key="cards" toggleGrey>
        <WelcomeSectionCards />
      </WelcomeSectionWrapper>
    ),
    cta: (
      <WelcomeSectionWrapper curveBottom key="cta" toggleGrey>
        <CTA />
      </WelcomeSectionWrapper>
    ),
    hero: (
      <WelcomeSectionWrapper curveBottom key="hero">
        <WelcomeSectionHero />
      </WelcomeSectionWrapper>
    ),
    quotes: (
      <WelcomeSectionWrapper key="quotes" toggleGrey>
        <WelcomeSectionQuotes />
      </WelcomeSectionWrapper>
    ),
    resources: (
      <WelcomeSectionWrapper curveTop key="resources">
        <WelcomeSectionResources />
      </WelcomeSectionWrapper>
    ),
    stories: (
      <WelcomeSectionWrapper key="stories" toggleGrey>
        <WelcomeSectionStories />
      </WelcomeSectionWrapper>
    ),
    tools: (
      <WelcomeSectionWrapper curveBottom curveTop key="tools">
        <WelcomeSectionTools />
      </WelcomeSectionWrapper>
    )
  }

  // each app type needs a welcome page list
  const widgetList = () => {
    if (isWellpower) return ['hero', 'cta', 'cards', 'tools', 'quotes']
    if (isCollective) return ['hero', 'quotes', 'about', 'tools', 'resources']
    if (isNLC) return ['hero', 'cards', 'tools']
    return ['hero', 'cards', 'tools', 'quotes']
  }

  return (
    <Box
      sx={{
        bgcolor: isCollective ? 'collective.dark' : 'white'
      }}
    >
      {widgetList().map(widgetName => prop(widgetName, welcomeWidgets))}
      <WelcomeFooter />
    </Box>
  )
}

export default Referral
