import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { prop } from 'ramda'

const linkStyles = {
  alignItems: 'center',
  color: 'school.primary',
  display: 'flex',
  fontFamily: 'mark-ot',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase'
}

const ContactInfo = ({ ctaLink, ctaText, geoLocatedResource }) => (
  <Card sx={{ maxWidth: 242 }}>
    <CardContent>
      <Box
        sx={{
          display: 'grid',
          gridGap: 8,
          gridTemplateColumns: '24px 1fr',
          justifyContent: 'center'
        }}
      >
        <LinkRoundedIcon sx={{ color: 'school.secondary' }} />
        <Typography
          component={Link}
          sx={{ ...linkStyles }}
          target="_blank"
          to={ctaLink}
        >
          {ctaText}
        </Typography>
        <PhoneEnabledRoundedIcon
          sx={{ color: 'school.secondary', transform: 'rotate(90deg)' }}
        />
        <Typography
          component={Link}
          sx={{ ...linkStyles }}
          to={`tel:${prop('phone_number', geoLocatedResource)}`}
        >
          {prop('phone_number', geoLocatedResource)}
        </Typography>
        <LocationOnRoundedIcon sx={{ color: 'school.secondary' }} />
        <Typography
          component={Link}
          sx={{ ...linkStyles }}
          target="_blank"
          to={prop('directions_link', geoLocatedResource)}
        >
          <FormattedMessage defaultMessage="Get Directions" id="Sj/uz4" />
        </Typography>
      </Box>
    </CardContent>
  </Card>
)

ContactInfo.propTypes = {
  ctaLink: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  geoLocatedResource: PropTypes.shape({
    directions_link: PropTypes.string,
    phone_number: PropTypes.string
  }).isRequired
}

export default ContactInfo
