/* eslint-disable sort-keys */
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'

const emojiButtonStyle = {
  bgcolor: 'white',
  boxShadow: 2,
  fontSize: 16.667,
  letterSpacing: '0.25px',
  lineHeight: '23.33px',
  py: 1
}

const UserFeedback = ({
  hasNeutral = false,
  negativeTitle,
  positiveTitle,
  subtitle
}) => {
  const { formatMessage } = useIntl()
  const { sendEvent } = useAnalytics()

  const [hasInteracted, setHasInteracted] = useState(false)
  const [rating, setRating] = useState('')

  const handleClick = rating => {
    setHasInteracted(true)
    sendEvent('User_feedback', {
      feedback_rating: rating,
      path: window.location.pathname
    })
  }

  return (
    <Stack sx={{ my: 12 }}>
      {hasInteracted ? (
        <Stack alignItems="center" spacing={1}>
          <Typography fontFamily="mark-ot-bold" variant="body2">
            {equals('negative', rating) ? negativeTitle : positiveTitle}
          </Typography>
          <Box textAlign="center">
            <Typography display="inline" variant="body2">
              {equals('negative', rating) ? (
                <FormattedMessage
                  defaultMessage="We always strive to improve. If you want to provide more feedback for our team, visit "
                  id="KQNkwM"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="If you have other thoughts or suggestions for our team, you can provide them via "
                  id="x1RCBR"
                />
              )}
            </Typography>
            <Typography
              component={Link}
              display="inline"
              sx={{
                color: 'primary.main',
                ml: 0.5,
                textDecoration: 'underline'
              }}
              to="/feedback"
              variant="body2"
            >
              <FormattedMessage defaultMessage="this form." id="2t0jus" />
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={3}>
          <Stack alignItems="center" spacing={1}>
            <Typography fontFamily="mark-ot-bold" variant="body2">
              <FormattedMessage defaultMessage="Let us know..." id="oqNR6o" />
            </Typography>
            <Typography textAlign="center" variant="body2">
              {subtitle}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={{ xs: 3, sm: 8 }}>
            <Stack alignItems="center" spacing={1}>
              <Button
                aria-label={formatMessage({
                  defaultMessage: 'negative',
                  id: 'h8bqAX'
                })}
                onClick={() => {
                  handleClick('Negative')
                  setRating('negative')
                }}
                sx={{ ...emojiButtonStyle }}
                variant="text"
              >
                👎
              </Button>
              <Typography
                sx={{ display: { xs: 'none', sm: 'block' } }}
                variant="body2"
              >
                <FormattedMessage defaultMessage="No" id="oUWADl" />
              </Typography>
            </Stack>
            {hasNeutral && (
              <Stack alignItems="center" spacing={1}>
                <Button
                  aria-label={formatMessage({
                    defaultMessage: 'neutral',
                    id: 'T66n2L'
                  })}
                  onClick={() => {
                    handleClick('Neutral')
                    setRating('neutral')
                  }}
                  sx={{ ...emojiButtonStyle }}
                  variant="text"
                >
                  😐
                </Button>
                <Typography
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  variant="body2"
                >
                  <FormattedMessage defaultMessage="Sort Of" id="psiRox" />
                </Typography>
              </Stack>
            )}
            <Stack alignItems="center" spacing={1}>
              <Button
                aria-label={formatMessage({
                  defaultMessage: 'positive',
                  id: 'w3uBGr'
                })}
                onClick={() => {
                  handleClick('Positive')
                  setRating('positive')
                }}
                sx={{ ...emojiButtonStyle }}
                variant="text"
              >
                👍
              </Button>
              <Typography
                sx={{ display: { xs: 'none', sm: 'block' } }}
                variant="body2"
              >
                <FormattedMessage defaultMessage="Yes" id="a5msuh" />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

UserFeedback.propTypes = {
  hasNeutral: PropTypes.bool,
  negativeTitle: PropTypes.node.isRequired,
  positiveTitle: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired
}

export default UserFeedback
