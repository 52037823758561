import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@mui/material'
import { append, equals, isNil, prop } from 'ramda'

const RadioField = ({
  disabled = false,
  options,
  onChange,
  userAnswers,
  isMulti = false,
}) => {
  const handleChange = answerId => {
    // if we aren't dealing with a multi select we can
    // just fire the debounced onChange with the updated
    // answerId
    if (!isMulti) {
      onChange([answerId])
      return
    }

    const existingItem = userAnswers.find(equals(answerId))

    if (isNil(existingItem)) {
      onChange(append(answerId, userAnswers))
    } else {
      const tempAnswers = [...userAnswers]
      const index = tempAnswers.indexOf(existingItem)
      if (index > -1) {
        // only splice array when item is found
        tempAnswers.splice(index, 1) // 2nd parameter means remove one item only
      }
      onChange(tempAnswers)
    }
  }

  return isMulti ? (
    <FormGroup
      sx={{
        display: 'grid',
        gridGap: 8,
        // eslint-disable-next-line sort-keys
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
      }}
    >
      {options.map(({ label, value }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={userAnswers.some(equals(value))}
              color="info"
              disabled={disabled}
              onChange={() => handleChange(value)}
            />
          }
          key={value}
          label={label}
        />
      ))}
    </FormGroup>
  ) : (
    <FormControl fullWidth>
      <RadioGroup
        onChange={handleChange}
        sx={{
          display: 'grid',
          gridGap: 8,
          // eslint-disable-next-line sort-keys
          gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
        }}
        value={prop(0, userAnswers)}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            control={<Radio color="info" />}
            key={label}
            label={label}
            value={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

RadioField.propTypes = {
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired,
}

export default RadioField
