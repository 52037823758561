import { useEffect, useState } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { FormattedMessage } from 'react-intl'
import {
  prop,
  concat,
  equals,
  or,
  not,
  lt,
  cond,
  always,
  evolve,
  propEq,
  slice,
  gt,
  length,
  isNil,
  inc
} from 'ramda'
import PropTypes from 'prop-types'
import { useCurrentDomain } from 'hooks'
import {
  TYPE_CAMPUS_GOAL,
  TYPE_GEO_RESOURCE,
  TYPE_LIFE_LESSON,
  TYPE_TIP
} from 'utils/card-functions'
import { mapFiltersToQueryParams } from 'utils/helpers'
import { useGetPublicCardsQuery } from 'api/publicApi'
import { useSelector } from 'react-redux'

import MasonryWrapper from 'components/common/MasonryWrapper'
import ResourceCard from '../../components/cards/ResourceCard'
import CTACard from '../../components/cards/CTACard'
import SortMenu from '../../components/SortMenu'
import StoryCard from '../../components/cards/StoryCard'
import LoadMoreButton from 'components/common/LoadMoreButton'

const ResourceBlock = ({
  campus_cards = null,
  expert_cards = null,
  frontend_name,
  id
}) => {
  const { enable_geolocated_resources, hide_resources, letters } = useSelector(
    prop('school')
  )
  const { currentDomain } = useCurrentDomain()
  // const { locale } = useRouter()

  const [filter, setFilter] = useState(0)
  const [page, setPage] = useState(1)
  const [cardParams, setCardParams] = useState({
    campus_resource: 0,
    subdomains: id,
    types: null
  })

  const {
    data: cards = [],
    fulfilledTimeStamp: hasFetchedCards,
    isFetching,
    refetch
  } = useGetPublicCardsQuery(
    concat(
      `org_domain=${currentDomain}&page[take]=${page * 5}&`,
      mapFiltersToQueryParams(cardParams)
    )
  )

  useEffect(() => {
    // If the fulfilled timestamp does exist and the locale is changed
    // refetch the card data so we can see translated content
    if (not(isNil(hasFetchedCards))) {
      refetch()
    }
    // }, [locale])
  }, [])

  const buildFilterOption = (label, value, isHidden = false) => ({
    isHidden,
    label,
    value
  })

  const cardFilters = [
    buildFilterOption(
      `${letters} Resources`,
      2,
      or(hide_resources, equals(0, campus_cards))
    ),
    buildFilterOption('Expert Info', 3, lt(expert_cards, 0)),
    buildFilterOption('Near me', 5, not(enable_geolocated_resources)),
    buildFilterOption('Stories', 1)
  ]

  useEffect(() => {
    const fetchObj = cond([
      [
        equals(0),
        always(
          evolve(
            { campus_resource: always(0), types: always(null) },
            cardParams
          )
        )
      ],
      [
        equals(1),
        always(
          evolve(
            { campus_resource: always(0), types: always(TYPE_TIP) },
            cardParams
          )
        )
      ],
      [
        equals(2),
        always(
          evolve(
            { campus_resource: always(1), types: always(null) },
            cardParams
          )
        )
      ],
      [
        equals(3),
        always(
          evolve(
            {
              campus_resource: always(0),
              types: always(TYPE_LIFE_LESSON)
            },
            cardParams
          )
        )
      ],
      [
        equals(4),
        always(
          evolve(
            {
              campus_resource: always(0),
              types: always(TYPE_CAMPUS_GOAL)
            },
            cardParams
          )
        )
      ],
      [
        equals(5),
        always(
          evolve(
            {
              campus_resource: always(0),
              types: always(TYPE_GEO_RESOURCE)
            },
            cardParams
          )
        )
      ]
    ])
    setCardParams(fetchObj(filter))
  }, [filter])

  // This is to render the diff card types
  // if we add new app types to OFD then
  // this block will need to change
  const renderCard = card =>
    equals(prop('type', card), 12) ? (
      <StoryCard blockTitle={frontend_name} key={prop('id', card)} {...card} />
    ) : (
      <ResourceCard
        blockTitle={frontend_name}
        key={prop('id', card)}
        {...card}
      />
    )

  return (
    <Stack sx={{ mb: 6 }}>
      <Stack direction="row" spacing={1}>
        <TrendingUpIcon />
        <Stack direction="row" spacing={0.5}>
          <Typography variant="subtitle1">
            <FormattedMessage defaultMessage="Explore" id="7JlauX" />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="{frontend_name}"
              id="4FbRoq"
              values={{
                frontend_name
              }}
            />
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <SortMenu
        filter={filter}
        options={concat(
          [{ isHidden: false, label: 'All', value: 0 }],
          cardFilters
        ).filter(propEq('isHidden', false))}
        setFilter={setFilter}
      />
      <Stack spacing={3} sx={{ mt: 2 }}>
        <MasonryWrapper>
          {slice(0, 2, cards).map(renderCard)}
          <Box sx={{ mb: 2, mt: -3 }}>
            <CTACard />
          </Box>
          {slice(2, Infinity, cards).map(renderCard)}
        </MasonryWrapper>
        {not(gt(length(cards) % 5, 0)) && (
          <LoadMoreButton
            handleClick={() => setPage(inc(page))}
            loading={isFetching}
          />
        )}
      </Stack>
    </Stack>
  )
}

ResourceBlock.propTypes = {
  campus_cards: PropTypes.number,
  expert_cards: PropTypes.number,
  frontend_name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}

export default ResourceBlock
