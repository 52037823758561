import PropTypes from 'prop-types'
import { gte, length } from 'ramda'
import ProfileQuestion from './ProfileQuestion'

function getUserAnswersForQuestion(question, userAnswers) {
  const answer =
    userAnswers &&
    userAnswers.find(answer => answer.profile_question_id === question.id)
  // user's answer can be 'multi answer'.. so a list is returned
  return answer ? answer.answers : []
}

function ProfileQuestions({ userAnswers = [], questions = [] }) {
  const formattedQuestions = questions?.map(question => [
    question,
    getUserAnswersForQuestion(question, userAnswers),
  ])

  return (
    <>
      {gte(length(formattedQuestions), 0) &&
        formattedQuestions.map(([question, userAnswers]) => (
          <ProfileQuestion
            key={question.id}
            question={question}
            userAnswers={userAnswers}
          />
        ))}
    </>
  )
}

ProfileQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  userAnswers: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ProfileQuestions
