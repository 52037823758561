import PropTypes from 'prop-types'
import BusinessCards from './BusinessCards'
import MHCDCards from './MHCDCards'
import NLCCards from './NLCCards'
import useCurrentAppType from 'hooks/useCurrentAppType'

const Cards = ({ role }) => {
  const { isBusiness, isNLC } = useCurrentAppType()

  if (isBusiness) return <BusinessCards role={role} />
  if (isNLC) return <NLCCards role={role} />
  return <MHCDCards role={role} />
}

Cards.propTypes = {
  role: PropTypes.string.isRequired
}
export default Cards
