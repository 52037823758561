import PropTypes from 'prop-types'
import { svg_get_started_completed } from './getStartedIcons'
import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import { useIntl } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'

const GetStartedItem = ({ icon, text, time, link, checked }) => {
  const intl = useIntl()
  const { sendEvent } = useAnalytics()

  const navigateToRoute = () =>
    sendEvent('block_interactions', {
      block_title: 'Get Started Block',
      click_element: 'Link',
      element_title: text,
      path: window.location.pathname
    })

  return (
    <Card
      aria-label={`${
        checked
          ? intl.formatMessage({
              defaultMessage: 'Completed',
              id: '95stPq'
            })
          : intl.formatMessage({
              defaultMessage: 'Incompleted',
              id: 'Et3vz2'
            })
      } ${text}`}
      sx={{
        borderTop: '4px solid',
        borderTopColor: 'school.accent'
      }}
    >
      <CardContent
        aria-label={`${
          checked
            ? intl.formatMessage({
                defaultMessage: 'Completed',
                id: '95stPq'
              })
            : intl.formatMessage({
                defaultMessage: 'Incompleted',
                id: 'Et3vz2'
              })
        } ${text}`}
        component={Link}
        onClick={navigateToRoute}
        to={link}
      >
        <Stack
          spacing={1.5}
          sx={{
            alignItems: 'center',
            px: 1
          }}
        >
          <Box
            alignItems="center"
            bgcolor="school.highlight"
            borderRadius="50%"
            display="flex"
            height={45}
            justifyContent="center"
            position="relative"
            width={45}
          >
            <Box
              alignItems="center"
              display="flex"
              height={60}
              justifyContent="center"
              position="relative"
              width={60}
            >
              {checked ? svg_get_started_completed : icon}
            </Box>
          </Box>

          <Typography
            color={checked ? 'primary.dark' : 'primary.main'}
            sx={{
              textDecoration: checked ? 'line-through' : ''
            }}
            textAlign="center"
            variant="body1"
          >
            {text}
          </Typography>

          <Chip icon={<WatchLaterRoundedIcon />} label={time} />
        </Stack>
      </CardContent>
    </Card>
  )
}

GetStartedItem.propTypes = {
  checked: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
}

GetStartedItem.defaultProps = {
  checked: false
}

export default GetStartedItem
