import GA from 'services/gaService'
import PropTypes from 'prop-types'
import { Alert, Fade, IconButton, Typography } from '@mui/material'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import useAnalytics from 'hooks/useAnalytics'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useState } from 'react'

const ShareButton = ({ params, title }) => {
  const { sendEvent } = useAnalytics()
  const { formatMessage } = useIntl()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => setOpen(false), 2500)
  }, [open])

  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(window.location)
    GA.track('Share-Card', 'Click')
    sendEvent('card_detail_view_share_card', { ...params })
  }

  return (
    <>
      <IconButton
        aria-label={formatMessage(
          {
            defaultMessage: 'Share, {title}',
            id: 'm72JRI'
          },
          {
            title
          }
        )}
        onClick={handleClick}
      >
        <ShareRoundedIcon color="primary.dark" />
      </IconButton>
      {open && (
        <Fade in sx={{ position: 'absolute', right: 16, top: 8, zIndex: 1 }}>
          <Alert
            elevation={2}
            severity="info"
            sx={{ alignItems: 'center', width: 300 }}
            variant="outlined"
          >
            <Typography variant="legal">
              <FormattedMessage
                defaultMessage="Copied link to clipboard!"
                id="rGf6cq"
              />
            </Typography>
          </Alert>
        </Fade>
      )}
    </>
  )
}

ShareButton.propTypes = {
  params: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired
}

export default ShareButton
