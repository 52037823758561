import {
  Box,
  Container,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import { find, findIndex, inc, isNil, length, not, prop, propEq } from 'ramda'
import { useParams } from 'react-router-dom'
import useGetSteps from './useGetSteps'

// Expects all text props to be pre-formatted with react-intl
const StepHeading = () => {
  const { step } = useParams()
  const stepsToRender = useGetSteps()

  const currStep = inc(findIndex(propEq(step, 'url'), stepsToRender))
  const foundStep = find(propEq(step, 'url'), stepsToRender)
  const image = prop('image', foundStep)
  const emoji = prop('emoji', foundStep)
  const subtitle = prop('subtitle', foundStep)

  return (
    <Container maxWidth="sm">
      <Stack alignItems="center" spacing={2} sx={{ width: 1 }}>
        <Typography textAlign="center" variant="overline">
          Step {currStep}: {prop('title', foundStep)}
        </Typography>
        <LinearProgress
          color="inherit"
          sx={{
            color: 'school.secondary',
            width: 1
          }}
          value={(currStep / length(stepsToRender)) * 100}
          variant="determinate"
        />
        {emoji && (
          <Typography fontSize="34px" textAlign="center">
            {emoji}
          </Typography>
        )}
        {not(isNil(image)) && (
          <Box component="img" maxWidth="100%" src={image} />
        )}
        {subtitle && (
          <Typography textAlign="center" variant="body2">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Container>
  )
}

export default StepHeading
