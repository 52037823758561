import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import {
  and,
  compose,
  defaultTo,
  equals,
  isNil,
  not,
  nthArg,
  prop,
  propEq,
  tap
} from 'ramda'
import AddHomeIcon from '@mui/icons-material/AddHome'
import { useUpdateProfileMutation } from 'api/profileApi'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'

const LocationSelector = () => {
  const dispatch = useDispatch()
  const { locations } = useSelector(prop('school'))
  const { location_id } = useSelector(prop('user'))

  const [selected, setSelected] = useState(null)

  const options = locations.map(({ id, name }) => ({
    label: name,
    value: id
  }))

  useEffect(() => {
    const found = options.find(propEq('value', location_id))
    if (not(isNil(found))) {
      setSelected(found)
    }
  }, [location_id])

  const [updateProfile] = useUpdateProfileMutation()

  const handleChange = ({ value }) =>
    dispatch(updateUserData({ name: 'temp_location_id', value }))

  return (
    <Stack alignItems="center" spacing={1} sx={{ mb: 2 }}>
      <Autocomplete
        getOptionLabel={prop('label')}
        isOptionEqualToValue={(option, value) =>
          equals(prop('value', option), prop('value', value))
        }
        // Remember read RTL or in this case Bottom Up
        onChange={compose(
          handleChange,
          tap(setSelected), // set location > { value, label }
          nthArg(1) // second argument "value" :: obj :: { value, label }
        )}
        options={defaultTo([], options)}
        renderInput={params => (
          <TextField
            {...params}
            label={<FormattedMessage defaultMessage="County" id="B+KJhc" />}
          />
        )}
        sx={{ width: 375 }}
        value={selected}
      />
      {and(
        not(isNil(selected)),
        not(equals(prop('value', selected), location_id))
      ) && (
        <Button
          fullWidth
          onClick={() => {
            const body = new FormData()
            body.append('location_id', prop('value', selected))
            updateProfile(body)
            dispatch(updateUserData({ name: 'temp_location_id', value: null }))
          }}
          startIcon={<AddHomeIcon />}
          variant="text"
        >
          <FormattedMessage defaultMessage="Save as home county" id="r2nBwC" />
        </Button>
      )}
    </Stack>
  )
}

export default LocationSelector
