import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  and,
  not,
  isNil,
  assoc,
  dissoc,
  equals,
  gt,
  inc,
  length,
  prop
} from 'ramda'
import { succeedThriveMatterFrontendUrlSlugs } from 'utils/domain-functions'
import { Box, Typography } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import { useGetUserDomainsStudentContentQuery } from 'api/userApi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PreparedCard from 'components/common/PreparedCardConnector'
import LoadMoreButton from 'components/common/LoadMoreButton'
import MasonryWrapper from 'components/common/MasonryWrapper'
import FilterSortMenu from 'components/common/FilterSortMenu'
import CardAnimationWrapper from 'components/common/CardAnimationWrapper'

const StudentContent = () => {
  const navigate = useNavigate()
  const { is_secondary_user } = useSelector(prop('user'))
  const [domainId, setDomainId] = useState(0)

  const [page, setPage] = useState(1)
  const [cardParams, setCardParams] = useState({
    skip: 0,
    take: page * 12,
    toggle_primary: true
  })

  const { data = [], isFetching } = useGetCardsQuery(cardParams)

  const { data: studentContent } = useGetUserDomainsStudentContentQuery()

  if (isNil(studentContent)) return null

  const { succeed, thrive, matter } =
    succeedThriveMatterFrontendUrlSlugs(studentContent)

  const paragraphStyles = {
    m: '0px auto 20px auto',
    maxWidth: '523px',
    textAlign: 'center'
  }

  // Bail is user isn't faculty (secondary user)
  // also have to check if it's not nil due to a race condition
  if (not(is_secondary_user)) {
    navigate('/404')
    return
  }

  const getFilterOptions = domain => {
    return {
      id: domain.id,
      name: `${domain.frontend_name} / ${domain.subtitle}`
    }
  }

  const filterOptions = [
    getFilterOptions(succeed),
    getFilterOptions(thrive),
    getFilterOptions(matter)
  ]

  return (
    <Box mx="1%">
      <Typography
        className="student-content-category-title"
        m="40px auto"
        textAlign="center"
        variant="h1"
      >
        <FormattedMessage defaultMessage="YOU for Students" id="zAMjPN" />
      </Typography>
      <Typography className="category-paragraph" {...paragraphStyles}>
        <FormattedMessage
          defaultMessage="Explore the YOU resources available to students to help you in your role supporting them. Browse, share, and repeat."
          id="qzlaNy"
        />
      </Typography>

      <Box sx={{ width: 'calc(98% - 6px)' }}>
        <FilterSortMenu
          activeId={domainId}
          handleChange={id => {
            setDomainId(id)
            // if we are in the 'all' filter, we need to remove domain id
            // from the card params. all is always id == 0
            if (id > 0) {
              setCardParams(assoc('take', 12, assoc('domain', id, cardParams)))
            } else {
              setCardParams(assoc('take', 12, dissoc('domain', cardParams)))
            }
            setPage(1)
          }}
          options={filterOptions}
        />
      </Box>

      <MasonryWrapper>
        {data.map((card, i) => (
          <CardAnimationWrapper key={i}>
            <PreparedCard card={card} />
          </CardAnimationWrapper>
        ))}
      </MasonryWrapper>

      {and(equals(length(data), 12 * page), gt(length(data), 0)) && (
        <LoadMoreButton
          handleClick={() => {
            setCardParams(assoc('take', inc(page) * 12, cardParams))
            setPage(inc(page))
          }}
          loading={isFetching}
        />
      )}
    </Box>
  )
}

export default StudentContent
