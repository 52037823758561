/* eslint-disable quotes */

import { FormattedMessage } from 'react-intl'

export const schoolQuestionProps = {
  answers: [
    'No stress detected here',
    'I only feel stressed in specific Situations (parties, exams, work)',
    "I'm feeling stressed more than I would like",
    "I'm stressed every day, and it interferes with my life",
  ],
  domain: 'succeed',
  groupId: 11,
  id: 1,
  prompt: 'In the past month, how would you categorize your stress level?',
  updateQuestion: () => undefined,
}

export const businessQuestionProps = {
  answers: [
    "No, I'm totally overwhelmed.",
    'Not really.',
    'For the most part.',
    'Got it in check.',
  ],
  domain: 'succeed',
  groupId: 11,
  id: 1,
  prompt: 'Do you feel in control of your work-life balance?',
  updateQuestion: () => undefined,
}

export const vetaranQuestionProps = {
  answers: [
    'Affirmative. I use my leadership skills and MOS in my everyday work.',
    "I have a good handle on how to use my skills from the military, but don't do it all the time.",
    'I have some ideas, but have not put too much thought into it. .',
    "Honestly, I've never thought about how my MOS and military experience can fit in a civilian job.",
  ],
  domain: 'succeed',
  groupId: 11,
  id: 1,
  prompt:
    'Do you apply your skills from your military experience in the workforce?',
  updateQuestion: () => undefined,
}

export const responderQuestionProps = {
  answers: [
    'Got it in check',
    'For the most part',
    'Not really',
    'No, I’m totally overwhelmed',
  ],
  domain: 'succeed',
  groupId: 11,
  id: 1,
  prompt: 'Do you feel in control of your work-life balance?',
  updateQuestion: () => undefined,
}

export const mhcdQuestionProps = {
  answers: [
    <FormattedMessage
      defaultMessage="No, I’m totally overwhelmed"
      id="9yk2xA"
      key={0}
    />,
    <FormattedMessage defaultMessage="Not really" id="VvbtiP" key={1} />,
    <FormattedMessage defaultMessage="For the most part" id="MBLT9Q" key={2} />,
    <FormattedMessage defaultMessage="Got it in check" id="e6PjoB" key={3} />,
  ],
  domain: <FormattedMessage defaultMessage="succeed" id="dspzB3" />,
  groupId: 11,
  id: 1,
  prompt: (
    <FormattedMessage
      defaultMessage="Do you feel in control of your work-life balance?"
      id="IcebHH"
    />
  ),
  updateQuestion: () => undefined,
}

export const collectiveQuestionProps = {
  answers: [
    <FormattedMessage
      defaultMessage="No stress detected here."
      id="G0weoN"
      key={0}
    />,
    <FormattedMessage
      defaultMessage="I only feel stressed in specific situations (work presentations, large groups, etc)."
      id="i09yrd"
      key={1}
    />,
    <FormattedMessage
      defaultMessage="I'm feeling stressed more than I would like."
      id="hVyTsH"
      key={2}
    />,
    <FormattedMessage
      defaultMessage="I'm stressed every day, and it interferes with my life."
      id="bGRTPU"
      key={3}
    />,
  ],
  domain: <FormattedMessage defaultMessage="thrive" id="weEbvM" />,
  groupId: 11,
  id: 1,
  prompt: (
    <FormattedMessage
      defaultMessage="In the past month, how would you categorize your stress level?"
      id="OPeAnM"
    />
  ),
  updateQuestion: () => undefined,
}
