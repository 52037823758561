import PropTypes from 'prop-types'
import BackgroundImage from 'components/common/BackgroundImage'
import { isNil, not } from 'ramda'
import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const ArticleContentSource = ({ articleImage, articleText, link }) => (
  <Box component={not(isNil(link)) ? Link : 'div'} href={link} target="_blank">
    <Stack direction="row" justifyContent="center">
      {not(isNil(articleImage)) && <BackgroundImage imageUrl={articleImage} />}
      <Typography variant="contentSource">{articleText}</Typography>
    </Stack>
  </Box>
)

ArticleContentSource.propTypes = {
  articleImage: PropTypes.string,
  articleText: PropTypes.string.isRequired,
  link: PropTypes.string
}

ArticleContentSource.defaultProps = {
  articleImage: null,
  link: null
}

export default ArticleContentSource
