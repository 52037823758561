/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { svg_goals } from 'icons'
import ScreenReaderMessage from 'components/common/ScreenReaderMessage'
import {
  always,
  assoc,
  assocPath,
  concat,
  evolve,
  gt,
  isNil,
  length,
  not,
  path,
  prop,
  sortBy,
  split
} from 'ramda'
import GA from 'services/gaService'
import {
  useDeleteGoalMutation,
  useGetGoalQuery,
  useUpdateGoalMutation
} from 'api/goalsApi'
import {
  Box,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  Card,
  CardContent,
  Typography
} from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate, useParams } from 'react-router-dom'

const EditGoal = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { formatMessage } = useIntl()

  const { data: goal } = useGetGoalQuery(id)
  const [goalToUpdate, setGoalToUpdate] = useState(null)

  const [updateGoal] = useUpdateGoalMutation()
  const [deleteGoal] = useDeleteGoalMutation()

  useEffect(() => {
    if (not(isNil(goal))) {
      setGoalToUpdate(goal)
    }
  }, [goal])

  const onSubmit = () => {
    // db expects a string in utc time (we do time conversions on the client side). So we convert here since it will be
    // saved directly onto the model by the endpoint. We send a blank string if there isn't a dueDate. The endpoint doesn't
    // handle a null case.
    updateGoal({
      body: evolve(
        {
          due_date: prop('due_date', goalToUpdate)
            ? always(
                prop(
                  0,
                  split(
                    'T',
                    new Date(prop('due_date', goalToUpdate)).toISOString()
                  )
                )
              )
            : always(null),
          steps: always(sortBy(prop('order'))(prop('steps', goalToUpdate)))
        },
        goalToUpdate
      ),
      id: prop('id', goalToUpdate)
    })
    GA.track('Update-Goal', 'Submit')
    navigate(`/goals/view/${prop('id', goal)}`)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{
        mb: { xs: 2, sm: 3 },
        mx: { xs: 2, sm: 3 }
      }}
    >
      <Typography mb={2} textAlign="center" variant="h1">
        <FormattedMessage defaultMessage="Edit Goal" id="sOXElg" />
      </Typography>
      <Card
        component="form"
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <CardContent>
          <Box
            sx={{
              '& > svg': {
                fill: 'red',
                height: 40,
                width: 40
              },
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {svg_goals}
          </Box>
          <ScreenReaderMessage>
            <FormattedMessage defaultMessage="Edit Goal" id="sOXElg" />
          </ScreenReaderMessage>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 16,
              mt: 2
            }}
          >
            <FormControl variant="outlined">
              <Typography variant="subtitle1">
                <FormattedMessage defaultMessage="Goal Title" id="zinMAj" />
              </Typography>
              <TextField
                onChange={e =>
                  setGoalToUpdate(
                    assoc('title', path(['target', 'value'], e), goalToUpdate)
                  )
                }
                value={goalToUpdate?.title}
              />
            </FormControl>
            <FormControl>
              <Typography variant="subtitle1">
                <FormattedMessage defaultMessage="Due On" id="pyS3VH" />
              </Typography>
              <DatePicker
                disablePast
                minDate={new Date()}
                onChange={val =>
                  setGoalToUpdate(assoc('due_date', val, goalToUpdate))
                }
                renderInput={params => <TextField {...params} />}
                value={
                  prop('due_date', goalToUpdate)
                    ? new Date(prop('due_date', goalToUpdate))
                    : ''
                }
              />
            </FormControl>
            {not(isNil(goalToUpdate)) &&
              sortBy(prop('order'))(prop('steps', goalToUpdate)).map(
                (step, index) => (
                  <FormControl key={index} variant="outlined">
                    <Typography variant="subtitle1">
                      <FormattedMessage
                        defaultMessage="Step {step}"
                        id="EJ8mg/"
                        values={{ step: index + 1 }}
                      />
                    </Typography>
                    <TextField
                      InputProps={{
                        endAdornment: gt(index, 0) && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={formatMessage({
                                defaultMessage: 'Delete',
                                id: 'K3r6DQ'
                              })}
                              onClick={() =>
                                setGoalToUpdate(
                                  assoc(
                                    'steps',
                                    prop('steps', goalToUpdate).filter(
                                      (_, idx) => idx !== index
                                    ),
                                    goal
                                  )
                                )
                              }
                            >
                              <DeleteRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      onChange={e =>
                        setGoalToUpdate(
                          assocPath(
                            ['steps', index, 'name'],
                            path(['target', 'value'], e),
                            goalToUpdate
                          )
                        )
                      }
                      value={step.name}
                    />
                  </FormControl>
                )
              )}
          </Box>
          <Button
            onClick={() => {
              const newSteps = concat(prop('steps', goalToUpdate), [
                {
                  completed: false,
                  id: null,
                  name: '',
                  order: length(prop('steps', goalToUpdate))
                }
              ])
              setGoalToUpdate(assoc('steps', newSteps, goalToUpdate))
            }}
            startIcon={<AddRoundedIcon />}
            variant="tertiary"
          >
            <FormattedMessage defaultMessage="Add your own step" id="Sphc64" />
          </Button>

          <Box sx={{ textAlign: 'center' }}>
            <Button onClick={() => onSubmit()} type="submit">
              <FormattedMessage defaultMessage="Save Goal" id="CxXCmS" />
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2, mx: 'auto' }}>
        <Button
          onClick={() => {
            deleteGoal(goalToUpdate)
            navigate('/goals?active=my-goals')
          }}
          variant="text"
        >
          <FormattedMessage defaultMessage="Delete this goal" id="Vyw42+" />
        </Button>
      </Box>
    </Box>
  )
}

export default EditGoal
