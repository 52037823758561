/* eslint-disable sort-keys */
import { useEffect } from 'react'
import { Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import { not, isNil, equals, lt, any, prop } from 'ramda'
import useWindowResize from 'hooks/useWindowResize'
import PropTypes from 'prop-types'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useSelector } from 'react-redux'

import LoggedInSidebar from './LoggedInSidebar'
import { useLocation } from 'react-router-dom'

const Sidebar = ({ isOpen, setIsOpen }) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const { id } = useSelector(prop('user'))

  const { x } = useWindowResize()
  const { pathname } = useLocation()

  // Close sidebar on window resize
  useEffect(() => {
    if (lt(x, 1023)) setIsOpen(false)
  }, [x])

  //USER
  const isLoggedIn = not(isNil(id))

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback'),
    pathname.includes('public'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('about-you'),
    pathname.includes('logout'),
    pathname.includes('destress'),
    pathname.includes('404'),
    pathname.includes('sso'),
    pathname.includes('email')
  ]

  if (any(equals(true))(anyCheck)) return null

  return (
    <Drawer
      anchor={isDesktop ? 'left' : 'right'}
      component="aside"
      ModalProps={{
        keepMounted: true
      }}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{
        component: 'nav',
        sx: {
          top: { xs: 0, lg: 68 },
          width: 260,
          zIndex: 4
        }
      }}
      sx={{
        width: 260
      }}
      variant={isDesktop ? 'permanent' : 'temporary'}
    >
      <IconButton
        aria-label="Close Menu"
        onClick={() => setIsOpen(false)}
        sx={{
          alignSelf: 'flex-end',
          display: { xs: 'flex', lg: 'none' },
          mr: 1,
          mt: 1
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <Toolbar
        component="nav"
        disableGutters
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 68px)',
          overflowY: 'auto',
          py: { xs: 0, lg: 3 }
        }}
      >
        {isLoggedIn && <LoggedInSidebar />}
      </Toolbar>
    </Drawer>
  )
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default Sidebar
