import { useState } from 'react'
import PreparedCard from 'components/common/PreparedCardConnector'
import { Box } from '@mui/material'
import * as R from 'ramda'
import LoadMoreButton from 'components/common/LoadMoreButton'
import { useGetNewestCardsQuery } from 'api/cardsApi'
import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import MasonryWrapper from 'components/common/MasonryWrapper'

const TrendingBlock = () => {
  const [page, setPage] = useState(1)

  const { data = [], isFetching } = useGetNewestCardsQuery({ take: page * 12 })

  return (
    <Box sx={{ pt: 2.5, width: 1 }}>
      <MasonryWrapper>
        {data.map((card, i) => (
          <CardAnimationWrapper key={i}>
            <PreparedCard blockTitle="New & Trending" card={card} />
          </CardAnimationWrapper>
        ))}
      </MasonryWrapper>
      {R.not(R.equals(R.length(data) % 2, NaN)) && (
        <LoadMoreButton
          handleClick={() => setPage(R.inc(page))}
          loading={isFetching}
        />
      )}
    </Box>
  )
}

export default TrendingBlock
