import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import { not } from 'ramda'

const ErrorMessage = ({ hasError = false, message = null }) => {
  if (not(hasError)) return null

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        alignItems: 'center',
        bgcolor: 'error.light',
        borderRadius: 1,
        mb: 2,
        p: 2,
      }}
    >
      <DoNotDisturbAltRoundedIcon color="error" />
      <Typography color="error" fontFamily="mark-ot-bold" variant="subtitle1">
        {message}
      </Typography>
    </Stack>
  )
}

ErrorMessage.propTypes = {
  hasError: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ErrorMessage
