import { Box, Button, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import WaveWrapper from '../WaveWrapper'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { not } from 'ramda'

const CTA = () => {
  const { isWellpower } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const renderBanner = () =>
    'url(/images/wellpower/explore/wellpower-explore-cta.jpg)'

  const renderHeading = () => (
    <FormattedMessage defaultMessage="Prioritize your well-being" id="nK29Nu" />
  )

  const renderSubtitle = () => (
    <FormattedMessage
      defaultMessage="YOU at Your Best is a free, confidential, and personalized space to navigate life's inherent ups and downs."
      id="rfJilB"
    />
  )

  const renderButtonText = () => (
    <FormattedMessage defaultMessage="sign up" id="yv/kr9" />
  )

  return (
    <WaveWrapper>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 1024,
          pt: 6,
          textAlign: 'center',
          width: 1
        }}
      >
        <Box
          sx={{
            background: renderBanner(),
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: 2,
            height: 313,
            mb: 5.5,
            width: 1
          }}
        />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 24,
            textAlign: 'center'
          }}
        >
          <Typography color="primary.main" variant="h2">
            {renderHeading()}
          </Typography>
          <Typography color="primary.main" variant="body2">
            {renderSubtitle()}
          </Typography>
          {not(isWellpower) && (
            <Box>
              <Typography
                color="primary.main"
                // eslint-disable-next-line sort-keys
                sx={{ display: { xs: 'none', md: 'block' } }}
                variant="caption"
              >
                <FormattedMessage
                  defaultMessage="Creating a free and confidential account gives you access to: Emergency Responder resources near you, tactical performance tips, expert perspectives, and Emergency Responder stories."
                  id="hmvuX/"
                />
              </Typography>
            </Box>
          )}
          <Button
            LinkComponent={Link}
            onClick={() =>
              sendEvent('block_interactions', {
                block_title: 'OFD Explore Prioritize your well-being',
                click_element: 'Link',
                element_title: 'Create Account CTA',
                path: window.location.pathname
              })
            }
            to="/sign-up"
          >
            {renderButtonText()}
          </Button>
        </Box>
      </Box>
    </WaveWrapper>
  )
}

export default CTA
