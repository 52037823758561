import PhoneCardImage from './PhoneCardImage'
import PropTypes from 'prop-types'

export const options = [
  { label: 'Work-Life Balance', value: 'work-life-balance' },
  { label: 'Resilience', value: 'resilience' },
  { label: 'Family & Friends', value: 'family-friends' },
  { label: 'Stress & Anxiety', value: 'stress-anxiety' }
]

const NLCCards = ({ role }) => {
  switch (role) {
    default:
    case options[0].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-nlc/work-life-balance/work-life-balance" />
      )
    case options[1].label:
      return (
        <PhoneCardImage
          numberOfCards={5}
          roleUrlPath="/images/welcome/card-images-nlc/resilience/resilience"
        />
      )
    case options[2].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-nlc/family-friends/family-friends" />
      )
    case options[3].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-nlc/stress-anxiety/stress-anxiety" />
      )
  }
}

NLCCards.propTypes = {
  role: PropTypes.string.isRequired
}
export default NLCCards
