import { useGetUserQuery } from 'api/userApi'
import { useLocalStorage } from 'hooks'
import { any, equals, isNil, not, prop } from 'ramda'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setUserData } from 'store/userSlice'
import { onAuthRoute, onPublicRoute } from 'utils/route-helpers'

const UserLoader = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { get } = useLocalStorage()

  const sessionToken = get()

  const { data, isSuccess } = useGetUserQuery(undefined, {
    skip: any(equals(true))([
      onPublicRoute(pathname),
      onAuthRoute(pathname),
      isNil(sessionToken)
    ])
  })

  useEffect(() => {
    if (not(isNil(data))) {
      dispatch(setUserData({ ...data, isSuccess }))
    }
  }, [data])

  useEffect(() => {
    if (not(isNil(prop('id', data)))) {
      dispatch(setUserData({ name: 'has_been_shown_a_modal', value: false }))
    }
  }, [data])

  return
}

export default UserLoader
