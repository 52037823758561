import {
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { and, dec, equals, join, length, not, prop, split } from 'ramda'

const HeroContent = ({ history, isLoggedIn, title, subtitle }) => {
  const { sendEvent } = useAnalytics()

  const renderBreadcrumb = () => {
    const prevRoute = prop(dec(dec(length(history))), history)
    const converted = () => {
      if (equals(1, length(history))) return 'Home'
      if (equals('/', prevRoute)) return 'Home'
      return join(' ', split('-', prop(1, split('/', prevRoute))))
    }

    return (
      <FormattedMessage
        defaultMessage="{converted}"
        id="RpkzcK"
        values={{
          converted: equals('public', converted()) ? 'Explore' : converted()
        }}
      />
    )
  }

  const renderLink = () => {
    if (and(equals(1, length(history)), isLoggedIn)) return '/home'
    if (and(equals(1, length(history)), not(isLoggedIn))) return '/'
    return prop(dec(dec(length(history))), history)
  }

  return (
    // eslint-disable-next-line sort-keys
    <Container sx={{ pt: { xs: 2, md: 4 }, textAlign: 'center' }}>
      <Stack alignItems="center" spacing={3}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="&#8226;"
          sx={{ alignSelf: 'flex-start' }}
        >
          <Link
            style={{
              color: 'inherit',
              fontSize: 12,
              textDecoration: 'underline',
              textTransform: 'uppercase'
            }}
            to={renderLink()}
          >
            {renderBreadcrumb()}
          </Link>
          <Link
            style={{
              color: 'inherit',
              fontSize: 12,
              textTransform: 'uppercase'
            }}
            to=""
          >
            <FormattedMessage defaultMessage="About" id="g5pX+a" />
          </Link>
          ,
        </Breadcrumbs>
        <Typography color="school.primary" variant="h1">
          <FormattedMessage
            defaultMessage="{title}"
            id="2YgLVn"
            values={{ title }}
          />
        </Typography>
        <Typography variant="body.1">
          <FormattedMessage
            defaultMessage="{subtitle}"
            id="7pSzje"
            values={{ subtitle }}
          />
        </Typography>
        {not(isLoggedIn) && (
          <Button
            LinkComponent={Link}
            onClick={() =>
              sendEvent('block_interactions', {
                block_title: 'About Page Welcome',
                click_element: 'Link',
                element_title: 'Create Account CTA',
                path: window.location.pathname
              })
            }
            to="/sign-in"
          >
            <FormattedMessage
              defaultMessage="create a free account"
              id="lrgM1/"
            />
          </Button>
        )}
      </Stack>
    </Container>
  )
}

HeroContent.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoggedIn: PropTypes.bool,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

HeroContent.defaultProps = {
  isLoggedIn: false
}

export default HeroContent
