import { Box, Button, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useCurrentDomain from 'hooks/useCurrentDomain'
import { prop, length, not } from 'ramda'

import ResourceCard from '../cards/ResourceCard'
import { useGetPublicCardsQuery } from 'api/publicApi'
import { useAnalytics } from 'hooks'

const Resources = () => {
  const { currentDomain } = useCurrentDomain()
  const { sendEvent } = useAnalytics

  const { data, isFetching } = useGetPublicCardsQuery(
    `org_domain=${currentDomain}&filter[types]=${2}&page[take]=${3}`
  )

  const renderHeading = () => (
    <FormattedMessage
      defaultMessage="Wellpower Resources and Support"
      id="pzM4pY"
    />
  )

  const renderSubtitle = () => (
    <FormattedMessage
      defaultMessage="Whatever is on your mind, YOU at Your Best can help."
      id="RDmtmj"
    />
  )

  return (
    // eslint-disable-next-line sort-keys
    <Box sx={{ mb: { xs: 4, md: 8 } }}>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 1024,
          textAlign: 'center',
          width: 1
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 24,
            mb: 7.5,
            textAlign: 'center'
          }}
        >
          <Typography color="primary" variant="h2">
            {renderHeading()}
          </Typography>
          <Typography variant="body1">{renderSubtitle()}</Typography>
          {not(isFetching) && (
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'grid',
                gridGap: 48,
                gridTemplateColumns: {
                  xs: '1fr',
                  // eslint-disable-next-line sort-keys
                  lg: `repeat(${length(data)}, 1fr)`
                }
              }}
            >
              {data.map(card => (
                <ResourceCard key={prop('id', card)} {...card} />
              ))}
            </Box>
          )}
        </Box>
        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'OFD Explore Resources',
              click_element: 'Link',
              element_title: 'See Resources CTA',
              path: window.location.pathname
            })
          }
          to="/public/resources"
        >
          <FormattedMessage defaultMessage="see all resources" id="qDNqW1" />
        </Button>
      </Box>
    </Box>
  )
}

export default Resources
