import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useLocalStorage } from 'hooks'
import { any, equals, isNil, not, prop, reject } from 'ramda'
import { useSelector } from 'react-redux'
import { getSSOBodyCopy } from 'store/authenticationSlice'
import { useSignInFacebookMutation, useSignInGoogleMutation } from 'api/authApi'
import { useNavigate } from 'react-router-dom'

import UserTypeSelect from 'pages/authentication/sign-up/components/UserTypeSelectConnector'
import ActiveConsentForm from 'pages/authentication/sign-up/components/ActiveConsentFormConnector'

const ConsentModal = ({
  consent,
  isOpen,
  location,
  modalType,
  resetState,
  responderType,
  setIsOpen,
  setUserData
}) => {
  const { save } = useLocalStorage()
  const navigate = useNavigate()
  const { domain } = useSelector(prop('school'))

  // Google
  const [signInGoogle] = useSignInGoogleMutation()

  // Facebook
  const [signInFacebook] = useSignInFacebookMutation()

  // Redux Selector
  const body = useSelector(({ authentication }) =>
    getSSOBodyCopy(authentication)
  )

  const handleSubmit = () => {
    const copy = {
      ...reject(isNil, body),
      org_domain: domain
    }
    if (equals(modalType, 'facebook')) {
      return signInFacebook(copy).then(({ data }) => handleSuccess(data))
    }
    signInGoogle(copy).then(({ data }) => handleSuccess(data))
  }

  const handleSuccess = data => {
    save(prop('x-session-id', data))
    setUserData(prop('user', data))
    resetState()
    navigate('/onboarding/introduction')
  }

  return (
    <Dialog
      aria-labelledby="dialog-title"
      onClose={() => {}}
      open={isOpen}
      role="alertdialog"
    >
      <DialogContent sx={{ pt: 2 }}>
        <Stack spacing={2}>
          <Typography
            component="h1"
            id="dialog-title"
            sx={{
              fontFamily: 'mark-ot',
              fontSize: 12,
              fontWeight: 500,
              letterSpacing: 1.5,
              lineHeight: '16px',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
            tabIndex={-1}
          >
            <FormattedMessage
              defaultMessage="Looks like this is your first time logging in"
              id="Pd8B5R"
            />
          </Typography>
          <Typography textAlign="center" variant="body2">
            <FormattedMessage
              defaultMessage="Please answer the following items"
              id="U0LMGK"
            />
          </Typography>
          <UserTypeSelect />
          <ActiveConsentForm />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            setIsOpen(false)
            resetState()
          }}
          variant="outlined"
        >
          <FormattedMessage defaultMessage="Go Back" id="ekfOaV" />
        </Button>
        <Button
          aria-label="Confirm Selections"
          disabled={any(equals(true))([
            isNil(location),
            isNil(responderType),
            not(consent)
          ])}
          onClick={handleSubmit}
        >
          <FormattedMessage defaultMessage="Continue" id="acrOoz" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConsentModal.propTypes = {
  consent: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  location: PropTypes.shape({}),
  modalType: PropTypes.string,
  resetState: PropTypes.func.isRequired,
  responderType: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  userType: PropTypes.string
}

ConsentModal.defaultProps = {
  isOpen: false,
  location: null,
  modalType: 'google',
  responderType: null,
  userType: null
}

export default ConsentModal
