/* eslint-disable quotes */
import { FormattedMessage } from 'react-intl'
import { Button, Container, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { useUpdateOnboardedUserMutation } from 'api/userApi'

const Intro = () => {
  const navigate = useNavigate()
  const { isBusiness, isCollective, isNLC } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const renderMessage = () => {
    if (isBusiness)
      return (
        <FormattedMessage
          defaultMessage="And, life gets complicated! We'll help you find and keep your balance in three key areas so that you can Succeed, Thrive and Matter on and off the job."
          id="mlzhV6"
        />
      )
    if (isNLC)
      return (
        <FormattedMessage
          defaultMessage="Finding and keeping that balance can be complicated with the demands of being an emergency responder. We'll help you focus on three key areas so that you can Succeed, Thrive and Matter."
          id="wWG7L+"
        />
      )
    if (isCollective)
      return (
        <FormattedMessage
          defaultMessage="And, life gets complicated! We'll help you find and keep your balance in three key areas so that you can Succeed, Thrive and Matter."
          id="ZH1Xrf"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Well-being is personal. We get it. Check out this video on how YOU can support your unique experience."
        id="gbkCX1"
      />
    )
  }

  const [updateUser] = useUpdateOnboardedUserMutation()

  const handleSkipAll = () => updateUser(new FormData())

  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      <Stack spacing={3}>
        <Typography
          sx={{ alignSelf: 'center', maxWidth: 515 }}
          textAlign="center"
          variant="body2"
        >
          {renderMessage()}
        </Typography>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center'
          }}
        >
          <Button
            onClick={() => {
              sendEvent('onboarding', {
                answers: 'intro',
                question: {
                  id: 0,
                  name: 'intro'
                },
                question_name: 'INTRO_STEP',
                skip: false
              })
              navigate('/onboarding/interests', undefined, { shallow: true })
            }}
          >
            <FormattedMessage defaultMessage="Get Started" id="9QXm1E" />
          </Button>

          <Button
            LinkComponent={Link}
            onClick={handleSkipAll}
            to="/home"
            variant="text"
          >
            <FormattedMessage defaultMessage="Skip Customization" id="RSh9DE" />
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Intro
