import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { equals, gt } from 'ramda'

const levels = [
  {
    gradient:
      'linear-gradient(90deg, #FF782D 0%, #EFDA1E 192.06%, #BCF73F 359.07%, #53C12C 534.43%)',
    level: 1,
  },
  {
    gradient:
      'linear-gradient(90deg, #F6AB1F 0%, #EFDA1E 54.21%, #D5E92F 99.37%)',
    level: 2,
  },
  {
    gradient:
      'linear-gradient(90deg, #D5E92F 0%, #BCF73F 56.32%, #98E538 100%)',
    level: 3,
  },
  {
    gradient:
      'linear-gradient(90deg, #98E538 0%, #74D232 53.16%, #53C12C 100%)',
    level: 4,
  },
]

const ResultsGauge = ({ score = 0, variant = '' }) => (
  <Box display="flex" width={equals(variant, 'compact') ? 'inherit' : '300px'}>
    {levels.map(({ gradient, level }) => (
      <Box
        alignItems="center"
        bgcolor="primary.light"
        display="flex"
        height={equals(variant, 'compact') ? '24px' : '12px'}
        key={level}
        mx={equals(variant, 'compact') ? '1px' : '4px'}
        width="25%"
      >
        <Box
          sx={{
            background: gt(level, score) ? null : gradient,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderBottomRightRadius: equals(level, score) ? '10px' : null,
            borderTopRightRadius: equals(level, score) ? '10px' : null,
            height: 7,
            width: 1,
          }}
        />
      </Box>
    ))}
  </Box>
)

ResultsGauge.propTypes = {
  score: PropTypes.number,
  variant: PropTypes.string,
}

export default ResultsGauge
