import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { and, any, equals, not } from 'ramda'

const baseStyle = {
  borderLeft: '8px solid transparent',
  borderRadius: 0,
  color: 'secondary.dark',
  fontFamily: 'mark-ot',
  fontSize: 16,
  fontWeight: 500,
  justifyContent: 'flex-start',
  letterSpacing: '0.75px',
  padding: '0 20px 0 16px',
  textDecoration: 'none',
  textTransform: 'none',
  width: 1
}

const activeStyle = {
  ...baseStyle,
  borderLeftColor: 'school.secondary',
  color: 'primary.main',
  fontFamily: 'mark-ot-bold'
}

const SidebarMenuItem = ({
  link,
  icon,
  title,
  onClick = () => {},
  isShown = false,
  target = null
}) => {
  const { pathname } = useLocation()

  const anyCheck = check => pathname.includes(check)

  const activeCheck = () => {
    if (equals(link, pathname)) return true
    if (
      and(
        equals(link, '/explore'),
        any(anyCheck)(['/succeed/', '/matter/', '/thrive/'])
      )
    )
      return true
    if (
      and(
        equals(link, '/goals?active=explore'),
        any(anyCheck)(['/goals?active=explore', '/goals?active=my-goals'])
      )
    )
      return true

    return false
  }

  if (not(isShown)) return null

  return (
    <Button
      aria-current={activeCheck() ? 'page' : ''}
      LinkComponent={Link}
      onClick={onClick}
      startIcon={icon}
      sx={activeCheck() ? activeStyle : baseStyle}
      target={target}
      to={link}
      variant=""
    >
      {title}
    </Button>
  )
}

SidebarMenuItem.propTypes = {
  icon: PropTypes.shape({}).isRequired,
  isShown: PropTypes.bool,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default SidebarMenuItem
