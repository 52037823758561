/* eslint-disable sort-keys */
import components from './components'
import palette from './palette'
import typography from './typography'

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1440,
    },
  },
  components,
  palette,
  spacing: 8,
  typography,
}

export default theme
