import PropTypes from 'prop-types'
import { Box, useMediaQuery } from '@mui/material'
import { isNil, not } from 'ramda'

const BackgroundImage = ({
  imageUrl,
  flagIcon,
  smallFlag,
  width,
  height,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  const borderSize = () => {
    if (smallFlag) return '40px'
    if (isMobile) return '38px'
    return '80px'
  }

  const iconSize = () => {
    if (smallFlag) return '26px'
    if (isMobile) return '24px'
    return '52px'
  }

  const Flag = () => (
    <Box
      data-testid={smallFlag ? 'small-flag' : 'flag'}
      sx={{
        borderBottom: `solid ${borderSize()}`,
        borderBottomColor: 'school.flag',
        borderLeft: `solid ${borderSize()} transparent`,
        borderRight: `solid ${borderSize()}`,
        borderRightColor: 'school.flag',
        borderTop: `solid ${borderSize()} transparent`,
        bottom: 0,
        position: 'absolute',
        right: 0,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${flagIcon})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bgcolor: 'transparent',
          bottom: {
            xs: `calc(-${iconSize()} - 5px)`,
            // eslint-disable-next-line sort-keys
            sm: `calc(-${iconSize()} - 10px)`,
          },
          height: iconSize(),
          maxWidth: 'initial',
          position: 'absolute',
          right: {
            xs: `calc(-${iconSize()} - 5px)`,
            // eslint-disable-next-line sort-keys
            sm: `calc(-${iconSize()} - 10px)`,
          },
          width: iconSize(),
        }}
      />
    </Box>
  )

  return (
    <Box
      data-testid="background-image"
      sx={{
        background: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: props.objectFit ?? 'cover',
        height: height,
        minWidth: '100%',
        position: 'relative',
        pt: '56.25%',
        width: width,
        ...props,
      }}
    >
      {not(isNil(flagIcon)) && <Flag />}
    </Box>
  )
}

BackgroundImage.propTypes = {
  flagIcon: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.number,
  ]),
  imageUrl: PropTypes.string.isRequired,
  objectFit: PropTypes.string,
  smallFlag: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.number,
  ]),
}

BackgroundImage.defaultProps = {
  flagIcon: null,
  height: null,
  objectFit: null,
  smallFlag: false,
  width: null,
}

export default BackgroundImage
