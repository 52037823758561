import { Box, useTheme } from '@mui/material'
import styles from './CompleteGoalAnimation.module.scss'

const Trophy = () => {
  const { palette } = useTheme()

  return (
    // eslint-disable-next-line sort-keys
    <Box className={styles['trophy-wrap']} mt={{ xs: 2, lg: 3 }}>
      <Box className={styles['trophy']}>
        <svg version="1.1" viewBox="0 0 80 87" width="100%">
          <g
            fill="none"
            fillRule="evenodd"
            id="B.-Goals-Update"
            stroke="none"
            strokeWidth="1"
          >
            <g
              fillRule="nonzero"
              id="background"
              transform="translate(-148.000000, -328.000000)"
            >
              <g
                fill={palette.school.primary}
                id="noun_Trophy_420934"
                transform="translate(148.000000, 328.000000)"
              >
                <path
                  d="M64.8,8.3 L64.8,3.5 C64.8,1.84314575 63.4568542,0.5 61.8,0.5 L18.2,0.5 C16.5431458,0.5 15.2,1.84314575 15.2,3.5 L15.2,8.3 L3.2,8.3 C1.54314575,8.3 0.2,9.64314575 0.2,11.3 L0.2,17.3 C0.2,26.6 5.8,34.3 15.2,34.3 L15.5,34.3 C17.1,44.5 24.8,52.6 34.8,54.7 L34.8,59.7 C34.8,61.3568542 33.4568542,62.7 31.8,62.7 L28.7,62.7 C26.8,62.7 25.3,64.2 25.3,66.1 L25.3,70.6 L23.1,70.6 C21.4431458,70.6 20.1,71.9431458 20.1,73.6 L20.1,83.5 C20.1,85.1568542 21.4431458,86.5 23.1,86.5 L56.9,86.5 C58.5568542,86.5 59.9,85.1568542 59.9,83.5 L59.9,73.6 C59.9,71.9431458 58.5568542,70.6 56.9,70.6 L54.7,70.6 L54.7,66.1 C54.7,64.2 53.2,62.7 51.3,62.7 L48.2,62.7 C46.5431458,62.7 45.2,61.3568542 45.2,59.7 L45.2,54.7 C55.2,52.6 62.9,44.4 64.5,34.3 L64.8,34.3 C74.1,34.3 79.8,26.7 79.8,17.3 L79.8,11.3 C79.8,9.64314575 78.4568542,8.3 76.8,8.3 L64.8,8.3 Z M8.8,20.3 L8.8,16.9 L15.2,16.9 L15.2,26.7 C10.6,26.7 8.8,24.9 8.8,20.3 Z M41.4,32.1 L37,36.4 L32.8,32 L28.6,27.8 L33,23.5 L37.2,27.7 L47.2,17.8 L51.5,22.2 L41.4,32.1 Z M71.2,20.3 C71.2,24.9 69.4,26.7 64.8,26.7 L64.8,16.9 L71.2,16.9 L71.2,20.3 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
      </Box>
      <div className={styles['dots']}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className={styles['squiggles']}>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
      </div>
    </Box>
  )
}

export default Trophy
