/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { prop } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NodChallengeArticle = ({ article }) => {
  // found this in the old code. we hard coded the link i guess
  const nodAppDownloadLink = 'https://nodapp.app.link/NsNjOKrF3Z'
  const challenge = prop('challenge', article)

  return (
    <Stack
      spacing={4}
      sx={{
        mt: 2,
        pb: 8,
        px: { xs: 2, md: 0 },
        width: { xs: 1, md: '50%' }
      }}
    >
      <Box
        sx={{
          bgcolor: `${prop('theme_name', challenge)}.color`,
          height: 290,
          mx: 'auto',
          position: 'relative',
          width: 1
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${prop('image_url', challenge)})`,
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            bgcolor: `${prop('theme_name', challenge)}.accent`,
            borderRadius: '50%',
            height: 290,
            left: 'calc(50% - 145px)',
            position: 'absolute',
            width: 290
          }}
        />
        <Box
          sx={{
            borderBottom: 'solid 50px',
            borderBottomColor: 'school.flag',
            borderLeft: 'solid 50px transparent',
            borderRight: 'solid 50px',
            borderRightColor: 'school.flag',
            borderTop: 'solid 50px transparent',
            bottom: 0,
            position: 'absolute',
            right: 0
          }}
        >
          <Box
            sx={{
              background: 'url(/images/icons/nod-logo.svg)',
              backgroundColor: 'transparent',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: 42,
              position: 'absolute',
              width: 42
            }}
          />
        </Box>
      </Box>
      <Typography component="div" textAlign="center" variant="body1">
        {parse(prop('main_content', challenge))}
      </Typography>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
          px: 2
        }}
      >
        <Typography
          borderBottom="2px solid"
          borderColor="white"
          color="#333333"
          component={Link}
          fontFamily="mark-ot-bold"
          fontSize="24px"
          target="_blank"
          to={nodAppDownloadLink}
        >
          <FormattedMessage
            defaultMessage="Download Nod to get Started"
            id="zsqZd0"
          />
        </Typography>
        <Typography textAlign="center" variant="body2">
          <FormattedMessage
            defaultMessage="Nod is an app designed to help you build strong social connections and get more out of your college experience."
            id="eBxW1s"
          />
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Button
            component={Link}
            target="_blank"
            to={nodAppDownloadLink}
            variant="text"
          >
            <Box
              alt=""
              component="img"
              height={45}
              src="/images/icons/Download-iOS.png"
              width={140}
            />
          </Button>
          <Button
            component={Link}
            target="_blank"
            to={nodAppDownloadLink}
            variant="text"
          >
            <Box
              alt=""
              component="img"
              height={45}
              src="/images/icons/Download-GooglePlay.png"
              width={140}
            />
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

NodChallengeArticle.propTypes = {
  article: PropTypes.shape({}).isRequired
}

export default NodChallengeArticle
