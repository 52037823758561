import { FormattedMessage, useIntl } from 'react-intl'
import { equals, any, not, isNil, and, prop } from 'ramda'
import { Button, Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import InfoMessage from './InfoMessage'
import ActiveConsentForm from './ActiveConsentFormConnector'
import StudentOrStaff from './StudentOrStaffConnector'
import CountySelect from './CountySelectConnector'

const SSOSignUpForm = ({ consent, location, resetState, role }) => {
  const intl = useIntl()
  const {
    show_locations_at_signup,
    sso_name,
    sso_link,
    sso_name_two,
    sso_link_two
  } = useSelector(prop('school'))
  const { isBusiness } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const sendData = () => {
    resetState()
  }

  return (
    <Stack
      onSubmit={e => {
        e.preventDefault()
        sendEvent('sign-up', {
          auth_type: 'sso',
          validation_errors: [],
          validation_success: true
        })
        setTimeout(() => sendData(), 2000)
      }}
      spacing={1}
    >
      {equals(window.location.hash, '#active-consent-needed') && (
        <Box>
          <InfoMessage
            message={
              <FormattedMessage
                defaultMessage="Hey! Looks like this is your first time signing in. Accept the terms below to continue."
                id="qNxO2W"
              />
            }
          />
          <br />
        </Box>
      )}
      {show_locations_at_signup && <CountySelect />}
      {not(isBusiness) && <StudentOrStaff />}
      <ActiveConsentForm />
      {sso_name && (
        <Button
          aria-label={
            intl.formatMessage({
              defaultMessage: 'Continue with my',
              id: '8+tCCq'
            }) +
            ' ' +
            sso_name
          }
          disabled={any(equals(true))([
            not(consent),
            and(show_locations_at_signup, isNil(location))
          ])}
          fullWidth
          LinkComponent={Link}
          sx={{
            bgcolor: 'secondary.light'
          }}
          to={`${sso_link}?params=activeConsent=true${
            location ? `_locationId=${prop('id', location)}` : ''
          }${role ? `_role=${role}` : ''}`}
          type="submit"
          variant="sso"
        >
          <FormattedMessage
            defaultMessage="Continue with my {name}"
            id="KX6QRB"
            values={{ name: sso_name }}
          />
        </Button>
      )}
      {not(isNil(sso_name_two)) && (
        <Button
          aria-label={
            intl.formatMessage({
              defaultMessage: 'Continue with my',
              id: '8+tCCq'
            }) +
            ' ' +
            sso_name_two
          }
          disabled={any(equals(true))([
            not(consent),
            and(show_locations_at_signup, isNil(location))
          ])}
          fullWidth
          LinkComponent={Link}
          sx={{
            backgroundColor: 'secondary.light',
            fontFamily: 'mark-ot-bold'
          }}
          to={`${sso_link_two}?params=activeConsent=true${
            location ? `_locationId=${prop('id', location)}` : ''
          }${role ? `_role=${role}` : ''}`}
          variant="sso"
        >
          <FormattedMessage
            defaultMessage="Continue with my {name}"
            id="KX6QRB"
            values={{ name: sso_name_two }}
          />
        </Button>
      )}
    </Stack>
  )
}

SSOSignUpForm.propTypes = {
  consent: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  resetState: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
}

SSOSignUpForm.defaultProps = {
  location: null
}

export default SSOSignUpForm
