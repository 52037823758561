import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

// todo - add images and style the background of the box
const EmptyState = ({ type }) => {
  let image, title, subtitle
  switch (type) {
    case 'inProgress':
      image = '/images/goals/in_progress_goals.png'
      title = (
        <FormattedMessage
          defaultMessage="Feels a bit empty here, why not set a few goals?"
          id="d1Sd/v"
        />
      )
      break
    case 'completed':
      image = '/images/goals/completed_goals.png'
      title = (
        <FormattedMessage
          defaultMessage="No goals completed... yet"
          id="iF5FrG"
        />
      )
      subtitle = (
        <FormattedMessage
          defaultMessage="When you complete goals, you'll see them listed here (along with that warm fuzzy feeling of accomplishment 👏🏽)."
          id="2p2uE2"
        />
      )
      break
  }
  return (
    <Box
      alignItems="center"
      bgcolor="white"
      border="3px solid"
      borderColor="primary.light"
      borderRadius="4px"
      boxShadow={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={3}
    >
      <Box component="img" src={image} />

      <Typography component="h3" mt={2} variant="cardTitle">
        {title}
      </Typography>
      <Typography m="16px 0 2em 0" variant="subtitle1">
        {subtitle}
      </Typography>

      <Button LinkComponent={Link} to="/goals/create/new">
        <FormattedMessage defaultMessage="Set a new goal" id="IPo0oP" />
      </Button>
    </Box>
  )
}

EmptyState.propTypes = {
  type: PropTypes.string.isRequired
}

export default EmptyState
