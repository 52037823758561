const MuiLinearProgress = {
  styleOverrides: {
    root: {
      borderRadius: 25,
      height: 8,
    },
  },
}

export default MuiLinearProgress
