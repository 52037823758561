import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import NextStepCard from './NextStepCard'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import { not, prop } from 'ramda'
import { useSelector } from 'react-redux'

const NextSteps = ({ subdomainId }) => {
  const { hide_resources } = useSelector(prop('school'))

  return (
    <Stack
      alignItems="flex-start"
      // eslint-disable-next-line sort-keys
      direction={{ xs: 'column', lg: 'row' }}
      spacing={2}
      sx={{
        mt: 4
      }}
    >
      <NextStepCard
        icon={
          <CheckRoundedIcon fontSize="small" sx={{ color: 'secondary.dark' }} />
        }
        subdomainId={subdomainId}
        title="Take Action"
      />
      <NextStepCard
        icon={
          <ChatBubbleRoundedIcon
            fontSize="small"
            sx={{ color: 'secondary.dark' }}
          />
        }
        subdomainId={subdomainId}
        title="Get Advice"
      />
      {not(hide_resources) && (
        <NextStepCard
          icon={
            <LocationOnRoundedIcon
              fontSize="small"
              sx={{ color: 'secondary.dark' }}
            />
          }
          subdomainId={subdomainId}
          title="Connect"
        />
      )}
    </Stack>
  )
}

NextSteps.propTypes = {
  subdomainId: PropTypes.number.isRequired
}

export default NextSteps
