import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { isNil, not } from 'ramda'

const AboutItem = ({ body, cta_link, cta_text, image, subtitle, title }) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'grid',
      // eslint-disable-next-line sort-keys
      gridGap: { xs: 16, lg: 24 },
      // eslint-disable-next-line sort-keys
      gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' }
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        // eslint-disable-next-line sort-keys
        height: { xs: 250, lg: 375 },
        maxWidth: 485,
        width: 1
      }}
    />
    <Stack>
      <Typography color="school.primary" variant="h2">
        {title}
      </Typography>
      {not(isNil(subtitle)) && (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontSize: '14',
            pb: 1
          }}
        >
          {subtitle}
        </Typography>
      )}
      <Typography component="div" variant="body1">
        {parse(body)}
      </Typography>
      {not(isNil(cta_link)) && (
        <Button
          aria-label={cta_text}
          component={Link}
          rel="noopener noreferrer"
          sx={{
            alignSelf: 'center'
          }}
          target="_blank"
          to={cta_link}
        >
          {cta_text}
        </Button>
      )}
    </Stack>
  </Box>
)

AboutItem.propTypes = {
  body: PropTypes.string.isRequired,
  cta_link: PropTypes.string,
  cta_text: PropTypes.string,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

AboutItem.defaultProps = {
  cta_link: null,
  cta_text: null,
  subtitle: null
}

export default AboutItem
