import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  any,
  assoc,
  compose,
  defaultTo,
  equals,
  isNil,
  not,
  path,
  prop,
  tap,
  toLower
} from 'ramda'
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import PropTypes from 'prop-types'
import { useSignUpAzureMutation } from 'api/authApi'
import { useSelector } from 'react-redux'

import Confidentiality from 'pages/authentication/sign-up/components/Confidentiality'
import ActiveConsentForm from './ActiveConsentFormConnector'
import SuccessMessage from 'components/common/SuccessMessage'
import ErrorMessage from 'components/common/ErrorMessage'

const AzureForm = ({ consent, email, setEmail }) => {
  const { formatMessage } = useIntl()
  const { domain, azureLoginUrl } = useSelector(prop('school'))
  const locale = localStorage.getItem('@MT:locale')

  const [signUp, { data, error, isError, isLoading }] = useSignUpAzureMutation()

  const isSuccess = prop('success', data)

  const [emailOptInEnabled, setEmailOptInEnabled] = useState(true)
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [errors, setErrors] = useState({
    consent: false,
    email: false
  })

  const validateEmail = email => {
    const regex =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setErrors(assoc('email', not(regex.test(email)), errors))
  }

  const onSubmit = async () => {
    signUp({
      azure_redirect: `${window.location.origin}/azure-signup-redirect`,
      comm_optin: emailOptInEnabled,
      email: toLower(email),
      locale: locale,
      org_domain: domain,
      tnc: consent
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setSignUpSuccess(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setApiError(path(['data', 'email', 0], error))
    }
  }, [isError])

  const renderError = () => {
    if (equals('already_fully_registered', apiError))
      return (
        <FormattedMessage
          defaultMessage="This email is already registered, please sign in"
          id="hmvlBe"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="This email is already registered to sign up, please check your email"
        id="v4wKTl"
      />
    )
  }

  return (
    <>
      <Stack
        component="form"
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        // eslint-disable-next-line sort-keys
        spacing={{ xs: 2, sm: 1 }}
        sx={{ px: 1 }}
      >
        <SuccessMessage
          hasSuccess={signUpSuccess}
          message={formatMessage({
            defaultMessage:
              'Success! Check your email to verify your new account.',
            id: 'MGQUIN'
          })}
        />
        <ErrorMessage hasError={not(isNil(apiError))} message={renderError()} />
        <TextField
          helperText={
            prop('email', errors) ? (
              <FormattedMessage
                defaultMessage="Hmm, this email address isn't valid."
                id="/g9wJ/"
              />
            ) : (
              <Tooltip
                title={
                  <Stack spacing={1}>
                    <Typography variant="caption">
                      <FormattedMessage
                        defaultMessage="YOU is a confidential platform. Your email is used only to establish an account so that you can save resources, assessments, and personalized interests."
                        id="dxLav5"
                      />
                    </Typography>
                    <Typography variant="caption">
                      <FormattedMessage
                        defaultMessage="We may send informational and reminder emails from time to time, but will never sell your email address or share any personal information about your usage of this tool."
                        id="f7YyLz"
                      />
                    </Typography>
                  </Stack>
                }
              >
                <Button
                  startIcon={<InfoIcon />}
                  sx={{ p: 0, textTransform: 'initial' }}
                  variant="text"
                >
                  <FormattedMessage
                    defaultMessage="Why do you need my email?"
                    id="C6vxyl"
                  />
                </Button>
              </Tooltip>
            )
          }
          id="email"
          label={
            <FormattedMessage defaultMessage="Email Address" id="xxQxLE" />
          }
          onChange={compose(
            validateEmail,
            tap(setEmail),
            path(['target', 'value'])
          )}
          required
          type="email"
          value={defaultTo('', email)}
        />
        <FormControl required>
          <FormControlLabel
            control={
              <Checkbox
                checked={emailOptInEnabled}
                color="info"
                onChange={() => setEmailOptInEnabled(not(emailOptInEnabled))}
              />
            }
            label={
              <Typography fontSize="14px" variant="body2">
                <FormattedMessage
                  defaultMessage="I agree to receive emails from WellPower on this platform"
                  id="90e6Kl"
                />
              </Typography>
            }
          />
        </FormControl>
        <ActiveConsentForm />
        <Button
          disabled={any(equals(true))([
            prop('email', errors),
            isNil(email),
            not(consent),
            isSuccess
          ])}
          fullWidth
          sx={{ mt: 1 }}
          type="submit"
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: 'school.primary' }} />
          ) : (
            <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
          )}
        </Button>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={0.5}
        sx={{ justifyContent: 'center', my: 2 }}
      >
        <Typography display="inline" variant="body2">
          <FormattedMessage
            defaultMessage="Already have an account?"
            id="uCk8r+"
          />
        </Typography>
        <Typography
          color="primary"
          component={Link}
          display="inline"
          sx={{
            textDecoration: 'underline'
          }}
          to={azureLoginUrl}
          variant="body2"
        >
          <FormattedMessage defaultMessage="Sign in." id="kxSQgH" />
        </Typography>
      </Stack>
      <Confidentiality />
    </>
  )
}

AzureForm.propTypes = {
  consent: PropTypes.bool.isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired
}

AzureForm.defaultProps = {
  email: null
}

export default AzureForm
