import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { and, defaultTo, isEmpty, isNil, not } from 'ramda'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'
import { useCurrentAppType } from 'hooks'

const LogInButton = ({ children, icon = null, isWhite = false }) => {
  const { azureLoginUrl, sso_link_two, sso_link } = useSelector(
    ({ school }) => school
  )
  const { sendEvent } = useAnalytics()
  const { isWellpower } = useCurrentAppType()

  const getSignInUrl = () => {
    const ssoEnabled = defaultTo(
      false,
      and(not(isNil(sso_link)), not(isEmpty(sso_link)))
    )

    if (isWellpower) return azureLoginUrl

    const ssoMultipleIdps = not(isNil(sso_link_two))
    if (and(ssoEnabled, not(ssoMultipleIdps))) return sso_link

    return '/sign-in'
  }

  return (
    <Button
      LinkComponent={Link}
      onClick={() =>
        sendEvent('block_interactions', {
          block_title: 'Logged Out Header',
          click_element: 'Link',
          element_title: 'Log In',
          path: window.location.pathname
        })
      }
      startIcon={icon}
      sx={{
        borderColor: isWhite ? 'white' : 'initial',
        color: isWhite ? 'white' : 'initial'
      }}
      to={getSignInUrl()}
      variant="outlined"
    >
      {children}
    </Button>
  )
}

LogInButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  isWhite: PropTypes.bool
}

export default LogInButton
