/* eslint-disable sort-keys */
import { lt, inc, length, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded'
import useAnalytics from 'hooks/useAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'

import { Link } from 'react-router-dom'
import LogInButton from 'components/common/LogInButton'

const links = [
  {
    href: '/accessibility',
    show: process.env.REACT_APP_PUBLIC_ACCESSIBILITY_DOC,
    text: <FormattedMessage defaultMessage="Accessibility" id="fgCBG8" />
  },
  {
    href: '/privacy-policy',
    show: true,
    text: <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
  },
  {
    href: '/terms-and-conditions',
    show: true,
    text: <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
  },
  {
    href: '/feedback',
    show: true,
    text: <FormattedMessage defaultMessage="Website Feedback" id="1678y9" />
  }
]

const Footer = () => {
  const dispatch = useDispatch()
  const { logoImage } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const thedate = new Date()
  const year = thedate.getFullYear()

  return (
    <>
      <Box
        component="footer"
        role="navigation"
        sx={{
          bgcolor: 'school.primary'
        }}
      >
        <Box
          sx={{
            alignItems: { xs: 'flex-start', md: 'center' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gridRowGap: 16,
            justifyContent: 'space-between',
            px: 3,
            py: 4
          }}
        >
          <Button
            aria-label="logo link: click to go to main page"
            LinkComponent={Link}
            to="/"
            variant="text"
          >
            <Box
              sx={{
                background: `url(${logoImage})`,
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: 24,
                p: 0,
                position: 'relative',
                width: 275
              }}
            />
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gridGap: 16
            }}
          >
            <Button
              onClick={() => {
                dispatch(setIsCrisisModalOpen(true))
                sendEvent('block_interactions', {
                  block_title: 'Logged Out Footer',
                  click_element: 'Link',
                  element_title: 'Help in Crisis',
                  path: window.location.pathname
                })
              }}
              startIcon={<PanToolRoundedIcon />}
              variant="footer"
            >
              <FormattedMessage defaultMessage="Help in Crisis" id="PRqLkL" />
            </Button>
            <LogInButton isWhite>
              <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
            </LogInButton>
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('block_interactions', {
                  block_title: 'OFD Explore Footer',
                  click_element: 'Link',
                  element_title: 'Sign Up CTA',
                  path: window.location.pathname
                })
              }
              to="/sign-up"
            >
              <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
            </Button>
          </Box>
        </Box>
        <Divider />
        <Stack
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pb: 2,
            px: 3
          }}
        >
          <Typography color="white" variant="legal">
            <FormattedMessage
              defaultMessage="<c>&copy;</c> {year} by Grit Digital Health, LLC. Grit does not claim copyright in the third party materials included on this site."
              id="6VdK1V"
              values={{
                c: chunks => <span>{chunks}</span>,
                year: year
              }}
            />
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gridGap: 4
            }}
          >
            {links.map(
              ({ href, show, text }, idx) =>
                show && (
                  <Box
                    key={idx}
                    sx={{
                      display: 'flex',
                      gridGap: 4
                    }}
                  >
                    <Typography
                      color="white"
                      component={Link}
                      display="block"
                      to={href}
                      variant="legal"
                      whiteSpace="nowrap"
                    >
                      {text}
                    </Typography>
                    {lt(inc(idx), length(links)) && (
                      <Box
                        sx={{
                          color: 'white',
                          display: { xs: 'none', md: 'block' }
                        }}
                      >
                        |
                      </Box>
                    )}
                  </Box>
                )
            )}
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default Footer
