const MuiFormLabel = {
  styleOverrides: {
    root: {
      fontSize: 16,
      lineHeight: '18px',
    },
  },
}

export default MuiFormLabel
