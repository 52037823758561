import { useEffect } from 'react'
import PropTypes from 'prop-types'
import AccessibleModal from '../common/AccessibleModal'
import { FormattedMessage } from 'react-intl'
import { Button, Stack, Typography } from '@mui/material'
import { all, equals, isNil, not } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'
import { useUpdateProfileMutation } from 'api/profileApi'
import { useLocation, useParams } from 'react-router-dom'

const EmailOptInModal = ({
  emailOptIn = false,
  emailOptInPendingUpdate = false,
  hasBeenShownAModal = false,
  id = null,
  isOpen,
  setIsCrisisModalOpen,
  setIsEmailModalOpen
}) => {
  const { sendEvent } = useAnalytics()
  const { inCrisis } = useParams()
  const { pathname } = useLocation()

  const [updateProfile, { isSuccess = false }] = useUpdateProfileMutation()

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('public'),
    pathname.includes('about-you'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback')
  ]

  let isMounted = true
  //EMAIL OPT IN INTERVAL SET UP
  useEffect(() => {
    // content wants to display the email opt in modal after
    // 2 minutes on the site provided the user hasn't seen another
    // modal before then
    if (isMounted) {
      setTimeout(() => {
        if (
          all(equals(true))([
            isNil(emailOptIn),
            not(isNil(id)),
            emailOptInPendingUpdate,
            not(hasBeenShownAModal),
            all(equals(false))(anyCheck)
          ])
        ) {
          setIsEmailModalOpen(true)
        }
      }, 120000)
    }

    if (inCrisis) setTimeout(() => setIsCrisisModalOpen(inCrisis), 100)

    return () => (isMounted = false)
  }, [inCrisis, isMounted])

  useEffect(() => {
    if (isOpen) sendEvent('you_email_opt_in_viewed')
  }, [])

  const handleClick = selected => {
    sendEvent('you_email_opt_in', {
      email_opt_in: equals(selected, 1)
    })

    const body = new FormData()
    body.append('email_opt_in', selected)
    updateProfile(body)
  }

  useEffect(() => {
    if (isSuccess) {
      setIsEmailModalOpen(false)
    }
  }, [isSuccess])

  // we pass handleClick to the setIsEmailModalOpen button handler here
  // so that we treat closing the modal as an optOut answer
  // if the user closes the modal without answering,
  // odds are high they dont want email notifications :)
  return (
    <AccessibleModal
      onClose={() => handleClick(0)}
      open={isOpen}
      title={
        <FormattedMessage defaultMessage="Way to be proactive!" id="HQ/UGt" />
      }
    >
      <Stack alignItems="center">
        <Typography sx={{ pb: 2 }} textAlign="center" variant="body2">
          <FormattedMessage
            defaultMessage="We can send tips to your email to help you keep healthy habits top of mind. No spam, we promise."
            id="/oySsd"
          />
        </Typography>
        <Button onClick={() => handleClick(1)}>
          <FormattedMessage defaultMessage="Sign me up!" id="SBfe/k" />
        </Button>
      </Stack>
    </AccessibleModal>
  )
}

EmailOptInModal.propTypes = {
  emailOptIn: PropTypes.bool,
  emailOptInPendingUpdate: PropTypes.bool,
  hasBeenShownAModal: PropTypes.bool,
  id: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  setIsCrisisModalOpen: PropTypes.func.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired
}

export default EmailOptInModal
