import palette from './palette'

const overlineBase = {
  color: palette.secondary.dark,
  fontFamily: 'mark-ot',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 1.5,
  lineHeight: '16px',
  textTransform: 'uppercase',
}

const subtitleBase = {
  color: palette.primary.main,
  fontFamily: 'mark-ot',
  fontSize: 14,
  letterSpacing: '0.25px',
  lineHeight: '26.22px',
}

const typography = {
  body1: {
    '@media (max-width:600px)': {
      fontSize: 18,
      lineHeight: '27px',
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot',
    fontSize: 20,
    letterSpacing: '0.1px',
    lineHeight: '28px',
  },
  body2: {
    color: palette.primary.main,
    fontFamily: 'mark-ot',
    fontSize: 16.667,
    letterSpacing: '0.25px',
    lineHeight: '23.33px',
  },
  body3: {
    color: palette.primary.main,
    fontFamily: 'mark-ot',
    fontSize: 14,
    lineHeight: '21px',
  },
  bodyLarge: {
    '@media (max-width:600px)': {
      fontSize: 22,
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot',
    fontSize: 28,
    letterSpacing: '-0.25px',
    lineHeight: '120%',
  },
  caption: {
    color: palette.primary.main,
    fontFamily: 'mark-ot',
    fontSize: 14,
    letterSpacing: '0.5px',
    lineHeight: '18.9px',
  },
  captionBold: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: 14,
    letterSpacing: '0.5px',
    lineHeight: '18.9px',
  },
  cardTitle: {
    color: palette.primary.main,
    display: 'block',
    fontFamily: 'mark-ot-bold',
    fontSize: 22,
    lineHeight: '26px',
    margin: 0,
  },
  contentSource: {
    color: palette.secondary.dark,
    fontSize: '12px',
    justifyContent: 'center',
    letterSpacing: '1.5px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    width: '100%',
  },
  domainSubtitle: {
    color: '#666666',
    fontFamily: 'mark-ot',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.1px',
    lineHeight: '22.4px',
  },
  h1: {
    '@media (max-width:600px)': {
      fontSize: '46px',
      lineHeight: '69px',
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '50px',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '63px',
  },
  h2: {
    '@media (max-width:600px)': {
      fontSize: '28px',
      lineHeight: '39px',
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '42px',
    letterSpacing: '-1px',
    lineHeight: '53px',
  },
  h3: {
    '@media (max-width:600px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '35px',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '42px',
  },
  h4: {
    '@media (max-width:600px)': {
      fontSize: '24px',
      lineHeight: '31.2px',
    },
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '28px',
    fontWeight: 700,
    letterSpacing: '-0.25px',
    lineHeight: '35px',
  },
  h5: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  h6: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  legal: {
    color: palette.primary.main,
    fontSize: '12px',
    letterSpacing: '0.25px',
  },
  link: {
    color: palette.primary.main,
    fontSize: 14,
    textDecoration: 'underline',
  },
  overline: {
    ...overlineBase,
  },
  overlineBold: {
    ...overlineBase,
    fontFamily: 'mark-ot-bold',
  },
  screenReaderOnly: {
    WebkitClipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
    clip: 'rect(1px,1px,1px,1px)',
    clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    width: '1px',
  },
  subtitle1: {
    ...subtitleBase,
  },
  subtitle2: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '24px',
    letterSpacing: 0,
    lineHeight: '31px',
  },
  subtitle3: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '16.66px',
    letterSpacing: '0.25px',
    lineHeight: '23.33px',
  },
  subtitle4: {
    color: palette.primary.main,
    fontFamily: 'mark-ot-bold',
    fontSize: '20px',
    letterSpacing: '0.1px',
    lineHeight: '28px',
  },
  subtitleBold: {
    ...subtitleBase,
    fontFamily: 'mark-ot-bold',
  },
}

export default typography
