import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia
} from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import useAnalytics from 'hooks/useAnalytics'

const DashboardFooterItem = ({ imgSrc, link, linkText }) => {
  const { sendEvent } = useAnalytics()

  const handleClick = () =>
    sendEvent('block_interactions', {
      block_title: 'Dashboard Footer Block',
      click_element: 'Link',
      element_title: linkText,
      path: window.location.pathname
    })

  return (
    <Card
      sx={{
        bgcolor: 'transparent',
        boxShadow: 0,
        maxWidth: 375,
        width: 1
      }}
    >
      <CardActionArea component={Link} onClick={handleClick} to={link}>
        <CardMedia component="img" height={200} src={imgSrc} />
        <CardActions
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            sx={{ px: 0, py: 1 }}
            tabIndex={-1}
            variant="text"
          >
            {linkText}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

DashboardFooterItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.any.isRequired
}

export default DashboardFooterItem
