/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { not, isEmpty, gte, length } from 'ramda'
import Trophy from './components/Trophy'
import { useGetGoalsQuery } from 'api/goalsApi'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import WatchLaterIcon from '@mui/icons-material/WatchLater'

const stackStyles = {
  alignItems: 'center',
  justifyContent: 'center'
}

const CompletedGoal = () => {
  const { data: goals = [], isFetching } = useGetGoalsQuery()

  return (
    <Box
      sx={{
        px: { xs: 2, lg: 3 },
        py: 3,
        textAlign: 'center'
      }}
    >
      <Box m="0 auto" maxWidth="500px">
        {not(isFetching) && (
          <Typography variant="h1">
            <FormattedMessage
              defaultMessage="Well done! You just completed your goal!"
              id="iJlswH"
            />
          </Typography>
        )}
        <Trophy />
        <Stack spacing={3} sx={{ ...stackStyles }}>
          <Typography
            component="span"
            sx={{
              color: 'secondary.dark',
              fontSize: '20px',
              lineHeight: '28px'
            }}
          >
            <FormattedMessage
              defaultMessage="Your hard work paid off! Now, on to the next one."
              id="xX3pi2"
            />
          </Typography>
          {not(isEmpty(goals)) && (
            <>
              <Typography
                component="span"
                sx={{
                  color: 'secondary.dark',
                  fontSize: '20px',
                  lineHeight: '28px'
                }}
              >
                {gte(length(goals), 2) ? (
                  <FormattedMessage
                    defaultMessage="Looks like you currently have 
                  <b>{total} goals in progress</b>"
                    id="wL9zgV"
                    values={{
                      b: chunks => <b>{chunks}</b>,
                      total: length(goals)
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Looks like you currently have 
                  <b>{total} goal in progress</b>"
                    id="80Prsp"
                    values={{
                      b: chunks => <b>{chunks}</b>,
                      total: length(goals)
                    }}
                  />
                )}
              </Typography>
              <Button
                LinkComponent={Link}
                startIcon={<WatchLaterIcon color="primary" />}
                to="/goals?active=my-goals"
                variant="outlined"
              >
                <FormattedMessage
                  defaultMessage="view my in progress goals"
                  id="vcrhKE"
                />
              </Button>
            </>
          )}
          <Button LinkComponent={Link} to="/goals?active=explore">
            <FormattedMessage defaultMessage="Start a new goal" id="QTg+WT" />
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default CompletedGoal
