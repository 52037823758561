import PropTypes from 'prop-types'
import { equals, isNil, prop } from 'ramda'
import { Box, Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'

const PollQuestions = ({
  content,
  selected = null,
  setIsSubmitted = () => {},
  setSelected = () => {}
}) => {
  const { sendEvent } = useAnalytics()

  const handleSubmit = () => {
    sendEvent('YOU_Subdomain_Poll', {
      poll_question_answer: selected,
      poll_question_title: prop('prompt', content),
      subdomain_name: prop('group', content)
    })
    setIsSubmitted(true)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: 8
        }}
      >
        {prop('answers', content).map((answer, idx) => (
          <Button
            key={idx}
            onClick={() => setSelected(idx)}
            selected={equals(selected, idx)}
            variant="poll"
          >
            <FormattedMessage
              defaultMessage="{answer}"
              id="MFRRt+"
              values={{ answer }}
            />
          </Button>
        ))}
      </Box>
      <Button disabled={isNil(selected)} fullWidth onClick={handleSubmit}>
        <FormattedMessage defaultMessage="Submit my Answer" id="nlkQVA" />
      </Button>
    </>
  )
}

PollQuestions.propTypes = {
  content: PropTypes.shape({}).isRequired,
  selected: PropTypes.number,
  setIsSubmitted: PropTypes.func,
  setSelected: PropTypes.func
}

export default PollQuestions
