import { baseQuery } from './baseQuery'

export const goalsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    createGoal: mutation({
      invalidatesTags: ['goals'],
      query: body => ({
        body,
        method: 'POST',
        url: '/goals/create',
      }),
    }),
    deleteGoal: mutation({
      invalidatesTags: ['goals'],
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/goals/${id}`,
      }),
    }),
    getCampusGoals: query({
      providesTags: ['campus goals'],
      query: () => '/campus-goals?take=4',
    }),
    getCompletedGoals: query({
      providesTags: ['completed goals'],
      query: () => '/goals?completed=true',
    }),
    getGoal: query({
      providesTags: ['goal'],
      query: id => `/goals/${id}`,
    }),
    getGoals: query({
      providesTags: ['goals'],
      query: () => '/goals',
    }),
    updateGoal: mutation({
      invalidatesTags: ['goal', 'goals', 'completed goals'],
      query: ({ id, ...body }) => ({
        ...body,
        method: 'PUT',
        url: `/goals/${id}`,
      }),
    }),
  }),
  reducerPath: 'goalsApi',
})

export const {
  useCreateGoalMutation,
  useDeleteGoalMutation,
  useGetCampusGoalsQuery,
  useGetCompletedGoalsQuery,
  useGetGoalQuery,
  useGetGoalsQuery,
  useUpdateGoalMutation,
} = goalsApi
