import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  isEmpty,
  prop,
  not,
  isNil,
  split,
  defaultTo,
  path,
  compose,
  equals,
  nthArg,
  or
} from 'ramda'
import getParams from 'utils/getParams'
import { useGetAddressQuery } from 'api/addressApi'
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { setLocation } from './onboardingSlice'
import { useDispatch, useSelector } from 'react-redux'

const Location = () => {
  const dispatch = useDispatch()
  const home_location = useSelector(path(['user', 'home_location', 'address']))
  const { location } = useSelector(prop('onboarding'))

  const [value, setValue] = useState('')

  useEffect(() => {
    if (not(isNil(home_location))) {
      setValue(prop(0, split(',', home_location)))
      const rest = split(' ', prop(1, split(',', home_location)))
      dispatch(
        setLocation({
          city: prop(1, rest),
          state: 'CO',
          street_line: prop(0, split(',', home_location)),
          zipcode: prop(3, rest)
        })
      )
    }
  }, [home_location])

  const { data, isFetching = true } = useGetAddressQuery(
    getParams({
      include_only_states: 'co',
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      search: value
    }),
    {
      skip: or(isEmpty(value), isNil(value))
    }
  )

  const options = defaultTo([], prop('suggestions', data))

  const handleChange = val => dispatch(setLocation(val))

  return (
    <Stack alignItems="center" spacing={2} sx={{ mt: 2 }}>
      <Autocomplete
        getOptionLabel={prop('street_line')}
        isOptionEqualToValue={(option, value) =>
          equals(prop('street_line', option), prop('street_line', value))
        }
        loading={isFetching}
        onChange={compose(handleChange, nthArg(1))}
        onInputChange={e => {
          if (isNil(e)) return
          compose(setValue, path(['target', 'value']))(e)
        }}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            label={
              <FormattedMessage defaultMessage="Enter an address" id="BKdjS1" />
            }
          />
        )}
        renderOption={(props, option) => (
          <li
            key={`${prop('street_line', option)}-${prop('entries', option)}`}
            {...props}
          >
            <Grid alignItems="center" container>
              <Grid item sx={{ width: 44 }}>
                <LocationOnRoundedIcon />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)' }}>
                <Stack direction="row">
                  <Typography variant="body2">
                    <FormattedMessage
                      defaultMessage="{street_line}"
                      id="359sJT"
                      values={{ street_line: prop('street_line', option) }}
                    />
                  </Typography>
                  {not(isEmpty(prop('secondary', option))) && (
                    <Typography variant="body2">
                      <FormattedMessage
                        defaultMessage=", {secondary}"
                        id="+Fk4CM"
                        values={{ secondary: prop('secondary', option) }}
                      />
                    </Typography>
                  )}
                </Stack>
                <Typography variant="caption">
                  <FormattedMessage
                    defaultMessage="{city}, {zipcode}"
                    id="GbmSf4"
                    values={{
                      city: prop('city', option),
                      zipcode: prop('zipcode', option)
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
        sx={{ width: 375 }}
        value={location}
      />
    </Stack>
  )
}

export default Location
