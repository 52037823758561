import { append, prop } from 'ramda'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setHistory } from 'store/historySlice'

const HistoryHandler = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { history } = useSelector(prop('history'))

  useEffect(() => {
    dispatch(setHistory(append(pathname, history)))
  }, [pathname])

  return
}

export default HistoryHandler
