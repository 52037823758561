import PropTypes from 'prop-types'
import { memo } from 'react'
import * as R from 'ramda'
import { Box, TextField, Typography } from '@mui/material'

function DropDownQuestionInput({ question, userAnswers, onUpdateAnswer }) {
  const debounceSubmit = ({ target: { value } }) =>
    onUpdateAnswer({
      answers: [value],
      profile_question_id: question.id,
    })

  return (
    <Box maxWidth={432}>
      <Typography mb={1} variant="subtitle4">
        {question.question}
      </Typography>
      <TextField
        fullWidth
        name={question.name}
        onChange={debounceSubmit}
        value={R.defaultTo('', userAnswers[0])}
      />
    </Box>
  )
}

DropDownQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    question: PropTypes.string,
  }).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default memo(DropDownQuestionInput)
