import Masonry from '@mui/lab/Masonry'
import PropTypes from 'prop-types'

// eslint-disable-next-line sort-keys
const MasonryWrapper = ({ children, columns = { xs: 1, sm: 2, lg: 3 } }) => (
  <Masonry columns={columns} spacing={2}>
    {children}
  </Masonry>
)

MasonryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.shape({}),
}

export default MasonryWrapper
