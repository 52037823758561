import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ConsentModal from './ConsentModalConnector'
import FacebookButton from './FacebookButtonConnector'
import GoogleButton from './GoogleButtonConnector'

const SSOButtons = ({ isSignUp }) => (
  <Stack alignItems="center" spacing={1.5} sx={{ mt: 1 }}>
    <Typography textAlign="center">or</Typography>
    <FacebookButton isSignUp={isSignUp} />
    <GoogleButton isSignUp={isSignUp} />
    <ConsentModal />
  </Stack>
)

SSOButtons.propTypes = {
  isSignUp: PropTypes.bool,
}

SSOButtons.defaultProps = {
  isSignUp: false,
}

export default SSOButtons
