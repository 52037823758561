import {
  equals,
  not,
  and,
  prop,
  isNil,
  path,
  defaultTo,
  all,
  gt,
  reject,
  length,
  replace,
  toLower,
  compose,
} from 'ramda'

export const addressAsString = address =>
  `${address.street_line}, ${address.city} ${address.state} ${address.zipcode}`

export function hexToRGBA(hex, opacity) {
  let r, g, b
  if (equals(length(hex), 4)) {
    // eslint-disable-next-line
    ;[, r, g, b] = hex
    r = [r, r].join('')
    g = [g, g].join('')
    b = [b, b].join('')
  } else {
    r = hex.substring(1, 3)
    g = hex.substring(3, 5)
    b = hex.substring(5, 7)
  }

  return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(
    b,
    16
  )}, ${opacity})`
}

const round = (number, decimalPlaces = 2) => {
  const n = Math.pow(10, decimalPlaces)
  return Math.round(n * number) / n
}

export const METERS_IN_MILE = 1609.344
export const metersToMiles = m => round(m / METERS_IN_MILE)

// convert a string into a html element id safe value
export const stringToID = s => (s && s.replace ? s.replace(/\W/g, '_') : s)

export const slugify = str => {
  if (typeof str === 'undefined') return false

  return str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^\w-]+/g, '')
}

export const getCurrentUserLocation = ({ user }) => {
  if (not(isNil(user))) return path(['temp_location', 'address'], user)
  return null
}

export const isFacultyExperience = (user, school) => {
  const secondaryAppTypeFeaturesEnabled = prop(
    'enable_secondary_user_type_features',
    school
  )
  const secondaryAppType = defaultTo(null, path(['app_type', 'slug'], user))
  const userType = prop('user_type', user)
  const checks = [
    secondaryAppTypeFeaturesEnabled,
    equals(secondaryAppType, 'faculty'),
    equals(userType, 'secondary'),
  ]

  return all(equals(true))(checks)
}

export const tipsEnabled = (schoolTipsEnabled, userTipsEnabled) => {
  if (isNil(userTipsEnabled)) return false
  return and(schoolTipsEnabled, userTipsEnabled)
}

export const excerpt = (str, maxLength) => {
  if (isNil(str)) return
  if (gt(maxLength, length(str))) return str

  //trim the string to the maximum length
  let trimmedString = str.substr(0, maxLength)

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  )

  return trimmedString + '...'
}

export const mapQueryParams = obj =>
  Object.entries(reject(isNil, obj))
    .map(
      // pair is key & value from each item in cards param obj
      // return recreated qp string
      // prop(int, pair) => index location in arr
      // if last item don't give & at end
      pair => `${prop(0, pair)}=${prop(1, pair)}`
    )
    .join('&')

export const mapFiltersToQueryParams = obj =>
  Object.entries(reject(isNil, obj))
    .map(
      // pair is key & value from each item in cards param obj
      // return recreated qp string
      // prop(int, pair) => index location in arr
      // if last item don't give & at end
      pair => `filter[${prop(0, pair)}]=${prop(1, pair)}`
    )
    .join('&')

export const subdomainsFromUserDomains = domains => {
  if (isNil(domains)) return
  // domains needs to be converted to a JS object using toJS() for this to work
  return domains.reduce(
    (subdomains, domain) => domain.subdomains.concat(subdomains),
    []
  )
}

// convert ampersand to and
// remove any illegal url characters
// replace the space with - character
// return all to lower case
export const sanitizeUrl = compose(
  toLower,
  replace(/ /g, '-'),
  replace(/[^a-zA-Z0-9 ]/g, ''),
  replace(/@/g, 'at'),
  replace(/&/g, 'and')
)
