import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const PhoneCardImage = ({ roleUrlPath, numberOfCards = 6 }) =>
  Array.from(Array(numberOfCards)).map((img, i) => (
    <Box
      component="img"
      height="100%"
      key={i}
      ml="2.5%"
      my=".3em"
      src={`${roleUrlPath + i}.png`}
      width="97%"
    />
  ))

PhoneCardImage.propTypes = {
  numberOfCards: PropTypes.number,
  roleUrlPath: PropTypes.string.isRequired,
}

export default PhoneCardImage
