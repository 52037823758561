/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { svg_goals } from 'icons'
import ProgressBar from 'components/common/ProgressBar'
import {
  prop,
  not,
  isEmpty,
  map,
  path,
  evolve,
  always,
  equals,
  dec,
  inc,
  reject,
  isNil,
  assoc,
  length
} from 'ramda'
import { useCreateGoalMutation } from 'api/goalsApi'
import GA from 'services/gaService'
import { useGetCardQuery } from 'api/cardsApi'
import { Box, Button, Typography } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import { useNavigate, useSearchParams } from 'react-router-dom'

import StepsForm from '../components/StepsForm'
import DueDate from '../components/DueDate'

const PremadeGoal = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const cardId = search.get('cardId')

  const [currentIndex, setCurrentIndex] = useState(0)

  const [createGoal, { isLoading, isSuccess }] = useCreateGoalMutation()

  useEffect(() => {
    if (isSuccess) {
      GA.track('Add-Goal', 'Submit')
      navigate('/goals?active=my-goals')
    }
  }, [isSuccess])

  // Updating Goal
  const [goal, setGoal] = useState({
    campus_goal_id: null,
    card_id: null,
    domain_id: null,
    due_date: null,
    steps: [
      {
        completed: false,
        id: null,
        name: ''
      }
    ],
    subdomain_id: null,
    title: ''
  })

  const creationSteps = [
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Save Steps',
        id: 'fKkniH'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effective goals include smaller, actionable steps. Write out your steps here.',
        id: 'pQx8Am'
      }),
      title: intl.formatMessage({
        defaultMessage: 'Your recipe for success',
        id: '4RTn/h'
      })
    },
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Complete',
        id: 'U78NhE'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Setting deadlines is helpful for some, but not all. If it's not your style, skip this step and your goal will appear as ongoing with no due date.",
        id: 'EeDvix'
      }),
      title: intl.formatMessage({
        defaultMessage: 'Set a due date',
        id: 'yRimE8'
      })
    }
  ]

  const currentStep = prop(currentIndex, creationSteps)

  const { data = {} } = useGetCardQuery(cardId)

  useEffect(() => {
    if (not(isEmpty(data))) {
      // Since res from campus goals is not correct shape
      // map steps to be correct shape
      // update the goal to the new found data
      const updatedSteps = map(
        (str, idx) => ({
          completed: false,
          id: idx,
          name: str
        }),
        path(['campus_goal', 'steps'], data)
      )
      setGoal(
        evolve(
          {
            campus_goal_id: always(path(['campus_goal', 'id'], data)),
            card_id: always(parseInt(cardId)),
            domain_id: always(prop('section_id', data)),
            steps: always(updatedSteps),
            subdomain_id: always(path(['campus_goal', 'subdomain_id'], data)),
            title: always(prop('title', data))
          },
          goal
        )
      )
    }
  }, [data])

  const handleBack = () => {
    if (equals(currentIndex, 0)) {
      navigate('/goals?active=my-goals')
    } else {
      setCurrentIndex(dec(currentIndex))
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = () => {
    // final step we send info to the BE and route off create
    // otherwise we move to the next step
    if (equals(inc(currentIndex), length(creationSteps))) {
      const body = new FormData()
      const transformedGoal = reject(
        isNil,
        assoc(
          'due_date',
          prop('due_date', goal) ? prop('due_date', goal).toISOString() : '',
          goal
        )
      )
      for (const [key, value] of Object.entries(transformedGoal)) {
        if (equals(key, 'steps')) {
          goal.steps.forEach((step, index) => {
            body.append('steps[' + index + '][name]', step.name)
          })
        } else {
          body.append(key, value)
        }
      }
      createGoal(body)
      navigate('/goals?active=my-goals')
    } else {
      window.scrollTo(0, 0)
      setCurrentIndex(inc(currentIndex))
    }
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: { xs: 'calc(100vh - 121px)', lg: 'calc(100vh - 137px)' },
          m: '0 auto',
          maxWidth: 572,
          px: { xs: 2, md: 3 },
          width: { xs: 'calc(100% - 32px)', lg: 'calc(100% - 48px)' }
        }}
      >
        <Typography mb="8px" variant="overline">
          <FormattedMessage
            defaultMessage="Step {currentStep}/{totalSteps}"
            id="BrVAMy"
            values={{
              currentStep: currentIndex + 1,
              totalSteps: 2
            }}
          />
        </Typography>
        <ProgressBar
          barColor="secondary"
          completed={currentIndex + 1}
          noLabel
          total={2}
        />
        <Typography
          component="h1"
          fontWeight="bold"
          sx={{
            my: { xs: 2, lg: 3 }
          }}
          textAlign="center"
          variant="h3"
        >
          {prop('title', currentStep)}
        </Typography>
        <Typography
          color="secondary.dark"
          sx={{
            my: { xs: 2, lg: 3 }
          }}
          textAlign="center"
          variant="body1"
        >
          {prop('subtitle', currentStep)}
        </Typography>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 8,
            mb: { xs: 2, lg: 3 }
          }}
        >
          <Box
            sx={{
              '& > svg': {
                fill: 'red',
                height: 25,
                width: 25
              },
              mt: 0.5
            }}
          >
            {svg_goals}
          </Box>

          <Typography
            alignItems="center"
            display="flex"
            fontWeight="bold"
            textAlign="center"
            variant="h4"
          >
            {goal.title}
          </Typography>
        </Box>
        {equals(currentIndex, 0) && <StepsForm goal={goal} setGoal={setGoal} />}
        {equals(currentIndex, 1) && <DueDate goal={goal} setGoal={setGoal} />}
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: 'white',
            bottom: 0,
            boxShadow: 3,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            pr: { xs: 2, lg: 4 },
            py: 1.5,
            right: 0,
            width: { xs: 'calc(100% - 16px)', lg: 'calc(100% - 260px)' }
          }}
        >
          <Button
            onClick={() => handleBack()}
            startIcon={<KeyboardArrowLeftRoundedIcon />}
            variant="text"
          >
            Back
          </Button>
          <Button disabled={isLoading} onClick={() => onSubmit()}>
            {isLoading
              ? intl.formatMessage({
                  defaultMessage: 'Creating your goal...',
                  id: 'Hkphf5'
                })
              : currentStep.buttonText}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default PremadeGoal
