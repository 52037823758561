import { FormattedMessage } from 'react-intl'
import { useGetCompletedGoalsQuery } from 'api/goalsApi'
import { equals, prop, length } from 'ramda'
import { Box, Stack, Typography } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

import GoalCard from './GoalCard'
import EmptyState from './EmptyState'

const Completed = () => {
  const { data: goals, isFetching = true } = useGetCompletedGoalsQuery()

  if (isFetching) return null

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center',
        }}
      >
        <CheckRoundedIcon color="inherit" />
        <Typography variant="subtitle1">
          <FormattedMessage
            defaultMessage="{number} Completed Goals"
            id="5fkgez"
            values={{ number: length(goals) }}
          />
        </Typography>
      </Stack>

      {equals(0, length(goals)) ? (
        <EmptyState type="completed" />
      ) : (
        <Box sx={{ display: 'grid', gridGap: 24, my: 3 }}>
          {goals.map(goal => (
            <GoalCard goal={goal} isCompleted key={prop('id', goal)} />
          ))}
        </Box>
      )}
    </>
  )
}

Completed.propTypes = {}

export default Completed
