import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'

const BlockWrapper = ({ title, children }) => (
  <Stack spacing={6}>
    <Typography
      sx={{
        color: 'primary.main',
        textAlign: 'center',
      }}
      variant="h2"
    >
      {title}
    </Typography>
    {children}
  </Stack>
)

BlockWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  title: PropTypes.node.isRequired,
}

export default BlockWrapper
