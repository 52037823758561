import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { isNil, not } from 'ramda'

const PageHeroBanner = ({ imageUrl }) =>
  not(isNil(imageUrl)) && (
    <Box
      data-testid="banner"
      sx={{
        background: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 250,
        width: 1,
      }}
    />
  )

PageHeroBanner.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default PageHeroBanner
