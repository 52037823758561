/* eslint-disable sort-keys */
import { Box, Button, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'

const mhcdTopics = [
  {
    image: '/images/welcome/cta/finances.jpg',
    internalURL: '/public',
    title: 'Finances'
  },
  {
    image: '/images/welcome/cta/fitness.jpg',
    internalURL: '/public',
    title: 'Fitness'
  },
  {
    image: '/images/welcome/cta/balance.jpg',
    internalURL: '/public',
    title: 'Work-Life Balance'
  }
]

const CTA = () => {
  const { sendEvent } = useAnalytics()

  const renderTopics = mhcdTopics

  const getSubheadText = () => (
    <FormattedMessage
      defaultMessage="Find expert perspectives, community stories and life hacks across a range of topics to support your health and well-being."
      id="2w8vxh"
    />
  )

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridGap: 40,
        gridTemplateColumns: {
          xs: '1fr',
          md: 'repeat(2, 1fr)',
          lg: '550px 1fr'
        },
        margin: '0 auto',
        maxWidth: 1440,
        position: 'relative',
        px: { xs: 2, md: 4 }
      }}
    >
      <Box
        sx={{
          background: 'url(/images/welcome/hash-circle-full.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 383, sm: 550, md: 400, lg: 550 },
          left: {
            xs: 'calc(50% - 200px)',
            sm: 'calc(50% - 250px)',
            md: '5%'
          },
          position: 'absolute',
          top: -50,
          width: { xs: 383, sm: 550, md: 400, lg: 550 },
          zIndex: 0
        }}
      />
      <Box
        component={Link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifySelf: 'center',
          maxWidth: { xs: 375, sm: 522, md: 400, lg: 522 },
          position: 'relative',
          py: 4,
          width: 1
        }}
        to="/public"
      >
        {renderTopics.map(({ title, image }) => (
          <Box
            key={title}
            sx={{
              ':first-of-type': {
                alignSelf: 'flex-end',
                mt: -5,
                mr: { xs: 10, sm: 6, md: 10, lg: 6 }
              },
              ':hover': {
                cursor: 'pointer'
              },
              ':last-of-type': {
                alignSelf: 'flex-end',
                mr: { xs: 0, sm: 4, md: 8, lg: 0 },
                mt: { xs: -14, sm: -8, lg: -16 }
              },
              ':nth-of-type(2)': {
                alignSelf: 'flex-start',
                ml: { xs: 2, sm: 8, md: 4, lg: 8 },
                mr: 'auto',
                mt: { xs: 0, sm: -8 }
              },
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gridGap: 8
            }}
          >
            <Box
              sx={{
                background: `url(${image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                height: { xs: 138, sm: 218, md: 138, lg: 218 },
                width: { xs: 138, sm: 218, md: 138, lg: 218 }
              }}
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: 8,
          pl: { xs: 0, md: 2, lg: 6 }
        }}
      >
        <Typography color="primary" variant="h2">
          <FormattedMessage
            defaultMessage="Not ready to sign up? Explore popular topics on YOU."
            id="bB2+xb"
          />
        </Typography>
        <Typography color="primary" mb={16} variant="bodyLarge">
          {getSubheadText()}
        </Typography>
        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'OFD Welcome Explore Topics',
              click_element: 'Link',
              element_title: 'Explore Topics CTA',
              path: window.location.pathname
            })
          }
          to="/public"
        >
          <FormattedMessage defaultMessage="Explore Topics" id="71zhJ/" />
        </Button>
      </Box>
    </Box>
  )
}

export default CTA
