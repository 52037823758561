/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { keyframes } from '@mui/system'
import { MenuItem, Select } from '@mui/material'
import { prop } from 'ramda'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const CardsSelectMenu = ({
  highlighted,
  options,
  selectedRole,
  handleSelectRoleChange,
}) => {
  const slideInFromLeftAnimation = keyframes`
    0%: {
      width: 0,
    }
    100%: {
      width: 100%,
    }
  `

  // const wiggleAnimation = keyframes({
  //   '0%': {
  //     transform: 'translateX(0%)',
  //   },
  //   '25%': {
  //     transform: 'translateX(-10%)',
  //   },
  //   '50%': {
  //     transform: 'translateX(0%)',
  //   },
  //   '75%': {
  //     transform: 'translateX(10%)',
  //   },
  //   // eslint-disable-next-line sort-keys
  //   '100%': {
  //     transform: 'translateX(0%)',
  //   },
  // })

  return (
    <Select
      aria-label="get tips and tools for"
      IconComponent={ExpandMoreIcon}
      onChange={handleSelectRoleChange}
      sx={{
        '& > .MuiSelect-icon': {
          bgcolor: 'school.primary',
          borderRadius: '50%',
          color: 'white',
        },
        '& > .MuiSelect-select': {
          position: 'relative',
          zIndex: 1,
        },
        '& > .MuiSelect-select:after': {
          animation: `${highlighted && slideInFromLeftAnimation} 1s ease`,
          animationFillMode: 'forwards',
          backgroundColor: 'school.highlight',
          bottom: 16,
          content: '""',
          height: 20,
          maxWidth: 390,
          left: 0,
          opacity: 0.7,
          position: 'absolute',
          right: 20,
          zIndex: '-1',
        },
        ':before': {
          content: 'none',
        },
        fontFamily: 'mark-ot-bold',
        fontSize: { xs: 28, sm: 42 },
        letterSpacing: '-1px',
        lineHeight: { xs: '39px', sm: '53px' },
        maxWidth: 425,
      }}
      value={prop('value', selectedRole)}
      variant="standard"
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

CardsSelectMenu.propTypes = {
  handleSelectRoleChange: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedRole: PropTypes.shape({}).isRequired,
}

export default CardsSelectMenu
