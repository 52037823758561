import { useEffect } from 'react'
import { and, isNil, not, prop } from 'ramda'
import { useGetSchoolDataQuery } from 'api/schoolApi'
import { setSchool as setState } from './schoolSlice'
import { useDispatch, useSelector } from 'react-redux'

import renderOrgDomain from './getOrgDomain'

const SchoolLoader = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(prop('school'))
  const orgDomain = renderOrgDomain()

  const { data } = useGetSchoolDataQuery(orgDomain, {
    skip: and(isNil(orgDomain), not(isNil(id)))
  })

  useEffect(() => {
    if (and(not(isNil(data)), isNil(id))) {
      dispatch(setState(data))
      sessionStorage.setItem('org_domain', prop('domain', data))
    }
  }, [data])

  return
}

export default SchoolLoader
