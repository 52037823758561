import CountySelect from './CountySelect'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setLocation } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  location: prop('location', authentication),
})

const mapDispatchToProps = {
  setLocation,
}

export default connect(mapStateToProps, mapDispatchToProps)(CountySelect)
