import PropTypes from 'prop-types'
import { Box, Button, Stack } from '@mui/material'
import { equals } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'
import { useLocation } from 'react-router-dom'

const FilterSortMenu = ({
  options = [],
  handleChange,
  activeId,
  defaultLabel = 'all',
  keenEventTitle = 'filter_engagement'
}) => {
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const sendKeenIOEvent = name =>
    sendEvent(keenEventTitle, {
      filter_selection: name,
      page_title: pathname
    })

  return (
    <Box
      sx={{
        height: 60,
        maxWidth: 1280,
        mx: 'auto',
        my: 2,
        overflow: 'hidden',
        position: 'relative',
        px: 1.5,
        width: 1
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: 'auto',
          // eslint-disable-next-line sort-keys
          pb: { xs: 'unset', lg: 1 },
          position: 'absolute',
          top: 0,
          width: 'calc(100% - 16px)'
        }}
      >
        <Button
          aria-label={`${equals(activeId, 0) ? 'selected: ' : ''} All Options`}
          onClick={() => {
            sendKeenIOEvent(defaultLabel)
            handleChange(0)
          }}
          selected={equals(activeId, 0)}
          variant="filterMenu"
        >
          {defaultLabel}
        </Button>
        {options.map(({ name, id }) => (
          <Button
            aria-label={`${equals(activeId, id) ? 'selected: ' : ''} ${name}`}
            key={id}
            onClick={() => {
              sendKeenIOEvent(name)
              handleChange(id)
            }}
            selected={equals(activeId, id)}
            variant="filterMenu"
          >
            {name}
          </Button>
        ))}
      </Stack>
    </Box>
  )
}

export default FilterSortMenu

FilterSortMenu.propTypes = {
  activeId: PropTypes.any.isRequired,
  defaultLabel: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  keenEventTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string
    })
  )
}
