import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { compose, equals, or, prop } from 'ramda'
import { Link, useNavigate } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

const ProfileDropdown = () => {
  const { sendEvent, isSuccess, isError } = useAnalytics()
  const navigate = useNavigate()

  const { avatar, allowed_to_view_stats_endpoint: canViewStatsDash } =
    useSelector(prop('user'))

  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const [visitTime, setVisitTime] = useState(null)

  useEffect(() => {
    setVisitTime(Math.floor(Date.now() / 1000))
  }, [])

  const handleBeforeLogout = () => {
    if (equals(0, canViewStatsDash)) {
      sendEvent('time_on_site', {
        time_on_site: Math.floor(Date.now() / 1000) - visitTime
      })
    }
    navigate('/logout')
  }

  useEffect(() => {
    if (or(isSuccess, isError)) {
      navigate('/logout')
    }
  }, [isSuccess, isError])

  return (
    <Box>
      <IconButton
        aria-haspopup="menu"
        aria-label="additional links dropdown"
        onClick={compose(setAnchorEl, prop('currentTarget'))}
        sx={{ py: 0 }}
      >
        <Avatar src={avatar} />
        {isOpen ? (
          <ExpandLessRoundedIcon sx={{ color: 'white' }} />
        ) : (
          <ExpandMoreRoundedIcon sx={{ color: 'white' }} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        onClose={() => setAnchorEl(null)}
        open={isOpen}
        sx={{
          top: 5
        }}
      >
        <MenuItem component={Link} to="/profile?active=about">
          <FormattedMessage defaultMessage="Profile" id="itPgxd" />
        </MenuItem>
        <MenuItem component={Link} to="/feedback">
          <FormattedMessage defaultMessage="Feedback" id="Ejhdi4" />
        </MenuItem>
        <MenuItem onClick={() => handleBeforeLogout()}>
          <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ProfileDropdown
