import StudentOrStaff from './StudentOrStaff'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setRole } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  role: prop('role', authentication),
})

export default connect(mapStateToProps, { setRole })(StudentOrStaff)
