import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { assoc, equals, inc, isNil, length, prop, propEq } from 'ramda'
import { Avatar, Button, Stack, Typography } from '@mui/material'
import {
  useUpdateScoreMutation,
  useUpdateSocialCountMutation
} from 'api/statsApi'
import useAnalytics from 'hooks/useAnalytics'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const QuizQuestion = ({ currentQuiz, question, questions, setQuestion }) => {
  const dispatch = useDispatch()
  const { userDomains } = useSelector(prop('user'))
  const { sendEvent } = useAnalytics()
  const navigate = useNavigate()

  const [submitAnswer] = useUpdateScoreMutation()
  const [updateSocialCount, { isSuccess }] = useUpdateSocialCountMutation()

  if (isNil(userDomains)) return null

  useEffect(() => {
    if (isSuccess) {
      assoc('completed', true, currentQuiz)
      // results are generated on the page load for results page, so don't need to do anything
      // special aside from route to it.
      sendEvent('reality_check_result', {
        section_name:
          prop('title', currentQuiz).charAt(0).toUpperCase() +
          prop('title', currentQuiz).slice(1)
      })
      navigate(
        `/self-checks/results/${prop('title', currentQuiz).toLowerCase()}`
      )
    }
  }, [isSuccess])

  const handleAnswer = score => {
    const body = new FormData()
    body.append('value', score)
    submitAnswer({ data: body, id: prop('id', question) })

    // if we are on the final question, we transition to results
    // otherwise we advance to the question
    const newIndex = inc(questions.indexOf(question))
    if (equals(newIndex, length(questions))) {
      // update the social count since someone finished the currentQuiz
      return updateSocialCount(prop('id', currentQuiz))
    }
    setQuestion(prop(newIndex, questions))
  }

  const subdomains = subdomainsFromUserDomains(userDomains)
  const subdomain = subdomains.find(propEq('id', prop('groupId', question)))

  return (
    <Stack alignItems="center" spacing={4}>
      <Typography component="h1" my={3} textAlign="center" variant="h4">
        {question.prompt}
      </Typography>
      <Stack
        spacing={3}
        sx={{
          // eslint-disable-next-line sort-keys
          width: { xs: 1, sm: '50%' }
        }}
      >
        {question.answers.map(({ answer, score, trigger_crisis_modal }) => (
          <Button
            fullWidth
            key={answer}
            onClick={() => {
              if (trigger_crisis_modal) dispatch(setIsCrisisModalOpen(true))
              handleAnswer(score)
            }}
            variant="quiz"
          >
            {answer}
          </Button>
        ))}
      </Stack>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <Avatar
          alt=""
          src={prop('display_image', subdomain)}
          sx={{
            height: 67,
            width: 67
          }}
        />
        <Typography variant="caption">
          <FormattedMessage
            defaultMessage="This question relates to <t>{subdomainName}...</t>"
            id="9pi0iU"
            values={{
              subdomainName: prop('group', question),
              t: chunks => (
                <Typography color="secondary.dark" variant="captionBold">
                  {chunks}
                </Typography>
              )
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  )
}

QuizQuestion.propTypes = {
  currentQuiz: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    group: PropTypes.string,
    prompt: PropTypes.string
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setQuestion: PropTypes.func.isRequired
}

export default QuizQuestion
