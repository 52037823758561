import { Box, Chip, Divider, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { Link } from 'react-router-dom'

const nodColorSchemes = {
  blue: {
    accent: '#91d4ff',
    color: '#aeddfb',
    ui: '#31afff'
  },
  cyan: {
    accent: '#8edbdb',
    color: '#a1e2e2',
    ui: '#2fb1b1'
  },
  green: {
    accent: '#98e0b2',
    color: '#b4e8c7',
    ui: '#31cd6a'
  },
  orange: {
    accent: '#ebc2a9',
    color: '#f1d2bf',
    ui: '#e29769'
  },
  purple: {
    accent: '#c9c8ec',
    color: '#d6d5ef',
    ui: '#9795cc'
  },
  red: {
    accent: '#eec1c1',
    color: '#f5cece',
    ui: '#de8e8e'
  }
}

const NodCard = ({
  challenge,
  internalUrl,
  imageUrl,
  studentCount,
  challengeTitle,
  description
}) => {
  const color = prop('theme_name', challenge)
  const renderDescription = str => str.split('<p>')[1].split('</p>')[0]

  return (
    <Box
      component={Link}
      sx={{
        bgcolor: path([color, 'color'], nodColorSchemes),
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        pb: 2,
        pt: 1,
        px: 2
      }}
      textDecoration="none"
      to={internalUrl}
    >
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: `${color}.accent`,
            borderRadius: '50%',
            display: 'flex',
            height: 190,
            justifyContent: 'center',
            m: '0 auto',
            position: 'relative',
            width: 190
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition: 'left center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              bgcolor: `${color}.accent`,
              borderRadius: '50%',
              height: '85%',
              left: 15,
              position: 'absolute',
              width: '85%'
            }}
          />
        </Box>
        <Chip
          color="secondary"
          icon={<PeopleRoundedIcon />}
          label={studentCount + ' Students'}
        />
      </Box>

      <Typography component="h5" my="1.5rem" variant="cardTitle">
        {challengeTitle}
      </Typography>
      <Typography aria-hidden color="primary.main" variant="body2">
        {renderDescription(description)}
      </Typography>

      <Divider />

      <Box alignItems="center" display="flex">
        <Box
          alt=""
          component="img"
          height={23}
          src="/images/icons/nod-logo.svg"
          width={36}
        />
        <Typography color="primary.main" ml="1.5rem" variant="body2">
          <FormattedMessage
            defaultMessage="An app to help you build social connections"
            id="8KibvP"
          />
        </Typography>
      </Box>
    </Box>
  )
}

export default NodCard

NodCard.propTypes = {
  challenge: PropTypes.shape({}).isRequired,
  challengeTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  internalUrl: PropTypes.string.isRequired,
  studentCount: PropTypes.number.isRequired
}
