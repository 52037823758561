import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import {
  and,
  assoc,
  gt,
  inc,
  isEmpty,
  isNil,
  length,
  not,
  or,
  path,
  prop,
  slice
} from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGetPublicCardsQuery } from 'api/publicApi'
import { useGetAddressCoordinatesQuery } from 'api/addressApi'
import getParams from 'utils/getParams'
import useCurrentDomain from 'hooks/useCurrentDomain'
import { useSelector } from 'react-redux'

import LocationInput from './LocationInput'
import CTASection from '../components/CTASection'
import ResourceCard from '../components/cards/ResourceCard'
import CTACard from '../components/cards/CTACard'
import BreadCrumbs from '../components/BreadCrumbs'
import LoadMoreButton from 'components/common/LoadMoreButton'
import MasonryWrapper from 'components/common/MasonryWrapper'

const ResourcesNearMe = () => {
  const { banner } = useSelector(prop('school'))
  const { currentDomain } = useCurrentDomain()
  const intl = useIntl()
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({})

  // Getting Geo Location
  const formatted = assoc('street', prop('street_line', selected), selected)
  const { data: coordinates } = useGetAddressCoordinatesQuery(
    getParams({
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      ...formatted
    }),
    { skip: isEmpty(selected) }
  )

  const lat = path(['metadata', 'latitude'], prop(0, coordinates))
  const lng = path(['metadata', 'longitude'], prop(0, coordinates))

  const { data, isFetching } = useGetPublicCardsQuery(
    `org_domain=${currentDomain}&page[take]=${
      page * 11
    }&filter[types]=11&filter[lat]=${lat}&filter[lng]=${lng}`,
    {
      skip: or(isNil(lat), isNil(lng))
    }
  )

  return (
    <Box
      component="article"
      id="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 8
      }}
    >
      <Box
        sx={{
          height: 370,
          // eslint-disable-next-line sort-keys
          mb: { xs: 4, sm: 8.5 },
          width: 1
        }}
      >
        <img
          alt=""
          src={banner}
          style={{
            border: 'none',
            height: '100%',
            objectFit: 'cover',
            width: '100%'
          }}
        />
      </Box>
      <Box>
        <Box
          sx={{
            margin: '0 auto',
            maxWidth: 1280,
            pb: 4,
            pl: 3,
            pr: 2,
            width: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 40
            }}
          >
            <BreadCrumbs
              links={[
                {
                  href: '/public',
                  label: intl.formatMessage({
                    defaultMessage: 'Explore',
                    id: '7JlauX'
                  })
                },
                {
                  href: '',
                  label: intl.formatMessage({
                    defaultMessage: 'Resources',
                    id: 'c/KktL'
                  })
                }
              ]}
            />
            <Typography alignSelf="center" color="primary" variant="h1">
              <FormattedMessage
                defaultMessage="Resources Near Me"
                id="TMmOp9"
              />
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              {not(isNil(data)) && (
                <Typography pb="8px" variant="body1">
                  <FormattedMessage
                    defaultMessage="Showing results near"
                    id="fLEiUR"
                  />
                </Typography>
              )}
              <LocationInput selected={selected} setSelected={setSelected} />
            </Box>
            {and(not(isNil(data)), not(isEmpty(selected))) ? (
              <>
                <MasonryWrapper>
                  {slice(0, 2, data).map(card => (
                    <ResourceCard key={prop('id', card)} {...card} />
                  ))}
                  <CTACard />
                  {slice(2, Infinity, data).map(card => (
                    <ResourceCard key={prop('id', card)} {...card} />
                  ))}
                </MasonryWrapper>
                {not(gt(length(data) % 11, 0)) && (
                  <LoadMoreButton
                    handleClick={() => setPage(inc(page))}
                    loading={isFetching}
                  />
                )}
              </>
            ) : (
              <Box
                sx={{
                  alignSelf: 'center'
                }}
              >
                <Typography variant="body2">
                  <FormattedMessage
                    defaultMessage="Please enter an address to see Resources"
                    id="OhnMGy"
                  />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <CTASection />
    </Box>
  )
}

export default ResourcesNearMe
