/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import {
  defaultTo,
  prop,
  propEq,
  sortBy,
  isNil,
  assoc,
  inc,
  dec,
  find,
  length,
  filter
} from 'ramda'
// import GoalDateDisplay from './GoalDateDisplay'

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  Typography,
  LinearProgress,
  Stack
} from '@mui/material'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useUpdateGoalMutation } from 'api/goalsApi'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const InProgressGoal = ({ goal }) => {
  const { userDomains } = useSelector(prop('user'))

  const [updateGoal] = useUpdateGoalMutation()

  const subdomains = defaultTo([], subdomainsFromUserDomains(userDomains))
  const subdomain = find(propEq(prop('subdomain_id', goal), 'id'))(subdomains)

  const steps = sortBy(prop('order'))(prop('steps', goal))
  const stepLength = length(steps)
  const stepToDisplay = find(propEq(false, 'completed'))(steps)

  // If goal is completed then return step length
  // If goal is not completed but all steps are completed return the last step, but increase the order prop
  // Else return the first non completed step
  const currentStep = () => {
    if (prop('isCompleted', goal)) return stepLength
    if (isNil(stepToDisplay))
      return assoc(
        'order',
        inc(prop('order', steps[dec(stepLength)])),
        steps[dec(stepLength)]
      )
    return stepToDisplay
  }

  const totalCompleted = filter(propEq(true, 'completed'), steps)

  return (
    <Card>
      <CardContent>
        <CardActionArea
          LinkComponent={Link}
          to={`/goals/view/${prop('id', goal)}`}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'grid',
              gridGap: '8px 16px',
              gridTemplateColumns: {
                xs: '91px 1fr',
                sm: '91px 1fr 0.75fr'
              },
              gridTemplateRows: { xs: '1fr 24px', sm: '1fr' },
              mb: 2,
              width: '100%'
            }}
          >
            <Avatar
              alt="subdomain"
              src={prop('display_image', subdomain)}
              variant="subdomain"
            />
            <Box display="flex" flexDirection="column">
              <Typography
                color="primary.main"
                component="span"
                variant="subtitle1"
              >
                <FormattedMessage
                  defaultMessage="Your goal in {goalTitle}"
                  id="Ezin2J"
                  values={{ goalTitle: prop('goal_category_name', subdomain) }}
                />
              </Typography>
              <Typography variant="cardTitle">{prop('title', goal)}</Typography>
            </Box>
            <Typography
              alignItems="center"
              alignSelf={['unset', 'flex-start']}
              component="span"
              display="flex"
              gridColumn={['1/3', '3']}
              justifySelf={['unset', 'flex-end']}
              variant="overline"
            >
              <FormattedMessage defaultMessage="View Goal" id="qPRvT+" />
              <ArrowForwardRoundedIcon fontSize="inherit" />
            </Typography>
          </Box>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                color: 'school.secondary',
                width: 1
              }}
            >
              <LinearProgress
                color="inherit"
                value={(length(totalCompleted) / stepLength) * 100}
                variant="determinate"
              />
            </Box>
            <Typography
              aria-label={`${length(totalCompleted)} / ${stepLength} steps`}
              color="secondary.dark"
              fontFamily="mark-ot-bold"
              ml="8px"
              variant="subtitle1"
              whiteSpace="nowrap"
            >
              <FormattedMessage
                defaultMessage="{label}"
                id="j8y+qc"
                values={{
                  label: `${length(totalCompleted)} / ${stepLength} steps`
                }}
              />
            </Typography>
          </Stack>
          <Box sx={{ alignSelf: 'flex-start' }}>
            <Typography
              component="span"
              display="block"
              mb={0}
              mt="12px"
              variant="overline"
            >
              <FormattedMessage defaultMessage="Next step" id="d+qgix" />
            </Typography>
            <Typography
              component="span"
              display="block"
              my="8px"
              textAlign="left"
              variant="body1"
            >
              {currentStep()?.name}
            </Typography>
          </Box>
        </CardActionArea>
        <CardActions variant="flex-end">
          <Button
            LinkComponent={Link}
            onClick={() => {
              updateGoal({
                body: assoc('completed', true, goal),
                id: prop('id', goal)
              })
            }}
            to="/goals/complete"
          >
            <FormattedMessage
              defaultMessage="Mark goal as complete"
              id="JvPa+I"
            />
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}

InProgressGoal.propTypes = {
  goal: PropTypes.shape({}).isRequired
}

export default InProgressGoal
