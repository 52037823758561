import PropTypes from 'prop-types'
import { keyframes } from '@emotion/react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { equals, inc } from 'ramda'

const WelcomeNumberNav = ({
  items,
  currentItem,
  handleClick,
  positionNumbersOnTop = false,
  ariaLabel = '',
  caption,
  captionBoxStyles = {}
}) => {
  const theme = useTheme()
  const draw = keyframes({
    to: {
      strokeDashoffset: 0
    }
  })

  return (
    <Stack>
      <Stack
        direction="row"
        // // eslint-disable-next-line sort-keys
        // spacing={{ xs: 1, md: 4 }}
        sx={{
          '& > .active:after': {
            ...(positionNumbersOnTop
              ? {
                  top: '-66%'
                }
              : {
                  top: '-70%'
                }),
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderTop: '20px solid white',
            content: '""',
            filter: 'drop-shadow(0px 3px 0px rgba(79, 79, 79, 0.15))',
            left: 12,
            position: 'absolute',
            zIndex: 2
          },
          justifyContent: 'center',
          order: positionNumbersOnTop ? 1 : 2
        }}
      >
        {items.map((item, index) => (
          <Box
            className={index === currentItem && 'active'}
            key={index}
            position="relative"
          >
            <Button
              aria-label={ariaLabel}
              onClick={e => handleClick(e, index)}
              variant="number"
            >
              {inc(index)}
              <Box
                sx={{
                  '& > svg': {
                    stroke: 'Highlight'
                  },
                  '& > svg > path': {
                    animation:
                      equals(index, currentItem) && `${draw} 1s forwards`,
                    stroke: theme.palette.school.highlight,
                    strokeDasharray: '400',
                    strokeDashoffset: '400',
                    strokeOpacity: '0.7'
                  },
                  position: 'absolute'
                }}
              >
                <svg fill="none" height="65" viewBox="0 0 72 65" width="72">
                  <path
                    d="M26.8321 9.26451C13.3321 14.2645 0.822309 29.4133 6.33215 44.2645C12.3663 60.5289 34.9144 62.1187 49.8324 53.2645C64.0645 44.8174 73.567 26.649 63.8324 13.2645C52.4199 -2.42699 18.4996 4.5001 16.5 30C14.5004 55.4999 29.349 61.7285 44.8324 59.7644C59.948 57.8469 69.2141 40.8574 64.8324 26.2644C60.4626 11.7109 42.3324 5.26451 29.8324 9.26451"
                    strokeWidth="8"
                  />
                </svg>
              </Box>
            </Button>
          </Box>
        ))}
      </Stack>

      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: 0.5,
          boxShadow: '0px 2px 4px rgba(79, 79, 79, 0.25)',
          mb: 4,
          mt: 2.5,
          mx: 'auto',
          // eslint-disable-next-line sort-keys
          p: { xs: 3, md: 2 },
          position: 'relative',
          zIndex: 1,
          ...captionBoxStyles
        }}
      >
        <Typography color="secondary.dark" position="relative" variant="body1">
          {caption}
        </Typography>
      </Box>
    </Stack>
  )
}

WelcomeNumberNav.propTypes = {
  ariaLabel: PropTypes.string,
  caption: PropTypes.string.isRequired,
  captionBoxStyles: PropTypes.shape({}),
  currentItem: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  positionNumbersOnTop: PropTypes.bool
}

export default WelcomeNumberNav
