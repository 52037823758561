/* eslint-disable sort-keys */
import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FilterSortMenu from 'components/common/FilterSortMenu'
import { TYPE_CAMPUS_GOAL } from 'utils/card-functions'
import ExploreGoalCard from './ExploreGoalCard'
import LoadMoreButton from 'components/common/LoadMoreButton'
import {
  gt,
  reverse,
  sortBy,
  prop,
  path,
  not,
  equals,
  inc,
  length
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import MasonryWrapper from 'components/common/MasonryWrapper'
import { useSelector } from 'react-redux'

const Explore = () => {
  const { userDomains } = useSelector(prop('user'))

  // needs to be 0 for the filter sort component (0 is the 'all' index)
  const [domainId, setDomainId] = useState(0)
  const [page, setPage] = useState(1)

  const params = {
    domain: domainId,
    skip: 6,
    take: page * 6,
    type: TYPE_CAMPUS_GOAL
  }

  const { data, isFetching } = useGetCardsQuery(params)

  const filterOptions = useMemo(
    () =>
      reverse(sortBy(prop('sort'), userDomains)).map(domain => ({
        id: domain.id,
        name: `${domain.frontend_name} / ${domain.subtitle}`
      })),
    [userDomains]
  )

  return (
    <Box>
      <FilterSortMenu
        activeId={domainId}
        handleChange={id => setDomainId(id)}
        options={filterOptions}
      />

      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MasonryWrapper>
            {gt(length(data), 0) &&
              data.map(card => {
                const nonActiveStep = gt(
                  length(path(['campus_goal', 'steps'], card)),
                  0
                )
                  ? path(['campus_goal', 'steps'], card)
                  : null

                return (
                  <ExploreGoalCard
                    goalTitle={prop('title', card)}
                    id={path(['campus_goal', 'card_id'], card)}
                    key={prop('id', card)}
                    stepDetail={nonActiveStep}
                    subdomainId={path(['campus_goal', 'subdomain_id'], card)}
                    totalSteps={length(path(['campus_goal', 'steps'], card))}
                  />
                )
              })}
          </MasonryWrapper>
        </Box>

        {not(equals(length(data) % 2, NaN)) && (
          <LoadMoreButton
            handleClick={() => setPage(inc(page))}
            loading={isFetching}
          />
        )}

        <Stack spacing={2} sx={{ mt: 3, px: 2 }}>
          <Typography sx={{ ml: 2 }} variant="h4">
            <FormattedMessage
              defaultMessage="Don't see the goal for you?"
              id="mEaq8w"
            />
          </Typography>
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            LinkComponent={Link}
            to="/goals/create/new"
            variant="text"
          >
            <FormattedMessage defaultMessage="create my own goal" id="wn1nUL" />
          </Button>
        </Stack>
      </Container>
    </Box>
  )
}

export default Explore
