import { FormattedMessage } from 'react-intl'
import TakeQuizCard from './components/TakeQuizCard'
import { or, isNil, sortBy, prop, propEq, __, find } from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { Box, Container, Skeleton, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const SelfChecks = () => {
  const { userDomains } = useSelector(prop('user'))

  const { data: selfChecks } = useGetSelfCheckQuizDataQuery(__, {
    refetchOnMountOrArgChange: true
  })

  const findDomain = domain =>
    find(propEq(prop('id', domain), 'id'), selfChecks)

  return (
    <Container sx={{ mb: 4 }}>
      <Stack spacing={3}>
        <Stack
          spacing={3}
          sx={{
            alignItems: 'center'
          }}
        >
          <Typography textAlign="center" variant="h1">
            <FormattedMessage
              defaultMessage="Check in with myself"
              id="j1d8tQ"
            />
          </Typography>
          <Typography component="span" textAlign="center">
            <FormattedMessage
              defaultMessage="Self Checks provide a quick snapshot of your well-being."
              id="+/JnDv"
            />
          </Typography>
        </Stack>
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: {
              xs: 'max(360px)',
              // eslint-disable-next-line sort-keys
              md: 'repeat(3, 1fr)'
            },
            justifyContent: 'center'
          }}
        >
          {or(isNil(userDomains), isNil(selfChecks)) ? (
            <>
              <Skeleton height={500} variant="skeleton" width="100%" />
              <Skeleton height={500} variant="skeleton" width="100%" />
              <Skeleton height={500} variant="skeleton" width="100%" />
            </>
          ) : (
            sortBy(prop('sort'), userDomains).map(domain => (
              <TakeQuizCard
                completed={prop('completed', findDomain(domain))}
                domainUrlSlug={prop('frontend_url_slug', domain)}
                image={prop('frontend_url_slug', domain)}
                key={prop('id', domain)}
                socialCount={prop(
                  'reality_check_quiz_social_count',
                  findDomain(domain)
                )}
                subdomains={prop('subdomains', domain)}
                title={prop('frontend_name', domain)}
              />
            ))
          )}
        </Box>
      </Stack>
    </Container>
  )
}

export default SelfChecks
