/* eslint-disable sort-keys */
import { FormattedMessage, useIntl } from 'react-intl'
import { useCurrentAppType } from 'hooks'
import { Box, Button, Typography } from '@mui/material'
import useAnalytics from 'hooks/useAnalytics'

const WelcomeSectionResources = () => {
  const intl = useIntl()
  const { isCollective } = useCurrentAppType()
  const { sendEvent } = useAnalytics()
  const mask = '/images/welcome/mask-resources.svg'
  const mobileMask = '/images/welcome/mask-tools-mobile.svg'

  // TEMPLATE VARIABLES
  let PDF, headline, content, documentImage1, documentImage2

  // COLLECTIVE COPY
  if (isCollective) {
    PDF = '/pdfs/collective_resources_download.pdf'
    headline = intl.formatMessage({
      defaultMessage: 'Download the free Collective guide.',
      id: 'YZBRLP'
    })
    content = (
      <Typography pb={2} pt=".5em" variant="body1">
        A quick guide to the information primary.main youth will find for
        yourself on The Collective platform.
      </Typography>
    )
    documentImage1 = '/images/welcome/documents-images/CL_PDFicon_1.png'
    documentImage2 = '/images/welcome/documents-images/CL_PDFicon_2.png'
  }

  const ContentSection = () => (
    <Box
      sx={{
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ml: { xs: 'initial', lg: '4em' },
        px: { xs: 1, lg: 0 },
        width: { xs: 'initial', lg: '65%' }
      }}
    >
      <Typography tabIndex={0} variant="h3">
        <FormattedMessage
          defaultMessage="{headline}"
          id="AEeXEL"
          values={{ headline }}
        />
      </Typography>
      {content}
      <DownloadButton />
    </Box>
  )

  const DocumentsImage = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        justifyContent: 'center',
        mb: { xs: 2.5, lg: 0 },
        ml: { xs: '7%', sm: '8%', md: '15%', lg: 0 },
        position: 'relative',
        width: { xs: '10em', sm: '12em' }
      }}
    >
      <Box
        sx={{
          background: `url(${documentImage2})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: 240,
          left: 70,
          position: 'absolute',
          transform: 'rotate(8deg)',
          width: 240
        }}
      />
      <Box
        sx={{
          background: `url(${documentImage1})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: 240,
          left: 40,
          position: 'absolute',
          transform: 'rotate(-8deg)',
          width: 240
        }}
      />
    </Box>
  )

  const DownloadButton = () => (
    <Button
      download
      href={PDF}
      LinkComponent="a"
      onClick={() =>
        sendEvent('welcome_page', {
          section: 'resources',
          element_title: 'download pdf',
          click_element: 'button'
        })
      }
      variant="download-pdf"
    >
      <FormattedMessage defaultMessage="Download PDF" id="TclprG" />
    </Button>
  )

  const maskStyles = {
    '& svg': {
      fill: '#FFCC00'
    },
    display: 'flex',
    height: { xs: 667, md: 450 },
    maskImage: { xs: `url(${mobileMask})`, lg: `url(${mask})` },
    maskRepeat: 'no-repeat',
    maskSize: 'cover',
    pt: { xs: '30%', md: '95%', lg: '50%' },
    width: '90%'
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        bgcolor: isCollective ? 'collective.dark' : 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mb: '10%',
        pt: '15%',
        width: '100vw'
      }}
    >
      <Box
        sx={{
          bgcolor: 'school.secondary',
          ml: { xs: '-5%', md: '-2%' },
          mt: { xs: '6%', md: '-2%' },
          ...maskStyles
        }}
      />
      <Box
        sx={{
          bgcolor: 'school.primary',
          position: 'absolute',
          ...maskStyles
        }}
      />
      <Box
        sx={{
          alignItems: { xs: 'initial', lg: 'center' },
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          height: { xs: '70%', md: '65%', lg: '55%' },
          justifyContent: 'space-around',
          left: { xs: 'initial', md: '11%', lg: 'initial' },
          position: 'absolute',
          width: { xs: '80%', md: '45%', lg: '85%' }
        }}
      >
        <DocumentsImage />
        <ContentSection />
      </Box>
    </Box>
  )
}

export default WelcomeSectionResources
