import { Button, Drawer, IconButton, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import LogInButton from '../common/LogInButton'
import CrisisButton from '../common/CrisisButton'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useWindowResize from 'hooks/useWindowResize'
import { lt } from 'ramda'
import { useEffect } from 'react'

const LoggedOutSidebar = ({ isOpen, setIsOpen }) => {
  const { x } = useWindowResize()
  useEffect(() => {
    if (lt(x, 1023)) setIsOpen(false)
  }, [x])

  return (
    <Drawer anchor="right" open={isOpen}>
      <IconButton
        aria-label="close sidebar"
        onClick={() => setIsOpen(false)}
        sx={{
          alignSelf: 'flex-end',
          // eslint-disable-next-line sort-keys
          display: { xs: 'flex', lg: 'none' },
          mr: 1,
          mt: 1
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <Stack alignItems="center" spacing={2} sx={{ width: 1 }}>
        <CrisisButton color="primary" fill="primary" />
        <LogInButton icon={<HomeIcon />}>
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        </LogInButton>
        <Button LinkComponent={Link} to="/sign-up">
          <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
        </Button>
      </Stack>
    </Drawer>
  )
}

LoggedOutSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default LoggedOutSidebar
