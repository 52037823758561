import { useState } from 'react'
import {
  Autocomplete,
  Button,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { equals, flip, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const CountySelect = ({ location, setLocation }) => {
  const { locations } = useSelector(prop('school'))

  const [inputValue, setInputValue] = useState('')

  const mappedLocations = locations.map(({ id, name }) => ({
    id,
    label: name
  }))

  return (
    <Autocomplete
      getOptionLabel={prop('label')}
      inputValue={inputValue}
      isOptionEqualToValue={(opt, val) =>
        equals(prop('id', opt), prop('id', val))
      }
      onChange={(__, val) => setLocation(val)}
      onInputChange={flip(setInputValue)}
      options={mappedLocations}
      renderInput={params => (
        <TextField
          {...params}
          helperText={
            <Tooltip
              title={
                <Typography variant="caption">
                  <FormattedMessage
                    defaultMessage="YOU is a confidential platform. Your location is used only to personalize the resources and tools we share based on your geographic region. We will never sell or distribute your data to anyone."
                    id="b63B63"
                  />
                </Typography>
              }
            >
              <Button
                startIcon={<InfoIcon />}
                sx={{ p: 0, textTransform: 'initial' }}
                variant="text"
              >
                <FormattedMessage
                  defaultMessage="Why do you need my location?"
                  id="v8A5sW"
                />
              </Button>
            </Tooltip>
          }
          label={
            <FormattedMessage
              defaultMessage="Where are you located?"
              id="Tse7aG"
            />
          }
        />
      )}
      value={location}
    />
  )
}

CountySelect.propTypes = {
  location: PropTypes.shape({}),
  setLocation: PropTypes.func.isRequired
}

CountySelect.defaultProps = {
  location: null
}

export default CountySelect
