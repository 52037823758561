import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { Chip } from '@mui/material'

const SocialBadge = ({ socialCount = null }) => (
  <Chip
    icon={<PeopleRoundedIcon />}
    label={
      <FormattedMessage
        defaultMessage="{socialCount} People"
        id="nKELC4"
        values={{ socialCount }}
      />
    }
  />
)

SocialBadge.propTypes = {
  socialCount: PropTypes.number
}

export default SocialBadge
