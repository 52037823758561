import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Button, CardActions } from '@mui/material'
import ErrorMessage from 'components/common/ErrorMessage'
import { Link } from 'react-router-dom'
import { equals, isNil, not, prop } from 'ramda'
import { useSelector } from 'react-redux'

const SSOSignIn = () => {
  const { formatMessage } = useIntl()
  // We will only ever have two per sso links per school according to management.
  // - Stan 3/1/2022
  const { sso_name, sso_link, sso_name_two, sso_link_two } = useSelector(
    prop('school')
  )

  return (
    <Box>
      {equals(window.location.hash, '#error-with-sso') && (
        <Box>
          <ErrorMessage
            hasError
            message={
              <FormattedMessage
                defaultMessage="Hmm. There was a problem with your single-sign-on. Contact your organization’s IT support to resolve it."
                id="gQjOju"
              />
            }
          />
          <br />
        </Box>
      )}

      <CardActions variant="center">
        {not(isNil(sso_name)) && (
          <Button
            aria-label={formatMessage(
              {
                defaultMessage: 'Continue with my {ssoType}',
                id: 'OKD//M'
              },
              { ssoType: sso_name }
            )}
            LinkComponent={Link}
            sx={{
              backgroundColor: 'secondary.light',
              fontFamily: 'mark-ot-bold',
              textAlign: not(isNil(sso_name_two)) ? 'center' : 'unset'
            }}
            to={sso_link}
            variant="sso"
          >
            <FormattedMessage
              defaultMessage="Continue with my {name}"
              id="KX6QRB"
              values={{ name: sso_name }}
            />
          </Button>
        )}

        {not(isNil(sso_name_two)) && (
          <Button
            aria-label={formatMessage(
              {
                defaultMessage: 'Continue with my {ssoType}',
                id: 'OKD//M'
              },
              { ssoType: sso_name_two }
            )}
            LinkComponent={Link}
            sx={{
              backgroundColor: 'secondary.light',
              fontFamily: 'mark-ot-bold',
              textAlign: 'center'
            }}
            to={sso_link_two}
            variant="sso"
          >
            <FormattedMessage
              defaultMessage="Continue with my {name}"
              id="KX6QRB"
              values={{ name: sso_name_two }}
            />
          </Button>
        )}
      </CardActions>
    </Box>
  )
}

export default SSOSignIn
