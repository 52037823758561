import { Button, Collapse, Divider, Stack, useMediaQuery } from '@mui/material'
import { useGetReferralLinksQuery } from 'api/schoolApi'
import { any, equals, isEmpty, isNil, not, path, slice } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp'
import useAnalytics from 'hooks/useAnalytics'

const buttonStyle = {
  borderLeft: '8px solid transparent',
  borderRadius: 0,
  color: 'secondary.dark',
  fontFamily: 'mark-ot',
  fontSize: 16,
  fontWeight: 500,
  justifyContent: 'flex-start',
  letterSpacing: '0.75px',
  padding: '0 20px 0 16px',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  width: 1
}

const ReferralLinks = () => {
  const letters = useSelector(path(['school', 'letters']))
  const domain = useSelector(path(['school', 'domain']))
  const isStaffFaculty = useSelector(path(['user', 'is_secondary_user']))

  const { sendEvent } = useAnalytics()
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'))
  const { formatMessage } = useIntl()

  const {
    data,
    isFetching = true,
    isError
  } = useGetReferralLinksQuery(
    { domain, isStaffFaculty },
    { skip: isNil(domain) }
  )

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(true)
    }
  }, [isDesktop])

  const links = useMemo(() => {
    if (any(equals(true))([isFetching, isEmpty(data), isNil(data)])) return null
    return slice(0, 3, data)
  }, [data, isFetching])

  if (any(equals(true))([isFetching, isEmpty(links), isNil(links), isError]))
    return null

  const handleClick = ({ id, link, name }) => {
    sendEvent('Strategic Link', {
      'Link ID': id,
      'Link Name': name,
      'Link URL': link
    })
    window.open(link, '_blank')
  }

  return (
    <>
      <Divider />
      <Collapse collapsedSize={48} in={isOpen}>
        <Button
          aria-label={
            isOpen
              ? formatMessage(
                  {
                    defaultMessage: 'expanded, Featured at {letters}',
                    id: 'QFNiKl'
                  },
                  {
                    letters
                  }
                )
              : formatMessage(
                  {
                    defaultMessage: 'collapsed, Featured at {letters}',
                    id: 'R10ycv'
                  },
                  {
                    letters
                  }
                )
          }
          endIcon={
            <ExpandMoreIcon
              sx={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '.2s linear'
              }}
            />
          }
          onClick={() => setIsOpen(not(isOpen))}
          startIcon={<BoltRoundedIcon />}
          sx={buttonStyle}
          variant=""
        >
          <FormattedMessage
            defaultMessage="Featured at {letters}"
            id="iOkM+s"
            values={{
              letters
            }}
          />
        </Button>
        <Stack alignItems="center" spacing={1.5} sx={{ mt: 1.5, pt: 1 }}>
          {links.map(({ id, link, name }) => (
            <Button
              endIcon={<OpenInNewSharpIcon />}
              key={id}
              onClick={() => handleClick({ id, link, name })}
              sx={{
                ...buttonStyle,
                justifyContent: 'space-between',
                padding: '0 44px 0 16px',
                textAlign: 'left'
              }}
              variant=""
            >
              {name}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </>
  )
}

export default ReferralLinks
