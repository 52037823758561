import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { equals, length, prop } from 'ramda'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const ConnectedSubdomains = ({ subdomainData = {} }) => {
  const relatedDomains = Object.keys(subdomainData)
  const { userDomains } = useSelector(prop('user'))

  if (equals(0, length(relatedDomains))) return null

  const relatedSubdomains = useMemo(() => {
    const subdomains = userDomains.reduce(
      (subdomains, domain) => domain.subdomains.concat(subdomains),
      []
    )
    return subdomains.filter(({ frontend_url_slug }) =>
      relatedDomains.some(equals(frontend_url_slug))
    )
  }, [subdomainData, userDomains])

  return (
    <Stack
      sx={{
        bgcolor: 'primary.light',
        mt: 4,
        py: 4,
        width: 1
      }}
    >
      <Container maxWidth="md">
        <Stack alignItems="center" spacing={3}>
          <Stack>
            <Typography textAlign="center" variant="cardTitle">
              <FormattedMessage
                defaultMessage="It's all connected..."
                id="mxJvGX"
              />
            </Typography>
            <Typography textAlign="center" variant="body1">
              <FormattedMessage
                defaultMessage="Explore other areas and ways to find balance."
                id="zPxuUS"
              />
            </Typography>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gridGap: 16,
              justifyContent: 'center'
            }}
          >
            {relatedSubdomains.map(({ link, title }) => (
              <Button
                endIcon={<ArrowForwardRoundedIcon />}
                key={title}
                LinkComponent={Link}
                sx={{
                  color: 'primary.main',
                  fontFamily: 'mark-ot-bold',
                  p: 0,
                  whiteSpace: 'nowrap'
                }}
                to={link}
                variant="text"
              >
                {title}
              </Button>
            ))}
          </Box>
        </Stack>
      </Container>
    </Stack>
  )
}

ConnectedSubdomains.propTypes = {
  subdomainData: PropTypes.shape({})
}

export default ConnectedSubdomains
