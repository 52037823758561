/* eslint-disable sort-keys */
import { forwardRef } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

// This is a forwarded ref component that wraps topics
// this is so the main banner actions can scroll this component into view
// ref must be passed forward and is required to work
// REF: https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element
const TopicsWrapper = forwardRef(({ children }, ref) => (
  <Box>
    <Box
      sx={{
        background: 'url(/images/wave1.svg)',
        backgroundPosition: { xs: 'left -12px', md: 'left -16px' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        pt: { xs: 16, md: 26 },
      }}
    />
    <Box id="topics" ref={ref} sx={{ bgcolor: 'white', px: { xs: 0, md: 6 } }}>
      {children}
    </Box>
    <Box
      sx={{
        background: 'url(/images/wave1.svg)',
        backgroundPosition: 'left bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // eslint-disable-next-line sort-keys
        pb: { xs: 8, md: 22 },
      }}
    />
  </Box>
))

TopicsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TopicsWrapper
