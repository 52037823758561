import { FormattedDate, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import diffInDays from '../../welcome/components/diffInDays'
import * as R from 'ramda'
import { Box, Typography } from '@mui/material'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'

const spanStyle = {
  color: 'inherit',
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: 0.5,
  lineHeight: '16px',
  whiteSpace: 'nowrap'
}

// business logic:
// use secondary.dark if the user has not marked a due date and say "ongoing" since creation date
// use green if the user has a due date that is in the future
// use red if user is over their due date for the goal
// use secondary.dark is the user has completed the goal
const GoalDateDisplay = ({ goal }) => {
  const dueDate = R.prop('due_date', goal)
  const completed = R.prop('completed', goal)

  const today = new Date()
  const startOfToday = today.setUTCHours(0, 0, 0, 0)
  const dateDue = new Date(dueDate)
  const resetDue = new Date(dateDue.setDate(dateDue.getDate() + 1)) // adding 1 day because JS date doesn't read yyyy-mm-dd
  const endOfDue = resetDue.setUTCHours(0, 0, 0, 0)

  const pastDue = R.and(
    R.not(R.isNil(dueDate)),
    R.gt(diffInDays(new Date(endOfDue), new Date(startOfToday)), 0)
  )

  const getColor = () => {
    if (R.all(R.equals(true))([R.not(completed), dueDate, R.not(pastDue)])) {
      return 'green'
    }
    if (R.all(R.equals(true))([R.not(completed), dueDate, pastDue])) {
      return 'red'
    }
    return 'secondary.dark'
  }

  return (
    <Box
      alignItems="center"
      color={getColor()}
      display="flex"
      sx={{ gridGap: 4 }}
    >
      {completed && R.not(R.isNil(dueDate)) && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gridGap: 4
          }}
        >
          <WatchLaterRoundedIcon />
          <Typography component="span" {...spanStyle}>
            <FormattedMessage defaultMessage="Completed on " id="YBijl9" />
            <FormattedDate
              day="numeric"
              month="numeric"
              value={endOfDue}
              year="numeric"
            />
          </Typography>
        </Box>
      )}

      {R.not(completed) && R.isNil(dueDate) && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gridGap: 4
          }}
        >
          <WatchLaterRoundedIcon />
          <Typography component="span" {...spanStyle}>
            <FormattedMessage defaultMessage="Ongoing since " id="tJH2r7" />
            &nbsp;
            <FormattedDate
              day="numeric"
              month="numeric"
              value={R.prop('created_at', goal)}
              year="numeric"
            />
          </Typography>
        </Box>
      )}

      {R.not(completed) && dueDate && pastDue && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gridGap: 4
          }}
        >
          <WatchLaterRoundedIcon />
          <Typography component="span" {...spanStyle}>
            <FormattedMessage
              defaultMessage="Overdue by {days} days"
              id="jYOvPp"
              // if we are past due.we swap goal.due_date and the current date
              // to get correct days (since number will be negative otherwise)
              values={{
                days: diffInDays(new Date(endOfDue), new Date(startOfToday))
              }}
            />
          </Typography>
        </Box>
      )}

      {R.not(completed) && dueDate && R.not(pastDue) && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gridGap: 4
          }}
        >
          <WatchLaterRoundedIcon />
          <Typography component="span" {...spanStyle}>
            <FormattedMessage
              defaultMessage="Due in {days} days"
              id="dmBF2O"
              values={{
                days: Math.abs(
                  diffInDays(new Date(startOfToday), new Date(endOfDue))
                )
              }}
            />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

GoalDateDisplay.propTypes = {
  goal: PropTypes.shape({}).isRequired
}

export default GoalDateDisplay
