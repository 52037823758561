import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import { useEffect, useState } from 'react'
import {
  always,
  and,
  assoc,
  equals,
  evolve,
  gt,
  includes,
  isNil,
  length,
  prop,
  reject,
  toLower
} from 'ramda'
import { TYPE_SKILL, TYPE_TIP } from 'utils/card-functions'
import { useSelector } from 'react-redux'

import PreparedCard from 'components/common/PreparedCardConnector'

const NextStepCard = ({ title, icon, subdomainId }) => {
  const { tips_enabled, goals_enabled } = useSelector(prop('school'))

  const [params, setParams] = useState({
    campus_resource: null,
    subdomain: [subdomainId],
    take: 1,
    type: null
  })

  const cleanedParams = reject(isNil, params)

  const { data = {} } = useGetCardsQuery(cleanedParams, {
    skip: gt(2, length(cleanedParams))
  })

  useEffect(() => {
    const coinFlip = Math.floor(Math.random() * 2)
    if (includes('action', toLower(title))) {
      setParams(assoc('type', goals_enabled ? coinFlip : TYPE_SKILL, params))
    }
    if (includes('advice', toLower(title))) {
      // get advice will coin flip between resource and tips if tips are enabled, otherwise just a resource
      setParams(
        evolve(
          {
            campus_resource: always(0),
            type: and(tips_enabled, equals(0, coinFlip)) && always(TYPE_TIP)
          },
          params
        )
      )
    }
    if (includes('connect', toLower(title))) {
      setParams(assoc('campus_resource', 1, params))
    }
  }, [goals_enabled, tips_enabled, title])

  return (
    // eslint-disable-next-line sort-keys
    <Stack sx={{ flex: { xs: 1, lg: 0.33 }, width: 1 }}>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center',
          mb: 1
        }}
      >
        {icon}
        <Typography variant="subtitle1">{title}</Typography>
      </Stack>
      <PreparedCard
        blockTitle="Self Check Results"
        card={prop(0, data)}
        size="mini"
      />
    </Stack>
  )
}

NextStepCard.propTypes = {
  icon: PropTypes.element.isRequired,
  subdomainId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default NextStepCard
