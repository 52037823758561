import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const addressApi = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: ({ query }) => ({
    getAddress: query({
      providesTags: ['address'],
      query: qp =>
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?${qp}`,
    }),
    getAddressCoordinates: query({
      providesTags: ['coordinates'],
      query: qp =>
        `https://us-street.api.smartystreets.com/street-address?${qp}`,
    }),
  }),
  reducerPath: 'addressApi',
  tagTypes: ['address', 'coordinates'],
})

export const { useGetAddressQuery, useGetAddressCoordinatesQuery } = addressApi
