import { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { isEmpty, prop, reject } from 'ramda'

const PollCard = ({ animate, pollingQuestion }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (animate) {
      setTimeout(() => setIsSubmitted(true), 1000)
    }
  }, [animate])

  const Answers = () => (
    <Stack
      aria-readonly={true}
      role="listbox"
      spacing={2}
      sx={{
        my: 2,
      }}
    >
      {reject(isEmpty, prop('questionSummary', pollingQuestion)).map(
        ({ answer, percentage, selected }) => (
          <Stack key={answer} role="option">
            <Stack
              alignItems="center"
              direction="row"
              sx={{ width: `calc(${percentage}% + 16px)` }}
            >
              <Box
                sx={{
                  color: selected ? 'school.secondary' : 'school.primary',
                  width: 1,
                }}
              >
                <LinearProgress
                  color="inherit"
                  value={100}
                  variant="determinate"
                />
              </Box>
              <Typography
                color="secondary.dark"
                fontFamily="mark-ot-bold"
                ml="8px"
                variant="subtitle1"
                whiteSpace="nowrap"
              >
                {percentage}%
              </Typography>
            </Stack>
            <Typography variant="body2">{answer}</Typography>
          </Stack>
        )
      )}
    </Stack>
  )

  const Questions = () => (
    <>
      <Typography mb="1.5em" variant="subtitle">
        <FormattedMessage
          defaultMessage="Select an answer to see what others said."
          id="fkOonE"
        />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: 16,
          mb: 2,
        }}
      >
        {prop('answers', pollingQuestion).map((answer, i) => (
          <Box
            key={i}
            sx={{
              ':nth-of-type(3)': {
                animation: `${animate ? 'pulseAnimation' : ''} 0.5s ease`,
              },
              bgcolor: 'white',
              border: '2px solid',
              borderColor: 'primary.light',
              borderRadius: 1,
              color: 'secondary.dark',
              fontFamily: 'mark-ot',
              fontSize: '16px',
              padding: '12px 16px',
              position: 'relative',
              textTransform: 'capitalize',
            }}
          >
            {answer}
          </Box>
        ))}
      </Box>
    </>
  )

  const renderContent = () => {
    if (isSubmitted) return <Answers />
    return <Questions />
  }

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'flex-end',
            color: 'school.accent',
            display: 'flex',
            gridGap: 4,
          }}
        >
          <LeaderboardIcon color="inherit" />
          <Typography color="black" mb="0" variant="overline">
            <FormattedMessage defaultMessage="Poll" id="h1gtUi" />
          </Typography>
        </Box>
        <Typography data-testid="pollTitle" mb="4px" variant="h5">
          {prop('prompt', pollingQuestion)}
        </Typography>
        {renderContent()}
      </CardContent>
    </Card>
  )
}

PollCard.propTypes = {
  animate: PropTypes.bool.isRequired,
  pollingQuestion: PropTypes.shape({}).isRequired,
}

export default PollCard
