import palette from '../palette'

const tabListNavButtonStyle = {
  ':hover': {
    backgroundColor: palette.primary.light,
  },
  backgroundColor: palette.primary.light,
  borderRadius: '15px 15px 0 0',
  boxShadow: '1px 2px 4px 0 rgba(79,79,79,0.25)',
  color: palette.secondary.dark,
  fontFamily: 'mark-ot',
  fontSize: 16,
  letterSpacing: '0.25px',
  lineHeight: '140%',
  padding: '8px 16px',
  textTransform: 'capitalize',
  width: '100%',
}

const filterMenuStyle = {
  ':hover': {
    borderColor: palette.primary.main,
  },
  ':last-child': {
    marginRight: '32px',
  },
  backgroundColor: 'white',
  border: '2px solid',
  borderColor: palette.primary.dark,
  borderRadius: 8,
  color: palette.primary.main,
  fontFamily: 'mark-ot',
  fontSize: '16px',
  height: '41px',
  letterSpacing: '0.25px',
  lineHeight: '140%',
  minWidth: 'unset',
  padding: '8px 16px',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
}

const MuiButton = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    contained: {
      '&:hover': {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        opacity: '0.94',
      },
      borderRadius: 2,
    },
    outlined: {
      ':hover': {
        border: '2px solid',
        boxShadow: '0px 4px 4px 0px #00000040',
      },
      border: '2px solid',
      borderColor: palette.primary.main,
    },
    root: {
      '&:disabled': {
        cursor: 'default',
        opacity: '0.65',
      },
      '&:focus-visible': {
        outline: '2px solid',
        outlineColor: palette.info.main,
      },
      borderRadius: 4,
      fontFamily: 'mark-ot-bold',
      fontSize: 14,
      letterSpacing: '0.75px',
      maxHeight: 48,
      padding: '16px 20px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      width: 'fit-content',
    },
    text: {
      ':hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: 4,
      fontFamily: 'mark-ot',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.75px',
      textDecoration: 'none',
    },
  },
  variants: [
    {
      props: { fullWidth: true },
      style: {
        width: '100%',
      },
    },
    {
      props: { variant: 'crisis' },
      style: {
        border: '2px solid white',
        color: 'white',
        textTransform: 'capitalize',
      },
    },
    {
      props: { variant: 'underline' },
      style: {
        backgroundColor: 'transparent',
        fontFamily: 'body.normal',
        fontSize: 14,
        padding: 0,
        textDecoration: 'underline',
      },
    },
    {
      props: { variant: 'selfCheck' },
      style: {
        bgcolor: palette.white,
        border: '2px solid',
        borderColor: palette.primary.light,
        borderRadius: '8px',
        color: 'black',
        fontFamily: 'mark-ot',
        textTransform: 'capitalize',
      },
    },
    {
      props: { variant: 'link' },
      style: {
        ':hover': {
          backgroundColor: 'transparent',
        },
        borderRadius: 4,
        display: 'block',
        fontFamily: 'mark-ot',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.75px',
        padding: 0,
        textTransform: 'uppercase',
        width: '100%',
      },
    },
    {
      props: { variant: 'poll' },
      style: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'unset',
        padding: 0,
      },
    },
    {
      props: { variant: 'underline' },
      style: {
        fontFamily: 'mark-ot-bold',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.75px',
        paddingLeft: 0,
        paddingRight: 0,
        textDecoration: 'underline',
        textTransform: 'uppercase',
      },
    },
    {
      props: { selected: true, variant: 'text' },
      style: {
        fontFamily: 'mark-ot-bold',
      },
    },
    {
      props: { variant: 'nav' },
      style: {
        borderRadius: 0,
        color: palette.secondary.dark,
        fontSize: 22,
        paddingBottom: 4,
        textTransform: 'none',
      },
    },
    {
      props: { variant: 'footer' },
      style: {
        ':disabled': {
          cursor: 'default',
          opacity: '0.65',
        },
        ':focus-visible': {
          outline: '2px solid',
          outlineColor: palette.info.main,
        },
        alignItems: 'center',
        border: '2px solid',
        borderColor: palette.white,
        borderRadius: 4,
        color: palette.white,
        display: 'flex',
        fontFamily: 'mark-ot-bold',
        fontSize: 14,
        letterSpacing: '0.75px',
        padding: '12px 20px',
        textDecoration: 'none',
        textTransform: 'none',
        width: 'fit-content',
      },
    },
    {
      props: { variant: 'filter' },
      style: {
        ':hover': {
          border: '2px solid',
        },
        border: '2px solid',
        borderColor: palette.primary.light,
        borderRadius: 8,
        display: 'block',
        fontFamily: 'mark-ot',
        minWidth: 'unset',
        paddingBottom: 8,
        paddingTop: 8,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
      },
    },
    {
      props: { variant: 'filterMenu' },
      style: { ...filterMenuStyle },
    },
    {
      props: { selected: true, variant: 'filterMenu' },
      style: {
        ...filterMenuStyle,
        border: '2px solid',
        borderColor: palette.primary.main,
        color: palette.primary.main,
        fontSize: '16.67px',
      },
    },
    {
      props: { variant: 'tabList' },
      style: { ...tabListNavButtonStyle },
    },
    {
      props: { selected: true, variant: 'tabList' },
      style: {
        ...tabListNavButtonStyle,
        ':hover': {
          backgroundColor: palette.white,
        },
        backgroundColor: 'white',
      },
    },
    {
      props: { variant: 'option' },
      style: {
        '&:disabled': {
          '&:hover': {
            backgroundColor: 'trasparent',
            borderColor: palette.primary.light,
            boxShadow: 'unset',
            color: palette.secondary.dark,
            cursor: 'default',
            fontFamily: 'mark-ot',
          },
        },
        '&:hover': {
          backgroundColor: 'white',
          borderColor: 'school.secondary',
          boxShadow: '0px 2px 4px 0 rgba(79, 79, 79, 0.25);',
          color: palette.primary.main,
          fontFamily: 'mark-ot',
        },
        backgroundColor: '#FFFFFF',
        border: '2px solid',
        borderColor: palette.primary.light,
        borderRadius: 8,
        color: palette.secondary.dark,
        cursor: 'pointer',
        fontFamily: 'mark-ot',
        fontSize: 14,
        maxHeight: 'unset',
        padding: '8px 16px',
        position: 'relative',
        textTransform: 'capitalize',
      },
    },
    {
      props: { selected: true, variant: 'option' },
      style: {
        backgroundColor: 'white',
        borderColor: 'school.secondary',
        boxShadow: '0px 2px 4px 0 rgba(79, 79, 79, 0.25);',
        color: palette.primary.main,
        textTransform: 'capitalize',
      },
    },
    {
      props: { variant: 'quiz' },
      style: {
        backgroundColor: 'white',
        border: `2px solid ${palette.primary.light}`,
        color: palette.secondary.dark,
        fontFamily: 'mark-ot',
        textTransform: 'none',
        width: '100%',
      },
    },
    {
      props: { variant: 'interactive' },
      style: {
        ':hover': {
          backgroundColor: palette.white,
        },
        '@media (max-width: 600px)': {
          width: '100%',
        },
        backgroundColor: palette.white,
        border: '2px solid',
        borderColor: palette.primary.light,
        borderRadius: 25,
        fontFamily: 'mark-ot',
        fontSize: '16px',
        maxHeight: 'unset',
        padding: '8px 32px',
        textTransform: 'none',
        transition: 'all .5s',
        width: 'auto',
      },
    },
    {
      props: { selected: true, variant: 'interactive' },
      style: {
        ':hover': {
          backgroundColor: palette.blue.infoLight,
        },
        '@media (max-width: 600px)': {
          width: '100%',
        },
        backgroundColor: palette.blue.infoLight,
        border: '1px solid',
        borderColor: palette.blue.info,
        borderRadius: 25,
        fontFamily: 'mark-ot',
        fontSize: '16px',
        maxHeight: 'unset',
        padding: '8px 24px 8px 40px',
        textTransform: 'none',
        transition: 'all .5s',
        width: 'auto',
      },
    },
  ],
}

export default MuiButton
