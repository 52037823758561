import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { always, cond, equals, not } from 'ramda'

import StepHeading from './components/StepHeading'
import StepNavigation from './components/StepNavigation'

import Intro from 'pages/onboarding/components/Intro'
import Interest from 'pages/onboarding/components/Interest'
import SelfCheck from 'pages/onboarding/components/SelfCheck'
import Goals from 'pages/onboarding/components/Goals'
import EmailOptIn from 'pages/onboarding/components/EmailOptIn'
import Location from 'pages/onboarding/components/Location'

const OnboardingLayout = () => {
  const { step } = useParams()

  const renderChild = cond([
    [equals('introduction'), always(<Intro />)],
    [equals('interests'), always(<Interest />)],
    [equals('self-check'), always(<SelfCheck />)],
    [equals('goals'), always(<Goals />)],
    [equals('email'), always(<EmailOptIn />)],
    [equals('location'), always(<Location />)]
  ])

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, pb: 12 }}>
        <StepHeading />
        {renderChild(step)}
      </Container>
      {not(equals('introduction', step)) && <StepNavigation />}
    </>
  )
}

export default OnboardingLayout
