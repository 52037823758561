/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Box, Container, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { flip } from 'ramda'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { useNavigate, useSearchParams } from 'react-router-dom'

import QuestionsPage from './components/QuestionsPage'
import SettingsPage from './components/SettingsPage'

const tabStyle = {
  color: 'secondary.dark',
  fontSize: 22,
  fontWeight: 700,
  px: 1,
  textTransform: 'none'
}

const Profile = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const active = search.get('active')

  const handleRouteChange = active => navigate(`/profile?active=${active}`)

  return (
    <Container sx={{ mt: 3 }}>
      <Typography textAlign="center" variant="h1">
        <FormattedMessage defaultMessage="Your Profile" id="3t83FH" />
      </Typography>

      <Box sx={{ position: 'relative' }}>
        <TabContext value={active}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              aria-label="profile view change"
              onChange={flip(handleRouteChange)}
            >
              <Tab
                icon={<AccountCircleRoundedIcon />}
                iconPosition="start"
                label={
                  <Typography sx={{ ...tabStyle }}>
                    <FormattedMessage defaultMessage="About You" id="QG1VMg" />
                  </Typography>
                }
                value="about"
              />
              <Tab
                icon={<SettingsRoundedIcon />}
                iconPosition="start"
                label={
                  <Typography sx={{ ...tabStyle }}>
                    <FormattedMessage defaultMessage="Settings" id="D3idYv" />
                  </Typography>
                }
                value="settings"
              />
            </TabList>
          </Box>
          <TabPanel value="about">
            <QuestionsPage />
          </TabPanel>
          <TabPanel value="settings">
            <SettingsPage />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  )
}

export default Profile
