/* eslint-disable sort-keys */
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@mui/material'
import { any, equals, inc, length, lt } from 'ramda'
import { Link, useLocation } from 'react-router-dom'

const links = [
  {
    href: '/accessibility',
    show: process.env.REACT_APP_PUBLIC_ACCESSIBILITY_DOC,
    text: <FormattedMessage defaultMessage="Accessibility" id="fgCBG8" />
  },
  {
    href: '/privacy-policy',
    show: true,
    text: <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
  },
  {
    href: '/terms-and-conditions',
    show: true,
    text: <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
  },
  {
    href: '/feedback',
    show: true,
    text: <FormattedMessage defaultMessage="Website Feedback" id="1678y9" />
  }
]

const Footer = () => {
  const thedate = new Date()
  const year = thedate.getFullYear()
  const { pathname } = useLocation()

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback'),
    pathname.includes('public'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('about-you'),
    pathname.includes('logout'),
    pathname.includes('profile'),
    pathname.includes('goals/create'),
    pathname.includes('goals/edit'),
    pathname.includes('public'),
    pathname.includes('about'),
    pathname.includes('destress'),
    pathname.includes('404'),
    pathname.includes('sso'),
    pathname.includes('email'),
    pathname.includes('unsubscribe')
  ]

  if (any(equals(true))(anyCheck)) return null

  return (
    <Box
      sx={{
        bgcolor: 'white',
        borderTop: '1px solid',
        borderTopColor: 'primary.light',
        display: 'flex',
        flexDirection: 'column',
        gridGap: 8,
        p: 2
      }}
    >
      <Typography component="span" display="block" variant="legal">
        <FormattedMessage
          defaultMessage="© {year} by Grit Digital Health, LLC. Grit does not claim copyright in the third party materials included on this site."
          id="cOrKqx"
          values={{
            c: chunks => <span>{chunks}</span>,
            year: year
          }}
        />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gridGap: '4px'
        }}
      >
        {links.map(
          ({ href, show, text }, idx) =>
            show && (
              <Fragment key={href}>
                <Typography
                  color="primary.main"
                  component={Link}
                  display="block"
                  to={href}
                  variant="legal"
                >
                  {text}
                </Typography>
                {lt(inc(idx), length(links)) && (
                  <Box
                    sx={{
                      color: 'primary.main',
                      display: { xs: 'none', sm: 'block' }
                    }}
                  >
                    |
                  </Box>
                )}
              </Fragment>
            )
        )}
      </Box>
    </Box>
  )
}

export default Footer
