import PreparedCard from './PreparedCard'
import { path, prop } from 'ramda'
import { connect } from 'react-redux'

const mapStateToProps = ({ school }) => ({
  categoryTitle: path(['tips_data', 'tips_category_title'], school),
  flagEventImage: prop('flag_event_image', school),
  flagImage: prop('flagImage', school),
  flagStoryImage: prop('flag_story_image', school),
  highlightColor: prop('highlight_color', school),
  secondaryColor: prop('secondary_color', school),
})

export default connect(mapStateToProps)(PreparedCard)
