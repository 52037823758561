import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
// import Autocomplete from '@mui/material/Autocomplete'
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import {
  useGetAddressQuery,
  useGetAddressCoordinatesQuery
} from 'api/addressApi'
import getParams from 'utils/getParams'
import {
  isEmpty,
  prop,
  defaultTo,
  compose,
  path,
  gte,
  length,
  equals,
  inc,
  and,
  tap,
  assoc,
  not,
  isNil,
  all,
  split,
  or
} from 'ramda'
import AddHomeIcon from '@mui/icons-material/AddHome'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import {
  useRemoveTempLocationMutation,
  useUpdateHomeLocationMutation,
  useUpdateTempLocationMutation
} from 'api/profileApi'
import { useSelector } from 'react-redux'

// TODO: When we move to MUI fully update this component to MUI Autocomplete...
// this will clean up a massive amount of code here
const LocationInput = () => {
  const homeLocation = useSelector(path(['user', 'home_location', 'address']))
  const tempLocation = useSelector(path(['user', 'temp_location', 'address']))

  const [value, setValue] = useState('')
  const [selected, setSelected] = useState({})

  const formattedAddress = isEmpty(selected)
    ? null
    : `${prop('street_line', selected)}, ${prop('city', selected)} ${prop(
        'state',
        selected
      )} ${prop('zipcode', selected)}`

  useEffect(() => {
    if (not(isNil(homeLocation))) {
      setValue(prop(0, split(',', homeLocation)))
      const rest = split(' ', prop(1, split(',', homeLocation)))
      setSelected({
        city: prop(1, rest),
        state: 'CO',
        street_line: prop(0, split(',', homeLocation)),
        zipcode: prop(3, rest)
      })
    }
  }, [homeLocation])

  const { data } = useGetAddressQuery(
    getParams({
      include_only_states: 'co',
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      search: value
    }),
    {
      skip: isEmpty(value)
    }
  )

  const [updateTempLocation] = useUpdateTempLocationMutation()
  const [removeTempLocation] = useRemoveTempLocationMutation()
  const [updateHomeLocation] = useUpdateHomeLocationMutation()

  const options = defaultTo([], prop('suggestions', data))

  // Getting Geo Location
  const formatted = assoc('street', prop('street_line', selected), selected)
  const { data: coordinates } = useGetAddressCoordinatesQuery(
    getParams({
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      ...formatted
    }),
    { skip: isEmpty(selected) }
  )

  const body = {
    address: formattedAddress,
    lat: path(['metadata', 'latitude'], prop(0, coordinates)),
    lng: path(['metadata', 'longitude'], prop(0, coordinates))
  }

  useEffect(() => {
    if (or(not(isNil(coordinates)), not(isEmpty(coordinates)))) {
      updateTempLocation(body)
    }
  }, [coordinates])

  return (
    <Box sx={{ display: 'grid', gridGap: 8, maxWidth: 360, mt: 1, width: 1 }}>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: not(isEmpty(selected)) && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  setValue('')
                  setSelected({})
                  removeTempLocation()
                }}
              >
                <ClearRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnRoundedIcon />
            </InputAdornment>
          )
        }}
        label={
          <FormattedMessage defaultMessage="Enter an address" id="BKdjS1" />
        }
        onChange={compose(
          () => setSelected({}),
          tap(setValue),
          path(['target', 'value'])
        )}
        value={value}
      />
      {and(gte(length(options), 1), isEmpty(selected)) && (
        <Box
          role="listbox"
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            boxShadow: 2,
            display: 'grid',
            gridGap: 8,
            maxHeight: 250,
            mt: -1,
            overflow: 'auto',
            py: 1
          }}
        >
          {options.map((option, idx) => (
            <Box
              key={prop('street_line', option)}
              onClick={() => {
                setValue(prop('street_line', option))
                setSelected(option)
              }}
              role="option"
              sx={{
                ':hover': {
                  bgcolor: 'secondary.light'
                },
                alignItems: 'center',
                borderBottom: equals(length(options), inc(idx))
                  ? 'unset'
                  : '1px solid',
                borderBottomColor: 'secondary.light',
                cursor: 'pointer',
                display: 'flex',
                gridGap: 8,
                pb: 1,
                px: 2
              }}
              tabIndex={0}
            >
              <LocationOnRoundedIcon />
              <Box>
                <Typography variant="body2">
                  <FormattedMessage
                    defaultMessage="{street_line}"
                    id="359sJT"
                    values={{ street_line: prop('street_line', option) }}
                  />
                </Typography>
                <Typography variant="caption">
                  <FormattedMessage
                    defaultMessage="{city}, {zipcode}"
                    id="GbmSf4"
                    values={{
                      city: prop('city', option),
                      zipcode: prop('zipcode', option)
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {
        // Selected not empty
        // Selected Location !== Home Location
        // Temp Location !== Home Location
        all(equals(true))([
          not(isEmpty(selected)),
          and(
            not(isNil(tempLocation)),
            not(equals(homeLocation, tempLocation))
          ),
          not(equals(tempLocation, homeLocation))
        ]) && (
          <Button
            fullWidth
            onClick={() => {
              updateHomeLocation(body)
              removeTempLocation()
            }}
            startIcon={<AddHomeIcon />}
            variant="text"
          >
            <FormattedMessage
              defaultMessage="Save as home location"
              id="vCrxmu"
            />
          </Button>
        )
      }
    </Box>
  )
}

export default LocationInput
