import { Box, Skeleton, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { equals, inc } from 'ramda'
import ProgressBar from '../common/ProgressBar'
import ScreenReaderMessage from '../common/ScreenReaderMessage'
import { useGetRealityScoresQuery } from 'api/statsApi'

const Results = ({ answers, id, selected }) => {
  const { data, isFetching } = useGetRealityScoresQuery(id, {
    refetchOnMountOrArgChange: true,
  })

  if (isFetching) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>
    )
  }

  const calcWidth = idx =>
    `${(data[inc(idx)] / Object.values(data).reduce((a, b) => a + b)) * 100}%`

  return (
    <Stack aria-readonly={true} role="listbox" spacing={1}>
      {answers.map((answer, i) => (
        <Box key={i} role="option">
          <Box width={`calc(${calcWidth(i)} + 32px)`}>
            <ProgressBar
              completed={data[inc(i)]}
              selected={equals(selected, i)}
              total={Object.values(data).reduce((a, b) => a + b)}
            />
          </Box>
          <ScreenReaderMessage>
            {equals(selected, i)
              ? 'answered like you: '
              : 'of others answered: '}
          </ScreenReaderMessage>
          <Typography
            color={equals(selected, i) ? 'primary.main' : 'secondary.dark'}
            fontFamily="mark-ot-bold"
            variant="body2"
          >
            {answer}
          </Typography>
        </Box>
      ))}
    </Stack>
  )
}

Results.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.number,
  selected: PropTypes.number,
}

Results.defaultProps = {
  id: null,
  selected: null,
}

export default Results
