import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { keyframes } from '@emotion/react'
import { prop } from 'ramda'
import { Box, Typography } from '@mui/material'
import useCurrentAppType from 'hooks/useCurrentAppType'

const animationInitialPause = 2000
const animationLength = 2000

const words_business = ['well-being', 'family', 'finances', 'career']
const words_responder = ['body', 'mind', 'family', 'career']

const words_mhcd = [
  <FormattedMessage defaultMessage="mental health" id="NXQZbT" key={0} />,
  <FormattedMessage defaultMessage="relationships" id="t0/xI3" key={1} />,
  <FormattedMessage defaultMessage="finances" id="0bUhKw" key={2} />,
  <FormattedMessage defaultMessage="well-being" id="Im8ynK" key={3} />
]

const WelcomeHeading = () => {
  const [currentWord, setCurrentWord] = useState(0)
  const [transition, setTransition] = useState(false)

  const { isCollective, isBusiness, isWellpower } = useCurrentAppType()

  const words = () => {
    if (isBusiness) return words_business
    if (isWellpower) return words_mhcd
    return words_responder
  }

  let isMounted = true

  const rotateWord = () => {
    if (!isMounted) return
    setTransition(true)

    setTimeout(() => {
      const nextCurrentWord = currentWord =>
        currentWord < words().length - 1 ? currentWord + 1 : 0
      setCurrentWord(nextCurrentWord)

      setTransition(false)
    }, 500)
  }

  //Sequential word rotation animations
  useEffect(() => {
    if (isMounted) {
      setTimeout(() => rotateWord(), animationInitialPause)
      setTimeout(() => rotateWord(), animationInitialPause + animationLength)
      setTimeout(
        () => rotateWord(),
        animationInitialPause + animationLength * 2
      )
    }
    return () => (isMounted = false)
  }, [])

  const slideOutToLeftAndBackAnimation = keyframes({
    '0%': {
      width: '100%'
    },
    '15%': {
      width: '0%'
    },
    '85%': {
      width: '0%'
    },
    // eslint-disable-next-line sort-keys
    '100%': {
      width: '100%'
    }
  })
  const fadeOutInAnimation = keyframes({
    '0%': {
      opacity: '1'
    },
    '10%': {
      opacity: '1'
    },
    '25%': {
      opacity: '0'
    },
    '75%': {
      opacity: '0'
    },
    '90%': {
      opacity: '1'
    },
    // eslint-disable-next-line sort-keys
    '100%': {
      opacity: '1'
    }
  })

  const getHeadlineCopy = () => {
    if (isCollective) {
      return (
        <FormattedMessage
          defaultMessage="Welcome to{br} {name}"
          id="HnOO1X"
          values={{
            br: <br />,
            name: (
              <span aria-atomic="true" aria-live="polite" className="word">
                <FormattedMessage
                  defaultMessage="The Collective!"
                  id="N/6tdt"
                />
              </span>
            )
          }}
        />
      )
    } else {
      return (
        <FormattedMessage
          defaultMessage="Take care of{br}your {word}"
          id="M2UYWG"
          values={{
            br: <br />,
            word: (
              <Box
                component="span"
                sx={{
                  '&:after': {
                    bgcolor: 'school.highlight',
                    bottom: '5px',
                    // eslint-disable-next-line quotes
                    content: "''",
                    height: '20px',
                    left: '0px',
                    opacity: '0.7',
                    position: 'absolute',
                    width: '100%',
                    zIndex: -1
                  },
                  opacity: '1',
                  position: 'relative',
                  zIndex: 1
                }}
              >
                {prop(currentWord, words())}.
              </Box>
            )
          }}
        />
      )
    }
  }
  let h1Classes = ''
  if (transition) {
    h1Classes += ' rotate-word-animation'
  }

  return (
    <Typography
      className={h1Classes}
      sx={{
        '&.rotate-word-animation': {
          'span.word': {
            '&:after': {
              animation: `${slideOutToLeftAndBackAnimation} 1.5s ease`,
              animationIterationCount: 1
            },
            animation: `${fadeOutInAnimation} 1.5s ease`,
            animationIterationCount: 1
          }
        },
        // eslint-disable-next-line sort-keys
        fontSize: { xs: 32, md: 49, xl: 60 },
        // eslint-disable-next-line sort-keys
        lineHeight: { xs: '38px', md: 'initial' }
      }}
      variant="h1"
    >
      {getHeadlineCopy()}
    </Typography>
  )
}

export default WelcomeHeading
