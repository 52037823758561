import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import {
  and,
  assoc,
  compose,
  defaultTo,
  equals,
  gt,
  isNil,
  length,
  lt,
  not,
  or,
  path,
  prop,
  tap
} from 'ramda'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'

const EmailAndPassword = ({
  email = null,
  password = null,
  revalidate = false,
  revalidation = null,
  setEmail,
  setPassword,
  setRevalidation = () => {},
  socialSSO = false
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRevalidation, setShowRevalidation] = useState(false)
  const [errors, setErrors] = useState({
    email: null,
    password: null
  })

  const validateEmail = email => {
    const regex =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setErrors(assoc('email', not(regex.test(email)), errors))
  }

  const validatePassword = pass => {
    if (or(lt(length(pass), 8), gt(length(pass), 13))) {
      setErrors(assoc('password', true, errors))
      return
    }
    setErrors(assoc('password', false, errors))
  }

  return (
    <Stack>
      <TextField
        defaultValue={defaultTo('', email)}
        helperText={
          prop('email', errors) ? (
            <FormattedMessage
              defaultMessage="Hmm, this email address isn't valid."
              id="/g9wJ/"
            />
          ) : (
            <Tooltip
              title={
                <Stack spacing={1}>
                  <Typography component="span" variant="caption">
                    <FormattedMessage
                      defaultMessage="YOU is a confidential platform. Your email is used only to establish an account so that you can save resources, assessments, and personalized interests."
                      id="dxLav5"
                    />
                  </Typography>
                  {socialSSO && (
                    <Typography component="span" mb={1} variant="caption">
                      <FormattedMessage
                        defaultMessage="You may also create an account with any existing Google or Facebook accounts, using the links below."
                        id="ZLCzuD"
                      />
                    </Typography>
                  )}
                  <Typography component="span" variant="caption">
                    <FormattedMessage
                      defaultMessage="We may send informational and reminder emails from time to time, but will never sell your email address or share any personal information about your usage of this tool."
                      id="f7YyLz"
                    />
                  </Typography>
                </Stack>
              }
            >
              <Button
                role="tooltip"
                startIcon={<InfoIcon />}
                sx={{ p: 0, textTransform: 'initial' }}
                variant="text"
              >
                <FormattedMessage
                  defaultMessage="Why do you need my email?"
                  id="C6vxyl"
                />
              </Button>
            </Tooltip>
          )
        }
        id="email"
        inputProps={{ 'aria-label': 'Email', autoComplete: 'off' }}
        label={<FormattedMessage defaultMessage="Email" id="sy+pv5" />}
        onChange={compose(
          validateEmail,
          tap(setEmail),
          path(['target', 'value'])
        )}
        required
        type="email"
      />

      <TextField
        defaultValue={defaultTo('', password)}
        helperText={
          <FormattedMessage defaultMessage="8-13 characters" id="lco3nU" />
        }
        id="password"
        inputProps={{ 'aria-label': 'Password', autoComplete: 'off' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="Show Password"
                onClick={() => setShowPassword(not(showPassword))}
              >
                {showPassword ? (
                  <VisibilityOffRoundedIcon />
                ) : (
                  <VisibilityRoundedIcon />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
        label={<FormattedMessage defaultMessage="Password" id="5sg7KC" />}
        onChange={compose(
          validatePassword,
          tap(setPassword),
          path(['target', 'value'])
        )}
        required
        sx={{
          my: 1
        }}
        type={showPassword ? 'text' : 'password'}
      />

      {revalidate && (
        <TextField
          defaultValue={defaultTo('', revalidation)}
          error={and(
            not(isNil(revalidation)),
            not(equals(password, revalidation))
          )}
          helperText={
            and(
              not(isNil(revalidation)),
              not(equals(password, revalidation))
            ) && (
              <FormattedMessage
                defaultMessage="Make sure your passwords match"
                id="2iOMhc"
              />
            )
          }
          id="re-validate"
          inputProps={{ 'aria-label': 'Revalidation' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="Show Revalidation"
                  onClick={() => setShowRevalidation(not(showRevalidation))}
                >
                  {showRevalidation ? (
                    <VisibilityOffRoundedIcon />
                  ) : (
                    <VisibilityRoundedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          label={
            <FormattedMessage defaultMessage="Re-enter Password" id="qxYRGU" />
          }
          onChange={compose(setRevalidation, path(['target', 'value']))}
          required
          type={showRevalidation ? 'text' : 'password'}
        />
      )}

      {not(revalidate) && (
        <Typography
          color="primary.main"
          component={Link}
          sx={{
            mb: 1,
            textDecoration: 'underline',
            width: 165
          }}
          to="/password-reset"
          variant="body2"
        >
          <FormattedMessage defaultMessage="Reset my password" id="OXLLjP" />
        </Typography>
      )}
    </Stack>
  )
}

EmailAndPassword.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  revalidate: PropTypes.bool,
  revalidation: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  setRevalidation: PropTypes.func,
  socialSSO: PropTypes.bool
}

export default EmailAndPassword
