import { createRef } from 'react'
import { Box } from '@mui/material'
import { useCurrentAppType, useHandlePublicRoutes } from 'hooks'

import TopicsWrapper from './components/explore/TopicsWrapper'
import Topics from './components/explore/Topics'
import Stories from './components/explore/Stories'
import Resources from './components/explore/Resources'
import LocalResources from './components/explore/wellpower/LocalResources'
import About from './components/explore/About'
import Banner from './components/explore/Banner'
import CTA from './components/explore/CTA'

const Public = () => {
  useHandlePublicRoutes()

  const { isWellpower } = useCurrentAppType()

  // This is for scroll into view component
  const topicsRef = createRef()
  const scrollToComponent = () => {
    if (topicsRef.current) {
      topicsRef.current.scrollIntoView()
      topicsRef.current.focus()
    }
  }

  return (
    <Box component="article" id="main" sx={{ display: 'grid', gridGap: 8 }}>
      <Banner handleScrollToComponent={scrollToComponent} />
      <TopicsWrapper ref={topicsRef}>
        <Topics />
      </TopicsWrapper>
      <Stories />
      <CTA />
      <Resources />
      {isWellpower && <LocalResources />}
      <About />
    </Box>
  )
}

export default Public
