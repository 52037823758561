/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import {
  useFavoriteMutation,
  useGetGeolocationResourcesQuery
} from 'api/cardsApi'
import {
  gte,
  isNil,
  length,
  path,
  prop,
  replace,
  slice,
  trim,
  not,
  inc,
  gt,
  equals,
  and,
  isEmpty
} from 'ramda'
import { metersToMiles } from 'utils/helpers'
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import { Link } from 'react-router-dom'
import { useRemoveTempLocationMutation } from 'api/profileApi'
import { useSelector } from 'react-redux'

import LocationInput from './LocationInput'
import MasonryWrapper from 'components/common/MasonryWrapper'
import LoadMoreButton from 'components/common/LoadMoreButton'

const ResourcesNearMe = () => {
  const [page, setPage] = useState(1)
  const homeLocation = useSelector(path(['user', 'home_location', 'address']))
  const tempLocation = useSelector(path(['user', 'temp_location', 'address']))

  const { data, isFetching, refetch } = useGetGeolocationResourcesQuery(
    `?take=${page * 12}`
  )

  useEffect(() => {
    if (
      and(not(isNil(tempLocation)), not(equals(homeLocation, tempLocation)))
    ) {
      refetch()
    }
  }, [homeLocation, tempLocation])

  const [favorite] = useFavoriteMutation()

  const handleExcerpt = html =>
    trim(slice(0, 63, replace(/<[^>]*>?/gm, '', html)))

  const [removeTempLocation] = useRemoveTempLocationMutation()

  useEffect(() => {
    return () => removeTempLocation()
  }, [])

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 170px)',
        pb: 4,
        pl: { xs: 2, md: 4 },
        pr: 1
      }}
    >
      <Box
        component="header"
        sx={{
          display: 'grid',
          justifyItems: 'center',
          mb: 5,
          pt: { xs: 2, md: 4 }
        }}
      >
        <Typography textAlign="center" variant="h1">
          <FormattedMessage defaultMessage="Resources Near Me" id="TMmOp9" />
        </Typography>
        {not(isEmpty(data)) && (
          <Typography pb="8px" variant="body1">
            <FormattedMessage
              defaultMessage="Showing results near"
              id="fLEiUR"
            />
          </Typography>
        )}
        <LocationInput />
      </Box>
      {gte(length(data), 0) && (
        <>
          <MasonryWrapper>
            {data.map(card => (
              <Card key={prop('id', card)}>
                <CardActionArea
                  LinkComponent={Link}
                  to={`/article/${prop('id', card)}/${prop('title', card)}`}
                >
                  <CardMedia
                    component="img"
                    height={190}
                    image={path(['photo', 'large_url'], card)}
                  />
                  <CardContent sx={{ pb: 0 }}>
                    <Stack spacing={1}>
                      <Typography variant="body2">
                        <FormattedMessage
                          defaultMessage="{distance} miles"
                          id="K8jcOk"
                          values={{
                            distance: metersToMiles(prop('distance', card))
                          }}
                        />
                      </Typography>
                      <Typography variant="cardTitle">
                        <FormattedMessage
                          defaultMessage="{title}"
                          id="2YgLVn"
                          values={{ title: prop('title', card) }}
                        />
                      </Typography>
                      <Typography variant="body2">
                        {handleExcerpt(prop('photo_description', card))}...
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
                <CardActions variant="flex-end">
                  <IconButton
                    onClick={() =>
                      favorite({
                        card_id: prop('id', card),
                        isFavorite: not(prop('is_favorite', card))
                      })
                    }
                  >
                    {prop('is_favorite', card) ? (
                      <BookmarkRoundedIcon />
                    ) : (
                      <BookmarkBorderRoundedIcon />
                    )}
                  </IconButton>
                </CardActions>
              </Card>
            ))}
          </MasonryWrapper>
          {not(gt(length(data) % 12, 0)) && (
            <LoadMoreButton
              handleClick={() => setPage(inc(page))}
              loading={isFetching}
            />
          )}
        </>
      )}
      {isNil(data) && (
        <Box
          sx={{
            display: 'grid',
            gridGap: 8,
            justifyItems: 'center',
            maxWidth: 600,
            m: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="subtitle4">
            <FormattedMessage
              defaultMessage="We’re not sure where you are."
              id="l2Qpan"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="If we know your location, we can show you the resources that are nearby. As always, we are 100% confidential."
              id="8SICUg"
            />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ResourcesNearMe
