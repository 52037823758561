import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, CircularProgress } from '@mui/material'

const LoadMoreButton = ({ handleClick = () => {}, loading = true }) => (
  <Button
    disabled={loading}
    onClick={handleClick}
    sx={{ alignSelf: 'center', mt: 3 }}
    variant="outlined"
  >
    {loading ? (
      <CircularProgress size="24px" />
    ) : (
      <FormattedMessage defaultMessage="Load More" id="xMnH8F" />
    )}
  </Button>
)

LoadMoreButton.propTypes = {
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
}

export default LoadMoreButton
