/* eslint-disable sort-keys */
import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Stack, Typography } from '@mui/material'
import {
  defaultTo,
  and,
  isNil,
  not,
  propEq,
  any,
  equals,
  or,
  all,
  __,
  isEmpty,
  prop
} from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { useDispatch, useSelector } from 'react-redux'

import { setUserData } from 'store/userSlice'
import { resetState } from 'pages/onboarding/components/onboardingSlice'

import BlockWrapper from './components/BlockWrapper'
import MyPriorities from './components/MyPriorities'
import GetStarted from './components/GetStarted'
import PickUpWhereYouLeftOff from './components/PickUpWhereYouLeftOff'
import Footer from './components/Footer'
import SelfChecksTabList from './components/SelfChecksTabList'
import ConfirmationModal from './components/ConfirmationModal'
import TopicsRegionBlock from 'components/common/RegionBlock'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { letters, hide_resources } = useSelector(prop('school'))
  const {
    has_been_shown_a_modal,
    has_viewed_a_priority,
    has_viewed_campus_resources,
    has_viewed_profile
  } = useSelector(prop('user'))

  const { data: rcqData } = useGetSelfCheckQuizDataQuery(__, {
    refetchOnMountOrArgChange: true
  })

  const navigate = useNavigate()
  const [search] = useSearchParams()
  const justOnboarded = search.get('justOnboarded')

  // MODAL DISPLAY LOGIC
  // onboarding passes a param to signal the display of this modal in the route params
  const [openConfirmationModal, setOpenConfirmationModal] = useState(
    defaultTo(false, justOnboarded)
  )

  // all three of the modals onClose methods use this
  const modalClose = closeStateSetter => {
    closeStateSetter(false)
    dispatch(setUserData({ name: 'has_been_shown_a_modal', value: true }))
    navigate('/home', { replace: true })
  }

  //PICK UP WHERE YOU LEFT OFF
  const succeedSelfCheckData = useMemo(() => {
    if (not(isNil(rcqData))) {
      return rcqData.find(propEq('title', 'Succeed'))
    }
    return {}
  }, [rcqData])
  const thriveSelfCheckData = useMemo(() => {
    if (not(isNil(rcqData))) {
      rcqData.find(propEq('title', 'Thrive'))
    }
    return {}
  }, [rcqData])
  const matterSelfCheckData = useMemo(() => {
    if (not(isNil(rcqData))) {
      rcqData.find(propEq('title', 'Matter'))
    }
    return {}
  }, [rcqData])

  const completeCheck = any(equals(true))([
    not(isEmpty(succeedSelfCheckData)),
    not(isEmpty(thriveSelfCheckData)),
    not(isEmpty(matterSelfCheckData))
  ])

  const additionalChecks = or(
    and(has_viewed_campus_resources, not(hide_resources)),
    has_viewed_profile
  )

  const showPickUpWhereYouLeftOff = all(equals(true))([
    completeCheck,
    or(equals(1, has_viewed_a_priority), equals(true, has_viewed_a_priority)),
    additionalChecks
  ])

  return (
    <Stack
      sx={{
        backgroundImage: 'url("/images/dashboard_bg.png")',
        pb: { xs: 2, lg: 4 }
      }}
    >
      <Container maxWidth="xl">
        {/*our modals*/}
        <ConfirmationModal
          onClose={() => {
            dispatch(resetState())
            modalClose(setOpenConfirmationModal)
          }}
          open={and(openConfirmationModal, not(has_been_shown_a_modal))}
        />

        <Typography
          color="secondary.dark"
          sx={{
            fontSize: { xs: 22 },
            fontWeight: 500,
            lineHeight: '28px',
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
          variant="h1"
        >
          <FormattedMessage defaultMessage="Welcome to YOU" id="4QohQh" />
        </Typography>
        <Stack spacing={{ xs: 2, lg: 4 }} sx={{ mt: { xs: 1, lg: 2 } }}>
          <BlockWrapper
            title={
              <FormattedMessage defaultMessage="My Priorities" id="dm4opd" />
            }
          >
            <MyPriorities />
          </BlockWrapper>

          {not(showPickUpWhereYouLeftOff) ? (
            <BlockWrapper
              title={
                <FormattedMessage defaultMessage="Get Started 🚀" id="L0uC4v" />
              }
            >
              <GetStarted />
            </BlockWrapper>
          ) : (
            <BlockWrapper
              title={
                <FormattedMessage
                  defaultMessage="Get advice, take action and connect."
                  id="Nwhnms"
                />
              }
            >
              <PickUpWhereYouLeftOff />
            </BlockWrapper>
          )}

          <BlockWrapper
            title={
              <FormattedMessage
                defaultMessage="Check in with yourself"
                id="Vf2FLw"
              />
            }
          >
            <SelfChecksTabList />
          </BlockWrapper>

          <BlockWrapper
            title={
              <FormattedMessage
                defaultMessage="Featured at {letters}"
                id="iOkM+s"
                values={{ letters }}
              />
            }
          >
            <TopicsRegionBlock limit={6} />
          </BlockWrapper>

          <BlockWrapper
            title={
              <FormattedMessage
                defaultMessage="More to do with YOU"
                id="kXk4og"
              />
            }
          >
            <Footer />
          </BlockWrapper>
        </Stack>
      </Container>
    </Stack>
  )
}

export default Dashboard
