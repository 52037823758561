import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grow,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/system'

import ChatBubble from 'assets/images/icons/ChatBubble'

import { excerpt, sanitizeUrl } from 'utils/helpers'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { defaultTo, prop, or, isEmpty, isNil } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'

const StoryCard = ({
  blockTitle = 'OFD Explore Stories',
  id,
  title,
  photo = {},
  photo_description = '',
  video_thumb_url = '',
  video_description = ''
}) => {
  const theme = useTheme()
  const { sendEvent } = useAnalytics()

  return (
    <Grow in>
      <Card aria-label={title}>
        <CardActionArea
          component={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: blockTitle,
              click_element: 'Link',
              element_title: title,
              path: window.location.pathname
            })
          }
          to={`/public/article/${id}/${sanitizeUrl(title)}`}
        >
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              alt={`${title} video`}
              component="img"
              height="164"
              image={defaultTo(video_thumb_url, prop('featured_url', photo))}
            />
            <Box sx={{ bottom: -30, position: 'absolute', right: 10 }}>
              <ChatBubble fill={theme.palette.school.primary} />
            </Box>
          </Box>
          <CardContent>
            <Box
              sx={{
                display: 'grid',
                gridGap: 8,
                textAlign: 'left'
              }}
            >
              <Typography display="block" variant="cardTitle">
                <FormattedMessage
                  defaultMessage="{title}"
                  id="2YgLVn"
                  values={{ title }}
                />
              </Typography>
              <Box
                sx={{
                  '& > div > p, & > p > span, & > p': {
                    margin: 0,
                    padding: 0
                  }
                }}
                variant="body1"
              >
                <FormattedMessage
                  defaultMessage="{parsed}"
                  id="yCdQ4W"
                  values={{
                    parsed: parse(
                      excerpt(
                        or(isEmpty(video_description), isNil(video_description))
                          ? photo_description
                          : video_description,
                        68
                      )
                    )
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  )
}

StoryCard.propTypes = {
  blockTitle: PropTypes.string,
  id: PropTypes.number.isRequired,
  photo: PropTypes.shape({}),
  photo_description: PropTypes.string,
  title: PropTypes.string.isRequired,
  video_description: PropTypes.string,
  video_thumb_url: PropTypes.string
}

export default StoryCard
