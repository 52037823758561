import { Button, CircularProgress, Stack } from '@mui/material'
import { and, any, assoc, equals, isNil, not, prop, reject } from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useSignUpMutation } from 'api/authApi'
import PropTypes from 'prop-types'
import { getBodyCopy } from 'store/authenticationSlice'

import EmailAndPassword from 'components/authentication/EmailAndPasswordConnector'
import SSOButtons from 'components/authentication/SSOButtons'
import SuccessMessage from 'components/common/SuccessMessage'
import ErrorMessage from 'components/common/ErrorMessage'

const CreateAccount = ({ email, password, revalidation }) => {
  const intl = useIntl()
  const { domain } = useSelector(prop('school'))

  // Redux Selector
  const bodyCopy = useSelector(({ authentication }) =>
    getBodyCopy(authentication)
  )

  const [signUp, { isLoading, data }] = useSignUpMutation()

  const handleSubmit = () =>
    signUp(reject(isNil, assoc('org_domain', domain, bodyCopy)))

  return (
    <>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <SuccessMessage
          hasSuccess={prop('success', data)}
          message={intl.formatMessage({
            defaultMessage:
              'Success! Check your email to verify your new account.',
            id: 'MGQUIN',
          })}
        />
        <ErrorMessage
          hasError={and(not(isNil(data)), not(prop('success', data)))}
          message={prop('message', data)}
        />
        <EmailAndPassword revalidate socialSSO />
        <Button
          disabled={any(equals(true))([
            isNil(email),
            isNil(password),
            isNil(revalidation),
            not(equals(password, revalidation)),
          ])}
          fullWidth
          id="sign-up"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: 'school.primary' }} />
          ) : (
            <FormattedMessage defaultMessage="Create Account" id="5JcXdV" />
          )}
        </Button>
      </Stack>

      <SSOButtons isSignUp />
    </>
  )
}

CreateAccount.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  revalidation: PropTypes.string,
}

CreateAccount.defaultProps = {
  email: null,
  password: null,
  revalidation: null,
}

export default CreateAccount
