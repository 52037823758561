import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'

import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useGetPublicCardQuery } from 'api/publicApi'
import {
  prop,
  isEmpty,
  not,
  isNil,
  slice,
  equals,
  defaultTo,
  map,
  replace
} from 'ramda'
import { useEffect } from 'react'
import { useAnalytics, useCurrentDomain, useHandlePublicRoutes } from 'hooks'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'

import TextArticle from './components/TextArticle'
import VideoArticle from './components/VideoArticle'
import CTASection from 'pages/public/components/CTASection'
import ResourceCard from 'pages/public/components/cards/ResourceCard'
import CTACard from 'pages/public/components/cards/CTACard'
import BreadCrumbs from 'pages/public/components/BreadCrumbs'

const ArticlePage = () => {
  useHandlePublicRoutes()
  const { id } = useParams()
  const intl = useIntl()
  const { sendEvent } = useAnalytics()

  const { currentDomain } = useCurrentDomain()
  const { data: article = {}, isFetching } = useGetPublicCardQuery(
    {
      id,
      org: currentDomain
    },
    { skip: isNil(id) }
  )

  useEffect(() => {
    if (not(isEmpty(article)))
      sendEvent('card_detail_view', {
        campus_resource: prop('campus_resource', article),
        card_domain: prop('domain_name', article),
        card_groups: defaultTo([], map(prop('name'), prop('groups', article))),
        card_id: prop('id', article),
        card_name: replace('"', '', prop('title', article)),
        card_type: convertCardTypeToString(prop('type', article))
      })
  }, [article])

  if (isFetching)
    return (
      <Container
        sx={{
          // eslint-disable-next-line sort-keys
          pt: { xs: 4, md: 8 }
        }}
      >
        <Stack>
          <Skeleton variant="text" width={135} />
          <Skeleton
            height={63}
            sx={{ alignSelf: 'center' }}
            variant="text"
            width="50%"
          />
          <Stack spacing={2}>
            <Skeleton
              height={300}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
            <Skeleton
              height={150}
              sx={{ alignSelf: 'center' }}
              variant="rectangular"
              width="60%"
            />
          </Stack>
        </Stack>
      </Container>
    )

  const filteredCards = prop('recommended_cards', article).filter(({ type }) =>
    not(equals(8, type))
  )

  return (
    <Box
      component="article"
      id="main"
      sx={{
        background: 'url(/images/CardInterior.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        gridGap: 8,
        // eslint-disable-next-line sort-keys
        pt: { xs: 4, md: 8 }
      }}
    >
      <Container
      // sx={{
      //   display: 'flex',
      //   flexDirection: 'column',
      // }}
      >
        <BreadCrumbs
          links={[
            {
              href: '/public',
              label: intl.formatMessage({
                defaultMessage: 'Explore',
                id: '7JlauX'
              })
            },
            {
              href: '',
              label: intl.formatMessage({
                defaultMessage: 'Article',
                id: 'jx7Hn3'
              })
            }
          ]}
        />
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gridGap: 40, mb: 4 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              maxWidth: 600,
              width: 1
            }}
          >
            {not(isNil(prop('video_url', article))) ? (
              <VideoArticle article={article} />
            ) : (
              <TextArticle article={article} />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 4
              }}
            >
              <Button
                LinkComponent={Link}
                onClick={() =>
                  sendEvent('block_interactions', {
                    // eslint-disable-next-line quotes
                    block_title: 'OFD Logged out Article Body CTA',
                    click_element: 'Link',
                    // eslint-disable-next-line quotes
                    element_title: 'Sign up CTA',
                    path: window.location.pathname
                  })
                }
                to="/sign-up"
              >
                <FormattedMessage defaultMessage="sign up" id="yv/kr9" />
              </Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ margin: '0 auto', maxWidth: 1024, width: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gridGap: 24
                }}
              >
                <Typography textAlign="center" variant="body1">
                  <FormattedMessage
                    defaultMessage="Recommended Content"
                    id="SwOdpM"
                  />
                </Typography>
                <Box
                  sx={{
                    alignItems: 'flex-start',
                    display: 'grid',
                    gridGap: 24,
                    // eslint-disable-next-line sort-keys
                    gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' }
                  }}
                >
                  {not(isEmpty(article)) &&
                    slice(0, 2, filteredCards).map(card => (
                      <ResourceCard key={prop('id', card)} {...card} />
                    ))}
                  <CTACard blockTitle="OFD Article Recommended Content" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <CTASection blockTitle="OFD Logged Out Article Body CTA" />
      </Container>
    </Box>
  )
}

export default ArticlePage
