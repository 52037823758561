import {
  Alert,
  AlertTitle,
  Button,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { useUpdateProfileMutation } from 'api/profileApi'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { updateUserData } from 'store/userSlice'
import PropTypes from 'prop-types'

const ProfileAlert = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const [update] = useUpdateProfileMutation()

  const handleClick = () => {
    update({ has_seen_profile_update: true })
    dispatch(
      updateUserData({
        name: 'has_seen_profile_update',
        value: true
      })
    )
    setShow(false)
  }

  return (
    <Slide direction="left" in={show}>
      <Alert
        component="div"
        icon={false}
        role="alert"
        severity="info"
        sx={{
          borderTopColor: 'school.secondary',
          boxShadow: 6
        }}
        variant="outlined"
      >
        <AlertTitle>
          <FormattedMessage defaultMessage="Update your Profile!" id="GVOTUB" />
        </AlertTitle>
        <Typography sx={{ display: 'inline' }} variant="body2">
          <FormattedMessage
            defaultMessage="Help us help you! Take 2 minutes to fill out your profile for a customized experience as unique as you."
            id="Fb3uha"
          />
        </Typography>
        <Stack direction="row" mt={2} spacing={2}>
          <Button
            LinkComponent={Link}
            onClick={handleClick}
            to="/profile?active=about"
          >
            <FormattedMessage defaultMessage="Visit Profile" id="AZj8qj" />
          </Button>
          <Button onClick={handleClick} variant="outlined">
            <FormattedMessage defaultMessage="Close" id="rbrahO" />
          </Button>
        </Stack>
      </Alert>
    </Slide>
  )
}

ProfileAlert.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default ProfileAlert
