/* eslint-disable sort-keys */
import { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import ScrollInView from './ScrollInView'
import styles from './WelcomeSectionTools.module.scss'
import PollCard from './PollCard'
import WelcomeGoalCard from './WelcomeGoalCard'
import { assoc } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

import {
  collectiveQuestionProps,
  businessQuestionProps,
  mhcdQuestionProps
} from './selfCheckCopy'

import {
  collectiveGoalProps,
  businessGoalProps,
  mhcdGoalProps,
  nlcGoalProps
} from './goalsCopy'
import { useAnalytics, useCurrentAppType } from 'hooks'

const WelcomeSectionTools = () => {
  const ref = useRef(null)
  const inView = ScrollInView(ref)
  const { isBusiness, isWellpower, isCollective, isNLC } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const headline = (
    <FormattedMessage
      defaultMessage="Take action with tools that put you in charge."
      id="SR6cz+"
    />
  )

  const paragraph_business =
    'Explore self check quizzes and set goals to be your best on and off the job.'
  const paragraph_nlc =
    'Take a self check and set goals on the areas you want to improve upon.'
  const paragraph_mhcd = (
    <FormattedMessage
      defaultMessage="Take a self check and set goals in the areas you want to improve upon."
      id="gElfvC"
    />
  )
  const paragraph_collective = (
    <FormattedMessage
      defaultMessage="Take a quick self-check that will help you set goals in the areas you want to grow in. "
      id="sgLuwN"
    />
  )

  let paragraph
  if (isBusiness) paragraph = paragraph_business
  if (isWellpower) paragraph = paragraph_mhcd
  if (isCollective) paragraph = paragraph_collective
  if (isNLC) paragraph = paragraph_nlc

  const CTALink = 'sign up to challenge yourself'

  let questionPropsObject = businessQuestionProps
  if (isWellpower) questionPropsObject = mhcdQuestionProps
  if (isCollective) questionPropsObject = collectiveQuestionProps

  const questionSummaryPropsObject = [
    {}, // add empty one to not break Poll Question
    { answer: questionPropsObject.answers[0], percentage: 9, selected: false },
    { answer: questionPropsObject.answers[1], percentage: 23, selected: false },
    { answer: questionPropsObject.answers[2], percentage: 51, selected: true },
    { answer: questionPropsObject.answers[3], percentage: 19, selected: false }
  ]

  let goalPropsObject
  if (isBusiness) goalPropsObject = businessGoalProps
  if (isWellpower) goalPropsObject = mhcdGoalProps
  if (isCollective) goalPropsObject = collectiveGoalProps
  if (isNLC) goalPropsObject = nlcGoalProps

  //Self Check Animation
  const [questionButtonPulse, setQuestionButtonPulse] = useState(false)
  const [questionProps, setQuestionProps] = useState(questionPropsObject)

  //Goal Animation
  const [goalButtonPulse, setGoalButtonPulse] = useState(false)
  const [showGoal, setShowGoal] = useState(true)
  const [goalProps, setGoalProps] = useState(goalPropsObject)

  useEffect(() => {
    let pulseTimeout, answersTimeout, goalPulse, goalPropsTimeout
    if (inView) {
      //Self check question pulses
      pulseTimeout = setTimeout(() => {
        setQuestionButtonPulse(true)
      }, 2000)

      //replace the questions with answers
      answersTimeout = setTimeout(() => {
        setQuestionProps(prev => ({
          ...prev,
          questionSummary: questionSummaryPropsObject
        }))
      }, 3000)

      //Goal button pulses
      goalPulse = setTimeout(() => {
        setGoalButtonPulse(true)
      }, 5000)

      //Goal state changes to active
      goalPropsTimeout = setTimeout(() => {
        setShowGoal(false)
        setGoalProps(assoc('activeGoal', true, goalPropsObject))
        setShowGoal(true)
      }, 6000)
    }
    return () => {
      clearTimeout(pulseTimeout)
      clearTimeout(answersTimeout)
      clearTimeout(goalPulse)
      clearTimeout(goalPropsTimeout)
    }
  }, [inView])

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'tools'
    })
  }

  return (
    <Stack
      direction={{ xs: 'column', md: 'row ' }}
      ref={ref}
      sx={{
        py: { xs: '30%', lg: '15%' }
      }}
    >
      <Stack
        sx={{
          flex: 1.1,
          order: { xs: 1, lg: 2 },
          position: 'relative'
        }}
      >
        <Box className={styles['hero-hash-circle-small-container']}>
          <Box className={styles['hero-hash-circle-small']} />
        </Box>

        <Box sx={{ position: 'relative', width: { xs: 1, sm: '85%', lg: 1 } }}>
          <Box
            bgcolor="school.secondary"
            className={styles['mask-tools-back']}
            sx={{ display: { xs: 'none', sm: 'initial' } }}
          />
          <Box
            bgcolor="school.primary"
            className={styles['mask-tools-front']}
            sx={{ display: { xs: 'none', sm: 'initial' } }}
          />

          <Box
            sx={{
              left: { xs: 0, sm: '4%', lg: '8%' },
              position: { xs: 'relative', sm: 'absolute' },
              px: { xs: 3, sm: 'initial' },
              top: { xs: '20%', lg: '-2%' },
              width: { xs: 1, md: '65%', lg: '55%' },
              zIndex: 3
            }}
          >
            <PollCard
              animate={questionButtonPulse}
              pollingQuestion={{ ...questionProps }}
            />
          </Box>

          {showGoal && (
            <WelcomeGoalCard {...goalProps} animate={goalButtonPulse} />
          )}
        </Box>
      </Stack>

      <Box display="flex" flex={1} position="relative">
        <Stack
          alignSelf="center"
          spacing={2}
          sx={{
            maxWidth: { xs: '92%', lg: '80%' },
            mx: 'auto',
            pb: { xs: 4, lg: 0 }
          }}
        >
          <Typography variant="h2">
            <FormattedMessage
              defaultMessage="{headline}"
              id="AEeXEL"
              values={{ headline: headline }}
            />
          </Typography>
          <Typography variant="bodyLarge">{paragraph}</Typography>
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            LinkComponent={Link}
            onClick={() => sendKeenIOEvent('Create a new Account', 'Link')}
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              px: 0
            }}
            to="/sign-up"
            variant="text"
          >
            <FormattedMessage
              defaultMessage="{ctaLink}"
              id="QBkvSl"
              values={{ ctaLink: CTALink }}
            />
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

export default WelcomeSectionTools
