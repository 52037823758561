import { baseQuery } from './baseQuery'

export const cardsApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    favorite: mutation({
      invalidatesTags: ['card', 'challenge card'],
      query: ({ isFavorite, card_id }) => ({
        body: { card_id },
        method: 'POST',
        url: `/favorite-${isFavorite ? 'add' : 'remove'}`,
      }),
    }),
    favoriteResource: mutation({
      query: ({ isFavorite, card_id }) => ({
        body: { card_id },
        method: 'POST',
        url: `/favorite-${isFavorite ? 'add' : 'remove'}`,
      }),
    }),
    getCard: query({
      providesTags: ['card'],
      query: id => `/v2/cards/${id}`,
    }),
    getCardSentiment: query({
      providesTags: ['card sentiment'],
      query: id => `/card-sentiment/${id}`,
    }),
    getCards: query({
      providesTags: ['cards'],
      query: params => ({
        params,
        url: '/v2/cards',
      }),
    }),
    getChallengeCard: query({
      providesTags: ['challenge card'],
      query: id => `/v2/cards/${id}/challenge`,
    }),
    getGeolocationResources: query({
      providesTags: ['geolocation resources'],
      query: qp => `/v2/geo-located-resources${qp}`,
    }),
    getNewestCards: query({
      providesTags: ['new cards'],
      query: params => ({
        params,
        url: '/v2/cards/newest',
      }),
    }),
    getPickupCards: query({
      providesTags: ['pick up cards'],
      query: () => '/v2/cards/pick-up-where-you-left-off',
    }),
    setCardSentiment: mutation({
      invalidatesTags: ['card sentiment'],
      query: body => ({
        body,
        method: 'POST',
        url: '/card-sentiment',
      }),
    }),
  }),
  reducerPath: 'cardsApi',
})

export const {
  useFavoriteMutation,
  useFavoriteResourceMutation,
  useGetCardQuery,
  useGetCardSentimentQuery,
  useGetCardsQuery,
  useGetChallengeCardQuery,
  useGetGeolocationResourcesQuery,
  useGetNewestCardsQuery,
  useGetPickupCardsQuery,
  useSetCardSentimentMutation,
} = cardsApi
