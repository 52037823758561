import PropTypes from 'prop-types'
import { memo } from 'react'
import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { defaultTo, path, prop, propEq } from 'ramda'
import { FormattedMessage } from 'react-intl'

const SelectQuestionInput = ({ question, userAnswers, onUpdateAnswer }) => (
  <Stack spacing={1}>
    <Typography variant="subtitle4">
      <FormattedMessage
        defaultMessage="{val}"
        id="3LsPxf"
        values={{ val: prop('question', question) }}
      />
    </Typography>
    <Select
      aria-label="gender select"
      onChange={e => {
        const updatedAnswer = {
          // need to pass the answer id here, so we use value.value
          answers: [path(['target', 'value'], e)],
          profile_question_id: question.id,
        }
        if (!userAnswers.includes(updatedAnswer)) {
          onUpdateAnswer(updatedAnswer)
        }
      }}
      sx={{ maxWidth: 432 }}
      value={defaultTo(
        '',
        prop('id', question.answers.find(propEq(userAnswers[0], 'id')))
      )}
    >
      {question.answers.map(({ answer, id }) => (
        <MenuItem key={id} value={id}>
          {answer}
        </MenuItem>
      ))}
    </Select>
  </Stack>
)

SelectQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    question: PropTypes.string,
  }).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired,
}

export default memo(SelectQuestionInput)
