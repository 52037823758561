const MuiCssBaseline = {
  styleOverrides: {
    a: {
      ' > span': {
        textDecoration: 'none',
      },
      textDecoration: 'none',
    },
  },
}

export default MuiCssBaseline
