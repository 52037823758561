/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { svg_goals } from 'icons'
import ProgressBar from 'components/common/ProgressBar'
import {
  prop,
  and,
  equals,
  isEmpty,
  isNil,
  dec,
  inc,
  reject,
  gte,
  length
} from 'ramda'
import { useCreateGoalMutation } from 'api/goalsApi'
import GA from 'services/gaService'
import { Box, Button, Typography } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'

import Focus from './components/Focus'
import Name from './components/Name'
import StepsForm from '../components/StepsForm'
import DueDate from '../components/DueDate'
import { useNavigate } from 'react-router-dom'

const NewGoal = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)

  const [createGoal, { isLoading, isSuccess }] = useCreateGoalMutation()

  useEffect(() => {
    if (isSuccess) {
      GA.track('Add-Goal', 'Submit')
      navigate('/goals?active=my-goals')
    }
  }, [isSuccess])

  // Updating Goal
  const [goal, setGoal] = useState({
    campus_goal_id: null,
    card_id: null,
    domain_id: null,
    due_date: null,
    steps: [
      {
        completed: false,
        id: null,
        name: ''
      }
    ],
    subdomain_id: null,
    title: ''
  })

  const creationSteps = [
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Next',
        id: '9+Ddtu'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'What priority area is your goal connected to?',
        id: 'syPtYA'
      }),
      title: intl.formatMessage({
        defaultMessage: 'Give your goal a focus',
        id: 'SGZToZ'
      })
    },
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Next',
        id: '9+Ddtu'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Dream big and think about what you're striving for right now. Don't worry about the details just yet.",
        id: 'MT4Eq4'
      }),
      title: intl.formatMessage({
        defaultMessage: 'So, what do you want to accomplish?',
        id: 'CwZABK'
      })
    },
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Save Steps',
        id: 'fKkniH'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effective goals include smaller, actionable steps. Write out your steps here.',
        id: 'pQx8Am'
      }),
      title: intl.formatMessage({
        defaultMessage: 'Your recipe for success',
        id: '4RTn/h'
      })
    },
    {
      buttonText: intl.formatMessage({
        defaultMessage: 'Complete',
        id: 'U78NhE'
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Setting deadlines is helpful for some, but not all. If it's not your style, skip this step and your goal will appear as ongoing with no due date.",
        id: 'EeDvix'
      }),
      title: intl.formatMessage({
        defaultMessage: 'Set a due date',
        id: 'yRimE8'
      })
    }
  ]

  const currentStep = prop(currentIndex, creationSteps)
  const isNextDisabled = () => {
    if (and(equals(1, currentIndex), isEmpty(prop('title', goal)))) {
      return true
    }
    if (and(equals(0, currentIndex), isNil(prop('subdomain_id', goal)))) {
      return true
    }
    if (isLoading) return true
    return false
  }

  const handleBack = () => {
    if (equals(currentIndex, 0)) {
      navigate('/goals?active=my-goals')
    } else {
      setCurrentIndex(dec(currentIndex))
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = () => {
    // final step we send info to the BE and route off create
    // otherwise we move to the next step
    if (equals(inc(currentIndex), length(creationSteps))) {
      const body = new FormData()
      const transformedGoal = reject(isNil, goal)
      for (const [key, value] of Object.entries(transformedGoal)) {
        if (equals(key, 'steps')) {
          goal.steps.forEach((step, index) => {
            body.append('steps[' + index + '][name]', step.name)
          })
        } else if (equals(key, 'due_date')) {
          body.append(key, new Date(value).toISOString())
        } else {
          body.append(key, value)
        }
      }
      createGoal(body)
    } else {
      window.scrollTo(0, 0)
      setCurrentIndex(inc(currentIndex))
    }
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          m: '0 auto',
          maxWidth: equals(currentIndex, 0) ? '100%' : '572px',
          px: { xs: 2, md: 3 },
          width: 1
        }}
      >
        <Typography mb="8px" variant="overline">
          <FormattedMessage
            defaultMessage="Step {currentStep}/{totalSteps}"
            id="BrVAMy"
            values={{
              currentStep: currentIndex + 1,
              totalSteps: 4
            }}
          />
        </Typography>
        <Box sx={{ width: '35%' }}>
          <ProgressBar
            barColor="secondary"
            completed={currentIndex + 1}
            noLabel
            total={4}
          />
        </Box>
        <Typography
          component="h1"
          fontWeight="bold"
          my={['16px', null, null, '24px']}
          textAlign="center"
          variant="h3"
        >
          {prop('title', currentStep)}
        </Typography>
        <Typography
          color="secondary.dark"
          mb={['16px', null, null, '24px']}
          textAlign="center"
          variant="body1"
        >
          {prop('subtitle', currentStep)}
        </Typography>
        {gte(currentIndex, 2) && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gridGap: 8,
              mb: { xs: 2, lg: 3 }
            }}
          >
            <Box
              sx={{
                '& > svg': {
                  fill: 'red',
                  height: 25,
                  width: 25
                },
                mt: 0.5
              }}
            >
              {svg_goals}
            </Box>

            <Typography
              alignItems="center"
              display="flex"
              fontWeight="bold"
              textAlign="center"
              variant="h4"
            >
              {goal.title}
            </Typography>
          </Box>
        )}
        {equals(currentIndex, 0) && <Focus goal={goal} setGoal={setGoal} />}
        {equals(currentIndex, 1) && <Name goal={goal} setGoal={setGoal} />}
        {equals(currentIndex, 2) && <StepsForm goal={goal} setGoal={setGoal} />}
        {equals(currentIndex, 3) && <DueDate goal={goal} setGoal={setGoal} />}
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: 'white',
            bottom: 0,
            boxShadow: 3,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            pr: { xs: 2, lg: 4 },
            py: 1.5,
            right: 0,
            width: { xs: 'calc(100% - 16px)', lg: 'calc(100% - 260px)' }
          }}
        >
          <Button
            onClick={() => handleBack()}
            startIcon={<KeyboardArrowLeftRoundedIcon />}
            variant="text"
          >
            Back
          </Button>
          <Button disabled={isNextDisabled()} onClick={() => onSubmit()}>
            {isLoading
              ? intl.formatMessage({
                  defaultMessage: 'Creating your goal...',
                  id: 'Hkphf5'
                })
              : currentStep.buttonText}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default NewGoal
