import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ErrorMessage from 'components/common/ErrorMessage'
import RadioQuestionInput from './RadioQuestionInput'
import YesNoQuestionInput from './YesNoQuestionInput'
import SelectQuestionInput from './SelectQuestionInput'
import TextQuestionInput from './TextQuestionInput'
import DropDownQuestionInput from './DropDownQuestionInput'
import useAnalytics from 'hooks/useAnalytics'

import {
  always,
  and,
  assoc,
  cond,
  equals,
  isNil,
  map,
  not,
  prop,
  propEq,
  T,
  when
} from 'ramda'
import { useUpdateUserQuestionsMutation } from 'api/userApi'
import { debounce } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updatePublicUser, updateUserData } from 'store/userSlice'

function ProfileQuestion({ question, userAnswers }) {
  const dispatch = useDispatch()
  const { profile_answers } = useSelector(prop('user'))

  const { sendEvent } = useAnalytics()
  const [answer, setAnswer] = useState(null)

  const [updateUser, { isSuccess }] = useUpdateUserQuestionsMutation()

  const handleAnswer = answer => {
    const id = prop('profile_question_id', answer)
    const answers = prop('answers', answer)

    if (equals(id, 37)) {
      return dispatch(
        updatePublicUser({
          name: 'preferred_name',
          value: prop(0, answers)
        })
      )
    }
    return dispatch(
      updateUserData({
        name: 'profile_answers',
        value: map(
          when(propEq(id, 'profile_question_id'), assoc('answers', answers))
        )(profile_answers)
      })
    )
  }

  // Using debounce for now
  const handleUpdateAnswer = debounce(answer => {
    updateUser(answer)
    handleAnswer(answer)
    setAnswer(answer)
  }, 1000)

  useEffect(() => {
    // After updating profile on success send keen event
    if (and(isSuccess, not(isNil(answer)))) {
      const anonymousQuestions = ['preferred_name']
      sendEvent('profile_question', {
        answer: anonymousQuestions.includes(question.name)
          ? 'n/a'
          : answer.answers
              .map(value => [question.answers.find(a => a.id === value), value])
              .map(({ answer, value }) =>
                answer
                  ? answer.analytics_value
                  : value instanceof Date
                    ? value.toISOString()
                    : value
              )
              .join(','),
        question: question.analytics_property_name
      })
      setAnswer(null)
    }
  }, [isSuccess, answer])

  const renderInput = cond([
    [
      equals('dropdown-menu'),
      always(
        <DropDownQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('radio-multi'),
      always(
        <RadioQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('radio-single'),
      always(
        <SelectQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('select-multi'),
      always(
        <RadioQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('select-single'),
      always(
        <SelectQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('text'),
      always(
        <TextQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      equals('yes-no-button'),
      always(
        <YesNoQuestionInput
          onUpdateAnswer={handleUpdateAnswer}
          question={question}
          userAnswers={userAnswers}
        />
      )
    ],
    [
      T,
      always(
        <ErrorMessage
          hasError
          message={
            <FormattedMessage
              defaultMessage="could not find an input for this question type"
              id="Y4s/Bo"
            />
          }
        />
      )
    ]
  ])

  return renderInput(prop('control_type', question))
}

ProfileQuestion.propTypes = {
  question: PropTypes.shape({
    control_type: PropTypes.string
  }).isRequired,
  userAnswers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired
}

export default ProfileQuestion
