const MuiAlert = {
  styleOverrides: {
    icon: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    message: {
      paddingLeft: 4,
    },
    root: {
      backgroundColor: 'white',
      borderTop: '4px solid',
    },
  },
}

export default MuiAlert
