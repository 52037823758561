import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Container, Stack, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { equals, split, map, isNil, and, not, prop } from 'ramda'
import { useSignInAzureMutation } from 'api/authApi'
import { useLocalStorage } from 'hooks'

import ErrorMessage from 'components/common/ErrorMessage'
import LogInButton from 'components/common/LogInButton'
import Modal from './Modal'

const AzureSignupRedirect = () => {
  const { hash } = useLocation()
  const { clear, save } = useLocalStorage()

  const [azureLogin, { data, isError }] = useSignInAzureMutation()

  useEffect(() => {
    // redirect logic
    let code
    let idToken
    // todo - we should probs tell wellpower to change up their return link from their azure set up
    // currently it sends back query params as starting with a '#' instead of a '?' and the next
    // router can't parse it properly to turn it into router.query items.
    // so we are going to do this nice little parsing of our own down here below, but if we get them
    // to use a '?' in the route to separate the route from the query params, we will be able to go back
    // to the original plan which I have left inside the code.
    // instead, if we are missing those params from the router.query, we will check the pathname
    // for the values.
    // example current redirect url: /azure-signup-redirect#code=123&id_token=456
    const path = split('#', hash)
    // second element in the array is the query params attached to the path
    const queryParams = path[1]
    // example queryParams: 'code=123&id_token=456
    map(
      param => {
        const [key, value] = split('=', param)
        if (equals('code', key)) code = value
        if (equals('id_token', key)) idToken = value
      },
      split('&', queryParams)
    )

    if (and(not(isNil(code)), not(isNil(idToken)))) {
      clear()
      azureLogin({
        code,
        id_token: idToken,
        org_domain: process.env.REACT_APP_PUBLIC_ORG_DOMAIN
      })
    }
  }, [hash])

  useEffect(() => {
    if (not(isNil(data))) {
      save(prop('x-session-id', data))
    }
  }, [data])

  const renderError = () => (
    <Container>
      <Stack alignItems="center" mt={8} spacing={2}>
        <Typography textAlign="center" variant="h1">
          <FormattedMessage defaultMessage="We’re sorry." id="KYWZ5c" />
        </Typography>

        <ErrorMessage
          hasError
          message={
            <FormattedMessage
              defaultMessage="We had an issue creating your account. Try signing up again."
              id="u52ozk"
            />
          }
        />

        <Button LinkComponent={Link} to="/sign-up">
          <FormattedMessage defaultMessage="TRY AGAIN" id="BaevIC" />
        </Button>

        <LogInButton>
          <FormattedMessage
            defaultMessage="Already have an account? Sign in."
            id="1PTHHE"
          />
        </LogInButton>
      </Stack>
    </Container>
  )

  return isError ? renderError() : <Modal data={data} />
}

export default AzureSignupRedirect
