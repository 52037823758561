/* eslint-disable sort-keys */
import { FormattedMessage, useIntl } from 'react-intl'
import optInImage from 'assets/images/onboarding/optIn.svg'
import { Box, Button, Stack, Typography } from '@mui/material'
import { not, prop } from 'ramda'
import { useAnalytics } from 'hooks'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { setEmailOptIn } from './onboardingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateUserMutation } from 'api/userApi'

const EmailOptIn = () => {
  const { sendEvent } = useAnalytics()
  const { formatMessage } = useIntl()
  const { emailOptIn } = useSelector(prop('onboarding'))
  const dispatch = useDispatch()
  const [updateUser] = useUpdateUserMutation()

  const handleChange = () => {
    dispatch(setEmailOptIn(not(emailOptIn)))
    sendEvent('you_email_opt_in', {
      email_opt_in: not(emailOptIn),
      opt_in_element: 'Onboarding'
    })
    updateUser({ email_opt_in: not(emailOptIn) })
  }

  return (
    <Stack alignItems="center" mt={1.5} spacing={2}>
      <Box component="img" maxWidth={295} src={optInImage.src} width={1} />
      <Stack>
        <Typography component="h1" textAlign="center" variant="h4">
          <FormattedMessage
            defaultMessage="Well-being, right to your inbox."
            id="6Xc5Lg"
          />
        </Typography>
        <Typography sx={{ maxWidth: 480 }} textAlign="center" variant="body1">
          <FormattedMessage
            defaultMessage="We'll help you focus on what matters most! What to expect from our well-being emails:"
            id="Srnv9W"
          />
        </Typography>
      </Stack>
      <Stack direction={{ xs: 'column', lg: 'row' }}>
        <Box
          component="ul"
          sx={{
            display: 'grid',
            gap: { xs: 1, lg: 6 },
            gridTemplateColumns: { xs: '1ft', lg: 'repeat(2, 1fr)' },
            maxWidth: 650,
            width: 1
          }}
        >
          <Stack spacing={1}>
            <Typography component="li" variant="body2">
              <FormattedMessage
                defaultMessage="Tips for well-being & success"
                id="EavRl3"
              />
            </Typography>
            <Typography component="li" variant="body2">
              <FormattedMessage
                defaultMessage="Resources to help you take action on your goals"
                id="Ty2r9u"
              />
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography component="li" variant="body2">
              <FormattedMessage
                defaultMessage="New features designed for you"
                id="pOQ+zy"
              />
            </Typography>
            <Typography component="li" variant="body2">
              <FormattedMessage defaultMessage="No spam, ever!" id="sogbEo" />
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Button
        aria-label={
          emailOptIn
            ? formatMessage({
                defaultMessage: 'selected, sign me up',
                id: 'CqmX9H'
              })
            : formatMessage({
                defaultMessage: 'un-selected, sign me up',
                id: '0QOGjL'
              })
        }
        onClick={handleChange}
        startIcon={
          emailOptIn ? (
            <CheckBoxIcon color="inherit" sx={{ color: 'info.main' }} />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )
        }
        variant="option"
      >
        <FormattedMessage defaultMessage="Yes, sign me up!" id="XPvk6t" />
      </Button>
      <Typography textAlign="center" variant="caption">
        <FormattedMessage
          defaultMessage="Don’t worry, you can always change your mind later."
          id="ZC95vR"
        />
      </Typography>
    </Stack>
  )
}

export default EmailOptIn
