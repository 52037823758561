import { Card, CardContent, Divider, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { isNil, not } from 'ramda'

const CalloutBox = ({ topSectionCopy, bottomSectionCopy = null }) => (
  <Card
    component="aside"
    sx={{
      borderTop: '4px solid',
      borderTopColor: 'school.accent',
    }}
  >
    <CardContent>
      <Typography data-testid="top-copy" variant="body1">
        {topSectionCopy}
      </Typography>
      {not(isNil(bottomSectionCopy)) && (
        <>
          <Divider />
          {bottomSectionCopy}
        </>
      )}
    </CardContent>
  </Card>
)

export default CalloutBox

CalloutBox.propTypes = {
  bottomSectionCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  topSectionCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
}
