import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button, Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { prop } from 'ramda'
function YesNoQuestionInput({ question, userAnswers, onUpdateAnswer }) {
  const intl = useIntl()
  const answerYes = question.answers[1]
  const answerNo = question.answers[0]
  const currentAnswer = question.answers.find(a => a.id === userAnswers[0])
  const [selected, setSelected] = useState(currentAnswer === answerYes)

  return (
    <Box mr={2} mt={2}>
      <Button
        aria-label={
          selected
            ? intl.formatMessage(
                { defaultMessage: 'Selected {question}', id: 'L7ZsDd' },
                {
                  question: prop('question', question),
                }
              )
            : intl.formatMessage(
                {
                  defaultMessage: 'Not selected {question}',
                  id: '73yDrq',
                },
                {
                  question: prop('question', question),
                }
              )
        }
        onClick={() => {
          const newSelected = !selected
          setSelected(newSelected)
          const answer = newSelected ? answerYes : answerNo
          onUpdateAnswer({
            answers: answer ? [answer.id] : null,
            profile_question_id: question.id,
          })
        }}
        selected={selected}
        sx={{
          borderColor: selected ? 'school.secondary' : 'primary.light',
        }}
        variant="option"
      >
        {question.question}
      </Button>
    </Box>
  )
}

YesNoQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    question: PropTypes.string,
  }).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired,
}

export default YesNoQuestionInput
