import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useAnalytics } from 'hooks'

const SelfCheckFooter = ({
  completed,
  domain = 'succeed',
  blockTitle,
  questionAnswered = false
}) => {
  const { sendEvent } = useAnalytics()

  const renderButton = () => {
    if (questionAnswered)
      return (
        <Button
          endIcon={<ArrowForwardRoundedIcon />}
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: blockTitle,
              click_element: 'Link',
              element_title: 'Take the self check',
              path: window.location.pathname
            })
          }
          sx={{
            fontWeight: 700
          }}
          to={`/self-checks/${domain}`}
          variant="text"
        >
          <FormattedMessage defaultMessage="Take the self check" id="eMbQrV" />
        </Button>
      )
    if (completed)
      return (
        <Button
          endIcon={<ArrowForwardRoundedIcon />}
          LinkComponent={Link}
          sx={{
            fontWeight: 700
          }}
          to={`/self-checks/results/${domain}`}
          variant="text"
        >
          <FormattedMessage
            defaultMessage="See Your Full Results"
            id="0/tx8W"
          />
        </Button>
      )
    return null
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTop: '1px solid',
        borderTopColor: completed ? 'primary.light' : 'transparent',
        mt: 1
      }}
    >
      {renderButton()}
    </Stack>
  )
}

SelfCheckFooter.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  questionAnswered: PropTypes.bool
}

export default SelfCheckFooter
