import { Box, MenuItem, Select, Typography } from '@mui/material'
import { assoc, compose, defaultTo, path, prop, propEq, tap } from 'ramda'
import { useUpdateUserMutation } from 'api/userApi'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from 'store/userSlice'

const LocationSelect = () => {
  const dispatch = useDispatch()
  const { location_id } = useSelector(prop('user'))
  const { locations, location_noun } = useSelector(prop('school'))
  const [updateUser] = useUpdateUserMutation()

  const options = locations.map(({ id, name }) => ({
    label: name,
    value: id
  }))
  const foundValue = options.find(propEq(location_id, 'value'))

  const handleUpdateUser = location_id => updateUser({ location_id })

  const handleSetUser = location => dispatch(setUserData(location))

  return (
    <Box
      sx={{
        display: 'grid',
        // eslint-disable-next-line sort-keys
        gridAutoColumns: { xs: '1fr', sm: 432 },
        gridGap: 8
      }}
    >
      <Typography component="label" variant="subtitle4">
        {location_noun}
      </Typography>
      <Select
        aria-label="gender select"
        onChange={compose(
          handleSetUser,
          assoc('location_id'),
          tap(handleUpdateUser),
          path(['target', 'value'])
        )}
        value={defaultTo('', prop('value', foundValue))}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default LocationSelect
