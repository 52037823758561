import { FormattedMessage } from 'react-intl'
import { Box, Divider, Stack, Typography } from '@mui/material'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { Link } from 'react-router-dom'

const Confidentiality = () => (
  <Box px={1} py={1.5}>
    <Divider />
    <Stack alignItems="center" direction="row" mb={1} spacing={0.5}>
      <LockRoundedIcon />
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="We’re 100% confidential."
          id="eE3p1x"
        />
      </Typography>
    </Stack>
    <Typography display="inline" variant="body2">
      <FormattedMessage
        defaultMessage="This app is affiliated with WellPower and no personal data will ever be shared with third parties."
        id="BYxIpo"
      />
    </Typography>
    <Typography display="inline" variant="body2">
      <FormattedMessage
        defaultMessage=" By continuing, you agree to the "
        id="LtYoMu"
      />
    </Typography>
    <Typography
      component={Link}
      display="inline"
      sx={{
        color: 'primary.main',
        fontSize: 16.667,
        pl: 0.5,
        textDecoration: 'underline'
      }}
      to="/terms-and-conditions"
      variant="body3"
    >
      <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
    </Typography>
    <Typography display="inline" sx={{ px: 0.5 }} variant="body">
      <FormattedMessage defaultMessage="and" id="3mvL2Q" />
    </Typography>
    <Typography
      component={Link}
      display="inline"
      sx={{
        color: 'primary.main',
        fontSize: 16.667,
        textDecoration: 'underline'
      }}
      to="/privacy-policy"
      variant="body3"
    >
      <FormattedMessage defaultMessage="Privacy Policy." id="X+ERbj" />
    </Typography>
  </Box>
)

export default Confidentiality
