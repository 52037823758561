import { FormattedMessage } from 'react-intl'
import {
  Card,
  Divider,
  CardContent,
  Typography,
  Container
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

import BasicSignUpForm from './components/BasicSignUpFormConnector'

const SignUpDemo = () => {
  const { name } = useSelector(prop('school'))

  return (
    <Container maxWidth="sm" sx={{ my: 8 }}>
      <Card
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent'
        }}
      >
        <CardContent>
          <Typography component="h1" pb={1} variant="h4">
            <FormattedMessage
              defaultMessage="Create your private account"
              id="g+gKIh"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Get access to interactive tools & well-being resources available through {school}."
              id="o4jkyB"
              values={{
                school: name
              }}
            />
          </Typography>
          <Typography mt={1} variant="body2">
            <FormattedMessage
              defaultMessage="Already have an account? "
              id="gWgSaX"
            />
            <Typography
              color="primary"
              component={Link}
              sx={{
                fontSize: 14,
                textDecoration: 'underline'
              }}
              to="/sign-in"
            >
              <FormattedMessage defaultMessage="Log in." id="WZDtf5" />
            </Typography>
          </Typography>
          <Divider />
          <BasicSignUpForm />
        </CardContent>
      </Card>
    </Container>
  )
}

export default SignUpDemo
