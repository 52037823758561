import { svg_matter, svg_succeed, svg_thrive } from 'icons'
import {
  always,
  cond,
  equals,
  reverse,
  sortBy,
  prop,
  reduce,
  propEq,
  filter,
  isNil,
  append,
  without,
  map,
  not
} from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useUpdateInterestsMutation } from 'api/userApi'
import useAnalytics from 'hooks/useAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'

const SubdomainSelector = () => {
  const dispatch = useDispatch()
  const { sendEvent } = useAnalytics()
  const { interested_subdomains: interestedSubdomains = [], userDomains = [] } =
    useSelector(prop('user'))

  const [updateInterests, { isLoading }] = useUpdateInterestsMutation()

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )

  const getSubdomains = id => {
    if (isNil(subdomains)) return []
    return reverse(
      sortBy(prop('sort'))(filter(propEq(id, 'section_id'), subdomains))
    )
  }

  const handleUpdate = interests => {
    dispatch(updateUserData({ name: 'has_viewed_a_priority', value: true }))
    dispatch(
      updateUserData({ name: 'interested_subdomains', value: interests })
    )
  }

  const domainIcon = cond([
    [equals('succeed'), always(svg_succeed)],
    [equals('thrive'), always(svg_thrive)],
    [equals('matter'), always(svg_matter)]
  ])

  const updatePriorities = priority => {
    const found = interestedSubdomains.find(propEq(prop('id', priority), 'id'))
    if (isNil(found)) {
      sendEvent('block_interactions', {
        block_title: 'My Priorities',
        click_element: 'button',
        element_title: prop('onboarding_title', priority),
        path: window.location.pathname,
        selected: true
      })
      handleUpdate(append(priority, interestedSubdomains))
      updateInterests({
        subdomains: map(prop('id'), append(priority, interestedSubdomains))
      })
      return
    }
    sendEvent('block_interactions', {
      block_title: 'My Priorities',
      click_element: 'button',
      element_title: prop('onboarding_title', priority),
      path: window.location.pathname,
      selected: false
    })
    handleUpdate(without([priority], interestedSubdomains))
    updateInterests({
      subdomains: map(prop('id'), without([priority], interestedSubdomains))
    })
  }

  return (
    <Stack alignItems="center" mt={3}>
      <Box
        sx={{
          display: 'grid',
          gridGap: 30,
          // eslint-disable-next-line sort-keys
          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
          px: 1
        }}
      >
        {userDomains.map(
          ({ id, frontend_url_slug, frontend_name, subtitle }) => (
            <Box key={id}>
              <Stack direction="row" mb={1}>
                {domainIcon(frontend_url_slug)}
                <Stack>
                  <Typography color="primary" tabIndex={0} variant="subtitle4">
                    {frontend_name}
                  </Typography>
                  <Typography
                    color="primary"
                    textTransform="capitalize"
                    variant="body1"
                  >
                    {subtitle}
                  </Typography>
                </Stack>
              </Stack>

              <Stack alignItems="center" spacing={2}>
                {getSubdomains(id).map(subdomain => (
                  <Button
                    aria-disabled={isLoading ? 'true' : 'false'}
                    aria-label={
                      interestedSubdomains.some(
                        propEq(prop('id', subdomain), 'id')
                      )
                        ? `Selected: ${frontend_name} ${prop(
                            'title',
                            subdomain
                          )}`
                        : `Unselected: ${frontend_name} ${prop(
                            'title',
                            subdomain
                          )}`
                    }
                    disabled={isLoading}
                    fullWidth
                    key={prop('id', subdomain)}
                    onClick={() =>
                      not(isLoading) && updatePriorities(subdomain)
                    }
                    selected={interestedSubdomains.some(
                      propEq(prop('id', subdomain), 'id')
                    )}
                    sx={{
                      borderColor: interestedSubdomains.some(
                        propEq(prop('id', subdomain), 'id')
                      )
                        ? 'school.secondary'
                        : 'primary.light'
                    }}
                    variant="option"
                  >
                    {prop('title', subdomain)}
                  </Button>
                ))}
              </Stack>
            </Box>
          )
        )}
      </Box>
    </Stack>
  )
}

export default SubdomainSelector
