import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Stack,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import WaveWrapper from 'pages/public/components/WaveWrapper'
import useAnalytics from 'hooks/useAnalytics'

const FAQ = ({ faqs }) => {
  const { sendEvent } = useAnalytics()

  return (
    <WaveWrapper>
      <Container>
        <Stack spacing={5}>
          <Typography color="school.primary" textAlign="center" variant="h2">
            <FormattedMessage
              defaultMessage="Frequently Asked Questions"
              id="QA1wrZ"
            />
          </Typography>
          <Stack spacing={2}>
            {faqs.map(({ answer, question }) => (
              <Accordion
                key={question}
                onClick={() =>
                  sendEvent('block_interactions', {
                    block_title: 'FAQ',
                    click_element: 'Link',
                    element_title: question,
                    path: window.location.pathname
                  })
                }
                sx={{
                  ':before': { content: 'none' },
                  bgcolor: 'secondary.main',
                  borderRadius: 2,
                  boxShadow: 'none'
                }}
              >
                <AccordionSummary
                  aria-controls="content"
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ '& > .Mui-expanded': { my: 1.5 }, py: 2.5 }}
                >
                  <Typography>
                    <FormattedMessage
                      defaultMessage="{question}"
                      id="kKheEw"
                      values={{ question }}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0, px: 6 }}>
                  <Typography>
                    <FormattedMessage
                      defaultMessage="{answer}"
                      id="MFRRt+"
                      values={{ answer }}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      </Container>
    </WaveWrapper>
  )
}

FAQ.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({}))
}

FAQ.defaultProps = {
  faqs: []
}

export default FAQ
