import { equals, or, prop } from 'ramda'
import { useSelector } from 'react-redux'

const useCurrentAppType = () => {
  const { app_type } = useSelector(prop('school'))

  const isBusiness = equals('business', app_type)
  const isWellpower = or(equals('mhcd', app_type), equals('MHCD', app_type))
  const isCollective = equals('collective', app_type)
  const isNLC = equals('nlc', app_type)

  return {
    isBusiness,
    isCollective,
    isNLC,
    isWellpower
  }
}

export default useCurrentAppType
