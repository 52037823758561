import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ProgressBar from 'components/common/ProgressBar'
import { subdomainsFromUserDomains } from 'utils/subdomain-functions'
import { defaultTo, propEq, prop, length, sortBy, find } from 'ramda'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const GoalCard = ({ goal }) => {
  const { userDomains } = useSelector(prop('user'))

  const subdomains = defaultTo([], subdomainsFromUserDomains(userDomains))
  const subdomain = find(propEq('id', prop('subdomain_id', goal)))(subdomains)

  const steps = sortBy(prop('order'))(prop('steps', goal))
  const stepLength = length(steps)

  return (
    <Card variant="completed goal">
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gridGap: 16,
            // eslint-disable-next-line sort-keys
            gridTemplateColumns: { xs: '91px 1fr', md: '91px 1fr 0.75fr' },
            mb: 2,
            width: 1,
          }}
        >
          <Avatar
            alt="subdomain"
            src={prop('display_image', subdomain)}
            variant="subdomain"
          />
          <Box>
            <Typography
              color="primary.main"
              component="span"
              variant="subtitle1"
            >
              <FormattedMessage
                defaultMessage="Your goal in {goalTitle}"
                id="Ezin2J"
                values={{ goalTitle: prop('goal_category_name', subdomain) }}
              />
            </Typography>
            <Typography variant="cardTitle">{prop('title', goal)}</Typography>
          </Box>
        </Box>
        <ProgressBar
          completed={stepLength}
          label={`${stepLength} / ${stepLength} steps`}
          total={stepLength}
        />
      </CardContent>
    </Card>
  )
}

GoalCard.propTypes = {
  goal: PropTypes.shape({}).isRequired,
}

export default GoalCard
