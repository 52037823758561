import palette from '../palette'

const MuiChip = {
  styleOverrides: {
    icon: {
      height: 15.5,
      width: 15.5,
    },
    label: {
      fontFamily: 'mark-ot',
      fontSize: 14,
      letterSpacing: '0.25px',
      lineHeight: '19.6px',
    },
    outlined: {
      backgroundColor: 'white',
      borderColor: palette.primary.light,
    },
    root: {
      height: 24,
    },
  },
}

export default MuiChip
