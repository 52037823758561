import { about_cactus } from './copy'
import { prop, equals, isNil, length, gt, any, not, and } from 'ramda'
import { useGetAboutPageDataQuery } from 'api/schoolApi'
import { Box, Container, Skeleton, Stack } from '@mui/material'
import { useCurrentAppType } from 'hooks'
import { useSelector } from 'react-redux'

import Hero from './components/Hero'
import HeroContent from './components/HeroContentConnector'
import FAQ from './components/FAQ'
import AboutGrit from './components/AboutGrit'
import AboutItem from './components/AboutItem'
import Contact from './components/Contact'
import { useNavigate } from 'react-router-dom'

const About = () => {
  const { domain, enable_about_page, id } = useSelector(prop('school'))
  const { isBusiness } = useCurrentAppType()
  const navigate = useNavigate()

  if (and(not(isNil(id)), not(enable_about_page))) navigate('/')

  const { data, isFetching = true } = useGetAboutPageDataQuery(domain, {
    skip: isNil(domain)
  })

  if (
    any(equals(true))([isNil(data), isFetching, isNil(prop('sections', data))])
  )
    return (
      <Box
        data-testid="skeleton"
        sx={{
          display: 'grid',
          gridGap: 16,
          justifyItems: 'center',
          // eslint-disable-next-line sort-keys
          mb: { xs: 7, lg: 15 }
        }}
      >
        <Skeleton height={375} variant="rectangular" width="100%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="75%" />
        <Stack direction="row" spacing={2} sx={{ width: 1 }}>
          <Skeleton height={375} variant="rectangular" width="100%" />
          <Skeleton height={375} variant="rectangular" width="100%" />
        </Stack>
      </Box>
    )

  return (
    <Box
      component="article"
      id="main"
      // eslint-disable-next-line sort-keys
      sx={{ display: 'grid', gridGap: 16, mb: { xs: 7, lg: 15 } }}
    >
      <Hero image={prop('header_image', data)} />
      <HeroContent
        subtitle={prop('subtitle', data)}
        title={prop('title', data)}
      />
      {gt(length(prop('faqs', data)), 0) && <FAQ faqs={prop('faqs', data)} />}
      <Container>
        <Stack spacing={2} sx={{ mb: 4 }}>
          {prop('sections', data)
            .map((section, index) => <AboutItem key={index} {...section} />)
            .sort((a, b) => a.sort - b.sort)}
        </Stack>
        {isBusiness && <Contact />}
        {/* agency only */}
        {equals(id, 196) ? <AboutItem {...about_cactus} /> : <AboutGrit />}
      </Container>
    </Box>
  )
}

export default About
