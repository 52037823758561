import GoogleButton from './GoogleButton'
import { connect } from 'react-redux'
import { setUserData } from 'store/userSlice'
import {
  setAccessToken,
  setIsConsentOpen,
  setSSOError,
  setSSOErrorCode,
} from 'store/authenticationSlice'
import { prop } from 'ramda'

const mapStateToProps = ({ authentication }) => ({
  accessToken: prop('accessToken', authentication),
})

export default connect(mapStateToProps, {
  setAccessToken,
  setIsOpen: setIsConsentOpen,
  setSSOError,
  setSSOErrorCode,
  setUserData,
})(GoogleButton)
