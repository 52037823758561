import { svg_goals } from '../../../../../icons'
import PropTypes from 'prop-types'
import { compose, assoc, path, prop } from 'ramda'
import { Box, Card, CardContent, TextField } from '@mui/material'

const Name = ({ goal, setGoal }) => (
  <Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gridGap: 16
        }}
      >
        <Box
          sx={{
            '& > svg': { fill: 'red' },
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {svg_goals}
        </Box>
        <TextField
          fullWidth
          // update goal title on change
          // get value from e.target.value
          // associate value to goal title in goal object
          // set goal
          onChange={compose(setGoal, assoc('title'), path(['target', 'value']))}
          placeholder="Name your Goal"
          value={prop('title', goal)}
        />
      </Box>
    </CardContent>
  </Card>
)

Name.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default Name
