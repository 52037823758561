import { useMemo, useState } from 'react'
import { gt, inc, isEmpty, isNil, length, not, or, prop, propEq } from 'ramda'
import { useGetFeaturedContentQuery } from 'api/schoolApi'
import { useGetCardsQuery } from 'api/cardsApi'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import MasonryWrapper from 'components/common/MasonryWrapper'
import PreparedCard from 'components/common/PreparedCardConnector'
import LoadMoreButton from 'components/common/LoadMoreButton'

const Group = () => {
  const [page, setPage] = useState(1)
  const { groupId } = useParams()

  const { data: featuredContent } = useGetFeaturedContentQuery()

  // need to parse int since groupId is a string
  const group = useMemo(() => {
    if (isNil(featuredContent)) return []
    return prop('groups', featuredContent).find(propEq('id', parseInt(groupId)))
  }, [featuredContent])

  const cardParams = {
    content_groups: groupId,
    take: page * 15
  }

  const { data = [], isValidating } = useGetCardsQuery(cardParams, {
    skip: isNil(featuredContent)
  })

  if (or(isNil(featuredContent), isEmpty(data)))
    return (
      <Stack
        data-testid="skeleton"
        // eslint-disable-next-line sort-keys
        direction={{ xs: 'column', lg: 'row' }}
        // eslint-disable-next-line sort-keys
        spacing={{ xs: 1, lg: 3 }}
      >
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
        <Skeleton height={200} variant="rectangular" width="100%" />
      </Stack>
    )

  return (
    <>
      {prop('hero_image_url', group) && (
        <Box
          sx={{
            background: `url(${prop('hero_image_url', group)})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 294
          }}
          width="100%"
        />
      )}
      <Stack alignItems="center" pb={4} px={2} spacing={3}>
        <Typography variant="h1">{prop('display_text', group)}</Typography>
        <MasonryWrapper>
          {data?.map((card, index) => (
            <CardAnimationWrapper key={index}>
              <PreparedCard card={card} />
            </CardAnimationWrapper>
          ))}
        </MasonryWrapper>
        {not(gt(length(data) % 15, 0)) && (
          <LoadMoreButton
            handleLoadMore={() => setPage(inc(page))}
            loading={isValidating}
          />
        )}
      </Stack>
    </>
  )
}

export default Group
