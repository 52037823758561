import PropTypes from 'prop-types'
import { compose, path, prop } from 'ramda'
import { Box, TextField, Typography } from '@mui/material'

const TextQuestionInput = ({ question, userAnswers, onUpdateAnswer }) => {
  const handleChange = val =>
    onUpdateAnswer({
      answers: [val],
      profile_question_id: prop('id', question),
    })

  return (
    <Box maxWidth={432}>
      <Typography mb={1} variant="subtitle4">
        {prop('question', question)}
      </Typography>
      <TextField
        defaultValue={prop(0, userAnswers)}
        fullWidth
        inputProps={{
          maxLength: 50,
        }}
        name={prop('name', question)}
        onChange={compose(handleChange, path(['target', 'value']))}
      />
    </Box>
  )
}

TextQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({}).isRequired,
  userAnswers: PropTypes.instanceOf(Array).isRequired,
}

export default TextQuestionInput
