import GA from 'services/gaService'
import TextArticle from './TextArticle'
import VideoArticle from './VideoArticle'
import PropTypes from 'prop-types'
import { defaultTo, map, prop, replace } from 'ramda'
import { Button, Container, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'
import useAnalytics from 'hooks/useAnalytics'

const ResourceArticle = ({ article }) => {
  const { sendEvent } = useAnalytics()

  return (
    <Container maxWidth="sm">
      <Stack spacing={3}>
        {prop('video_url', article) ? (
          <VideoArticle {...article} />
        ) : (
          <TextArticle article={article} />
        )}
        {prop('cta_url', article) && (
          <Button
            LinkComponent={Link}
            onClick={() => {
              sendEvent('card_detail_view_cta', {
                campus_resource: prop('campus_resource', article),
                card_domain: prop('domain_name', article),
                card_groups: defaultTo(
                  [],
                  map(prop('name'), prop('groups', article))
                ),
                card_id: prop('id', article),
                card_name: replace('"', '', prop('title', article)),
                card_type: convertCardTypeToString(prop('type', article)),
                cta_analytics_tag: prop('cta_ga_tag', article),
                cta_button_url: prop('cta_url', article)
              })
              GA.track('CTA-Vendor', prop('cta_ga_tag', article))
            }}
            rel="noreferrer"
            sx={{
              alignSelf: 'center'
            }}
            target="_blank"
            to={prop('cta_url', article)}
          >
            {prop('cta', article)}
          </Button>
        )}
      </Stack>
    </Container>
  )
}

ResourceArticle.propTypes = {
  article: PropTypes.shape({}).isRequired
}

export default ResourceArticle
