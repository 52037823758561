import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import CalloutBox from 'components/common/CalloutBox'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useCurrentAppType } from 'hooks'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import { useDispatch } from 'react-redux'

const SubdomainCalloutBox = ({
  crisisSubdomain = false,
  topSectionCopy = null
}) => {
  const intl = useIntl()
  const { isWellpower } = useCurrentAppType()
  const dispatch = useDispatch()

  const wellpowerCopy = intl.formatMessage({
    defaultMessage:
      'If you or someone you know is thinking about hurting themselves or someone else, reach out to crisis supports immediately.',
    id: 'ARqjS8'
  })
  const defaultCopy = intl.formatMessage({
    defaultMessage: 'If you are seeking immediate support',
    id: 'hY33rf'
  })

  const getHelperCopy = () => {
    if (isWellpower) return wellpowerCopy
    return defaultCopy
  }

  const toggleCrisis = e => {
    e.preventDefault()
    dispatch(setIsCrisisModalOpen(true))
  }

  const getBottomCopy = () => {
    if (isWellpower)
      return (
        <FormattedMessage
          defaultMessage="And remember, you don't have to be in immediate crisis to get support - if you've not been feeling like yourself, been more down than usual, or something just feels off for longer than it should -"
          id="tQDjfu"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="When life gets hard, your campus community is here to help."
        id="EFNW3S"
      />
    )
  }

  const bottomCopy = (
    <>
      <Typography component="span" fontFamily="mark-ot-bold" variant="body1">
        {getBottomCopy()}
      </Typography>
      <Typography component="span" id="help" sx={{ ml: 0.5 }} variant="body1">
        <FormattedMessage
          defaultMessage="{copy}, {resourceLink}."
          id="ywZQ/U"
          values={{
            copy: getHelperCopy(),
            resourceLink: (
              <Typography
                component={Link}
                onClick={e => toggleCrisis(e)}
                sx={{
                  textDecoration: 'underline'
                }}
                to=""
              >
                <FormattedMessage
                  defaultMessage="connect with crisis resources here"
                  id="gBYlUI"
                />
              </Typography>
            )
          }}
        />
      </Typography>
    </>
  )

  return (
    <CalloutBox
      bottomSectionCopy={crisisSubdomain ? bottomCopy : null}
      topSectionCopy={topSectionCopy}
    />
  )
}

SubdomainCalloutBox.propTypes = {
  crisisSubdomain: PropTypes.bool,
  topSectionCopy: PropTypes.string
}

export default SubdomainCalloutBox
