import palette from '../palette'

const MuiDivider = {
  styleOverrides: {
    fullWidth: {
      height: '1px',
    },
    root: {
      borderColor: palette.grey.light,
    },
  },
  variants: [
    {
      props: { orientation: 'horizontal' },
      style: {
        margin: '12px 0',
        width: '100%',
      },
    },
    {
      props: { variant: 'card accent' },
      style: {
        height: 3,
        margin: 0,
        width: '100%',
      },
    },
  ],
}

export default MuiDivider
