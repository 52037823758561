const authenticationRoutes = ['sign-up', 'sign-in', 'password-reset', 'logout']

const publicRoutes = [
  'accessibility',
  'privacy',
  'terms',
  'feedback',
  'public',
  'verify',
  'referral',
]

export const onPublicRoute = pathname =>
  publicRoutes.some(route => pathname.includes(route))
export const onAuthRoute = pathname =>
  authenticationRoutes.some(route => pathname.includes(route))
