import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Stack } from '@mui/material'

import CreateAccount from './CreateAccountConnector'
import ActiveConsentForm from './ActiveConsentFormConnector'

const SocialSSOSignUpForm = () => {
  const [isConsented, setIsConsented] = useState(false)

  return (
    <Stack spacing={2}>
      {isConsented ? (
        <CreateAccount />
      ) : (
        <>
          <ActiveConsentForm />
          <Button fullWidth onClick={() => setIsConsented(true)}>
            <FormattedMessage defaultMessage="Continue" id="acrOoz" />
          </Button>
        </>
      )}
    </Stack>
  )
}

export default SocialSSOSignUpForm
