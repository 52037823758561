import { isNil, propEq } from 'ramda'

export const succeedThriveMatterFrontendUrlSlugs = data => {
  if (isNil(data)) return
  const succeed = data.find(propEq('succeed', 'frontend_url_slug'))
  const thrive = data.find(propEq('thrive', 'frontend_url_slug'))
  const matter = data.find(propEq('matter', 'frontend_url_slug'))

  return {
    matter,
    succeed,
    thrive,
  }
}
