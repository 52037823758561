import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const HOST = process.env.REACT_APP_PUBLIC_YOU_API_HOST

export const publicBaseQuery = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST}`,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json')
      return headers
    }
  }),
  endpoints: () => ({}),
  reducerPath: 'publicApi',
  tagTypes: [
    'card',
    'cards',
    'featured',
    'public subdomain',
    'public user domains'
  ]
})
