import { baseQuery } from './baseQuery'

export const profileApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    getOriginalProfileQuestions: query({
      providesTags: ['profile questions'],
      query: () => '/profile-questions',
    }),
    getProfileQuestions: query({
      providesTags: ['profile questions'],
      query: () => '/v2/profile',
    }),
    removeTempLocation: mutation({
      invalidatesTags: ['user', 'geolocation resources'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/temp-location-remove',
      }),
    }),
    updateHomeLocation: mutation({
      invalidatesTags: ['user', 'geolocation resources'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/home-location',
      }),
    }),
    updateInterestedSubdomains: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/interests',
      }),
    }),
    updateProfile: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile',
      }),
    }),
    updateProfilePhoto: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/photo',
      }),
    }),
    updateProfileTerms: mutation({
      invalidatesTags: ['user'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/tou-pp-version',
      }),
    }),
    updateTempLocation: mutation({
      invalidatesTags: ['user', 'geolocation resources'],
      query: body => ({
        body,
        method: 'POST',
        url: '/profile/temp-location',
      }),
    }),
  }),
  reducerPath: 'profileApi',
})

export const {
  useGetOriginalProfileQuestionsQuery,
  useGetProfileQuestionsQuery,
  useRemoveTempLocationMutation,
  useUpdateHomeLocationMutation,
  useUpdateInterestedSubdomainsMutation,
  useUpdateProfileMutation,
  useUpdateProfilePhotoMutation,
  useUpdateProfileTermsMutation,
  useUpdateTempLocationMutation,
} = profileApi
