import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ScreenReaderMessage from './ScreenReaderMessage'
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'

const MiniSkillsCard = ({
  resourceUrl,
  handleAnalytics = () => {},
  imageUrl,
  headline
}) => (
  <Card sx={{ maxWidth: 375 }}>
    <CardActionArea
      LinkComponent={Link}
      onClick={handleAnalytics}
      to={resourceUrl}
    >
      <Stack direction="row" spacing={0.5}>
        <Box sx={{ flex: '0 0 125px' }}>
          <CardMedia component="img" height={150} image={imageUrl} />
          <ScreenReaderMessage>
            <FormattedMessage
              defaultMessage="Clickable image linking to {headline}"
              id="y2vr0v"
              values={{ headline }}
            />
          </ScreenReaderMessage>
        </Box>
        <Stack
          sx={{
            py: 1
          }}
        >
          <Typography color="secondary.dark" variant="subtitleBold">
            <FormattedMessage defaultMessage="Learn how to..." id="daFuqa" />
          </Typography>
          <Typography color="primary.main" component="span" variant="cardTitle">
            {headline}
          </Typography>
        </Stack>
      </Stack>
    </CardActionArea>
  </Card>
)

MiniSkillsCard.propTypes = {
  handleAnalytics: PropTypes.func,
  headline: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  resourceUrl: PropTypes.string.isRequired
}

export default MiniSkillsCard
