import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prop } from 'ramda'

const getSessionId = () => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem('@you:sessionId'))
  }
}

const HOST = process.env.REACT_APP_PUBLIC_YOU_API_HOST
const key = process.env.REACT_APP_PUBLIC_KEEN_POST_KEY

export const analyticsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST}`,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json')
      headers.set('X-Session-Id', getSessionId())
      headers.set('keen-post-key', key)
      return headers
    }
  }),
  endpoints: ({ mutation }) => ({
    sendKeenEvent: mutation({
      query: data => {
        const body = new FormData()
        body.append('event', prop('name', data))
        body.append('data', JSON.stringify(data))
        return {
          body,
          method: 'POST',
          url: `/v2/analytics?org_domain=${prop('org_domain', data)}`
        }
      }
    })
  }),
  reducerPath: 'analytics'
})

export const { useSendKeenEventMutation } = analyticsApi
