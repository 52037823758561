import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetPublicCardsQuery,
  useGetPublicFeaturedContentQuery
} from 'api/publicApi'
import { gt, inc, isNil, length, not, prop, propEq } from 'ramda'
import { useCurrentDomain } from 'hooks'
import { Box, Stack, Typography } from '@mui/material'

import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import LoadMoreButton from 'components/common/LoadMoreButton'
import MasonryWrapper from 'components/common/MasonryWrapper'
import PreparedCard from 'components/common/PreparedCardConnector'

const GroupPage = () => {
  const { groupId } = useParams()
  const { currentDomain } = useCurrentDomain()
  const [page, setPage] = useState(1)

  const { data: content } = useGetPublicFeaturedContentQuery(currentDomain)

  // need to parse int since groupId is a string
  const group = prop('groups', content).find(propEq('id', parseInt(groupId)))

  const { data, isFetching } = useGetPublicCardsQuery(
    `org_domain=${currentDomain}&filter[content_groups]=${groupId}&page[take]=${
      page * 15
    }`,
    { skip: isNil(content) }
  )

  return (
    <>
      {prop('hero_image_url', group) && (
        <Box
          sx={{
            background: `url(${prop('hero_image_url', group)})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 294
          }}
          width="100%"
        />
      )}
      <Stack alignItems="center" pb={4} px={2} spacing={3}>
        <Typography variant="h1">{prop('display_text', group)}</Typography>
        <MasonryWrapper>
          {data?.map((card, index) => (
            <CardAnimationWrapper key={index}>
              <PreparedCard card={card} isPublic />
            </CardAnimationWrapper>
          ))}
        </MasonryWrapper>
        {not(gt(length(data) % 15, 0)) && (
          <LoadMoreButton
            handleClick={() => setPage(inc(page))}
            loading={isFetching}
          />
        )}
      </Stack>
    </>
  )
}

export default GroupPage
