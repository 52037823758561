import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

const InfoMessage = ({ infoMessage }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{
      alignItems: 'center',
      color: 'info.main',
      mt: 1,
      px: 1.5,
    }}
  >
    <InfoRoundedIcon />
    <Typography variant="subtitle1">{infoMessage}</Typography>
  </Stack>
)

InfoMessage.propTypes = {
  infoMessage: PropTypes.node,
}

InfoMessage.defaultProps = {
  infoMessage: null,
}

export default InfoMessage
