import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import { Box, Button, CircularProgress } from '@mui/material'
import { always, cond, defaultTo, equals, path, prop, T } from 'ramda'
import { useSignInMutation } from 'api/authApi'
import PropTypes from 'prop-types'
import { useAnalytics, useLocalStorage, useSession } from 'hooks'
import { useSelector } from 'react-redux'

import EmailAndPassword from 'components/authentication/EmailAndPasswordConnector'
import ErrorMessage from 'components/common/ErrorMessage'
import SSOButtons from 'components/authentication/SSOButtons'
import { useNavigate } from 'react-router-dom'

const SocialSignIn = ({
  email,
  password,
  resetState,
  setUserData,
  ssoError,
  ssoErrorCode
}) => {
  const { domain } = useSelector(prop('school'))
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { save } = useLocalStorage()

  const [signIn, { isError, isLoading, isSuccess, data, error }] =
    useSignInMutation()

  const onSubmit = () =>
    signIn({
      auth_type: 'traditional',
      email,
      org_domain: domain,
      password
    })

  const handleAnalytics = token => {
    const promises = [
      sessionEvent('login', { session_id: token }),
      sendEvent('login', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      }),
      sendEvent('sign-in', {
        auth_type: 'traditional',
        validation_errors: [],
        validation_success: true
      })
    ]
    return Promise.allSettled(promises)
  }

  useEffect(() => {
    if (isSuccess) {
      const sessionId = path(['headers', 'headers'], data).get('X-Session-Id')
      save(sessionId)
      // Tracking
      GA.click('Login', 'Click')
      handleAnalytics(sessionId)
        .then(() => {
          resetState()
          setUserData(path(['res', 'user'], data))
        })
        .finally(() => navigate('/home'))
    }
  }, [isSuccess])

  const renderError = cond([
    [
      equals(422),
      always(
        <FormattedMessage
          defaultMessage="Sorry, but that email domain isn't allowed."
          id="Jw4mPU"
        />
      )
    ],
    [
      equals(429),
      always(
        <FormattedMessage
          defaultMessage="You made 5 failed attempts, so your account is now locked for 5 minutes. If you need to, reset your password."
          id="0eQpnn"
        />
      )
    ],
    [
      equals(500),
      always(
        <FormattedMessage
          defaultMessage="There was an error accessing your account. Please try again."
          id="PCMbDq"
        />
      )
    ],
    [
      T,
      always(
        <FormattedMessage
          defaultMessage="This is not an authorized account."
          id="+yV2p0"
        />
      )
    ]
  ])

  return (
    <Box>
      <ErrorMessage
        hasError={isError}
        message={defaultTo(
          renderError(prop('status', error)),
          defaultTo(
            path(['data', 'errors', 'email'], error),
            path(['data', 'errors', 'password'], error)
          )
        )}
      />
      <ErrorMessage
        hasError={ssoError}
        message={
          <FormattedMessage
            defaultMessage="There was an error with your Social Login please try again later, code: {code}"
            id="Czd5vx"
            values={{ code: ssoErrorCode }}
          />
        }
      />
      <Box
        component="form"
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <EmailAndPassword socialSSO />
        <Button fullWidth type="submit">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
          )}
        </Button>
      </Box>
      <SSOButtons />
    </Box>
  )
}

SocialSignIn.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  resetState: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  ssoError: PropTypes.bool.isRequired,
  ssoErrorCode: PropTypes.number
}

SocialSignIn.defaultProps = {
  email: null,
  password: null,
  ssoErrorCode: null
}

export default SocialSignIn
