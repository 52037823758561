import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { useAnalytics } from 'hooks'
import { FormattedMessage } from 'react-intl'

const PrioritiesImageCard = ({
  priority,
  imgURL,
  priorityURL,
  socialCount
}) => {
  const [isActive, setIsActive] = useState(false)
  const { sendEvent } = useAnalytics()

  return (
    <Stack
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      sx={{
        position: 'relative',
        zIndex: 0
      }}
    >
      <CardActionArea
        component={Link}
        onClick={() =>
          sendEvent('block_interactions', {
            block_title: 'Priority Carousel Card',
            click_element: 'Link',
            element_title: priority,
            path: window.location.pathname
          })
        }
        sx={{
          height: '100%',
          position: 'relative',
          transform: isActive ? 'scale(1.1)' : 'initial',
          transition: 'all .3s ease-in-out',
          zIndex: isActive ? 1 : 100
        }}
        to={priorityURL}
      >
        <CardMedia alt={priority} component="img" height={225} src={imgURL} />
        <Box
          sx={{
            bottom: 10,
            left: 'calc(50% - 66px)',
            opacity: isActive ? 1 : 0,
            position: 'absolute',
            transition: 'all .3s ease-in-out'
          }}
        >
          <Chip
            aria-hidden
            color="secondary"
            icon={<PeopleRoundedIcon />}
            label={
              <FormattedMessage
                defaultMessage="{socialCount} People"
                id="nKELC4"
                values={{ socialCount }}
              />
            }
          />
        </Box>
      </CardActionArea>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography aria-hidden variant="body.2">
          {priority}
        </Typography>
      </CardContent>
    </Stack>
  )
}

PrioritiesImageCard.propTypes = {
  imgURL: PropTypes.string.isRequired,
  priority: PropTypes.string.isRequired,
  priorityURL: PropTypes.string.isRequired,
  socialCount: PropTypes.number.isRequired
}

export default PrioritiesImageCard
