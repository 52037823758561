import { svg_matter, svg_succeed, svg_thrive } from 'icons'
import PropTypes from 'prop-types'
import {
  cond,
  equals,
  always,
  reverse,
  sortBy,
  prop,
  not,
  isNil,
  assoc,
  isEmpty,
} from 'ramda'
import { Box, Button, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const Focus = ({ goal, setGoal }) => {
  const { userDomains } = useSelector(prop('user'))

  const domainIcon = cond([
    [equals('succeed'), always(svg_succeed)],
    [equals('thrive'), always(svg_thrive)],
    [equals('matter'), always(svg_matter)],
  ])

  if (isEmpty(userDomains)) return null

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 30,
        // eslint-disable-next-line sort-keys
        gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
      }}
    >
      {reverse(sortBy(prop('sort'))(userDomains)).map(
        ({ id, frontend_url_slug, frontend_name, subdomains, subtitle }) => (
          <Box key={id}>
            <Box display="flex" mb={1}>
              {domainIcon(frontend_url_slug)}
              <Box display="flex" flexDirection="column">
                <Typography color="primary" tabIndex={0} variant="subtitle4">
                  {frontend_name}
                </Typography>
                <Typography
                  color="primary"
                  textTransform="capitalize"
                  variant="body1"
                >
                  {subtitle}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                '& > button': {
                  width: 1,
                },
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gridGap: 16,
              }}
            >
              {reverse(
                sortBy(prop('sort'))(
                  subdomains.filter(({ onboarding_title }) =>
                    not(isNil(onboarding_title))
                  )
                )
              )
                .map(({ id, onboarding_title }) => (
                  <Button
                    aria-label={
                      equals(prop('subdomain_id', goal), id)
                        ? `Selected: ${frontend_name} ${onboarding_title}`
                        : `Unselected: ${frontend_name} ${onboarding_title}`
                    }
                    key={id}
                    onClick={() => setGoal(assoc('subdomain_id', id, goal))}
                    selected={equals(prop('subdomain_id', goal), id)}
                    sx={{
                      borderColor: equals(prop('subdomain_id', goal), id)
                        ? 'school.secondary'
                        : 'primary.light',
                    }}
                    variant="option"
                  >
                    {onboarding_title}
                  </Button>
                ))
                .sort((a, b) => a.sort - b.sort)}
            </Box>
          </Box>
        )
      )}
    </Box>
  )
}

Focus.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired,
}

export default Focus
