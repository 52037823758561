/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'

const ExploreBlockWrapper = ({ title, icon, children }) => (
  <Box
    sx={{
      m: { xs: '0 16px 16px', lg: '0 auto 24px' },
      px: { xs: 2, md: 4 },
      width: 1,
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gridGap: 4,
      }}
    >
      {icon}
      <Typography
        display="flex"
        fontFamily="mark-ot"
        justifyContent="space-between"
        variant="subtitle1"
      >
        {title}
      </Typography>
    </Box>
    <Divider sx={{ m: 0 }} />
    <Box mb={2} />
    {children}
  </Box>
)

ExploreBlockWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}).isRequired,
  title: PropTypes.node.isRequired,
}

export default ExploreBlockWrapper
