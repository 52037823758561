'use client'
import { always, evolve } from 'ramda'
import { useEffect, useState } from 'react'

const useWindowResize = () => {
  const [size, setSize] = useState({ x: 0, y: 0 })

  useEffect(() => {
    function updateSize() {
      setSize(
        evolve({
          x: always(window.innerWidth),
          y: always(window.innerHeight),
        })
      )
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

export default useWindowResize
