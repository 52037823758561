import EmailAndPassword from './EmailAndPassword'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import {
  setEmail,
  setPassword,
  setRevalidation,
} from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  email: prop('email', authentication),
  password: prop('password', authentication),
  revalidation: prop('revalidation', authentication),
})

const mapDispatchToProps = {
  setEmail,
  setPassword,
  setRevalidation,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailAndPassword)
