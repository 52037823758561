import { useEffect, useState } from 'react'
import Question from './Question'
import DomainSummary from './DomainSummary'
import SelfCheckFooter from './SelfCheckFooter'
import {
  filter,
  prop,
  propEq,
  gt,
  path,
  assocPath,
  inc,
  assoc,
  or,
  isNil,
  not,
  length,
  equals,
  flip
} from 'ramda'
import { Box, Skeleton, Stack, Tab } from '@mui/material'
import { parseSelfCheckApiRequestToObject } from 'utils/self-check-functions'
import useGetSelfCheckData from './useGetSelfCheckData'
import {
  useGetRealityScoresQuery,
  useGetSelfCheckQuizDataQuery
} from 'api/statsApi'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const panelStyle = {
  bgcolor: 'white',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  boxShadow: '0px 6px 4px 0px rgb(0 0 0 / 20%)',
  position: 'relative',
  py: 2,
  width: 1
}

const tabStyle = active => ({
  ':hover': {
    backgroundColor: active ? 'white' : 'primary.light'
  },
  backgroundColor: active ? 'white' : 'primary.light',
  borderRadius: '15px 15px 0 0',
  boxShadow: '1px 2px 4px 0 rgba(79,79,79,0.25)',
  color: 'secondary.dark',
  fontFamily: 'mark-ot',
  fontSize: 16,
  letterSpacing: '0.25px',
  lineHeight: '140%',
  maxWidth: '32%',
  textTransform: 'capitalize',
  width: 1
})

const getActiveTab = ({ succeed, thrive, matter }) => {
  const tabList = [succeed, thrive, matter]
  const inProgress = filter(propEq('inProgress', true), tabList)
  // in progress has 1 or more
  // return the first in progress tab
  if (gt(length(inProgress), 0)) {
    return prop('domain', inProgress[0])
  }
  // else all not in progress return succeed
  return prop('domain', succeed)
}

const SelfChecksTabList = () => {
  const { pathname } = useLocation()
  const blockTitle = 'Self Checks Block'
  const { sendEvent } = useAnalytics()
  const { interested_subdomains = [] } = useSelector(prop('user'))

  const [currentTab, setCurrentTab] = useState('succeed')

  const [selfCheckData, setSelfCheckData] = useState({})

  const { data, fulfilledTimeStamp } = useGetSelfCheckQuizDataQuery()

  useEffect(() => {
    if (not(isNil(data))) {
      const parsedObj = parseSelfCheckApiRequestToObject(data)
      setSelfCheckData({
        matter: useGetSelfCheckData(
          prop('matter', parsedObj),
          'matter',
          'connections & purpose',
          interested_subdomains
        ),
        succeed: useGetSelfCheckData(
          prop('succeed', parsedObj),
          'succeed',
          'career success & education',
          interested_subdomains
        ),
        thrive: useGetSelfCheckData(
          prop('thrive', parsedObj),
          'thrive',
          'mental & physical health',
          interested_subdomains
        )
      })
    }
  }, [data])

  useEffect(() => {
    // On initial fetch we want to show the next self check
    // that has not been completed so we run a quick check
    // then set the current tab to that uncompleted self check
    if (not(isNil(getActiveTab(selfCheckData)))) {
      setCurrentTab(getActiveTab(selfCheckData))
    }
  }, [fulfilledTimeStamp])

  const handleTabChange = tab => {
    sendEvent('block_interactions', {
      block_title: blockTitle,
      click_element: 'Button',
      element_title: tab.charAt(0).toUpperCase() + tab.slice(1),
      path: window.location.pathname
    })
    setCurrentTab(tab)
  }

  const { data: stats } = useGetRealityScoresQuery(
    path([currentTab, 'question', 'id'], selfCheckData),
    {
      refetchOnMountOrArgChange: true,
      skip: isNil(path([currentTab, 'question', 'id'], selfCheckData))
    }
  )

  const submitQuestion = value => {
    const currentData = prop(currentTab, selfCheckData)
    // fetch stats for question now that we have answered it
    // need to add 1 here because the value is 0 indexed, but the stats are 1 indexed
    // update the context so ui updates
    const data = assocPath(
      ['userAnswer', 'score', 'score'],
      inc(value),
      currentData
    )
    // need to update the question object with the stats so the Question component knows what to
    // display
    const question = assoc('stats', stats, prop('question', currentData))
    // combine our new data with the old and set it to currentData
    const newCurrentData = assoc('question', question, data)
    // need to update the context data as well so that the state maintains between tabs
    setSelfCheckData(assoc(currentTab, newCurrentData, selfCheckData))
    // currentTab holds the domain we are on
    sendEvent('block_interactions', {
      block_title: blockTitle,
      click_element: 'Button',
      element_answer: prop(value, path(['question', 'answers'], currentData)),
      element_title: currentTab,
      path: pathname
    })
  }

  const tabs = [
    {
      keenTitle: 'Succeed',
      label: <FormattedMessage defaultMessage="Succeed" id="EzJaJ+" />,
      name: 'succeed'
    },
    {
      keenTitle: 'Thrive',
      label: <FormattedMessage defaultMessage="Thrive" id="VylkOa" />,
      name: 'thrive'
    },
    {
      keenTitle: 'Matter',
      label: <FormattedMessage defaultMessage="Matter" id="vMncJL" />,
      name: 'matter'
    }
  ]

  if (or(isNil(prop('succeed', selfCheckData)), prop('loading', selfCheckData)))
    return (
      <Stack spacing={1} sx={{ height: 385 }}>
        <Skeleton variant="text" width={64} />
        <Skeleton height={35} variant="rectangular" />
        <Skeleton variant="text" width={183} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
        <Skeleton height={48} variant="rectangular" width={250} />
      </Stack>
    )

  return (
    <Box>
      <TabContext value={currentTab}>
        <Box>
          <TabList
            onChange={flip(handleTabChange)}
            selectionFollowsFocus
            sx={{
              '& > div > div': {
                justifyContent: 'space-between'
              },
              '& > div > span': {
                display: 'none'
              }
            }}
          >
            {tabs.map(({ label, name }) => (
              <Tab
                key={name}
                label={
                  <Box
                    borderBottom={equals(currentTab, name) && '2px solid'}
                    borderBottomColor={equals(currentTab, name) && 'secondary'}
                    color="primary.main"
                    component="span"
                  >
                    {label}
                  </Box>
                }
                sx={tabStyle(equals(currentTab, name))}
                value={name}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ name }) => (
          <TabPanel key={name} sx={panelStyle} value={name}>
            {path([name, 'domainSummary'], selfCheckData) ? (
              <DomainSummary
                domain={currentTab}
                key={currentTab}
                name={name}
                title={path([name, 'domainSummary', 'title'], selfCheckData)}
              />
            ) : (
              <Question
                key={currentTab + ' question'}
                question={path([name, 'question'], selfCheckData)}
                updateQuestion={submitQuestion}
                userAnswer={path([name, 'userAnswer'], selfCheckData)}
              />
            )}
            <SelfCheckFooter
              blockTitle={blockTitle}
              completed={not(
                isNil(path([name, 'domainSummary'], selfCheckData))
              )}
              domain={currentTab}
              questionAnswered={not(
                isNil(path([name, 'userAnswer', 'score'], selfCheckData))
              )}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default SelfChecksTabList
