import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { Avatar, Box, Grow, Stack, Typography } from '@mui/material'

const TopicImage = ({ title = null, internalURL = null, image = null }) => {
  const { sendEvent } = useAnalytics()

  const sendKeenIOEvent = () =>
    sendEvent('block_interactions', {
      block_title: 'Featured Cards Block',
      click_element: 'Link',
      element_title: title,
      path: window.location.pathname
    })

  return (
    <Grow in>
      <Box
        component={Link}
        onClick={sendKeenIOEvent}
        sx={{ width: 160 }}
        to={internalURL}
      >
        <Stack
          spacing={2}
          sx={{
            ':hover > .MuiBox-root': {
              display: 'block'
            },
            ':hover > span': {
              fontFamily: 'mark-ot-bold'
            },
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Avatar src={image} sx={{ position: 'relative' }} variant="profile" />
          <Typography component="span" textAlign="center" variant="body2">
            {title}
          </Typography>
          <Box
            sx={{
              bgcolor: 'school.secondary',
              bottom: -8,
              display: 'none',
              height: 4,
              position: 'absolute',
              width: 99
            }}
          />
        </Stack>
      </Box>
    </Grow>
  )
}

export default TopicImage

TopicImage.propTypes = {
  image: PropTypes.string,
  internalURL: PropTypes.string,
  title: PropTypes.string
}
