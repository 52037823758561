import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { not, prop } from 'ramda'
import { useSelector } from 'react-redux'

const Label = () => {
  const { register_disclaimer } = useSelector(prop('school'))

  return (
    <Typography fontSize="14px" htmlFor="active-consent-input" variant="body2">
      <FormattedMessage
        defaultMessage="I accept the {terms_of_use} and {privacy_policy}, I understand that the information I share will be {data_collection}"
        id="Bh7zYZ"
        values={{
          data_collection: (
            <Typography
              color="primary"
              component={Link}
              fontSize="14px"
              sx={{
                textDecoration: 'underline',
                textTransform: 'none'
              }}
              to="/privacy-policy#2-how-we-use-collected-information"
              variant="body2"
            >
              <FormattedMessage
                defaultMessage="used to create and service my account."
                id="bLvgyA"
              />
            </Typography>
          ),
          privacy_policy: (
            <Typography
              color="primary"
              component={Link}
              fontSize="14px"
              sx={{
                textDecoration: 'underline'
              }}
              to="/privacy-policy"
              variant="body2"
            >
              <FormattedMessage defaultMessage="privacy policy" id="LIDXd2" />
            </Typography>
          ),
          terms_of_use: (
            <Typography
              color="primary"
              component={Link}
              fontSize="14px"
              sx={{
                textDecoration: 'underline'
              }}
              to="/terms-and-conditions"
              variant="body2"
            >
              <FormattedMessage defaultMessage="terms of use" id="bhx5xa" />
            </Typography>
          )
        }}
      />
      {register_disclaimer && (
        <Box fontFamily="mark-ot" fontSize="12px" gridColumnStart={3} my={2}>
          {parse(register_disclaimer)}
        </Box>
      )}
    </Typography>
  )
}

const ActiveConsentForm = ({ consent, setConsent }) => (
  <FormControl required>
    <FormControlLabel
      control={
        <Checkbox
          checked={consent}
          color="info"
          onChange={() => setConsent(not(consent))}
        />
      }
      label={<Label />}
    />
  </FormControl>
)

ActiveConsentForm.propTypes = {
  consent: PropTypes.bool.isRequired,
  setConsent: PropTypes.func.isRequired
}
export default ActiveConsentForm
