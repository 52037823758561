const useLocalStorage = () => {
  const clear = () => localStorage.removeItem('@you:sessionId')

  const get = () => {
    const key = localStorage.getItem('@you:sessionId')
    return JSON.parse(key)
  }

  const save = v => localStorage.setItem('@you:sessionId', JSON.stringify(v))

  return {
    clear,
    get,
    save
  }
}

export default useLocalStorage
