import BasicSignIn from './components/BasicSignInConnector'
import { FormattedMessage } from 'react-intl'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

const SignInDemo = () => {
  const { name } = useSelector(prop('school'))

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 74px)',
        maxWidth: 1074,
        mt: {
          xs: 'calc(50% - 74px)',
          // eslint-disable-next-line sort-keys
          sm: 'calc(30% - 74px)',
          // eslint-disable-next-line sort-keys
          md: 'calc(20% - 74px)'
        },
        mx: 'auto',
        px: 2
      }}
    >
      <Card
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent',
          maxWidth: 500,
          width: 1
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography component="h1" variant="h4">
            <FormattedMessage
              defaultMessage="Log into your account"
              id="g/KaJY"
            />
          </Typography>
          <Stack spacing={0.5} sx={{ pt: 1, px: 2 }}>
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="New to YOU at {school}?"
                id="9Iytmg"
                values={{ school: name }}
              />
            </Typography>
            <Typography
              color="primary.main"
              component={Link}
              sx={{
                textDecoration: 'underline',
                width: 'fit-content'
              }}
              to="/sign-up"
              variant="body2"
            >
              <FormattedMessage
                defaultMessage="Create an account."
                id="kuGAl7"
              />
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <Box
          sx={{
            pb: 2,
            px: 2
          }}
        >
          <BasicSignIn />
        </Box>
      </Card>
    </Box>
  )
}

export default SignInDemo
