/* eslint-disable sort-keys */
import { Box, Typography } from '@mui/material'
import ResultsGauge from 'components/common/ResultsGauge'
import PropTypes from 'prop-types'
import { and, defaultTo, gte, lte, prop } from 'ramda'
import { Link } from 'react-router-dom'

const DomainScore = ({ subdomain = {}, scores = [] }) => {
  // results are a giant list of all answers for the entire quiz
  // we filter that down to the subdomain we are currently on
  // then sum the values associated to those answers
  // then determine the score rating
  // this score is given to us in the subdomain.answers list which contains
  // ranges of integer values that correspond to the score of 1 through 4
  const userAnswers = scores.filter(({ reality_check_question_id }) =>
    prop('values', subdomain)
      .map(({ id }) => id)
      .includes(reality_check_question_id)
  )

  const sumAnswerValues = userAnswers.reduce(
    (sum, answer) => sum + answer.score,
    0
  )

  const score = prop('answers', subdomain).find(answer =>
    and(
      gte(sumAnswerValues, prop('low_value', answer)),
      lte(sumAnswerValues, prop('high_value', answer))
    )
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 8,
        gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' }
      }}
    >
      <Typography
        color="primary.main"
        component={Link}
        sx={{
          flex: 1,
          fontWeight: 700,
          maxWidth: { xs: 'unset', lg: 215 },
          mb: { xs: 2, lg: 0 },
          textDecoration: 'underline'
        }}
        to={prop('link', subdomain)}
        variant="subtitle1"
      >
        {prop('title', subdomain)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%'
        }}
      >
        <ResultsGauge
          emoji={null}
          score={defaultTo(0, prop('star', score))}
          variant="compact"
        />
      </Box>
    </Box>
  )
}

DomainScore.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subdomain: PropTypes.shape({}).isRequired
}

export default DomainScore
