import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const WelcomeHeroImage = ({ imageUrl, position = 'left' }) => {
  const RightImage = () => (
    <>
      <Box
        sx={{
          bgcolor: 'school.secondary',
          borderRadius: 1,
          float: 'right',
          height: 1,
          maskImage: 'url(/images/welcome/mask-hero.svg)',
          maskPosition: 'top center',
          maskRepeat: 'no-repeat',
          maskSize: 'contain',
          ml: 1,
          mt: '3%',
          position: 'relative',
          pt: '100%',
          width: 1
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            borderRadius: 1,
            bottom: 25,
            position: 'absolute',
            pt: '100%',
            right: -22,
            width: 1
          }}
        />
      </Box>
    </>
  )

  const LeftImage = () => (
    <>
      <Box
        sx={{
          bgcolor: 'school.secondary',
          maskImage: 'url(/images/welcome/mask-hero-left.svg)',
          maskPosition: 'top center',
          maskRepeat: 'no-repeat',
          maskSize: 'contain',
          ml: '-4%',
          pt: '100%',
          width: '100%'
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            borderBottomRightRadius: 1,
            bottom: 0,
            left: '-3.5%',
            ml: '-4%',
            position: 'absolute',
            pt: '100%',
            top: '-3.5%',
            width: '100%'
          }}
        />
      </Box>
    </>
  )

  return <Box>{position === 'left' ? <LeftImage /> : <RightImage />}</Box>
}

export default WelcomeHeroImage

WelcomeHeroImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  position: PropTypes.string
}
