import { FormattedMessage } from 'react-intl'
import { Box, Tooltip, Typography } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

const WhyWeAskForInfoDisclaimer = () => (
  <Tooltip
    arrow
    describeChild
    placement="top"
    title={
      <Typography color="white" variant="subtitle1">
        <FormattedMessage
          defaultMessage="Anything you share will only be used to customize content and provide personalized recommendations. All information you share is confidential."
          id="odtTWG"
        />
      </Typography>
    }
  >
    <Box
      sx={{
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        gridGap: 8,
        maxWidth: 300,
        px: 1,
      }}
      tabIndex={0}
    >
      <InfoRoundedIcon fontSize="16px" sx={{ color: 'primary.dark' }} />
      <Typography
        color="primary.main"
        textDecoration="underline"
        variant="body2"
      >
        <FormattedMessage
          defaultMessage="How is my information used?"
          id="/TZ9DQ"
        />
      </Typography>
    </Box>
  </Tooltip>
)

export default WhyWeAskForInfoDisclaimer
