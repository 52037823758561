/* eslint-disable sort-keys */
const palette = {
  collective: {
    dark: '#FFFA93',
    light: '#FFFBB3',
  },
  error: {
    dark: '#FF633A',
    light: '#FEF1E7',
    main: '#C22F1E',
  },
  grey: {
    light: '#ECECEC', // light
    socialSSO: '#F2F2F2',
  },
  info: {
    light: '#F1FBFE',
    main: '#1F61A3',
  },
  primary: {
    dark: '#B1B1B1', // grey.3
    main: '#333333', // grey.1
    light: '#F4F4F4', // grey.4
  },
  secondary: {
    dark: '#737373', // grey.2
    main: '#FBFBFB', // grey.5
    light: '#EAEAEA', // grey.6
  },
  success: {
    light: '#F3FBEF',
    main: '#61A904',
  },
  tonal: {
    hover: '#CFDFE1',
    main: '#E0EAEC',
  },
  warning: {
    main: '#FBCE36',
  },
  white: '#FFFFFF',
  // Nod has specific color schemes which we need to abide by.
  // but, instead of passing us that color scheme,
  // they instead just pass us a string like "blue" or "purple"
  // so we have to hard code this color object here and pray
  // that if these change on the nod side, someone remembers
  // to update them here.
  // Stan 5/4/2022
  blue: {
    accent: '#91d4ff',
    color: '#aeddfb',
    info: '#1c8ef8',
    infoLight: '#1c8ef810',
    ui: '#31afff',
  },
  cyan: {
    accent: '#8edbdb',
    color: '#a1e2e2',
    ui: '#2fb1b1',
  },
  green: {
    accent: '#98e0b2',
    color: '#b4e8c7',
    ui: '#31cd6a',
  },
  orange: {
    accent: '#ebc2a9',
    color: '#f1d2bf',
    ui: '#e29769',
  },
  purple: {
    accent: '#c9c8ec',
    color: '#d6d5ef',
    ui: '#9795cc',
  },
  red: {
    accent: '#eec1c1',
    color: '#f5cece',
    ui: '#de8e8e',
  },
}

export default palette
