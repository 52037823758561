import { useAnalytics } from 'hooks'
import Quicklinks from 'components/common/Quicklinks'
import { isNil, or, prop } from 'ramda'
import { useEffect } from 'react'
import { useGetDocumentQuery } from 'api/legalApi'
import { useSelector } from 'react-redux'

const PrivacyPolicy = () => {
  const { privacy_policy_id } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  useEffect(() => {
    sendEvent('tou_pp_viewed', {})
  }, [])

  const { data, isFetching } = useGetDocumentQuery(privacy_policy_id, {
    skip: isNil(privacy_policy_id)
  })

  if (or(isFetching, isNil(data))) return null

  return <Quicklinks rawHtml={prop('statement', data)} />
}

export default PrivacyPolicy
