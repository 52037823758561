import PropTypes from 'prop-types'

const ChatBubble = ({ fill }) => (
  <svg
    fill="none"
    height="54"
    viewBox="0 0 64 54"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57785 42.916C2.20256 37.4617 -0.706375 30.3666 0.147179 22.9775C1.82738 8.43212 17.4504 -1.74348 35.0422 0.2492C52.6341 2.24188 65.533 15.6485 63.8528 30.1935C62.1726 44.7386 46.5496 54.9145 28.9578 52.9218C24.03 52.3637 19.4706 50.9099 15.4966 48.7862C13.1509 50.5625 10.4847 52.0543 7.57785 53.1948V42.916Z"
      fill={fill}
    />
    <path
      d="M28.0152 24.4865C28.6671 24.4865 29.4602 24.8933 30.3946 25.7069C31.3507 26.4985 31.8288 27.6309 31.8288 29.1041C31.8288 30.5553 31.2964 31.8857 30.2316 33.095C29.1669 34.3044 27.8957 34.9091 26.418 34.9091C24.9404 34.9091 23.7235 34.4803 22.7674 33.6228C21.8113 32.7652 21.3333 31.3909 21.3333 29.4999C21.3333 27.6089 22.1916 25.443 23.9082 23.0023C25.6466 20.5615 27.6349 18.9893 29.8731 18.2857C30.6119 18.6815 31.0139 19.2862 31.0791 20.0998C30.2751 20.7374 29.5689 21.529 28.9604 22.4745C28.3737 23.3981 28.0587 24.0687 28.0152 24.4865ZM39.6842 24.4865C40.3361 24.4865 41.1292 24.8933 42.0636 25.7069C43.0197 26.4985 43.4978 27.6309 43.4978 29.1041C43.4978 30.5553 42.9654 31.8857 41.9006 33.095C40.8358 34.3044 39.5646 34.9091 38.087 34.9091C36.6094 34.9091 35.3925 34.4803 34.4364 33.6228C33.502 32.7652 33.0348 31.5888 33.0348 30.0936C33.0348 28.5984 33.4151 27.0372 34.1756 25.41C34.9579 23.7609 36.0118 22.2766 37.3373 20.9573C38.6628 19.638 40.0753 18.7475 41.5747 18.2857C42.3135 18.7035 42.7155 19.3082 42.7807 20.0998C41.9549 20.7374 41.2378 21.529 40.6294 22.4745C40.0427 23.3981 39.7276 24.0687 39.6842 24.4865Z"
      fill="white"
    />
  </svg>
)

ChatBubble.propTypes = {
  fill: PropTypes.string,
}

ChatBubble.defaultProps = {
  fill: '',
}

export default ChatBubble
