import { FormattedMessage } from 'react-intl'
import { equals, length, gt, prop } from 'ramda'
import { useGetGoalsQuery } from 'api/goalsApi'
import { Box, Stack, Typography } from '@mui/material'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'

import InProgressGoal from './InProgressGoal'
import EmptyState from './EmptyState'

const InProgress = () => {
  const { data: goals = [] } = useGetGoalsQuery()

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center',
        }}
      >
        <WatchLaterRoundedIcon color="school.primary" />
        <Typography variant="subtitle1">
          <FormattedMessage
            defaultMessage="{number} Goals In Progress"
            id="3ka4yM"
            values={{ number: length(goals) }}
          />
        </Typography>
      </Stack>
      {equals(length(goals), 0) && <EmptyState type={'inProgress'} />}
      {gt(length(goals), 0) && (
        <Box sx={{ display: 'grid', gridGap: 32, my: 3 }}>
          {goals.map(goal => (
            <InProgressGoal goal={goal} key={prop('id', goal)} />
          ))}
        </Box>
      )}
    </>
  )
}

export default InProgress
