import { useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { compose, not, path, tap } from 'ramda'
import { useReVerifyUserMutation } from 'api/userApi'

import ErrorMessage from 'components/common/ErrorMessage'
import SuccessMessage from 'components/common/SuccessMessage'

const Revalidate = () => {
  const [reverfiyEmail, setReverfiyEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const [resendVerification, { error, isError, isSuccess }] =
    useReVerifyUserMutation()

  const validateEmail = email => {
    const regex =
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setEmailError(not(regex.test(email)))
  }

  return (
    <>
      <SuccessMessage
        hasSuccess={isSuccess}
        message={
          <FormattedMessage
            defaultMessage="Success! Check your email to verify your new account."
            id="MGQUIN"
          />
        }
      />
      <ErrorMessage
        hasError={isError}
        message={path(['data', 'message'], error)}
      />
      <Typography mr="auto" textAlign="center" variant="body2">
        <FormattedMessage
          defaultMessage="If you are having trouble logging in or creating an account on YOU, please enter your school email address to receive another verification email."
          id="/DPl3+"
        />
      </Typography>

      <Stack alignItems="center" spacing={2} sx={{ width: 1 }}>
        <TextField
          defaultValue={reverfiyEmail}
          fullWidth
          helperText={
            emailError && (
              <FormattedMessage
                defaultMessage="Hmm, this email address isn't valid."
                id="/g9wJ/"
              />
            )
          }
          id="reverify-email"
          label={<FormattedMessage defaultMessage="Email" id="sy+pv5" />}
          onChange={compose(
            validateEmail,
            tap(setReverfiyEmail),
            path(['target', 'value'])
          )}
          required
          type="email"
        />

        <Button
          onClick={() => {
            const body = new FormData()
            body.append('email', reverfiyEmail)
            resendVerification(body)
          }}
        >
          <FormattedMessage
            defaultMessage="Resend Verification Email"
            id="2cW5aH"
          />
        </Button>
      </Stack>
      <Typography mr="auto" variant="legal">
        <FormattedMessage
          defaultMessage="If you are still having trouble, please contact {email} for assistance."
          id="v3iNb2"
          values={{
            email: (
              <a href="mailto:paige@gritdigitalhealth.com">
                paige@gritdigitalhealth.com
              </a>
            ),
          }}
        />
      </Typography>
    </>
  )
}

export default Revalidate
