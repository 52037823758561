import { Box, Stack, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { equals, prop, path, not, or, isNil, isEmpty, any } from 'ramda'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { TYPE_FACT_TIP } from 'utils/card-functions'
import { useSelector } from 'react-redux'

const TextArticle = ({ article }) => {
  const { flagImage } = useSelector(prop('school'))
  const articleToRender = equals(prop('type', article), TYPE_FACT_TIP)
    ? prop('facts_tips_copy', article)
    : prop('page_content', article)

  return (
    <Stack spacing={4}>
      <Typography alignSelf="center" variant="h1">
        <FormattedMessage
          defaultMessage="{title}"
          id="2YgLVn"
          values={{ title: prop('title', article) }}
        />
      </Typography>
      <Box
        sx={{
          height: 360,
          // eslint-disable-next-line sort-keys
          mb: { xs: 4, sm: 8.5 },
          position: 'relative',
          width: 1,
        }}
      >
        {prop('campus_resource', article) ? (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              alt=""
              component="img"
              src={path(['photo', 'large_url'], article)}
              sx={{
                border: 'none',
                height: '100%',
                objectFit: 'cover',
                width: '100%',
              }}
            />
            <Box
              sx={{
                borderBottom: 'solid 52px',
                borderBottomColor: 'school.flag',
                borderLeft: 'solid 52px transparent',
                borderRight: 'solid 52px',
                borderRightColor: 'school.flag',
                borderTop: 'solid 52px transparent',
                bottom: 7,
                position: 'absolute',
                right: 0,
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${flagImage})`,
                  backgroundPostion: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  bgcolor: 'transparent',
                  height: 52,
                  left: -8,
                  position: 'absolute',
                  width: 52,
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            alt=""
            component="img"
            src={path(['photo', 'large_url'], article)}
            sx={{
              border: 'none',
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        )}
      </Box>
      <Box>
        {not(
          or(
            isNil(prop('photo_description', article)),
            isEmpty(prop('photo_description', article))
          )
        ) && (
          <Box sx={{ alignSelf: 'center' }}>
            {parse(prop('photo_description', article))}
          </Box>
        )}
        {any(equals(true))([
          not(isNil(prop('facts_tips_copy', article))),
          not(isEmpty(prop('page_content', article))),
          not(isNil(prop('type', article))),
        ]) && <Box>{parse(articleToRender)}</Box>}
      </Box>
    </Stack>
  )
}

TextArticle.propTypes = {
  article: PropTypes.shape({}).isRequired,
}

export default TextArticle
