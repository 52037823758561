import { useParams } from 'react-router-dom'
import Quiz from './components/Quiz'
import { isNil, or, toLower } from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import { Box, Container, Skeleton, Stack } from '@mui/material'
import { useMemo } from 'react'

const QuizPage = () => {
  const { quiz } = useParams()
  const { data, isFetching = true } = useGetSelfCheckQuizDataQuery()

  const currentQuiz = useMemo(() => {
    if (isNil(data)) return {}
    return data.find(({ title }) => toLower(title).includes(quiz))
  }, [data])

  if (or(isNil(data), isFetching))
    return (
      <Container maxWidth="sm" sx={{ py: 6 }}>
        <Stack alignItems="center" spacing={1}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton
            height={16}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="100%"
          />
          <Skeleton height={63} variant="text" width="100%" />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton
            height={48}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width="70%"
          />
          <Skeleton height={63} variant="text" width="75%" />
        </Stack>
      </Container>
    )

  return (
    <Box sx={{ height: 'calc(100vh - 168px)' }}>
      <Quiz currentQuiz={currentQuiz} />
    </Box>
  )
}

export default QuizPage
