import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import QuestionSummary from './QuestionSummary'
import { defaultTo, path, isNil, prop, inc } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'

const Question = ({ question, updateQuestion, userAnswer }) => {
  const [answerIndex, setAnswerIndex] = useState(
    defaultTo(null, path(['score', 'score'], userAnswer))
  )

  if (isNil(question)) return null

  return (
    <Box
      sx={{
        pt: 1,
        // eslint-disable-next-line sort-keys
        px: { xs: 2, lg: 4 },
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'flex-end',
        }}
      >
        <LeaderboardIcon sx={{ color: 'school.accent' }} />
        <Typography fontSize="14px" variant="overline">
          <FormattedMessage defaultMessage="Poll" id="h1gtUi" />
        </Typography>
      </Stack>
      <Box border="0" component="fieldset" m="0" p="0">
        <Typography
          aria-label={`Poll: ${prop('prompt', question)}`}
          tabIndex={0}
          variant="h4"
        >
          {prop('prompt', question)}
        </Typography>

        <Typography
          color="secondary.dark"
          sx={{
            mb: 1,
            mt: 0.5,
          }}
          variant="subtitle1"
        >
          <FormattedMessage
            defaultMessage="Select an answer to see what others said."
            id="fkOonE"
          />
        </Typography>
        {prop('stats', question) ? (
          <QuestionSummary
            key={prop('id', question)}
            question={question}
            selectedAnswerIndex={answerIndex}
          />
        ) : (
          <Stack
            spacing={1}
            sx={{
              flexWrap: 'wrap',
              m: 0,
              mt: 0.5,
            }}
          >
            {prop('answers', question).map(({ answer, id }, index) => (
              <Button
                aria-label={`Select to answer poll: ${answer}`}
                key={id}
                onClick={() => {
                  setAnswerIndex(inc(index))
                  updateQuestion(index)
                }}
                variant="selfCheck"
              >
                {answer}
              </Button>
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  )
}

Question.propTypes = {
  question: PropTypes.shape({}).isRequired,
  updateQuestion: PropTypes.func.isRequired,
  userAnswer: PropTypes.shape({}).isRequired,
}

export default Question
