import { prop, assoc, defaultTo, not, gt, length } from 'ramda'

const HOST = process.env.REACT_APP_PUBLIC_YOU_API_HOST

const getSessionId = () => {
  if (typeof window !== 'undefined') {
    const key = localStorage.getItem('@you:sessionId')
    return JSON.parse(key)
  }
  return
}

const createSelfCheckDomainSummary = (title, selfCheckDomain) => {
  const results = selfCheckDomain.results.map(result => {
    //Taken from RealityCheckResults.jsx
    const resultValues = result.values
    let resultValue = resultValues.map(x => x.value).reduce((a, b) => a + b, 0)

    if (resultValue <= 0) {
      resultValue = 1
    }
    const detailInfo = result.answers.find(details => {
      return (
        resultValue >= details.low_value && resultValue <= details.high_value
      )
    })
    return {
      link: result.link,
      rating: prop('star', detailInfo),
      subdomain: result.title
    }
  })
  return {
    results,
    title
  }
}

const useGetSelfCheckData = (
  selfCheckDomain,
  domain,
  title,
  selectedSubdomains
) => {
  //Find some questions related to their interests.
  const interestedSubdomainIds = defaultTo(
    [],
    selectedSubdomains.map(prop('id'))
  )
  // some questions we do not want to display first (like suicidal ideation)
  const scq_safe_questions = selfCheckDomain.questions.filter(
    ({ hide_from_onboarding }) => not(hide_from_onboarding)
  )
  const scq_safe_questions_related_to_interests = scq_safe_questions.filter(
    ({ groupId }) => interestedSubdomainIds.includes(groupId)
  )
  const scq_question = gt(length(scq_safe_questions_related_to_interests), 0)
    ? scq_safe_questions_related_to_interests[
        Math.floor(
          scq_safe_questions_related_to_interests.length * Math.random()
        )
      ]
    : scq_safe_questions[Math.floor(scq_safe_questions.length * Math.random())]

  // check if user has answered question before
  const headers = {
    credentials: 'omit',
    headers: new Headers({
      Accept: 'application/json',
      'X-Session-Id': getSessionId()
    }),
    method: 'GET',
    mode: 'cors'
  }
  const userAnswer = fetch(`${HOST}/scores/${scq_question.id}`, headers)
    .then(res => res.json())
    .then(data => {
      if (prop('score', data)) {
        fetch(
          `${HOST}/stats/reality-check-scores/${prop('id', scq_question)}`,
          headers
        ).then(({ data }) => assoc('stats', data, scq_question))
      }
      return data
    })

  return {
    domain,
    domainSummary: selfCheckDomain.completed
      ? createSelfCheckDomainSummary(title, selfCheckDomain)
      : null,
    inProgress: not(selfCheckDomain.completed),
    question: scq_question,
    userAnswer
  }
}

export default useGetSelfCheckData
