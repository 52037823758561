/* eslint-disable react/no-unescaped-entities */
import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Contact = () => (
  <Stack
    sx={{
      bgcolor: 'primary.light',
      border: '1px solid',
      borderColor: 'primary.dark',
      borderRadius: 1,
      mb: 4,
      // eslint-disable-next-line sort-keys
      p: { xs: 2, md: 4 },
      width: '100%'
    }}
  >
    <Typography sx={{ fontSize: '22px' }} variant="h4">
      Want more info?
    </Typography>
    <Box>
      <Typography
        component="span"
        sx={{ display: 'inline', lineHeight: '16px', mr: 0.5 }}
        variant="subtitle1"
      >
        If you're looking to bring the YOU platform to your agency, contact our
        team at
      </Typography>
      <Typography
        component={Link}
        sx={{ display: 'inline', lineHeight: '16px' }}
        to="mailto:inquiries@gritdigitalhealth.com"
        variant="subtitle1"
      >
        inquiries@gritdigitalhealth.com
      </Typography>
    </Box>
  </Stack>
)

export default Contact
