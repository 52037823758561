import { css, Global } from '@emotion/react'
import { prop } from 'ramda'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

const HeadComponent = () => {
  const { meta_content, meta_description, meta_img, meta_title } = useSelector(
    prop('school')
  )

  return (
    <>
      <Helmet>
        <title>{meta_title}</title>
        <meta
          content="width=device-width, initial-scale=1, minimal-ui"
          name="viewport"
        />
        <meta content={meta_content} key="meta-title" property="og:title" />
        <meta content={meta_img} key="meta-img" property="og:img" />
        <meta
          content={meta_description}
          key="meta-description"
          property="og:description"
        />
        <meta
          content={meta_description}
          key="meta-name-description"
          name="og:description"
        />
      </Helmet>
      <Global
        styles={css`
          .MuiPickersCalendarHeader-labelContainer {
            white-space: nowrap;
          }

          // Safari only
          @media not all and (min-resolution: 0.001dpcm) {
            -webkit-font-smoothing: subpixel-antialiased;
          }

          // Carousel
          .slick-track {
            display: flex;
            flex-direction: row;
            gap: 20px;
          }

          .slick-list {
            padding: 0;
            overflow: hidden;
          }

          .slick-slider {
            position: relative;
          }

          .slick-arrow {
            padding: 0;
            z-index: 1;
          }

          .slick-next,
          .slick-prev {
            background: white;
            border-radius: 50%;
            background-size: cover;
            box-shadow: 0 2px 4px rgba(79, 79, 79, 0.25);
            height: 35px;
            width: 35px;
            position: absolute;
            // Best way to center from top
            // 50% of the parent height minus the height of the component
            top: calc(50% - 35px);
            cursor: pointer;
            border: none;
          }
          .slick-next {
            &:after {
              content: '';
              position: absolute;
              z-index: 1;
              width: 60px;
              top: -150px;
              height: 365px;
              pointer-events: none;
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0.9) 0%,
                rgba(255, 255, 255, 0) 64.79%
              );
              right: -20px;
            }
            background-image: url('/images/icons/arrow-carot-right-black.svg');
            right: 20px;
          }
          .slick-prev {
            &:before {
              content: '';
              position: absolute;
              z-index: 1;
              width: 60px;
              top: -150px;
              height: 365px;
              pointer-events: none;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.9) 0%,
                rgba(255, 255, 255, 0) 64.79%
              );
              left: -20px;
            }
            background-image: url('/images/icons/arrow-carot-left-black.svg');
            left: 20px;
          }

          .slick-disabled {
            visibility: hidden;
          }

          .carousel-box {
            width: 100%;
          }

          .slick-track {
            align-items: stretch;
            margin: 0 auto 8px;
            gap: 0;
          }
          .slick-list {
            @media screen and (min-width: 768px) {
              margin-top: 40px;
            }
            margin-top: 20px;
          }

          // Animations
          @keyframes loader {
            0% {
              transform: scale(0.6) rotate(0deg);
            }
            60% {
              transform: scale(1.15) rotate(180deg);
            }
            100% {
              transform: scale(0.6) rotate(360deg);
            }
          }

          @keyframes pulseAnimation {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
            }
          }

          // FORMS
          .email-and-password-input {
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 2px;
            font-size: 16px;
            height: 36px;
            padding: 0 2%;
            width: 95%; //280px;

            &:focus {
              border: 2px solid rgba(81, 203, 238, 1);
              box-shadow: 0 0 5px rgba(81, 203, 238, 1);
            }

            &--error {
              border: 1px solid #fc522f;
              box-shadow: 0 0 5px #fc522f;
              border-radius: 2px;
              font-size: 16px;
              height: 36px;
              padding: 0 10px;
              width: 100%; //280px;
            }
          }
        `}
      />
    </>
  )
}

export default HeadComponent
