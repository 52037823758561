import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop, equals, dec, inc, length, filter, propEq, or } from 'ramda'
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Typography
} from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Stack } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import QuizQuestion from './QuizQuestion'

const Quiz = ({ currentQuiz }) => {
  const navigate = useNavigate()

  // Hiding questions where this prop is true
  // https://gitlab.com/grit-digital-health/YOU-Frontend/-/issues/2236
  const questions = filter(
    propEq(or(false, null), 'hide_from_reality_check'),
    prop('questions', currentQuiz)
  )

  const [question, setQuestion] = useState(questions[0])

  return (
    <Container maxWidth="md" sx={{ pb: 6 }}>
      <Button
        onClick={() => {
          // if we are at the start of the currentQuiz, we just go back to the self checks select page
          if (equals(questions.indexOf(question), 0))
            return navigate('/self-checks')
          setQuestion(questions[dec(questions.indexOf(question))])
        }}
        startIcon={<ChevronLeftRoundedIcon />}
        sx={{ px: 0 }}
        variant="text"
      >
        <FormattedMessage defaultMessage="back" id="yLtLwT" />
      </Button>
      <Stack alignItems="center" spacing={2}>
        <Typography
          color="primary.main"
          component="h1"
          fontFamily="mark-ot-bold"
          variant="overline"
        >
          <FormattedMessage
            defaultMessage="{quizType} self check"
            id="qfqa6e"
            values={{ quizType: prop('title', currentQuiz) }}
          />
        </Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="overline">
            <FormattedMessage defaultMessage="Question:" id="XbWN0a" />
          </Typography>
          <Typography id="count" variant="overline">
            {inc(questions.indexOf(question))} / {length(questions)}
          </Typography>
        </Stack>
        <Box
          sx={{
            maxWidth: 400,
            width: 1
          }}
        >
          <Box
            sx={{
              color: 'school.secondary',
              width: 1
            }}
          >
            <LinearProgress
              aria-labelledby="count"
              color="inherit"
              value={
                (inc(questions.indexOf(question)) / length(questions)) * 100
              }
              variant="determinate"
            />
          </Box>
        </Box>
        <QuizQuestion
          currentQuiz={currentQuiz}
          question={question}
          questions={questions}
          setQuestion={setQuestion}
        />
      </Stack>
    </Container>
  )
}

Quiz.propTypes = {
  currentQuiz: PropTypes.shape({}).isRequired
}

export default Quiz
