import palette from '../palette'

const MuiCard = {
  styleOverrides: {
    root: {
      boxShadow: '0 2px 4px rgb(79 79 79 / 25%)',
    },
  },
  variants: [
    {
      props: { variant: 'completed goal' },
      style: {
        borderTop: '2px solid',
        borderTopColor: palette.primary,
      },
    },
    {
      props: { variant: 'interactive' },
      style: {
        border: '2px solid',
        borderColor: 'transparent',
        borderRadius: 12,
        transition: 'all .5s',
      },
    },
    {
      props: { selected: true, variant: 'interactive' },
      style: {
        ':hover': {
          backgroundColor: palette.blue.infoLight,
        },
        backgroundColor: palette.blue.infoLight,
        borderColor: palette.blue.info,
      },
    },
  ],
}

export default MuiCard
