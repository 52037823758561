import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  completedSelfCheck: false,
  emailOptIn: false,
  location: null,
  onboardingState: [],
  realityCheckAnswers: [],
  realityCheckQuestion: {
    answers: [],
  },
  selectedGoals: [],
  selectedInterests: [],
}

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    resetState: () => {
      return initialState
    },
    setCompletedSelfCheck: (state, { payload }) =>
      assoc('completedSelfCheck', payload, state),
    setEmailOptIn: (state, { payload }) => assoc('emailOptIn', payload, state),
    setLocation: (state, { payload }) => assoc('location', payload, state),
    setOnboardingState: (state, { payload }) =>
      assoc('onboardingState', payload, state),
    setRealityCheckAnswers: (state, { payload }) =>
      assoc('realityCheckAnswers', payload, state),
    setRealityCheckQuestion: (state, { payload }) =>
      assoc('realityCheckQuestion', payload, state),
    setSelectedGoals: (state, { payload }) =>
      assoc('selectedGoals', payload, state),
    setSelectedInterests: (state, { payload }) =>
      assoc('selectedInterests', payload, state),
  },
})

export const {
  resetState,
  setEmailOptIn,
  setCompletedSelfCheck,
  setLocation,
  setOnboardingState,
  setRealityCheckAnswers,
  setRealityCheckQuestion,
  setSelectedGoals,
  setSelectedInterests,
} = onboardingSlice.actions

export default onboardingSlice.reducer
