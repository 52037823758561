import PropTypes from 'prop-types'
import { excerpt } from 'utils/helpers'
import parse from 'html-react-parser'
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { and, isEmpty, isNil, not } from 'ramda'

const SmallResourceCard = ({
  flagIcon = null,
  handleAnalytics = () => {},
  headline,
  imageUrl = null,
  internalUrl,
  subtitle = null
}) => (
  <Card>
    <CardActionArea component={Link} onClick={handleAnalytics} to={internalUrl}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia component="img" height={250} image={imageUrl} />
        {not(isNil(flagIcon)) && (
          <Box
            sx={{
              borderBottom: 'solid 50px',
              borderBottomColor: 'school.flag',
              borderLeft: 'solid 50px transparent',
              borderRight: 'solid 50px',
              borderRightColor: 'school.flag',
              borderTop: 'solid 50px transparent',
              bottom: 0,
              position: 'absolute',
              right: 0
            }}
          >
            <Box
              sx={{
                background: `url(${flagIcon})`,
                backgroundColor: 'transparent',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: 42,
                position: 'absolute',
                width: 42
              }}
            />
          </Box>
        )}
      </Box>
      <CardHeader
        subheader={
          and(not(isEmpty(subtitle)), not(isNil(subtitle))) && (
            <Box
              color="primary.main"
              sx={{
                '& > p': {
                  fontSize: 18,
                  my: 1
                }
              }}
            >
              {parse(excerpt(subtitle, 72))}
            </Box>
          )
        }
        title={
          <Typography
            sx={{
              fontSize: 20,
              hyphens: 'auto'
            }}
            variant="h4"
          >
            {headline}
          </Typography>
        }
      />
    </CardActionArea>
  </Card>
)

export default SmallResourceCard

SmallResourceCard.propTypes = {
  flagIcon: PropTypes.string,
  handleAnalytics: PropTypes.func,
  headline: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  internalUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}
