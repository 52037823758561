import { FormattedMessage } from 'react-intl'
import { prop, gt, length } from 'ramda'
import { useGetCampusGoalsQuery } from 'api/goalsApi'
import { Box, Card, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useSelector } from 'react-redux'
import GoalCard from './GoalCard'

const Goals = () => {
  const { selectedGoals } = useSelector(prop('onboarding'))
  const { data: goals, isFetching } = useGetCampusGoalsQuery()

  return (
    <Stack alignItems="center" spacing={2} sx={{ mt: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridGap: 24,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            // eslint-disable-next-line sort-keys
            sm: 'repeat(2, 1fr)',
            // eslint-disable-next-line sort-keys
            lg: 'repeat(4, 1fr)'
          },
          marginBottom: 5
        }}
      >
        {isFetching ? (
          <Stack data-testid="skeleton">
            <Skeleton height={200} variant="rectangular" width="100%" />
            <Skeleton height={200} variant="rectangular" width="100%" />
            <Skeleton height={200} variant="rectangular" width="100%" />
            <Skeleton height={200} variant="rectangular" width="100%" />
          </Stack>
        ) : (
          goals.map(goal => <GoalCard goal={goal} key={prop('id', goal)} />)
        )}
      </Box>

      {gt(length(selectedGoals), 0) && (
        <Card
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 835,
            mx: 'auto',
            p: 1.5
          }}
        >
          <Typography
            color="primary.main"
            textAlign="center"
            variant="subtitle4"
          >
            <FormattedMessage defaultMessage="Way to go!" id="JF/bbb" /> 🙌
          </Typography>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography color="primary.main" textAlign="center" variant="body1">
              <FormattedMessage
                defaultMessage="If you write down a goal you are "
                id="muAdUr"
              />
            </Typography>
            <Typography color="primary.main" fontSize={18} variant="subtitle3">
              <FormattedMessage
                defaultMessage="5 times more likely"
                id="3YAl74"
              />
            </Typography>
            <Typography color="primary.main" textAlign="center" variant="body1">
              <FormattedMessage defaultMessage=" to achieve it." id="4tDInK" />
            </Typography>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}

export default Goals
