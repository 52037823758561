import { svg_you_triad } from 'icons'
import { FormattedMessage } from 'react-intl'
import { CardActionArea, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const AddPrioritiesCard = ({ toggleMenu }) => (
  <Stack
    sx={{
      ':hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
      alignItems: 'center',
      bgcolor: 'white',
      height: 225,
      position: 'relative',
      transition: 'all .3s ease-in-out',
      zIndex: 0,
    }}
  >
    <CardActionArea onClick={() => toggleMenu()} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        {svg_you_triad}
        <Typography color="primary.main" textAlign="center" variant="body1">
          <FormattedMessage defaultMessage="Priorities change." id="GpXcE/" />
        </Typography>
      </Stack>
    </CardActionArea>
  </Stack>
)

AddPrioritiesCard.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
}

export default AddPrioritiesCard
