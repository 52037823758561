import ActiveConsentForm from './ActiveConsentForm'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setConsent } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  consent: prop('consent', authentication),
})

const mapDispatchToProps = {
  setConsent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveConsentForm)
