/* eslint-disable sort-keys */
/* eslint-disable jsx-a11y/aria-role */
import { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import ScrollInView from './ScrollInView'
import Cards from './Cards'
import CardsMask from './CardsMask'
import ScreenReaderMessage from 'components/common/ScreenReaderMessage'
import GA from 'services/gaService'
import { options as businessOptions } from './BusinessCards'
import { options as mhcdOptions } from './MHCDCards'
import { options as nlcOptions } from './NLCCards'
import styles from './WelcomeSectionCards.module.scss'
import CardsSelectMenu from './CardsSelectMenu'
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { any, equals, find, not, path, prop, propEq } from 'ramda'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const WelcomeSectionCards = () => {
  const { sendEvent } = useAnalytics()

  const { isBusiness, isCollective, isWellpower, isNLC } = useCurrentAppType()

  const ref = useRef(null)
  const inView = ScrollInView(ref)
  const { name } = useSelector(prop('school'))

  const paragraph_business = 'Personalized and confidential, no exceptions.'
  const paragraph_mhcd = (
    <FormattedMessage
      defaultMessage="Personalized based on your interests, with topics like job performance, family, stress, mental health, and more."
      id="1EOhLm"
    />
  )
  const paragraph_nlc =
    'Personalized based on your interests, with topics like job performance, family, stress, mental health, and more.'

  let paragraph = paragraph_business
  if (isWellpower || isCollective) paragraph = paragraph_mhcd
  if (isNLC) paragraph = paragraph_nlc

  const ctaLink_other = 'sign up to choose your priorities'

  const ctaLink = ctaLink_other

  const phoneLoaderTime = 1500

  const getPicklistHeadlineScreenRead = role => {
    if (isBusiness)
      return (
        <FormattedMessage
          defaultMessage="Get well-being tips & tools for {role}. "
          id="5Ujp91"
          values={{ role }}
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Get tips & tools for {role}. "
        id="sWP2o7"
        values={{ role }}
      />
    )
  }

  // todo - need to import these files from develop
  const responderOptions = []

  let options = responderOptions
  if (isBusiness) options = businessOptions
  if (isWellpower || isCollective) options = mhcdOptions
  if (isNLC) options = nlcOptions

  const [phoneLoading, setPhoneLoading] = useState(true)
  const [selectedRole, setSelectRoleValue] = useState(prop(0, options))
  const [highlighted, setHighlighted] = useState(false)

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'cards'
    })
  }

  const handleSelectRoleChange = e => {
    const selected = path(['target', 'value'], e)
    const found = find(propEq('value', selected), options)
    setHighlighted(false)
    setTimeout(() => setHighlighted(true), 250)
    setPhoneLoading(true)
    setSelectRoleValue(found)
    setTimeout(() => setPhoneLoading(false), phoneLoaderTime)
    sendKeenIOEvent('Role: ' + prop('label', found), 'Select')
    GA.click('Welcome-Role-Content: ' + prop('label', found), 'Select')
  }

  useEffect(() => {
    // TODO - debug inView, highlight double render
    // if (isFirstRun.current) {
    //   isFirstRun.current = false
    //   return
    // }
    setHighlighted(false)
    setTimeout(() => setHighlighted(true), 1000)
    setPhoneLoading(true)
    setTimeout(() => setPhoneLoading(false), phoneLoaderTime)
  }, [inView])

  const getPhoneContent = () => {
    if (phoneLoading) {
      return (
        <Stack spacing={2}>
          <Skeleton height={210} variant="rectangular" />
          <Skeleton height={110} variant="rectangular" />
          <Skeleton height={110} variant="rectangular" />
          <Skeleton height={110} variant="rectangular" />
          <Skeleton height={110} variant="rectangular" />
        </Stack>
      )
    }
    if (any(equals(true))([isWellpower, isBusiness, isNLC])) {
      return (
        <Stack>
          <Cards role={prop('label', selectedRole)} />
        </Stack>
      )
    }
    return null
  }

  const getHeadline = () => (
    <>
      <ScreenReaderMessage>
        {getPicklistHeadlineScreenRead(selectedRole?.label)}
      </ScreenReaderMessage>
      <Typography color="primary.main" variant="h2">
        <FormattedMessage
          defaultMessage="Get {tips} & tools for"
          id="j9wOMm"
          values={{
            br: <Box component="br" display={['none', null, 'initial']} />,
            tips: isBusiness ? 'well-being tips' : 'tips'
          }}
        />
      </Typography>
      <CardsSelectMenu
        handleSelectRoleChange={handleSelectRoleChange}
        highlighted={highlighted}
        options={options}
        selectedRole={selectedRole}
      />
    </>
  )

  const messageContent = org => {
    if (isBusiness)
      return (
        <FormattedMessage
          defaultMessage="Features {org} resources"
          id="y4oC8q"
          values={{ org }}
        />
      )
    if (isWellpower)
      return (
        <FormattedMessage
          defaultMessage="Features resources around Denver"
          id="SYxq1Q"
        />
      )
  }

  const getCallout = (org, side) => (
    <Box
      bottom={'8%'}
      className={`callout ${(side, styles)}`}
      color="secondary.dark"
      position="absolute"
      width="250px"
      zIndex="0"
      {...(side === 'left'
        ? {
            display: ['block', null, null, 'none'],
            margin: '10% 0 0 25%',
            position: 'relative'
          }
        : {
            display: ['none', null, null, 'block']
          })}
    >
      <svg
        className={styles['callout-graphic']}
        height="72"
        viewBox="0 0 113 72"
        width="113"
      >
        <path
          d="M4.9972 34.4531C4.91172 33.3518 5.73517 32.3898 6.83643 32.3043L24.7824 30.9114C25.8837 30.8259 26.8457 31.6493 26.9312 32.7506C27.0167 33.8519 26.1932 34.8139 25.092 34.8994L9.13997 36.1375L10.3781 52.0896C10.4636 53.1908 9.64017 54.1529 8.53891 54.2383C7.43766 54.3238 6.47562 53.5004 6.39014 52.3991L4.9972 34.4531ZM79.8501 32.934C80.6036 32.1263 81.8692 32.0824 82.6768 32.8359C83.4845 33.5894 83.5284 34.8549 82.7749 35.6626L79.8501 32.934ZM8.29173 32.7789C21.2069 43.8336 34.107 49.1745 46.1674 49.1648C58.1886 49.1551 69.685 43.8298 79.8501 32.934L82.7749 35.6626C71.9977 47.2145 59.4941 53.154 46.1706 53.1648C32.8862 53.1755 19.0968 47.2926 5.69067 35.8177L8.29173 32.7789Z"
          fill="#B0B0B0"
        />
        <path
          className={styles['location-icon']}
          d="M92.375 27.6875C92.6562 28.125 93.3125 28.125 93.5938 27.6875C98.1562 21.125 99 20.4375 99 18C99 14.6875 96.3125 12 93 12C89.6562 12 87 14.6875 87 18C87 20.4375 87.8125 21.125 92.375 27.6875ZM93 20.5C91.5938 20.5 90.5 19.4062 90.5 18C90.5 16.625 91.5938 15.5 93 15.5C94.375 15.5 95.5 16.625 95.5 18C95.5 19.4062 94.375 20.5 93 20.5Z"
          fill="#B0B0B0"
        />
        <circle
          cx="93"
          cy="20"
          fill="transparent"
          r="18"
          stroke="#B0B0B0"
          strokeWidth="4"
        />
      </svg>
      <Typography color="secondary.dark" variant="body1">
        {messageContent(org)}
      </Typography>
    </Box>
  )

  const ImageContentColumn = () => {
    const hash = '/images/welcome/hash-square.svg'

    return (
      <Box
        className="image-content-column"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: { xs: 'initial', lg: 1 },
          gridRow: { xs: 1, lg: 'initial' },
          pt: '20%',
          width: { xs: 1, lg: '45vw' }
        }}
      >
        <Box
          alignItems={'initial'}
          className="image-wrapper"
          display="flex"
          flexDirection="column"
          justifyContent={'flex-start'}
        >
          <Box
            className="hash-square"
            sx={{
              position: 'absolute',
              top: { xs: '-2%', md: '12%' },
              width: { xs: 1, md: '85%', lg: '50%' }
            }}
          >
            <Box
              sx={{
                background: `url(${hash})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                pt: '100%',
                width: 1
              }}
            />
          </Box>

          {(isWellpower || isCollective || isBusiness || isNLC) && (
            <CardsMask dynamicCards position="left">
              <Box className={styles['phone-wrapper']}>
                <Box className={styles['phone-bg']} />
                <Box className={styles['phone-screen']}>
                  <Box className={styles['phone-screen-content']}>
                    <Box
                      sx={{
                        display: 'grid',
                        width: 1
                      }}
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ px: 1 }}
                      >
                        <StarRoundedIcon fontSize="14px" />
                        <Typography variant="subtitle1">
                          <FormattedMessage
                            defaultMessage="Recommended for You"
                            id="41sr4c"
                          />
                        </Typography>
                      </Stack>
                      <Divider />
                    </Box>
                    {getPhoneContent()}
                  </Box>
                </Box>
              </Box>
            </CardsMask>
          )}
          {getCallout(name, 'left')}
        </Box>
      </Box>
    )
  }

  const CopyContentColumn = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: { xs: 'initial', lg: 2 },
        gridRow: { xs: 2, lg: 1 },
        justifyContent: 'center',
        p: { xs: '8%', md: '2% 5%', lg: '0 2% 0 2%', xl: '20% 7%' }
      }}
    >
      {getHeadline()}
      <Typography my={1} variant="bodyLarge">
        <FormattedMessage
          defaultMessage="{paragraph}"
          id="sXRPrT"
          values={{ paragraph }}
        />
      </Typography>
      <Button
        endIcon={<ArrowForwardRoundedIcon />}
        LinkComponent={Link}
        onClick={() => sendKeenIOEvent('Create a new Account', 'Link')}
        sx={{
          color: 'primary.main',
          fontWeight: 700,
          px: 0
        }}
        to="/sign-up"
        variant="text"
      >
        <FormattedMessage
          defaultMessage="{ctaLink}"
          id="QBkvSl"
          values={{ ctaLink }}
        />
      </Button>
      {not(isNLC) && getCallout(name, 'right')}
    </Box>
  )

  return (
    <Box
      className="welcome-section-cards"
      ref={ref}
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'auto auto' },
        pb: 0,
        position: 'relative'
      }}
    >
      <ImageContentColumn />
      <CopyContentColumn />
    </Box>
  )
}

WelcomeSectionCards.propTypes = {}

export default WelcomeSectionCards
