import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grow,
  Typography
} from '@mui/material'

import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'

const CTACard = () => {
  const { sendEvent } = useAnalytics()

  return (
    <Grow in>
      <Card
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent'
        }}
      >
        <Box sx={{ height: 50, position: 'relative', width: 1 }}>
          <Box
            alt="card call to action"
            component="img"
            sizes="50px 100%"
            src="/images/card-cta.jpg"
          />
          <Box
            sx={{
              background: 'url(/images/you-logo.svg)',
              backgroundPosition: 'center 5px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '22px',
              bgcolor: 'school.secondary',
              borderRadius: '50%',
              bottom: 'calc(-50% + 10px)',
              height: 32,
              left: 'calc(50% - 16px)',
              position: 'absolute',
              width: 32
            }}
          />
        </Box>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 16
            }}
          >
            <Typography variant="cardTitle">
              <FormattedMessage
                defaultMessage="There's more to explore..."
                id="FeTghm"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                defaultMessage="Create a free, confidential account to explore more."
                id="gQcS22"
              />
            </Typography>
            <CardActions variant="center">
              <Button
                LinkComponent={Link}
                onClick={() =>
                  sendEvent('block_interactions', {
                    // eslint-disable-next-line quotes
                    block_title: "There's more to Explore CTA",
                    click_element: 'Link',
                    // eslint-disable-next-line quotes
                    element_title: "There's more to Explore CTA",
                    path: window.location.pathname
                  })
                }
                to="/sign-up"
              >
                <FormattedMessage defaultMessage="Onward" id="61unQs" />
              </Button>
            </CardActions>
          </Box>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default CTACard
