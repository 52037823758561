import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import diffInDays from './diffInDays'
import * as R from 'ramda'
import { Box, Typography } from '@mui/material'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'

const GoalStatus = ({ dueDate = null, startDate = null, ...props }) => {
  const intl = useIntl()
  const today = new Date()
  const dateDue = new Date(dueDate)
  const daysRemaining = diffInDays(dateDue, today)

  const dueDateStatus = R.cond([
    [
      R.lt(0),
      R.always({
        color: 'red',
        text: intl.formatMessage(
          {
            defaultMessage: 'Overdue by {days} Days',
            id: 'B/RKEq',
          },
          { days: Math.abs(daysRemaining) }
        ),
      }),
    ],
    [
      R.equals(0),
      R.always({
        color: 'green',
        text: intl.formatMessage({
          defaultMessage: 'Due Today',
          id: '6z29NR',
        }),
      }),
    ],
    [
      R.gt(0),
      R.always({
        color: 'green',
        text: intl.formatMessage(
          {
            defaultMessage: 'Due in {days} Days',
            id: 'XSV7Gl',
          },
          { days: Math.abs(daysRemaining) }
        ),
      }),
    ],
    [
      R.T,
      R.always({
        color: '#737373',
        text: intl.formatMessage(
          {
            defaultMessage: 'Ongoing since {dueDate}',
            id: '4kWy6s',
          },
          {
            dueDate: intl.formatDate(startDate, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          }
        ),
      }),
    ],
  ])

  const goalStatus = R.not(R.isNil(dueDate))
    ? dueDateStatus(daysRemaining)
    : {
        color: '#737373',
        text: intl.formatMessage(
          {
            defaultMessage: 'Ongoing since {date}',
            id: 'bE1rph',
          },
          {
            date: intl.formatDate(startDate, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          }
        ),
      }

  return (
    <Box
      alignItems="center"
      color={goalStatus.color}
      display="flex"
      flexDirection="row"
      {...props}
    >
      <WatchLaterRoundedIcon />
      <Typography fontWeight="bold" ml=".5em" variant="subtitle1">
        {goalStatus.text}
      </Typography>
    </Box>
  )
}

GoalStatus.propTypes = {
  dueDate: PropTypes.string,
  startDate: PropTypes.string,
}

export default GoalStatus
