import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import styles from './WelcomeFooter.module.scss'
import { Box, Stack, Typography } from '@mui/material'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

const WelcomeFooter = () => {
  const { enable_about_page, name } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()
  const { isBusiness, isWellpower, isCollective } = useCurrentAppType()

  const getPartnershipBusiness = () => (
    <Typography>
      Created in partnership with {name} and Grit Digital Health
    </Typography>
  )

  const getPartnershipMHCD = sendKeenEvent => (
    <FormattedMessage
      defaultMessage="Created in partnership by Wellpower and {grit}"
      id="4+yVnh"
      values={{
        grit: (
          <Typography
            color="white"
            component={Link}
            onClick={() => sendKeenEvent('Grit Digital Health', 'Link')}
            target="_blank"
            to="https://www.gritdigitalhealth.com"
          >
            Grit Digital Health
          </Typography>
        )
      }}
    />
  )

  const getPartnership = sendKeenEvent => {
    if (isBusiness) return getPartnershipBusiness(sendKeenEvent)
    if (isWellpower) return getPartnershipMHCD(sendKeenEvent)
  }

  const sendKeenEvent = (element_title, click_element) =>
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'footer'
    })

  const AboutBox = () => (
    <Box
      alignItems="center"
      color="white"
      display="flex"
      flexDirection="column"
      m="0 auto"
      position="relative"
      py="10%"
      zIndex="1"
    >
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Still curious?" id="riqcFX" />
      </Typography>
      <Typography my="1.5em" variant="body1">
        <FormattedMessage
          defaultMessage="Get the story behind {org}."
          id="Z7hrDA"
          values={{ org: name }}
        />
      </Typography>
      <Typography
        color="white"
        component={Link}
        mx={2}
        onClick={() => {
          sendKeenEvent('About YOU', 'Link')
          GA.click('Welcome-Footer-About-YOU', 'Click')
        }}
        textAlign="center"
        to="/about-you"
        variant="secondary"
      >
        <FormattedMessage
          defaultMessage="About YOU | {name}"
          id="OQ12ap"
          values={{
            name: name
          }}
        />
      </Typography>
    </Box>
  )

  return (
    <Box
      bgcolor={isCollective ? 'collective.light' : 'secondary.main'}
      className={styles['welcome-footer']}
      display="flex"
      flexDirection="column"
      id="welcome-footer"
      overflow="hidden"
      position="relative"
    >
      <Box
        sx={{
          bgcolor: 'school.primary',
          position: 'absolute',
          pt: '200%',
          right: '0px',
          transform: 'rotateZ(-2deg)',
          transformOrigin: 'top right',
          // eslint-disable-next-line sort-keys
          width: { xs: '105%', md: '102%' },
          zIndex: '0'
        }}
      />

      <Box className={styles['hero-hash-circle-container']}>
        <Box className={styles['hero-hash-circle']} />
      </Box>

      <Box className={styles['cards-hash-square-container']}>
        <Box className={styles['cards-hash-square']} />
      </Box>

      {enable_about_page && <AboutBox />}

      <Stack
        alignItems="center"
        className={styles['welcome-footer_bottom-footnotes']}
        color="secondary.main"
        mb="10px"
        opacity="0.7"
        p={enable_about_page ? '0 5%' : '20% 0 0 0'}
        zIndex="1"
      >
        <Box color="white" fontSize="14px" fontWeight="bold">
          {getPartnership(sendKeenEvent)}
        </Box>

        <Typography
          color="white"
          fontSize="14px"
          my={1}
          textAlign="center"
          width="80%"
        >
          © {new Date().getFullYear()}{' '}
          <FormattedMessage
            defaultMessage="by Grit Digital Health, LLC. All rights reserved. Grit does not claim copyright in third party materials on this site."
            id="9Nki3t"
          />
        </Typography>

        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          {process.env.REACT_APP_PUBLIC_ACCESSIBILITY_DOC && (
            <>
              <Typography
                color="white"
                component={Link}
                fontSize="14px"
                onClick={() => sendKeenEvent('Accessibility', 'Link')}
                p={0}
                textDecoration="underline"
                textTransform="none"
                to="/accessibility"
              >
                <FormattedMessage defaultMessage="Accessibility" id="fgCBG8" />
              </Typography>
              <Typography color="white">|</Typography>
            </>
          )}
          <Typography
            color="white"
            component={Link}
            fontSize="14px"
            onClick={() => sendKeenEvent('Privacy Policy', 'Link')}
            p={0}
            textDecoration="underline"
            textTransform="none"
            to="/privacy-policy"
          >
            <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
          </Typography>
          <Typography color="white">|</Typography>
          <Typography
            color="white"
            component={Link}
            fontSize="14px"
            onClick={() => sendKeenEvent('Terms of Use', 'Link')}
            p={0}
            textDecoration="underline"
            textTransform="none"
            to="/terms-and-conditions"
          >
            <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
          </Typography>
          <Typography color="white">|</Typography>
          <Typography
            color="white"
            component={Link}
            fontSize="14px"
            onClick={() => sendKeenEvent('Feedback', 'Link')}
            p={0}
            textDecoration="underline"
            textTransform="none"
            to="/feedback"
          >
            <FormattedMessage defaultMessage="Website Feedback" id="1678y9" />
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default WelcomeFooter
