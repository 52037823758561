import SocialSSOSignUpForm from './SocialSSOSignUpForm'
import { prop } from 'ramda'
import { connect } from 'react-redux'

const mapStateToProps = ({ authentication }) => ({
  consent: prop('consent', authentication),
  location: prop('location', authentication),
  responderType: prop('responderType', authentication),
  userType: prop('userType', authentication),
})

export default connect(mapStateToProps)(SocialSSOSignUpForm)
