import { FormattedMessage } from 'react-intl'
import { useAnalytics } from 'hooks'
import { useUpdateUserMutation } from 'api/userApi'
import { not, equals, isNil, and, prop } from 'ramda'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { updateUserData } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const SettingsPage = () => {
  const dispatch = useDispatch()
  const {
    email_opt_in,
    id: userId,
    is_secondary_user,
    research_opted_in
  } = useSelector(prop('user'))

  const { id: schoolId } = useSelector(prop('school'))

  const { sendEvent } = useAnalytics()

  const [updateUser] = useUpdateUserMutation()

  if (isNil(userId))
    return (
      <Stack data-testid="skeleton" spacing={2}>
        <Stack spacing={1}>
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={212} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton height={26} variant="rectangular" width={250} />
          <Skeleton height={26} variant="rectangular" width={300} />
          <Skeleton height={26} variant="rectangular" width={212} />
          <Skeleton height={26} variant="rectangular" width={300} />
          <Skeleton height={26} variant="rectangular" width={212} />
        </Stack>
      </Stack>
    )

  return (
    <Stack spacing={1.5}>
      <Stack spacing={1}>
        <Typography component="h2" variant="cardTitle">
          <FormattedMessage
            defaultMessage="Notification Preferences"
            id="4tJqOL"
          />
        </Typography>

        <Typography variant="body2">
          <FormattedMessage
            defaultMessage="Occasional, personalized well-being tips via email (no spam 🤓)"
            id="MCcBcc"
          />
        </Typography>
        <FormGroup sx={{ px: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={email_opt_in}
                color="info"
                onChange={() => {
                  updateUser({ email_opt_in: not(email_opt_in) })
                  dispatch(
                    updateUserData({
                      name: 'email_opt_in',
                      value: not(email_opt_in)
                    })
                  )
                  sendEvent('you_email_opt_in', {
                    email_opt_in: not(email_opt_in),
                    opt_in_element: 'Profile'
                  })
                }}
              />
            }
            data-testid="email"
            label={
              <FormattedMessage defaultMessage="Yes, sign me up" id="Oe17me" />
            }
          />
        </FormGroup>

        {and(equals(1, schoolId), not(is_secondary_user)) && (
          <>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="Research opportunities to help improve this site experience"
                id="/VnMUf"
              />
            </Typography>
            <FormGroup sx={{ px: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={research_opted_in}
                    color="info"
                    onChange={() => {
                      updateUser({ research_opted_in: not(research_opted_in) })
                      dispatch(
                        updateUserData({
                          name: 'research_opted_in',
                          value: not(research_opted_in)
                        })
                      )
                    }}
                  />
                }
                data-testid="research"
                label={
                  <FormattedMessage
                    defaultMessage="Yes, sign me up"
                    id="Oe17me"
                  />
                }
              />
            </FormGroup>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default SettingsPage
