/* eslint-disable sort-keys */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import { slugify } from 'utils/helpers'
import parse from 'html-react-parser'
import { equals, isNil, not, prop } from 'ramda'
import { Link } from 'react-router-dom'

const Quicklinks = ({ rawHtml }) => {
  const [activeQuicklink, setActiveQuicklink] = useState(null)

  const htmlSplit = rawHtml.match(/<h(.)>.*?<\/h\1>/g)
  const sideAnchors = not(isNil(htmlSplit))
    ? htmlSplit.map(tag => tag.slice(4, -5))
    : ''

  const sections = rawHtml.split('<h3>').map((content, index) => {
    const id = slugify(prop(index, sideAnchors))
    return {
      content: `<h3 id="${id}">${content}`,
      link: `#${id}`
    }
  })

  const wrapperStyle = {
    '& > h1': {
      fontSize: '50px',
      lineHeight: '43px'
    },
    '& > hr': {
      display: 'none'
    },
    '& > img': {
      border: '1px solid #000',
      marginBottom: '16px',
      width: '100%'
    },
    '& > ol': {
      '& > li': {
        marginBottom: '16px'
      },
      listStyle: 'decimal',
      marginLeft: '32px'
    },
    '& > p': {
      lineHeight: '32px',
      marginBottom: '8px'
    },
    '& > table': {
      '& > td': {
        border: '1px solid #000',
        borderCollapse: 'collapse',
        padding: '10px',
        textAlign: 'center'
      },
      '& > thead': {
        fontWeight: 'bold'
      },
      '& > thead, tbody': {
        width: '100%'
      },
      border: '1px solid #000',
      borderCollapse: 'collapse',
      marginBottom: '48px',
      width: '100%'
    },
    '& > ul': {
      '& > li': {
        '& > .li-header': {
          fontWeight: 'bold',
          marginBottom: '8px'
        },
        '& > ul': {
          listStyle: 'circle'
        },
        marginBottom: '16px'
      },
      listStyle: 'inherit'
    },
    display: 'grid',
    fontFamily: 'mark-ot',
    gridGap: '16px',
    gridTemplateColumns: { xs: '1fr', md: '3fr 7fr' },
    position: 'relative'
  }

  const quicklinksWrapStyle = {
    display: { xs: 'none', md: 'flex' },
    height: 'calc(100vh - 69px)',
    overflow: 'auto',
    position: 'sticky',
    px: 3,
    py: 2,
    top: '69px'
  }

  const quicklinksStyle = active => ({
    color: 'primary.main',
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
    fontFamily: active ? 'mark-ot-bold' : 'mark-ot',
    letterSpacing: '0.5px',
    lineHeight: '22px',
    textDecoration: 'none'
  })

  return (
    <Box sx={{ ...wrapperStyle }}>
      <Stack spacing={2} sx={{ ...quicklinksWrapStyle }}>
        {sideAnchors
          .map((link, index) => (
            <Typography
              component={Link}
              key={index}
              onClick={() => setActiveQuicklink('#' + slugify(link))}
              sx={{
                ...quicklinksStyle(equals(activeQuicklink, `#${slugify(link)}`))
              }}
              to={'#' + slugify(link)}
              variant="link"
            >
              {link}
            </Typography>
          ))
          .slice(1)}
      </Stack>
      <Box
        sx={{
          overflow: 'auto',
          pl: { xs: 2, md: 0 },
          pr: { xs: 2, md: 3 },
          py: { xs: 1.5, sm: 3, md: 4 }
        }}
      >
        {sections.map((section, index) => (
          <Box key={index}>{parse(section.content)}</Box>
        ))}
      </Box>
    </Box>
  )
}

Quicklinks.propTypes = {
  rawHtml: PropTypes.string.isRequired
}

export default Quicklinks
