import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { svg_chat_bubble } from 'icons'
import ScreenReaderMessage from './ScreenReaderMessage'
import quotesImg from 'assets/images/icons/quotes.svg'
import { hexToRGBA } from 'utils/helpers'
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'

const TipsCard = ({
  tipsUrl,
  photo,
  tipsTitle,
  categoryTitle,
  highlightColor,
  handleAnalytics
}) => (
  <Card>
    <CardActionArea component={Link} onClick={handleAnalytics} to={tipsUrl}>
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <CardMedia component="img" height={190} src={photo} />
        <Box
          className="gradient"
          sx={{
            backgroundImage: `linear-gradient(318.7deg, ${hexToRGBA(
              highlightColor,
              0.7
            )}, ${hexToRGBA(highlightColor, 0)} 67.65%)`,
            height: '100%',
            left: 0,
            opacity: 0.75,
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2
          }}
        ></Box>
      </Box>
      <ScreenReaderMessage>
        <FormattedMessage
          defaultMessage="Link to {headline}"
          id="CajdAF"
          values={{ headline: tipsTitle }}
        />
      </ScreenReaderMessage>

      <Stack p={1.5}>
        <Box position="relative">
          <Box
            sx={{
              '& > svg': {
                fill: 'school.primary'
              },
              bottom: -10,
              position: 'absolute',
              right: 0,
              zIndex: 3
            }}
          >
            {svg_chat_bubble}
          </Box>
          <Box
            alt="Student Tips"
            className="quotes"
            component="img"
            src={quotesImg}
            sx={{
              bottom: 0,
              position: 'absolute',
              right: 20,
              top: -32,
              zIndex: 4
            }}
          />
        </Box>
        <Typography color="primary.main" variant="overline">
          {categoryTitle}
        </Typography>
        <Typography variant="cardTitle">{tipsTitle}</Typography>
      </Stack>
    </CardActionArea>
  </Card>
)

TipsCard.propTypes = {
  categoryTitle: PropTypes.string.isRequired,
  handleAnalytics: PropTypes.func.isRequired,
  highlightColor: PropTypes.string,
  photo: PropTypes.string.isRequired,
  tipsTitle: PropTypes.string.isRequired,
  tipsUrl: PropTypes.string.isRequired
}

TipsCard.defaultProps = {
  highlightColor: null
}

export default TipsCard
