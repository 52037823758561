import { FormattedMessage } from 'react-intl'

export const schoolGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: 'Master job interview skills',
  stepDetail: 'Schedule a mock interview with a friend/mentor',
  stepsCompleted: 1,
  totalSteps: 3,
}

export const businessGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: 'Master job interview skills',
  stepDetail: 'Schedule a mock interview with a friend/mentor',
  stepsCompleted: 1,
  totalSteps: 3,
}

export const veteranGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: 'Get on Top of my Budget',
  stepDetail: 'Define needs vs. wants',
  stepsCompleted: 1,
  totalSteps: 3,
}

export const responderGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: 'Get on top of my budget',
  stepDetail: 'Log expenses for 2 weeks',
  stepsCompleted: 1,
  totalSteps: 3,
}

export const mhcdGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: (
    <FormattedMessage defaultMessage="Get on Top of my Budget" id="uKVSL9" />
  ),
  stepDetail: (
    <FormattedMessage defaultMessage="Log expenses for two weeks" id="ZJxqx1" />
  ),
  stepsCompleted: 1,
  totalSteps: 3,
}

export const collectiveGoalProps = {
  activeGoal: false,
  //dueDate: '08/02/2020',
  goalTitle: (
    <FormattedMessage
      defaultMessage="Take time to relax or meditate"
      id="pJQ4N3"
    />
  ),
  stepDetail: (
    <FormattedMessage
      defaultMessage="meditate once a day for 5 minutes."
      id="/DaIJd"
    />
  ),
  stepsCompleted: 1,
  totalSteps: 3,
}

export const nlcGoalProps = {
  activeGoal: false,
  goalTitle: 'Try new ways to De-Stress',
  //dueDate: '08/02/2020',
  stepDetail:
    'Share gratitude with a friend or family member. This is shown to decrease stress and anxiety.',
  stepsCompleted: 1,
  totalSteps: 3,
}
