import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'

const Onboarding = () => (
  <Container sx={{ mt: 4, pb: 12 }}>
    <Outlet />
  </Container>
)

export default Onboarding
