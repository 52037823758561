/* eslint-disable sort-keys */
import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Collapse,
  Divider,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'

import SpaRoundedIcon from '@mui/icons-material/SpaRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { FormattedMessage, useIntl } from 'react-intl'
import { prop, sortBy, not, reverse, slice, or, length, isEmpty } from 'ramda'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'

const ExploreTopics = ({ userDomains = [] }) => {
  const intl = useIntl()
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { sendEvent } = useAnalytics()

  if (isEmpty(userDomains)) return null

  const uniqueTitles = sortBy(prop('sort'))

  const domainSubtext = {
    Matter: intl.formatMessage({
      defaultMessage: 'connections & purpose',
      id: 'XY6yEK'
    }),
    Succeed: intl.formatMessage({
      defaultMessage: 'life & career',
      id: 'xkryKP'
    }),
    Thrive: intl.formatMessage({
      defaultMessage: 'mind & body',
      id: 'Q2hKy0'
    })
  }

  const [expanded, setExpanded] = useState(false)

  return (
    // eslint-disable-next-line sort-keys
    <Box sx={{ maxWidth: 1024, mt: { xs: 5, md: 9 }, mx: 'auto', width: 1 }}>
      <Box>
        <Stack alignItems="center" direction="row" spacing={1}>
          <SpaRoundedIcon />
          <Typography variant="subtitle1">
            <FormattedMessage
              defaultMessage="All Well-Being Topics"
              id="MXtbhk"
            />
          </Typography>
        </Stack>
        <Divider />
        <Box
          sx={{
            display: 'grid',
            gridGap: 16,
            // eslint-disable-next-line sort-keys
            gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
            px: 3
          }}
        >
          {userDomains.map(
            ({ id, title, subdomains, frontend_url_slug: slug }) => (
              <Box key={id}>
                <Box
                  sx={{
                    alignItems: { xs: 'center', md: 'flex-start' },
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gridGap: 4
                  }}
                >
                  <Typography noWrap variant="subtitle3">
                    <FormattedMessage
                      defaultMessage="{title}"
                      id="2YgLVn"
                      values={{ title }}
                    />
                    {not(isTablet) && ' /'}
                  </Typography>
                  <Typography noWrap variant="domainSubtitle">
                    {prop(title, domainSubtext)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'grid', gridGap: 8, mt: 1 }}>
                  {slice(0, 3, reverse(uniqueTitles(subdomains))).map(
                    ({ title, frontend_url_slug }) => (
                      <Typography
                        aria-label={`${slug} ${frontend_url_slug}`}
                        color="primary.main"
                        component={Link}
                        display="block"
                        key={title}
                        onClick={() =>
                          sendEvent('block_interactions', {
                            block_title: 'OFD Explore All Well-Being Topics',
                            click_element: 'Link',
                            element_title: title,
                            path: window.location.pathname
                          })
                        }
                        to={`/public/${slug}/${frontend_url_slug}`}
                        variant="body2"
                      >
                        {title}
                      </Typography>
                    )
                  )}
                  <Collapse in={or(expanded, isTablet)}>
                    <Box sx={{ display: 'grid', gridGap: 8 }}>
                      {slice(
                        3,
                        length(subdomains),
                        reverse(uniqueTitles(subdomains))
                      ).map(({ title, frontend_url_slug }) => (
                        <Typography
                          aria-label={`${slug} ${frontend_url_slug}`}
                          color="primary.main"
                          component={Link}
                          display="block"
                          key={title}
                          onClick={() =>
                            sendEvent('block_interactions', {
                              block_title: 'OFD Explore All Well-Being Topics',
                              click_element: 'Link',
                              element_title: title,
                              path: window.location.pathname
                            })
                          }
                          to={`/public/${slug}/${frontend_url_slug}`}
                          variant="body2"
                        >
                          {title}
                        </Typography>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            mt: 3
          }}
        >
          <Button
            endIcon={
              expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
            }
            onClick={() => setExpanded(not(expanded))}
            variant="text"
          >
            {expanded ? (
              <FormattedMessage defaultMessage="show less" id="vb8spb" />
            ) : (
              <FormattedMessage defaultMessage="show more" id="IRPFF9" />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

ExploreTopics.propTypes = {
  userDomains: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ExploreTopics
