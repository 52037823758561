import { useState } from 'react'
import { Box, Card, CardContent, Divider, Typography } from '@mui/material'

import MasonryWrapper from 'components/common/MasonryWrapper'
import StoryCard from '../components/cards/StoryCard'
import SortMenu from '../components/SortMenu'
import CTACard from '../components/cards/CTACard'
import CTASection from '../components/CTASection'
import BreadCrumbs from '../components/BreadCrumbs'
import LoadMoreButton from 'components/common/LoadMoreButton'

import { FormattedMessage, useIntl } from 'react-intl'
import useCurrentDomain from 'hooks/useCurrentDomain'
import { equals, slice, prop, not, gt, length, isNil, inc } from 'ramda'
import { useGetPublicCardsQuery } from 'api/publicApi'
import useHandlePublicRoutes from 'hooks/useHandlePublicRoutes'
import { useSelector } from 'react-redux'

const Stories = () => {
  useHandlePublicRoutes()
  const { tips_data } = useSelector(prop('school'))
  const { currentDomain } = useCurrentDomain()
  const intl = useIntl()
  const [sortMethod, setSortMethod] = useState('score')
  const [page, setPage] = useState(1)

  const dataSetInt = equals('score', sortMethod) ? 12 : 11

  const { data, isFetching = true } = useGetPublicCardsQuery(
    `org_domain=${currentDomain}&filter[types]=${12}&page[take]=${
      page * dataSetInt
    }&sort[method]=${sortMethod}`
  )

  return (
    <Box
      component="article"
      id="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 8
      }}
    >
      <Box
        sx={{
          background: `url(${prop('tips_hero_image', tips_data)})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 248,
          width: 1
        }}
      />
      <Box>
        <Box
          sx={{
            margin: '0 auto',
            maxWidth: 1280,
            px: 2,
            width: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 32,
              mb: 4
            }}
          >
            <BreadCrumbs
              links={[
                {
                  href: '/public',
                  label: intl.formatMessage({
                    defaultMessage: 'Explore',
                    id: '7JlauX'
                  })
                },
                {
                  href: '',
                  label: intl.formatMessage({
                    defaultMessage: 'Stories',
                    id: 'Ldxym4'
                  })
                }
              ]}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 24
              }}
            >
              <Typography variant="h1">
                <FormattedMessage defaultMessage="Stories" id="Ldxym4" />
              </Typography>
              <Card>
                <Divider color="school.primary" variant="card accent" />
                <CardContent>
                  <Typography variant="body1">
                    <FormattedMessage
                      defaultMessage="{content}"
                      id="zb24ln"
                      values={{
                        content: prop('tips_context_copy', tips_data)
                      }}
                    />
                  </Typography>
                </CardContent>
              </Card>
              <SortMenu
                filter={sortMethod}
                options={[
                  {
                    label: 'Most Relevant To Me',
                    value: 'score'
                  },
                  {
                    label: 'Most Recent',
                    value: 'recency'
                  },
                  {
                    label: 'Most Popular',
                    value: 'popularity'
                  }
                ]}
                setFilter={setSortMethod}
              />
              {not(isNil(data)) && (
                <MasonryWrapper>
                  {slice(0, 2, data).map(card => (
                    <StoryCard
                      blockTitle="OFD Stories Cards"
                      key={prop('id', card)}
                      {...card}
                    />
                  ))}
                  {not(equals('score', sortMethod)) && <CTACard />}
                  {slice(2, Infinity, data).map(card => (
                    <StoryCard
                      blockTitle="OFD Stories Cards"
                      key={prop('id', card)}
                      {...card}
                    />
                  ))}
                </MasonryWrapper>
              )}
              {not(gt(length(data) % dataSetInt, 0)) && (
                <LoadMoreButton
                  handleClick={() => setPage(inc(page))}
                  loading={isFetching}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <CTASection />
    </Box>
  )
}

export default Stories
