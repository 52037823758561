import PropTypes from 'prop-types'
import { equals, inc, prop, values } from 'ramda'
import ProgressBar from 'components/common/ProgressBar'
import ScreenReaderMessage from 'components/common/ScreenReaderMessage'
import { Box, Button, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'

// TODO: when moving to MUI change this to use Linear Progress
// See below commented out section
const PollResults = ({ answers = null, content, selected = null }) => {
  const { sendEvent } = useAnalytics()

  const calcWidth = idx =>
    `${
      (answers[inc(idx)] / Object.values(answers).reduce((a, b) => a + b)) * 100
    }%`

  return (
    <Box
      aria-readonly={true}
      role="listbox"
      sx={{ display: 'flex', flexDirection: 'column', gridGap: 16, my: 2 }}
    >
      {values(answers).map((_, i) => (
        <Box key={i} role="option">
          <Box width={`calc(${calcWidth(i)} + 32px)`}>
            <ProgressBar
              completed={answers[inc(i)]}
              selected={equals(selected, i)}
              total={Object.values(answers).reduce((a, b) => a + b)}
            />
          </Box>
          <ScreenReaderMessage>
            {equals(inc(selected), inc(i))
              ? 'answered like you: '
              : 'of others answered: '}
          </ScreenReaderMessage>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="{answer}"
              id="MFRRt+"
              values={{ answer: prop(i, prop('answers', content)) }}
            />
          </Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: 16
        }}
      >
        <Typography variant="body2">
          <FormattedMessage
            defaultMessage="Want to take the full Self Check? Create your free and confidential account to take the full assessment."
            id="BjBngV"
          />
        </Typography>
        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'OFD Subdomain Poll',
              click_element: 'Link',
              element_title: 'Sign Up CTA',
              path: window.location.pathname
            })
          }
          to="/sign-up"
        >
          <FormattedMessage defaultMessage="Join the community" id="X1+nqY" />
        </Button>
      </Box>
    </Box>
  )
}

PollResults.propTypes = {
  answers: PropTypes.shape({}),
  content: PropTypes.shape({}).isRequired,
  selected: PropTypes.number
}

export default PollResults
