import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { svg_goals } from 'icons'
import { TYPE_RESOURCE } from 'utils/card-functions'
import {
  assoc,
  equals,
  gt,
  isEmpty,
  length,
  not,
  prop,
  propEq,
  sortBy
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import {
  useDeleteGoalMutation,
  useGetGoalQuery,
  useUpdateGoalMutation
} from 'api/goalsApi'
import GA from 'services/gaService'
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Link, useParams } from 'react-router-dom'

import GoalDateDisplay from '../components/GoalDateDisplay'
import GoalStepDisplay from './components/GoalStepDisplay'
import AddGoalStepDisplay from './components/AddGoalStepDisplay'
import AccessibleModal from 'components/common/AccessibleModal'
import RecommendedCards from 'components/common/RecommendedCards'

const ViewGoal = () => {
  const { id } = useParams()

  const [isOpen, setIsOpen] = useState(false)

  const { data: goal = {} } = useGetGoalQuery(id)

  const params = {
    subdomain: [prop('subdomain_id', goal)],
    take: 3,
    type: TYPE_RESOURCE
  }

  const { data: recommendedContent, isFetching } = useGetCardsQuery(params)
  const [updateGoal] = useUpdateGoalMutation()
  const [deleteGoal] = useDeleteGoalMutation()

  const completeGoal = () => {
    updateGoal({
      body: assoc('completed', true, goal),
      id: prop('id', goal)
    })
    GA.track('Update-Goal', 'Submit')
  }

  useEffect(() => {
    if (not(isEmpty(goal))) {
      const stepsToComplete = prop('steps', goal).filter(
        propEq(false, 'completed')
      )
      const shouldShowPrompt = equals(length(stepsToComplete), 0)
      if (shouldShowPrompt) setIsOpen(true)
    }
  }, [goal])

  return (
    <>
      <Button
        LinkComponent={Link}
        startIcon={<ChevronLeftRoundedIcon />}
        to="/goals?active=my-goals"
        variant="text"
      >
        <FormattedMessage defaultMessage="back" id="yLtLwT" />
      </Button>
      <AccessibleModal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title={
          <FormattedMessage defaultMessage="Congratulations!" id="lOzDTt" />
        }
      >
        <Typography variant="subtitle1">
          <FormattedMessage
            defaultMessage="Looks like you completed all your steps, would you like to mark this goal as completed?"
            id="kc1Y2T"
          />
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button
            LinkComponent={Link}
            onClick={() => completeGoal()}
            to="/goals/complete"
          >
            <FormattedMessage defaultMessage="Yes please" id="VcwrfF" />
          </Button>
          <Button onClick={() => setIsOpen(false)} variant="outlined">
            <FormattedMessage defaultMessage="No thank you" id="hCnv0P" />
          </Button>
        </Stack>
      </AccessibleModal>
      <Stack
        spacing={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          px: 3
        }}
      >
        {isFetching ? (
          <Skeleton
            height={650}
            sx={{ mb: 7 }}
            variant="rectangular"
            width="100%"
          />
        ) : (
          <>
            <Typography variant="h3">
              <Box
                sx={{
                  '& > svg': {
                    fill: 'red'
                  },
                  display: 'inline',
                  height: 25,
                  mr: 1
                }}
              >
                {svg_goals}
              </Box>
              {prop('title', goal)}
            </Typography>
            <GoalDateDisplay goal={goal} />
            <Button
              LinkComponent={Link}
              onClick={completeGoal}
              to="/goals/complete"
            >
              <FormattedMessage
                defaultMessage="Mark goal as complete"
                id="JvPa+I"
              />
            </Button>
            <Button
              endIcon={<EditRoundedIcon />}
              LinkComponent={Link}
              to={`/goals/edit/${prop('id', goal)}`}
              variant="text"
            >
              <FormattedMessage defaultMessage="edit goal" id="yuDS3+" />
            </Button>
            {gt(length(prop('steps', goal)), 0) &&
              sortBy(prop('order'))(prop('steps', goal)).map(step => (
                <GoalStepDisplay
                  goal={goal}
                  key={prop('id', step)}
                  step={step}
                />
              ))}

            <AddGoalStepDisplay goal={goal} />

            <Button
              LinkComponent={Link}
              onClick={completeGoal}
              to="/goals/complete"
            >
              <FormattedMessage
                defaultMessage="Mark goal as complete"
                id="JvPa+I"
              />
            </Button>
            <Button
              LinkComponent={Link}
              onClick={() => deleteGoal(goal)}
              to="/goals?active=my-goals"
              variant="text"
            >
              <FormattedMessage defaultMessage="Delete this goal" id="Vyw42+" />
            </Button>
          </>
        )}

        {recommendedContent && (
          <RecommendedCards
            blockTitle="goals recommended"
            recommendedCards={recommendedContent}
            type="resource"
          />
        )}
      </Stack>
    </>
  )
}

export default ViewGoal
