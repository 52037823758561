/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography
} from '@mui/material'

import { not, isNil, propEq, prop, path, find, and } from 'ramda'
import useCurrentDomain from 'hooks/useCurrentDomain'
import {
  useGetPublicSubdomainQuery,
  useGetPublicUserDomainsQuery
} from 'api/publicApi'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHandlePublicRoutes } from 'hooks'
import { useParams } from 'react-router-dom'

import PollCard from 'pages/public/components/cards/PollCard'
import ResourceBlock from './components/ResourceBlock'
import CTASection from 'pages/public/components/CTASection'
import RecommendedSubdomains from './components/RecommendedSubdomains'
import BreadCrumbs from 'pages/public/components/BreadCrumbs'

const Subdomain = () => {
  useHandlePublicRoutes()
  const { currentDomain } = useCurrentDomain()
  // const { locale } = useRouter()
  const { domain, subdomain } = useParams()
  const intl = useIntl()
  const [subdomainId, setSubdomainId] = useState(null)

  const {
    data: userDomains,
    fulfilledTimeStamp: hasFetchedDomains,
    refetch: refetchUserDomains
  } = useGetPublicUserDomainsQuery(currentDomain)

  useEffect(() => {
    if (not(isNil(userDomains))) {
      const foundDomain = find(propEq(domain, 'frontend_url_slug'), userDomains)
      setSubdomainId(
        prop(
          'id',
          find(
            propEq(subdomain, 'frontend_url_slug'),
            prop('subdomains', foundDomain)
          )
        )
      )
    }
  }, [userDomains, subdomain])

  const {
    data,
    fulfilledTimeStamp: hasFetchedSudomain,
    refetch: refetchSubdomains
  } = useGetPublicSubdomainQuery(subdomainId, {
    skip: isNil(subdomainId)
  })

  useEffect(() => {
    // If the fulfilled timestamps do exist and the locale is changed
    // refetch the domain and subdomain data so we can see translated content
    if (and(not(isNil(hasFetchedSudomain)), not(isNil(hasFetchedDomains)))) {
      refetchUserDomains()
      refetchSubdomains()
    }
    // }, [locale])
  }, [])

  const hasPoll = path(['poll_question', 'allow_as_poll_on_subdomain'], data)

  if (isNil(data)) return null

  return (
    <Stack component="article" id="main" spacing={1}>
      <Box
        sx={{
          height: 365,
          mb: { xs: 4, sm: 8.5 },
          width: 1
        }}
      >
        <img
          alt=""
          src={path(['subdomain', 'hero_image_url'], data)}
          style={{
            border: 'none',
            height: '100%',
            width: '100%'
          }}
        />
      </Box>
      <Box>
        <Container>
          <Box
            sx={{
              display: 'grid',
              gridGap: { xs: 16, md: 32 },
              gridTemplateColumns: { xs: '1fr', md: '1fr 350px' },
              mb: { xs: 4, md: hasPoll ? 0 : 3 }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 16
              }}
            >
              <BreadCrumbs
                links={[
                  {
                    href: '/public',
                    label: intl.formatMessage({
                      defaultMessage: 'Explore',
                      id: '7JlauX'
                    })
                  },
                  {
                    href: `/${domain}/${subdomain}`,
                    label: path(['subdomain', 'frontend_name'], data)
                  }
                ]}
              />
              <Typography variant="h1">
                <FormattedMessage
                  defaultMessage="{title}"
                  id="2YgLVn"
                  values={{
                    title: path(['subdomain', 'onboarding_title'], data)
                  }}
                />
              </Typography>
              <Card>
                <Divider color="school.primary" variant="card accent" />
                <CardContent>
                  <Typography variant="body1">
                    <FormattedMessage
                      defaultMessage="{content}"
                      id="zb24ln"
                      values={{
                        content: path(['subdomain', 'context_copy'], data)
                      }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            {hasPoll && <PollCard content={prop('poll_question', data)} />}
          </Box>
          <ResourceBlock {...prop('subdomain', data)} />
          <RecommendedSubdomains subdomainData={prop('subdomain', data)} />
        </Container>
        <CTASection />
      </Box>
    </Stack>
  )
}

export default Subdomain
