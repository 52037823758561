import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider, Stack } from '@mui/material'
import { inc, isNil, prop, reject } from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'

import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import UserFeedback from 'components/common/UserFeedback'
import PreparedCard from 'components/common/PreparedCardConnector'
import ErrorMessage from 'components/common/ErrorMessage'
import LoadMoreButton from 'components/common/LoadMoreButton'
import MasonryWrapper from 'components/common/MasonryWrapper'
import { useSelector } from 'react-redux'

const Resources = ({ domainId }) => {
  const intl = useIntl()
  const { location_id, temp_location_id } = useSelector(prop('user'))

  const [page, setPage] = useState(1)

  const locations = reject(isNil, [location_id, temp_location_id])

  const params = {
    campus_resource: true,
    domain: domainId,
    locations,
    skip: 0,
    take: page * 12
  }

  const { data: cards = [], error, isFetching } = useGetCardsQuery(params)

  const getTitleType = () => (
    <FormattedMessage defaultMessage="community " id="moJ6f3" />
  )

  const getType = () => <FormattedMessage defaultMessage="local " id="I/AhtK" />

  return (
    // eslint-disable-next-line sort-keys
    <Stack mb={3} pl={{ xs: 2, md: 4 }}>
      <MasonryWrapper>
        {cards.map(card => (
          <CardAnimationWrapper key={prop('id', card)}>
            <PreparedCard card={card} />
          </CardAnimationWrapper>
        ))}
      </MasonryWrapper>
      <LoadMoreButton
        handleClick={() => setPage(inc(page))}
        loading={isFetching}
      />

      <Divider sx={{ mt: 4 }} />
      <UserFeedback
        hasNeutral
        negativeTitle={
          <FormattedMessage
            defaultMessage="Seems like you know your {type}really well."
            id="Yzq0i5"
            values={{
              type: getTitleType()
            }}
          />
        }
        positiveTitle={
          <FormattedMessage
            defaultMessage="Awesome! These resources are here whenever you need them."
            id="RHgDKZ"
          />
        }
        subtitle={
          <FormattedMessage
            defaultMessage="Has this information increased your awareness of {type} resources?"
            id="xMSlqd"
            values={{
              type: getType()
            }}
          />
        }
      />

      {error && (
        <ErrorMessage
          hasError
          message={intl.formatMessage({
            defaultMessage: '{message}',
            id: 'PR2F1h',
            values: { message: error.message }
          })}
        />
      )}
    </Stack>
  )
}

Resources.propTypes = {
  domainId: PropTypes.number.isRequired
}

export default Resources
