import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const CardsMask = ({
  children,
  position = 'left',
  dynamicCards = false,
  ...props
}) => {
  const mask = '/images/welcome/mask-cards-right.svg'
  const maskLeft = '/images/welcome/mask-cards.svg'

  const maskStyles = {
    display: 'flex',
    maskImage: 'url(' + (position === 'left' ? maskLeft : mask) + ')',
    maskPosition: 'top center',
    maskRepeat: 'no-repeat',
    maskSize: 'contain'
  }

  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
        // eslint-disable-next-line sort-keys
        width: { xs: '87%', md: '60%', lg: '90%' },
        ...props
      }}
    >
      <Box
        sx={{
          ...maskStyles,
          bgcolor: 'school.secondary',
          pt: position === 'left' ? '140%' : '128%',
          width: '105%'
        }}
      />
      <Box
        sx={{
          ...maskStyles,
          bgcolor: 'school.primary',
          position: 'absolute',
          pt: '130%',
          right: position === 'left' ? '-2%' : '-8%',
          top: '-3%',
          width: '105%'
        }}
      />
      {position === 'left' ? (
        <Box
          sx={{
            left: '42%',
            ml: 2,
            position: 'absolute',
            top: '45%',
            transform: 'translate(-50%, -50%)',
            // eslint-disable-next-line sort-keys
            width: dynamicCards ? { xs: '70%', lg: '60%' } : '85%'
          }}
        >
          {children}
        </Box>
      ) : (
        <Box
          sx={{
            left: '50%',
            ml: 2,
            position: 'absolute',
            top: '55%',
            transform: 'translate(-50%, -50%)',
            // eslint-disable-next-line sort-keys
            width: { xs: '72%', xl: '65%' }
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

CardsMask.propTypes = {
  children: PropTypes.node.isRequired,
  dynamicCards: PropTypes.bool,
  position: PropTypes.string
}

export default CardsMask
