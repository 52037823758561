import PropTypes from 'prop-types'
import { deepmerge } from '@mui/utils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '.'
import { isNil, prop } from 'ramda'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useSelector } from 'react-redux'

const MuiThemeProvider = ({ children }) => {
  const {
    gradient_start_color,
    gradient_stop_color,
    button_font_color,
    primary_color,
    accent_color,
    secondary_color,
    action_color,
    action_2_color,
    action_color_2_reverse,
    action_reverse_color,
    flag_color,
    highlight_color,
    infoLink,
    tertiary_color
  } = useSelector(prop('school'))

  if (isNil(gradient_start_color)) return

  // Any components that include school color overrides must be here
  const schoolTheme = {
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            background: `linear-gradient(-180deg, ${gradient_start_color} 0%, ${gradient_stop_color} 99%)`,
            color: button_font_color
          },
          text: {
            color: theme.palette.secondary.dark
          }
        },
        variants: [
          {
            props: { variant: 'link-underline' },
            style: {
              fontFamily: 'mark-ot',
              fontSize: 18,
              letterSpacing: '0.75px',
              padding: '0',
              textDecoration: 'underline',
              textTransform: 'none'
            }
          },
          {
            props: { variant: 'card' },
            style: {
              display: 'block'
            }
          },
          {
            props: { variant: 'underline' },
            style: {
              backgroundColor: 'transparent',
              fontFamily: 'mark-ot',
              fontSize: 14,
              padding: 0,
              textDecoration: 'underline'
            }
          },
          {
            props: { variant: 'selfCheck' },
            style: {
              bgcolor: theme.palette.white,
              border: '2px solid',
              borderColor: theme.palette.primary.light,
              borderRadius: '8px',
              color: theme.palette.primary.main,
              fontFamily: 'mark-ot',
              textTransform: 'capitalize'
            }
          },
          {
            props: { variant: 'link' },
            style: {
              ':hover': {
                backgroundColor: 'transparent'
              },
              borderRadius: 4,
              display: 'block',
              fontFamily: 'mark-ot',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: '0.75px',
              padding: 0,
              textTransform: 'uppercase',
              width: '100%'
            }
          },
          {
            props: { variant: 'poll' },
            style: {
              ':hover': {
                backgroundColor: theme.palette.primary.light
              },
              backgroundColor: theme.palette.white,
              border: `2px solid ${theme.palette.primary.light}`,
              borderRadius: 8,
              color: theme.palette.secondary.dark,
              fontFamily: 'mark-ot',
              fontSize: 16,
              justifyContent: 'flex-start',
              padding: '12px 16px',
              textAlign: 'left',
              textTransform: 'capitalize',
              transition: 'all 0.2s ease-in-out'
            }
          },
          {
            props: { selected: true, variant: 'poll' },
            style: {
              backgroundColor: theme.palette.white,
              border: `2px solid ${primary_color}`,
              borderRadius: 8,
              color: theme.palette.secondary.dark,
              fontFamily: 'mark-ot',
              fontSize: 16,
              justifyContent: 'flex-start',
              padding: '12px 16px',
              textAlign: 'left',
              textTransform: 'capitalize',
              transition: 'all 0.2s ease-in-out'
            }
          },
          {
            props: { variant: 'underline' },
            style: {
              fontFamily: 'mark-ot-bold',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: '0.75px',
              paddingLeft: 0,
              paddingRight: 0,
              textDecoration: 'underline',
              textTransform: 'uppercase'
            }
          },
          {
            props: { selected: true, variant: 'text' },
            style: {
              fontFamily: 'mark-ot-bold'
            }
          },
          {
            props: { variant: 'nav' },
            style: {
              borderRadius: 0,
              color: theme.palette.secondary.dark,
              fontSize: 22,
              paddingBottom: 4,
              textTransform: 'none'
            }
          },
          {
            props: { variant: 'footer' },
            style: {
              ':disabled': {
                cursor: 'default',
                opacity: '0.65'
              },
              ':focus-visible': {
                outline: '2px solid',
                outlineColor: theme.palette.info.main
              },
              alignItems: 'center',
              border: '2px solid',
              borderColor: theme.palette.white,
              borderRadius: 4,
              color: theme.palette.white,
              display: 'flex',
              fontFamily: 'mark-ot-bold',
              fontSize: 14,
              letterSpacing: '0.75px',
              padding: '12px 20px',
              textDecoration: 'none',
              textTransform: 'none',
              width: 'fit-content'
            }
          },
          {
            props: { variant: 'filter' },
            style: {
              ':hover': {
                border: '2px solid'
              },
              border: '2px solid',
              borderColor: theme.palette.primary.light,
              borderRadius: 8,
              display: 'block',
              fontFamily: 'mark-ot',
              minWidth: 'unset',
              paddingBottom: 8,
              paddingTop: 8,
              textTransform: 'uppercase',
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'cta' },
            style: {
              ':hover': {
                backgroundColor: 'transparent'
              },
              borderRadius: 4,
              fontFamily: 'mark-ot-bold',
              fontSize: 18,
              fontWeight: 500,
              letterSpacing: '0.75px',
              padding: 0,
              textDecoration: 'underline',
              textTransform: 'none'
            }
          },
          {
            props: { variant: 'login' },
            style: {
              border: '2px solid white',
              color: 'white'
            }
          },
          {
            props: { variant: 'download-pdf' },
            style: {
              border: '2px solid white',
              color: 'white'
            }
          },
          {
            props: { variant: 'search' },
            style: {
              '@media (max-width: 1024px)': {
                fontSize: 14
              },
              backgroundColor: 'white',
              border: `1px solid ${theme.palette.secondary.main}`,
              color: theme.palette.secondary.dark,
              fontFamily: 'mark-ot',
              fontSize: 20,
              margin: '16px auto',
              textTransform: 'capitalize'
            }
          },
          {
            props: { variant: 'number' },
            style: {
              color: theme.palette.primary,
              fontFamily: 'mark-ot-bold',
              fontSize: 35,
              padding: '0 20px'
            }
          }
        ]
      },
      MuiCssBaseline: {
        styleOverrides: {
          // Styling for scrollbar
          // '::-webkit-scrollbar': {
          //   height: '4px',
          //   paddingBottom: '2px',
          //   paddingTop: '2px',
          //   width: '4px',
          // },
          // '::-webkit-scrollbar-thumb': {
          //   background: accent_color,
          //   border: `2px solid ${accent_color}`,
          //   borderRadius: 8,
          // },
          // '::-webkit-scrollbar-track': {
          //   background: '#FFFFFF',
          //   border: '1px solid #F4F4F4',
          // },
          a: {
            textDecoration: 'none'
          },
          body: {
            backgroundColor: '#FBFBFB'
          },
          html: {
            scrollBehavior: 'smooth'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          container: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          },
          paper: {
            borderTop: '11px solid',
            borderTopColor: accent_color
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: secondary_color
          }
        }
      }
    },
    palette: {
      school: {
        accent: accent_color,
        action: action_color,
        action2: action_2_color,
        action2Reverse: action_color_2_reverse,
        actionReverse: action_reverse_color,
        buttonFontColor: button_font_color,
        buttonGradient: `linear-gradient(-180deg, ${gradient_start_color} 0%, ${gradient_stop_color} 99%)`,
        flag: flag_color,
        gradientStart: gradient_start_color,
        gradientStop: gradient_stop_color,
        highlight: highlight_color,
        infoLink: infoLink,
        primary: primary_color,
        secondary: secondary_color,
        tertiary: tertiary_color
      }
    }
  }

  const combined = createTheme(deepmerge(schoolTheme, theme))

  return (
    <ThemeProvider theme={combined}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

MuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default MuiThemeProvider
