/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MiniSkillsCard from 'components/common/MiniSkillsCard'
import { Box, Divider, Typography } from '@mui/material'
import BuildRoundedIcon from '@mui/icons-material/BuildRounded'

const LearnNewSkillsBlock = ({ skillCards, containsPolling = false }) => (
  <Box
    sx={{
      display: 'grid',
      mb: containsPolling ? { xs: 3, md: 4 } : 0,
    }}
  >
    <Box
      sx={{
        display: 'grid',
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <BuildRoundedIcon />
        <Typography color="secondary.dark" ml="10px" variant="subtitle1">
          <FormattedMessage defaultMessage="Learn New Skills" id="H2i23P" />
        </Typography>
      </Box>
      <Divider />
    </Box>
    <Box
      sx={{
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: {
          xs: '1fr',
          lg: containsPolling ? '1fr' : 'repeat(2, 1fr)',
          xl: containsPolling ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
        },
        mx: 'auto',
        my: 3,
      }}
    >
      {skillCards
        .map((card, i) => (
          <MiniSkillsCard
            data-testid={i}
            headline={card.title}
            imageUrl={card.photo.thumb_url}
            key={i}
            resourceUrl={`/article/${card.id}/${encodeURIComponent(
              card.title
            )}`}
            subtitle="Learn How To..."
          />
        ))
        .slice(0, containsPolling ? 4 : 6)}
    </Box>
  </Box>
)

LearnNewSkillsBlock.propTypes = {
  containsPolling: PropTypes.bool,
  skillCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default LearnNewSkillsBlock
