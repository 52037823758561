import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { compose, defaultTo, path, prop } from 'ramda'
import { useSelector } from 'react-redux'

const UserTypeSelect = ({ setUserType, userType }) => {
  const { primary_user_type_name, secondary_user_type_name } = useSelector(
    prop('school')
  )

  const userTypes = [
    { label: primary_user_type_name, value: 'primary' },
    { label: secondary_user_type_name, value: 'secondary' },
  ]

  return (
    <FormControl fullWidth required>
      <InputLabel id="userType">
        <FormattedMessage
          defaultMessage="What is your user type?"
          id="omKMvj"
        />
      </InputLabel>
      <Select
        aria-label="user-type-select"
        id="user-type-select"
        label={
          <FormattedMessage
            defaultMessage="What is your user type?"
            id="omKMvj"
          />
        }
        labelId="userType"
        onChange={compose(setUserType, path(['target', 'value']))}
        value={defaultTo('', userType)}
      >
        {userTypes.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

UserTypeSelect.propTypes = {
  setUserType: PropTypes.func.isRequired,
  userType: PropTypes.string,
}

UserTypeSelect.defaultProps = {
  userType: null,
}

export default UserTypeSelect
