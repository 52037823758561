import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { getSubdomainNameFromCard } from 'utils/subdomain-functions'
import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

const FactCard = ({ content, handleClick, id, internalUrl, tipTitle }) => {
  const { userDomains } = useSelector(prop('user'))

  return (
    <Card
      sx={{
        borderTop: '3px solid',
        borderTopColor: 'school.accent'
      }}
    >
      <CardActionArea component={Link} onClick={handleClick} to={internalUrl}>
        <Stack direction="row" justifyContent="center" position="relative">
          <Box
            sx={{
              background: 'url(/images/fact-tips-bg.jpg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 50,
              width: 1
            }}
          />
          <Stack
            alignItems="center"
            bgcolor="school.secondary"
            borderRadius="50%"
            height={45}
            justifyContent="center"
            mt={2.5}
            position="absolute"
            width={45}
          >
            <LightbulbIcon sx={{ fill: 'white' }} />
          </Stack>
        </Stack>
        <Stack
          sx={{
            pb: 1.5,
            pt: 2,
            px: 1
          }}
        >
          <Typography variant="overline">
            <FormattedMessage defaultMessage="Facts & Tips" id="XCSS4l" />
          </Typography>

          <Typography
            aria-label={`Clickable content to ${tipTitle}`}
            component="h5"
            variant="cardTitle"
          >
            {tipTitle}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ '& > p': { m: 0, pb: 2 } }}>{parse(content)}</Box>
          <Typography color="secondary.dark" variant="overline">
            {getSubdomainNameFromCard(id, userDomains)}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

FactCard.propTypes = {
  content: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  internalUrl: PropTypes.string.isRequired,
  tipTitle: PropTypes.string.isRequired
}

export default FactCard
