import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as R from 'ramda'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

const DueDate = ({ goal, setGoal }) => (
  <DatePicker
    label={<FormattedMessage defaultMessage="Due Date" id="8XUukm" />}
    minDate={new Date()}
    onChange={date => setGoal(R.assoc('due_date', date))}
    renderInput={params => <TextField {...params} />}
    value={R.prop('due_date', goal)}
  />
)

DueDate.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired,
}

export default DueDate
