/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { prop, not, isNil, defaultTo, and, or, equals, any, all } from 'ramda'
import { excerpt } from 'utils/helpers'
import useCurrentAppType from 'hooks/useCurrentAppType'
import useGetSchoolName from 'hooks/useGetSchoolName'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import HomeIcon from '@mui/icons-material/Home'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import SpaRoundedIcon from '@mui/icons-material/SpaRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded'
import AddLocationRoundedIcon from '@mui/icons-material/AddLocationRounded'

import CrisisButton from 'components/common/CrisisButton'
import SidebarFeature from './SidebarFeature'
import SidebarMenuItem from './SidebarMenuItem'
import ReferralLinks from './ReferralLinks'

const LoggedInSidebar = () => {
  const navigate = useNavigate()
  const {
    allowed_to_view_stats_endpoint,
    app_type,
    hide_resources,
    home_location,
    id,
    is_secondary_user,
    location_id
  } = useSelector(prop('user'))
  const {
    enable_about_page,
    enable_geolocated_resources,
    enable_goals,
    enable_stories,
    enable_tips,
    powered_by_image_url,
    tips_data
  } = useSelector(prop('school'))
  const homeLocation = prop('address', home_location)
  const tipsTitle = prop('tips_category_title', tips_data)

  // APP TYPE BOOLEANS
  const { isCollective, isNLC, isWellpower } = useCurrentAppType()

  //GOALS
  const showGoalsForPrimaryUser = and(not(is_secondary_user), enable_goals)
  const showGoalsForSecondaryUser = and(
    is_secondary_user,
    prop('enable_goals', app_type)
  )
  const showGoals = or(showGoalsForPrimaryUser, showGoalsForSecondaryUser)

  //LOCATION
  const locationName = useGetSchoolName(location_id)

  //SIDEBAR LOGO
  const sidebarLogo = defaultTo(
    '/images/all-clear-foundation.png',
    powered_by_image_url
  )

  //STATS
  const canViewStats = and(
    not(isNil(id)),
    equals(allowed_to_view_stats_endpoint, 1)
  )

  return (
    <>
      <Stack spacing={2}>
        <SidebarMenuItem
          icon={<HomeIcon />}
          isShown
          link="/home"
          title={<FormattedMessage defaultMessage="Home" id="ejEGdx" />}
        />
        <SidebarMenuItem
          icon={<TrendingUpIcon />}
          isShown
          link="/explore"
          title={<FormattedMessage defaultMessage="Explore" id="7JlauX" />}
        />
        <SidebarMenuItem
          icon={<CheckRoundedIcon />}
          isShown
          link="/self-checks"
          title={<FormattedMessage defaultMessage="Self Checks" id="OB/ZtU" />}
        />
        <SidebarMenuItem
          icon={<TrackChangesRoundedIcon />}
          isShown={showGoals}
          link="/goals?active=explore"
          title={<FormattedMessage defaultMessage="Goals" id="XHLYdJ" />}
        />
        <SidebarMenuItem
          icon={<SchoolRoundedIcon />}
          isShown={is_secondary_user}
          link="/student-content"
          title={<FormattedMessage defaultMessage="For Students" id="vDwbU8" />}
        />

        <Divider />
        <SidebarMenuItem
          icon={<StarRateRoundedIcon />}
          isShown={canViewStats}
          link="/stats-dashboard"
          title={<FormattedMessage defaultMessage="Stats" id="U86B6w" />}
        />
        <SidebarMenuItem
          icon={<LocationOnRoundedIcon />}
          isShown={not(hide_resources)}
          link="/resources"
          title={
            <FormattedMessage
              defaultMessage="{locationName} Resources"
              id="6cfbB5"
              values={{ locationName }}
            />
          }
        />
        <SidebarMenuItem
          icon={<NearMeRoundedIcon />}
          isShown={all(equals(true))([
            enable_geolocated_resources,
            not(isNil(homeLocation))
          ])}
          link="/resources-near-me"
          title={
            <Stack>
              <FormattedMessage
                defaultMessage="Resources near me"
                id="NXOAf0"
              />
              <Typography variant="caption">
                {excerpt(homeLocation, 21)}
              </Typography>
            </Stack>
          }
        />
        <SidebarMenuItem
          icon={<AddLocationRoundedIcon />}
          isShown={and(enable_geolocated_resources, isNil(homeLocation))}
          link="/profile?active=about"
          title={
            <FormattedMessage defaultMessage="Set my location" id="hqh+8T" />
          }
        />
        <SidebarMenuItem
          icon={<FormatQuoteRoundedIcon />}
          isShown={and(enable_tips, not(is_secondary_user))}
          link="/tips"
          title={tipsTitle}
        />
        <SidebarMenuItem
          icon={<FavoriteRoundedIcon />}
          isShown
          link="/favorites"
          title={<FormattedMessage defaultMessage="Favorites" id="SMrXWc" />}
        />
        <SidebarMenuItem
          icon={<SpaRoundedIcon />}
          isShown={enable_about_page}
          link="/about-you"
          title={<FormattedMessage defaultMessage="About YOU" id="8/WWHN" />}
        />
        <SidebarMenuItem
          icon={<PeopleRoundedIcon />}
          isShown={enable_stories}
          link="/stories"
          title={<FormattedMessage defaultMessage="Stories" id="Ldxym4" />}
        />
        <Stack
          alignItems="center"
          sx={{ display: { xs: 'flex', lg: 'none' }, mt: 1.5 }}
        >
          <CrisisButton />
        </Stack>
        <ReferralLinks />
        <Stack
          sx={{
            display: { xs: 'flex', lg: 'none' }
          }}
        >
          <Divider />
          <Stack spacing={1.5}>
            <SidebarMenuItem
              icon={<AccountCircleRoundedIcon />}
              isShown
              link="/profile?active=about"
              title={<FormattedMessage defaultMessage="Profile" id="itPgxd" />}
            />
            <Button
              onClick={() => navigate('/logout')}
              startIcon={<LogoutRoundedIcon />}
              sx={{
                borderLeft: '8px solid transparent',
                borderRadius: 0,
                color: 'secondary.dark',
                fontFamily: 'mark-ot',
                fontSize: 16,
                fontWeight: 500,
                justifyContent: 'flex-start',
                letterSpacing: '0.75px',
                padding: '0 20px 0 16px',
                textDecoration: 'none',
                textTransform: 'none',
                width: 1
              }}
              variant="sidebar"
            >
              <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />
            </Button>
            <SidebarMenuItem
              icon={<ChatBubbleRoundedIcon />}
              isShown
              link="/feedback"
              title={
                <FormattedMessage defaultMessage="Give Feedback" id="6jDabx" />
              }
            />
          </Stack>
        </Stack>
      </Stack>
      {any(equals(true))([isWellpower, isCollective, isNLC]) && (
        <SidebarFeature sidebarLogo={sidebarLogo} />
      )}
    </>
  )
}

export default LoggedInSidebar
