import { useEffect } from 'react'
import PropTypes from 'prop-types'
import ScreenReaderMessage from 'components/common/ScreenReaderMessage'
import { FormattedMessage } from 'react-intl'
import { equals, isEmpty, isNil, prop } from 'ramda'
import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded'
import { useGetRealityScoresQuery } from 'api/statsApi'
import {
  setCompletedSelfCheck,
  setRealityCheckAnswers,
} from './onboardingSlice'
import { useDispatch } from 'react-redux'

const PollResults = ({
  pollingQuestion,
  selectedAnswerIndex,
  setSelectedAnswerIndex,
}) => {
  const dispatch = useDispatch()
  const { data: stats = {}, isFetching } = useGetRealityScoresQuery(
    prop('id', pollingQuestion),
    {
      skip: isNil(prop('id', pollingQuestion)),
    }
  )

  useEffect(() => {
    dispatch(setCompletedSelfCheck(true))
  }, [])

  if (isFetching) {
    return (
      <Skeleton data-testid="skeleton" height={500} variant="rectangular" />
    )
  }

  const handleReset = () => {
    setSelectedAnswerIndex(null)
    dispatch(setRealityCheckAnswers([]))
    dispatch(setCompletedSelfCheck(false))
  }

  const getPercentage = idx => {
    if (isEmpty(stats)) return 0
    return Math.round(
      (stats[idx + 1] / Object.values(stats).reduce((a, b) => a + b)) * 100
    )
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridGap: 16,
          mb: 3,
        }}
      >
        {pollingQuestion.answers.map(({ answer }, i) => (
          <Stack key={i}>
            <Stack
              alignItems="center"
              direction="row"
              sx={{ width: `${getPercentage(i)}%` }}
            >
              <Box
                sx={{
                  color: equals(selectedAnswerIndex, i)
                    ? 'school.secondary'
                    : 'school.primary',
                  width: 1,
                }}
              >
                <LinearProgress
                  color="inherit"
                  value={100}
                  variant="determinate"
                />
              </Box>
              <Typography
                color="secondary.dark"
                fontFamily="mark-ot-bold"
                ml="8px"
                variant="subtitle1"
                whiteSpace="nowrap"
              >
                {getPercentage(i)}%
              </Typography>
            </Stack>
            <Typography
              color="secondary.dark"
              fontFamily="mark-ot-bold"
              fontSize={14}
              letterSpacing="0.25px"
              lineHeight="140%"
            >
              <ScreenReaderMessage>
                {equals(selectedAnswerIndex, i) ? 'selected' : ''}
              </ScreenReaderMessage>
              {answer}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          onClick={handleReset}
          startIcon={<ReplayRoundedIcon />}
          variant="text"
        >
          <FormattedMessage defaultMessage="Reset my answer" id="YjCksY" />
        </Button>
      </Box>
    </Box>
  )
}

PollResults.propTypes = {
  pollingQuestion: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    ),
  }).isRequired,
  selectedAnswerIndex: PropTypes.number.isRequired,
  setSelectedAnswerIndex: PropTypes.func.isRequired,
}

export default PollResults
