/* eslint-disable sort-keys */
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const WaveWrapper = ({ children }) => (
  <Box>
    <Box
      sx={{
        background: 'url(/images/wave1.svg)',
        backgroundPosition: { xs: 'left -12px', md: 'left -16px' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        pt: { xs: 16, md: 26 },
      }}
    />
    <Box sx={{ bgcolor: 'white', px: { xs: 0, md: 6 } }}>{children}</Box>
    <Box
      sx={{
        background: 'url(/images/wave1.svg)',
        backgroundPosition: 'left bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // eslint-disable-next-line sort-keys
        pb: { xs: 8, md: 22 },
      }}
    />
  </Box>
)

WaveWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WaveWrapper
