import { useSendKeenEventMutation } from 'api/analyticsApi'
import { equals, prop } from 'ramda'
import { useSelector } from 'react-redux'

const useAnalytics = () => {
  const { allowed_to_view_stats_endpoint } = useSelector(prop('user'))

  const [fireAnalytics, { isError, isLoading, isSuccess, isUninitialized }] =
    useSendKeenEventMutation()

  const sendEvent = (name, props) => {
    const body = {
      dev_environment: process.env.REACT_APP_PUBLIC_KEENIO_ENV,
      keen_session_id: sessionStorage.getItem('@you:keenSessionId'),
      // locale,
      name,
      org_domain: sessionStorage.getItem('org_domain'),
      ...props
    }

    if (equals(0, allowed_to_view_stats_endpoint)) {
      fireAnalytics(body)
    }

    // uncomment for testing purposes
    // fireAnalytics(body)
  }

  return {
    isError,
    isLoading,
    isSuccess,
    isUninitialized,
    sendEvent
  }
}

export default useAnalytics
