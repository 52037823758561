import {
  TYPE_CAMPUS_GOAL,
  TYPE_EVENT,
  TYPE_GEO_RESOURCE,
  TYPE_NOD_CHALLENGE
} from 'utils/card-functions'
import PropTypes from 'prop-types'
import { equals, prop } from 'ramda'
import { useNavigate } from 'react-router-dom'

import ResourceArticle from './ResourceArticle'
import GeoLocated from './GeoLocated'
import NodChallengeArticle from 'components/nod/NodChallengeArticle'

// this is gonna mirror the Prepared Card in terms of design
// we will prepare any data from the card object from the BE
// and then feed it to the proper display component
// based on the type
// types are Card types from card-functions
const PreparedArticle = ({ article }) => {
  const navigate = useNavigate()
  // return means a new card type
  if (equals(prop('type', article), TYPE_CAMPUS_GOAL)) {
    return navigate(`/goals/create/premade?cardId=${prop('id', article)}`)
  }
  if (equals(prop('type', article), TYPE_GEO_RESOURCE)) {
    return <GeoLocated article={article} />
  }
  if (equals(prop('type', article), TYPE_NOD_CHALLENGE)) {
    return <NodChallengeArticle article={article} />
  }
  if (equals(prop('type', article), TYPE_EVENT)) {
    return null
  }
  return <ResourceArticle article={article} />
}

PreparedArticle.propTypes = {
  article: PropTypes.shape({
    type: PropTypes.number
  }).isRequired
}

export default PreparedArticle
