import { Button, Container, Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAnalytics } from 'hooks'

import ErrorMessage from 'components/common/ErrorMessage'
import { Link } from 'react-router-dom'

const AzureError = () => {
  const { azureLoginUrl } = useSelector(prop('school'))

  const { sendEvent } = useAnalytics()

  return (
    <Container maxWidth="sm" mx="auto">
      <Stack alignItems="center">
        <Typography mb={2} mt="2em" textAlign="center" variant="h1">
          <FormattedMessage defaultMessage="We’re sorry." id="KYWZ5c" />
        </Typography>

        <ErrorMessage
          hasError
          message={
            <FormattedMessage
              defaultMessage="We had an issue signing into your account. Try signing in again."
              id="ONhagU"
            />
          }
        />

        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'Logged Out Header',
              click_element: 'Link',
              element_title: 'Log In',
              path: window.location.pathname
            })
          }
          to={azureLoginUrl}
          variant="outlined"
        >
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        </Button>
      </Stack>
    </Container>
  )
}

export default AzureError
