import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useGetCardsQuery } from 'api/cardsApi'
import { TYPE_TIP } from 'utils/card-functions'
import { equals, inc, length, modulo, prop } from 'ramda'
import { Container, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import FilterSortMenu from 'components/common/FilterSortMenu'
import PageHeroBanner from 'components/common/PageHeroBanner'
import PreparedCard from 'components/common/PreparedCardConnector'
import CalloutBox from 'components/common/CalloutBox'
import MasonryWrapper from 'components/common/MasonryWrapper'
import LoadMoreButton from 'components/common/LoadMoreButton'
import CardAnimationWrapper from 'components/common/CardAnimationWrapper'

const Tips = () => {
  const { formatMessage } = useIntl()
  // tips_data is a handful of images, text, etc which set at the school level to normalize the experience
  const { tips_data } = useSelector(prop('school'))

  const [sort, setSort] = useState(0)
  const [page, setPage] = useState(1)

  const sortCheck = () => {
    if (equals(1, sort)) return 'recency'
    if (equals(2, sort)) return 'popularity'
    return null
  }

  const params = useMemo(
    () => ({
      skip: 0,
      sort: sortCheck(),
      take: 15 * page,
      type: TYPE_TIP
    }),
    [sort, page]
  )

  const { data: tips, isFetching } = useGetCardsQuery(params)

  return (
    <>
      <PageHeroBanner imageUrl={prop('tips_hero_image', tips_data)} />
      <Container sx={{ mb: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h1">
            {prop('tips_category_title', tips_data)}
          </Typography>
          <CalloutBox topSectionCopy={prop('tips_context_copy', tips_data)} />
          <FilterSortMenu
            activeId={sort}
            defaultLabel={formatMessage({
              defaultMessage: 'Most relevant to me',
              id: '6s5MJ0'
            })}
            handleChange={setSort}
            options={[
              {
                id: 1,
                name: formatMessage({
                  defaultMessage: 'Most Recent',
                  id: 'RTkF4y'
                })
              },
              {
                id: 2,
                name: formatMessage({
                  defaultMessage: 'Most Popular',
                  id: 'G+DXc9'
                })
              }
            ]}
          />
          <MasonryWrapper
            columns={{
              xs: 1,
              // eslint-disable-next-line sort-keys
              sm: 3
            }}
          >
            {tips?.map((card, i) => (
              <CardAnimationWrapper key={i}>
                <PreparedCard card={card} />
              </CardAnimationWrapper>
            ))}
          </MasonryWrapper>
          {equals(modulo(length(tips), 15), 0) && (
            <LoadMoreButton
              handleClick={() => setPage(inc(page))}
              loading={isFetching}
            />
          )}
        </Stack>
      </Container>
    </>
  )
}

export default Tips
