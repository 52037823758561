import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Badge, Box, Container, Stack, Tab, Typography } from '@mui/material'
import { flip, length } from 'ramda'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import { useGetGoalsQuery } from 'api/goalsApi'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Explore from './components/Explore'
import InProgress from './components/InProgress'
import Completed from './components/Completed'

const Goals = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const active = search.get('active')

  const { data: inProgress } = useGetGoalsQuery()

  const handleRouteChange = active => navigate(`/goals?active=${active}`)

  return (
    <Stack sx={{ m: '0 auto', maxWidth: 1280 }}>
      <Stack
        alignItems="center"
        spacing={{
          xs: 2,
          // eslint-disable-next-line sort-keys
          sm: 3
        }}
        sx={{ mb: 2 }}
      >
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Goals" id="XHLYdJ" />
        </Typography>
        <Typography
          color="secondary.dark"
          // eslint-disable-next-line sort-keys
          sx={{ maxWidth: { xs: '95%', md: '65%' } }}
          textAlign="center"
        >
          <FormattedMessage
            defaultMessage="Writing a goal down makes you 5x more likely to achieve it. This is your space to create your own or explore 100+ ready made ideas."
            id="8KUduv"
          />
        </Typography>
      </Stack>
      <TabContext value={active}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList aria-label="goal tabs" onChange={flip(handleRouteChange)}>
            <Tab
              icon={<TrendingUpRoundedIcon color="primary" />}
              iconPosition="start"
              label={
                <Typography
                  color="primary.main"
                  fontFamily="mark-ot"
                  fontSize={14}
                  fontWeight={700}
                >
                  <FormattedMessage
                    defaultMessage="Explore Goals"
                    id="LJ9vOv"
                  />
                </Typography>
              }
              sx={{
                minHeight: 48,
                px: 0
              }}
              value="explore"
            />
            <Tab
              icon={
                <Badge
                  badgeContent={length(inProgress)}
                  color="error"
                  overlap="circular"
                  variant="dot"
                >
                  <WatchLaterRoundedIcon />
                </Badge>
              }
              iconPosition="start"
              label={
                <Typography
                  color="primary.main"
                  fontFamily="mark-ot"
                  fontSize={14}
                  fontWeight={700}
                >
                  <FormattedMessage defaultMessage="My Goals" id="TenbOz" />
                </Typography>
              }
              sx={{
                minHeight: 48,
                px: 0
              }}
              value="my-goals"
            />
          </TabList>
        </Box>
        <TabPanel sx={{ px: 0 }} value="explore">
          <Explore />
        </TabPanel>
        <TabPanel sx={{ px: 0 }} value="my-goals">
          <Container maxWidth="md">
            <InProgress />
            <Completed />
          </Container>
        </TabPanel>
      </TabContext>
    </Stack>
  )
}

export default Goals
