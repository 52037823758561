import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  // CardMedia,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import Highlight from './Highlight'

const LargeSkillsCard = ({
  resourceUrl,
  headline,
  imageUrl,
  handleAnalytics,
  subtitle
}) => (
  <Card>
    <CardActionArea component={Link} onClick={handleAnalytics} to={resourceUrl}>
      <Box
        sx={{
          background: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          bgcolor: 'white',
          height: 250
        }}
      />
      {/* <CardMedia
        component="img"
        height={250}
        objectFit="contain"
        src={imageUrl}
      /> */}
      <CardContent>
        <Highlight variant="subtitleBold">{subtitle}</Highlight>
        <Typography variant="cardTitle">{headline}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
)

LargeSkillsCard.propTypes = {
  handleAnalytics: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  resourceUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default LargeSkillsCard
