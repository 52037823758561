import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { prop, assoc, path, gt, compose, append, length, inc } from 'ramda'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const StepsForm = ({ goal, setGoal }) => {
  const steps = prop('steps', goal)
  const intl = useIntl()

  return (
    <Box display="flex" flexDirection="column" pb="104px" width="100%">
      <Card>
        <CardContent>
          {steps.map((step, index) => (
            <Box display="flex" flexDirection="column" key={index} mb={2}>
              <Typography mb="4px" variant="body2">
                <FormattedMessage
                  defaultMessage="Step {step}"
                  id="EJ8mg/"
                  values={{ step: inc(index) }}
                />
              </Typography>
              <TextField
                InputProps={{
                  endAdornment: gt(length(steps), 1) && (
                    <IconButton
                      onClick={() =>
                        setGoal(
                          assoc(
                            'steps',
                            steps.filter((_, idx) => idx !== index),
                            goal
                          )
                        )
                      }
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  ),
                  sx: {
                    height: 'initial'
                  }
                }}
                multiline
                onChange={e => {
                  const updated = assoc(
                    'name',
                    path(['target', 'value'], e),
                    step
                  )
                  steps[index] = updated
                  setGoal(assoc('steps', [...steps], goal))
                }}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Add your step',
                  id: 'G+Nl9Q'
                })}
                value={prop('name', step)}
              />
            </Box>
          ))}
          <CardActions>
            <Button
              onClick={() =>
                // grab steps from goal obj
                // append new empty step to arr
                // update steps with new concatenated arr
                compose(
                  setGoal,
                  assoc('steps'),
                  append({
                    completed: false,
                    id: null,
                    name: ''
                  }),
                  prop('steps')
                )(goal)
              }
              startIcon={<AddRoundedIcon />}
              variant="text"
            >
              <FormattedMessage
                defaultMessage="Add your own step"
                id="Sphc64"
              />
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  )
}
StepsForm.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired
}

export default StepsForm
