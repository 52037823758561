import { FormattedMessage } from 'react-intl'
import { Box, Container, Stack, Tab, Tabs, Typography } from '@mui/material'
import { and, compose, equals, isNil, not, nthArg, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentAppType } from 'hooks'

const StatsDash = () => {
  const {
    mixpanel_primary_user_dashboard_url: primaryUrl,
    mixpanel_secondary_user_dashboard_url: secondaryUrl
  } = useSelector(prop('school'))
  const { allowed_to_view_stats_endpoint: allowedToViewStats, id } =
    useSelector(prop('user'))
  const { isBusiness } = useCurrentAppType()

  const isLoggedIn = not(isNil(id))
  const navigate = useNavigate()

  const [value, setValue] = useState('primary')

  const srcUrl = useMemo(() => {
    if (isNil(secondaryUrl)) return primaryUrl
    return equals('primary', value) ? primaryUrl : secondaryUrl
  }, [primaryUrl, secondaryUrl, value])

  if (and(isLoggedIn, not(allowedToViewStats))) {
    navigate('/404')
  }

  const renderLabel = () => {
    if (isBusiness) return <Tab label="Employees" value="primary" />
    return <Tab label="Emergency Responders" value="primary" />
  }

  return (
    <Container maxWidth="xl" sx={{ mb: 2 }}>
      <Stack alignItems="center" justifyContent="center" spacing={3}>
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Stats Dashboard" id="pXwsW3" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage defaultMessage="Select your report" id="t2eKIL" />
        </Typography>
        <Tabs
          direction="row"
          onChange={compose(setValue, nthArg(1))}
          spacing={1}
          value={value}
        >
          {renderLabel()}
          {not(isNil(secondaryUrl)) && (
            <Tab label="Faculty" value="secondary" />
          )}
        </Tabs>
        <Box
          component="iframe"
          id="mixpanel"
          src={srcUrl}
          sx={{
            border: 'none',
            borderRadius: 4,
            height: 524,
            width: 1
          }}
        />
      </Stack>
    </Container>
  )
}

export default StatsDash
