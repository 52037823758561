import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop, propEq, defaultTo, isNil, not, all, equals } from 'ramda'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import { useSelector } from 'react-redux'
import PollResults from './PollResults'

const PollCard = ({ pollingQuestion }) => {
  const { realityCheckAnswers } = useSelector(prop('onboarding'))
  // logic to set selectedAnswerIndex if there are any previous answers:
  const matchIndex = realityCheckAnswers.findIndex(
    propEq('question', prop('id', pollingQuestion))
  )
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(
    defaultTo(null, prop(matchIndex, realityCheckAnswers)?.score - 1)
  )

  const PollAnswerOptions = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: 16 }}>
      {pollingQuestion.answers.map(({ answer }, idx) => (
        <Button
          key={answer}
          onClick={() => setSelectedAnswerIndex(idx)}
          selected={equals(selectedAnswerIndex, idx)}
          variant="option"
        >
          {answer}
        </Button>
      ))}
    </Box>
  )

  const renderPoll = all(equals(true))([not(isNil(selectedAnswerIndex))])

  const renderContent = () => {
    if (renderPoll) {
      return (
        <PollResults
          pollingQuestion={pollingQuestion}
          selectedAnswerIndex={selectedAnswerIndex}
          setSelectedAnswerIndex={setSelectedAnswerIndex}
        />
      )
    }
    return <PollAnswerOptions />
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ alignItems: 'flex-end', display: 'flex', gridGap: 4 }}>
          <Box sx={{ color: 'school.secondary' }}>
            <EqualizerIcon color="inherit" />
          </Box>
          <Typography color="secondary.dark" variant="overline">
            <FormattedMessage defaultMessage="Poll" id="h1gtUi" />
          </Typography>
        </Box>
        <Typography mb="24px" textAlign="left" variant="subtitle2">
          {pollingQuestion.question}
        </Typography>
        <Typography mb="24px" textAlign="left" variant="caption">
          <FormattedMessage
            defaultMessage="Select an answer to see what others said."
            id="fkOonE"
          />
        </Typography>
        {renderContent()}
      </CardContent>
    </Card>
  )
}

PollCard.propTypes = {
  pollingQuestion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    question: PropTypes.string,
    subdomain: PropTypes.number,
  }).isRequired,
}

export default PollCard
