import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import SuccessMessage from 'components/common/SuccessMessage'
import ErrorMessage from 'components/common/ErrorMessage'
import { prop, assoc, compose, path, length, gt } from 'ramda'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useSendFeedbackMutation } from 'api/userApi'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const initialFormData = {
  email: '',
  emailError: false,
  error: false,
  success: false,
  text: ''
}

const FeedbackPage = () => {
  const { history } = useSelector(prop('history'))
  const { name } = useSelector(prop('school'))

  const [formData, setFormData] = useState(initialFormData)

  const email = prop('email', formData)
  const textLength = length(prop('text', formData))

  const { pathname } = useLocation()
  const { formatMessage } = useIntl()

  const [send, { isFetching, isSuccess, isError }] = useSendFeedbackMutation()

  const sendFeedback = () => {
    setFormData(assoc('emailError', false, formData))
    const body = new FormData()
    body.append('page', pathname)
    body.append('body', prop('text', formData))
    body.append('email', email)
    body.append('prior_path', prop(1, history))
    body.append('school_name', name)
    send(body)
  }

  useEffect(() => {
    if (isSuccess) {
      setFormData(assoc('success', true, formData))
      setFormData(initialFormData)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setFormData(assoc('error', true, formData))
      setFormData(initialFormData)
    }
  }, [isError])

  return (
    <Stack
      spacing={3}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 700,
        minHeight: 'calc(100vh - 118px)',
        // eslint-disable-next-line sort-keys
        mx: { xs: 3, sm: 'auto' },
        my: 3
      }}
    >
      <Typography textAlign="center" variant="h1">
        <FormattedMessage
          defaultMessage="We Welcome Your Feedback"
          id="v87wCE"
        />
      </Typography>
      <Typography textAlign="center">
        <FormattedMessage
          defaultMessage="When we ask, 'How can YOU help you?', we mean it. Use the form below to ask questions, share your ideas or even send words of affirmation."
          id="PrZLId"
        />
      </Typography>
      <Typography textAlign="center">
        <FormattedMessage
          defaultMessage="All messages submitted here are anonymous. If you wish for a response from our team, please include an email address where we can reach you. Thank you!"
          id="W4Pevy"
        />
      </Typography>
      <Box
        sx={{
          width: '100%',
          // eslint-disable-next-line sort-keys
          '& .MuiInputBase-root': { height: '100%' }
        }}
      >
        <TextField
          aria-label={formatMessage(
            {
              defaultMessage:
                'Feedback Message. {length} characters remaining.',
              id: 'wgAMio'
            },
            {
              length: 500 - textLength
            }
          )}
          data-testid="textarea"
          error={gt(textLength, 500)}
          fullWidth
          helperText={
            gt(textLength, 500) ? (
              formatMessage({
                defaultMessage: 'Max Character Limit is 500',
                id: 'xUPp31'
              })
            ) : (
              <>
                <FormattedMessage
                  defaultMessage="*Character Limit: "
                  id="dM30yp"
                />
                {500 - textLength}
              </>
            )
          }
          label={formatMessage({
            defaultMessage: 'Feedback',
            id: 'Ejhdi4'
          })}
          multiline
          onChange={compose(
            setFormData,
            assoc('text'),
            path(['target', 'value'])
          )}
          placeholder={formatMessage({
            defaultMessage:
              'Tell us here if you have any questions, ideas or feedback.',
            id: 'bKygbm'
          })}
          rows={10}
          value={prop('text', formData)}
        />
      </Box>
      <TextField
        aria-label={formatMessage({
          defaultMessage: 'email address',
          id: 'eGwKE1'
        })}
        data-testid="textbox"
        fullWidth
        label={formatMessage({
          defaultMessage: 'Email Address',
          id: 'xxQxLE'
        })}
        onChange={compose(
          setFormData,
          assoc('email'),
          path(['target', 'value'])
        )}
        value={prop('email', formData)}
      />
      <Button disabled={gt(textLength, 500)} onClick={sendFeedback}>
        {isFetching ? (
          <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
        ) : (
          <FormattedMessage defaultMessage="Send Feedback" id="b11hht" />
        )}
      </Button>
      <SuccessMessage
        hasSuccess={prop('success', formData)}
        message={formatMessage({
          defaultMessage: 'Thank you for your feedback!',
          id: 'Nrc9ba'
        })}
      />
      <ErrorMessage
        hasError={prop('error', formData)}
        message={
          <FormattedMessage
            defaultMessage="There was an error processing your feedback, please retry."
            id="C8QPke"
          />
        }
      />
    </Stack>
  )
}

export default FeedbackPage
