import { useEffect } from 'react'
import CheckboxCard from './CheckboxCard'
import {
  not,
  isNil,
  defaultTo,
  prop,
  propEq,
  reverse,
  sortBy,
  without,
  map,
  append
} from 'ramda'
import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { useUpdateInterestsMutation } from 'api/userApi'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import {
  setRealityCheckQuestion,
  setSelectedInterests
} from './onboardingSlice'
import { updateUserData } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const Interest = () => {
  const dispatch = useDispatch()
  const { selectedInterests } = useSelector(prop('onboarding'))
  const { id, userDomains } = useSelector(prop('user'))

  const [updateInterests, { isLoading }] = useUpdateInterestsMutation()

  useEffect(() => {
    if (not(isNil(id))) {
      dispatch(updateUserData({ name: 'interested_subdomains', value: [] }))
      updateInterests([])
    }
  }, [id])

  // Updating user via API for now on every interaction
  // Would be nice to rebuild this better without the api call every interaction
  const handleSelection = item => {
    const found = selectedInterests.find(propEq(prop('id', item), 'id'))
    if (not(isNil(found))) {
      const filtered = without([item], selectedInterests)
      dispatch(setSelectedInterests(filtered))
      updateInterests({
        subdomains: map(prop('id'), filtered)
      })
      return
    }
    dispatch(setSelectedInterests(append(item, selectedInterests)))
    updateInterests({
      subdomains: map(prop('id'), append(item, selectedInterests))
    })
    if (prop('crisis_subdomain', item)) {
      dispatch(setIsCrisisModalOpen(true))
    }
  }

  return (
    <Container>
      <Stack spacing={2}>
        {userDomains.map(({ id, title, subtitle, subdomains }) => (
          <Box key={id}>
            <Stack alignItems="center" direction="row">
              <Typography color="primary.main" variant="subtitle3">
                {title}
              </Typography>
              <Typography variant="subtitle1">&nbsp;/ {subtitle}</Typography>
            </Stack>
            <Divider />

            <Box
              sx={{
                display: 'grid',
                gridGap: 16,
                gridTemplateColumns: {
                  xs: '1fr',
                  // eslint-disable-next-line sort-keys
                  sm: 'repeat(2, 1fr)',
                  // eslint-disable-next-line sort-keys
                  md: 'repeat(4, 1fr)'
                },
                my: 4
              }}
            >
              {reverse(sortBy(prop('sort'))(subdomains)).map(subdomain => (
                <CheckboxCard
                  disabled={isLoading}
                  handleClick={() => {
                    handleSelection(subdomain)
                    // Need to re-init self-check question and goals when interests change,
                    // so next steps can find an appropiate content
                    dispatch(
                      setRealityCheckQuestion({
                        answers: []
                      })
                    )
                  }}
                  image={defaultTo('', prop('display_image', subdomain))}
                  key={prop('id', subdomain)}
                  selected={selectedInterests.some(
                    propEq(prop('id', subdomain), 'id')
                  )}
                  title={prop('onboarding_title', subdomain)}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Stack>
    </Container>
  )
}

export default Interest
