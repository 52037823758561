/* eslint-disable */
import { any, equals, isEmpty, isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'

const AnalyticsComponent = () => {
  const { google_analytics_id } = useSelector(prop('school'))

  if (
    any(equals(true))([
      isNil(google_analytics_id),
      isEmpty(google_analytics_id)
    ])
  )
    return

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: ` window.dataLayer = window.dataLayer || []; function gtag()
        {dataLayer.push(arguments)}
        gtag('js', new Date()); gtag('config', 'TAG_ID');`
        }}
      />
    </>
  )
}

export default AnalyticsComponent
