/* eslint-disable sort-keys */
import { useState } from 'react'
import { TYPE_STORY } from 'utils/card-functions'
import PreparedCard from 'components/common/PreparedCardConnector'
import LoadMoreButton from 'components/common/LoadMoreButton'
import { FormattedMessage } from 'react-intl'
import MasonryWrapper from 'components/common/MasonryWrapper'
import { equals, gt, inc, length, not } from 'ramda'
import { Box, Typography } from '@mui/material'
import { useGetCardsQuery } from 'api/cardsApi'
import CardAnimationWrapper from 'components/common/CardAnimationWrapper'

const Stories = () => {
  const [page, setPage] = useState(1)

  const params = {
    take: page * 15,
    skip: 0,
    type: TYPE_STORY
  }

  const { data: stories, isFetching } = useGetCardsQuery(params)

  return (
    <Box px={{ xs: 2, lg: 3 }}>
      <Typography variant="h1">
        <FormattedMessage defaultMessage="Stories" id="Ldxym4" />
      </Typography>

      {gt(stories, 0) && (
        <MasonryWrapper>
          {stories.map((card, i) => (
            <CardAnimationWrapper key={i}>
              <PreparedCard card={card} />
            </CardAnimationWrapper>
          ))}
        </MasonryWrapper>
      )}
      {not(equals(length(stories) % 15, 0)) && (
        <LoadMoreButton
          handleClick={() => setPage(inc(page))}
          loading={isFetching}
        />
      )}
    </Box>
  )
}

export default Stories
