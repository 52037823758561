import { MenuItem, Select, Stack } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import {
  compose,
  find,
  isNil,
  length,
  lte,
  not,
  or,
  path,
  prop,
  propEq
} from 'ramda'
import { useUpdateUserMutation } from 'api/userApi'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const languages = [
  {
    displayText: 'English',
    shorthand: 'en',
    value: 'en-US'
  },
  {
    displayText: 'Español',
    shorthand: 'es',
    value: 'es'
  }
]

const LanguageSelect = () => {
  const enabledLocales = useSelector(path(['school', 'enabled_locales']))
  const preferredLocale = useSelector(path(['user', 'preferred_locale']))
  const storedLocale = localStorage.getItem('@MT:locale')

  const [updateUser] = useUpdateUserMutation()

  // Renders based on enabled locale length in school object
  if (or(isNil(enabledLocales), lte(length(enabledLocales), 1))) return null

  useEffect(() => {
    if (isNil(storedLocale)) localStorage.setItem('@MT:locale', 'en')
  }, [storedLocale])

  const getActiveLocale = () => {
    // If we've already been through and worked out what locale to use (and stored it locally)
    if (not(isNil(storedLocale))) {
      return storedLocale
    }

    // If the user has a preferredLocale set, we use this value
    if (not(isNil(preferredLocale))) {
      return preferredLocale
    }

    // Otherwise we try to derive locale from the user's browser language, but fallback to
    // 'en' if all else fails
    const browserLocale = prop(
      'shorthand',
      find(propEq(window.navigator.language, 'value'), languages)
    )
    return browserLocale ?? 'en'
  }

  const handleChange = val => {
    localStorage.setItem('@MT:locale', val)
    updateUser({ preferred_locale: val })
    setTimeout(() => window.location.reload(), 1000)
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <LanguageRoundedIcon />
      <Select
        IconComponent={ExpandMoreRoundedIcon}
        onChange={compose(handleChange, path(['target', 'value']))}
        sx={{
          '& > .MuiSelect-icon': {
            color: 'white'
          },
          ':before, :after': {
            content: 'none'
          },
          color: 'white'
        }}
        value={getActiveLocale()}
        variant="standard"
      >
        {enabledLocales.map(({ display_text, locale }) => (
          <MenuItem key={locale} value={locale}>
            {display_text}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

export default LanguageSelect
