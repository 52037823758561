import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { compose, path } from 'ramda'

const StudentOrStaff = ({ role, setRole }) => (
  <FormControl>
    <FormLabel>
      <FormattedMessage defaultMessage="Sign up as a:" id="2okVX5" />
    </FormLabel>
    <RadioGroup
      name="Sign Up Type Control"
      onChange={compose(setRole, path(['target', 'value']))}
      row
      value={role}
    >
      <FormControlLabel
        control={<Radio color="info" />}
        label={<FormattedMessage defaultMessage="Student" id="UvFUuq" />}
        value="student"
      />
      <FormControlLabel
        control={<Radio color="info" />}
        label={<FormattedMessage defaultMessage="Staff" id="gFJ4sU" />}
        value="staff"
      />
    </RadioGroup>
  </FormControl>
)

StudentOrStaff.propTypes = {
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
}

export default StudentOrStaff
