const MuiCardContent = {
  styleOverrides: {
    root: {
      ':last-child': {
        paddingBottom: 16,
      },
    },
  },
}

export default MuiCardContent
