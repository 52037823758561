export const about_cactus = {
  body:
    '<a href="https://www.cactusinc.com">Cactus</a> is a Denver-based creative agency that’s dedicated to growing the brands that help people thrive. With a staff of 50, Cactus is built on the belief that small agency creativity and ingenuity can actually help solve the world’s biggest problems. Our size gives us the freedom to work only with the companies, causes and products we truly believe in—the ones that make the world a better place to live, work and play. <br><br>' +
    'Celebrating 30 years in 2020, Cactus has developed deep expertise in the area of shaping consumer belief & behavior—combining principles of behavioral psychology with creativity and innovation to design for clients’ desired business outcomes.',
  image: '/images/about-cactus.png',
  subtitle: '30 Years of Sharp Ideas',
  title: 'About Cactus'
}

export const about_grit = {
  body:
    '<a href="https://www.gritdigitalhealth.com">Grit Digital Health</a> develops behavioral health and well-being solutions through design and technology that envision a new way to approach mental health and well-being.<br><br>' +
    'The company solves complex health problems through innovation and creativity, including products that address veteran transitions to civilian life, student loneliness and well-being, employee satisfaction, community mental health and men’s mental health.',
  image: '/images/about-grit.png',
  subtitle: 'Creating Solutions that Personalize Well-being',
  title: 'About Grit Digital Health'
}
