import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Highlight = ({ children, variant = 'h3' }) => (
  <Box sx={{ display: 'inline-block', position: 'relative' }}>
    <Typography position="relative" variant={variant} zIndex={1}>
      {children}
    </Typography>
    <Box
      sx={{
        bgcolor: 'school.highlight',
        bottom: 4,
        height: 18,
        opacity: 0.7,
        position: 'absolute',
        width: 1,
        zIndex: 0,
      }}
    />
  </Box>
)

Highlight.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.string,
}

export default Highlight
