import { Box, Button, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAnalytics, useCurrentAppType } from 'hooks'

const Banner = ({ handleScrollToComponent }) => {
  const { isWellpower } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const getBackground = () =>
    'url(/images/wellpower/explore/wellpower-banner.jpg)'

  const renderHeading = () => (
    <FormattedMessage
      defaultMessage="Welcome to YOU at Your Best"
      id="UyTlt/"
    />
  )

  const renderSubtitle = () => (
    <FormattedMessage
      defaultMessage="YOU at Your Best is a free, confidential place where you can connect with online and local resources to support your well-being."
      id="b8jj0t"
    />
  )

  const renderConfidentiality = () => (
    <FormattedMessage
      defaultMessage="Everything on You at Your Best is 100% free & confidential."
      id="aLp/ks"
    />
  )

  const renderButtonText = () => (
    <FormattedMessage defaultMessage="about youatyourbest" id="LzDW7W" />
  )

  return (
    <Box>
      <Box
        sx={{
          background: getBackground(),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 248,
          mt: '-1px',
          width: 1
        }}
      />
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 1024,
          pt: 6,
          textAlign: 'center',
          width: 1
        }}
      >
        <Box sx={{ display: 'grid', gridGap: 24 }}>
          <Typography color="primary" variant="h2">
            {renderHeading()}
          </Typography>
          <Box sx={{ display: 'grid', gridGap: 16, justifyItems: 'center' }}>
            {isWellpower && (
              <>
                <Typography>
                  <FormattedMessage defaultMessage="POWERED BY:" id="eG0Edm" />
                </Typography>
                <Box
                  alt="WellPower Logo"
                  component="img"
                  height={39}
                  src="/images/wellpower/explore/wellpower-logo.svg"
                  style={{
                    alignSelf: 'center'
                  }}
                  width={237}
                />
              </>
            )}
            <Typography variant="body1">{renderSubtitle()}</Typography>
            <Typography variant="body1">{renderConfidentiality()}</Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              // eslint-disable-next-line sort-keys
              flexDirection: { xs: 'column', sm: 'row' },
              gridGap: 20,
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                handleScrollToComponent()
                sendEvent('block_interactions', {
                  block_title: 'OFD Explore Welcome to YOU',
                  click_element: 'Button',
                  element_title: 'Start Exploring',
                  path: window.location.pathname
                })
              }}
            >
              <FormattedMessage defaultMessage="start exploring" id="XlGpSX" />
            </Button>
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('block_interactions', {
                  block_title: 'OFD Explore Welcome to YOU',
                  click_element: 'Link',
                  element_title: 'Learn More',
                  path: window.location.pathname
                })
              }
              to="/about-you"
              variant="outlined"
            >
              {renderButtonText()}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Banner.propTypes = {
  handleScrollToComponent: PropTypes.func.isRequired
}

export default Banner
