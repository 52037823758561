import { Button, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { useIntl } from 'react-intl'

const AnswerOptions = ({ answers, selected = null, setSelected }) => {
  const { formatMessage } = useIntl()

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      {answers.map((answer, i) => (
        <Button
          aria-label={
            equals(selected, i)
              ? formatMessage(
                  {
                    defaultMessage: 'Selected {answer}',
                    id: '2LGX5J',
                  },
                  {
                    answer,
                  }
                )
              : formatMessage(
                  {
                    defaultMessage: 'Not Selected {answer}',
                    id: '319iT2',
                  },
                  {
                    answer,
                  }
                )
          }
          fullWidth
          key={i}
          onClick={() => setSelected(i)}
          selected={equals(selected, i)}
          sx={{
            borderColor: equals(selected, i)
              ? 'school.secondary'
              : 'primary.light',
          }}
          variant="option"
        >
          {answer}
        </Button>
      ))}
    </Stack>
  )
}

AnswerOptions.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
}

export default AnswerOptions
