import PropTypes from 'prop-types'
import PhoneCardImage from './PhoneCardImage'

export const options = [
  { label: 'career', value: 'career' },
  { label: 'mental well-being', value: 'mental-well-being' },
  { label: 'healthy relationships', value: 'healthy-relationships' },
  { label: 'diversity & identity', value: 'diversity-and-identity' }
]

const MHCDCards = ({ role }) => {
  switch (role) {
    default:
    case options[0].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-mhcd/career/career" />
      )
    case options[1].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-mhcd/well-being/well-being" />
      )
    case options[2].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-mhcd/relationships/relationships" />
      )
    case options[3].label:
      return (
        <PhoneCardImage roleUrlPath="/images/welcome/card-images-mhcd/diversity/diversity" />
      )
  }
}

MHCDCards.propTypes = {
  role: PropTypes.string.isRequired
}
export default MHCDCards
