import { FormattedMessage } from 'react-intl'
import { useMemo, useState } from 'react'
import { succeedThriveMatterFrontendUrlSlugs } from 'utils/domain-functions'
import { Container, Skeleton, Stack, Typography } from '@mui/material'
import { equals, propEq, prop, isEmpty, or, defaultTo, find } from 'ramda'
import { useGetFavoritesQuery } from 'api/userApi'
import { useSelector } from 'react-redux'

import FilterSortMenu from 'components/common/FilterSortMenu'
import PreparedCard from 'components/common/PreparedCardConnector'
import MasonryWrapper from 'components/common/MasonryWrapper'

const Favorites = () => {
  const { data, isFetching } = useGetFavoritesQuery()
  const { userDomains } = useSelector(prop('user'))

  const [domainId, setDomainId] = useState(0)

  const { succeed, thrive, matter } =
    succeedThriveMatterFrontendUrlSlugs(userDomains)

  // Filter Logic
  const filterCards = () => {
    // get the current category obj from active filter. ex 'succeed', 'matter', 'thrive'
    const { subdomains } = defaultTo(
      [],
      find(propEq(domainId, 'id'), [succeed, thrive, matter])
    )
    // get cards that match current category
    // Default - return all cards
    return data.filter(({ domain_id }) =>
      equals(domainId, 0) ? data : subdomains.find(propEq(domain_id, 'id'))
    )
  }

  const renderComponent = () => {
    if (or(isFetching, isEmpty(userDomains)))
      return (
        <Stack data-testid="skeleton" mb={3} spacing={2}>
          <Stack direction="row" spacing={2}>
            <Skeleton height={265} variant="rectangular" width="100%" />
            <Skeleton height={265} variant="rectangular" width="100%" />
            <Skeleton height={265} variant="rectangular" width="100%" />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Skeleton height={265} variant="rectangular" width="100%" />
            <Skeleton height={265} variant="rectangular" width="100%" />
            <Skeleton height={265} variant="rectangular" width="100%" />
          </Stack>
        </Stack>
      )
    return (
      <MasonryWrapper>
        {filterCards().map(card => (
          <PreparedCard card={card} key={prop('id', card)} />
        ))}
      </MasonryWrapper>
    )
  }

  // Generate the filter ui from categories
  const getFilterOptions = ({ id, frontend_name: name }) => ({
    id,
    name
  })

  const filterOptions = useMemo(() => {
    if (isEmpty(userDomains)) return []
    return [
      getFilterOptions(succeed),
      getFilterOptions(thrive),
      getFilterOptions(matter)
    ]
  }, [userDomains])

  return (
    <Container>
      <Typography textAlign="center" variant="h1">
        <FormattedMessage defaultMessage="Favorites" id="SMrXWc" />
      </Typography>
      <FilterSortMenu
        activeId={domainId}
        handleChange={setDomainId}
        options={filterOptions}
      />
      <Typography sx={{ mb: 2 }}>
        <FormattedMessage defaultMessage="Favorite Cards" id="V0Z/Dt" />
      </Typography>
      {renderComponent()}
    </Container>
  )
}

export default Favorites
