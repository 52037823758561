import PropTypes from 'prop-types'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import RadioField from './RadioField'
import { equals, prop } from 'ramda'
import { Box, Typography } from '@mui/material'

const RadioQuestionInput = ({ question, userAnswers, onUpdateAnswer }) => {
  const isMulti = equals(prop('type', question), 'multi')

  return (
    <Box mt={5}>
      <Typography mb="10px" variant="subtitle4">
        {question.question}
      </Typography>
      {isMulti && (
        <Typography mb="20px" variant="body2">
          <FormattedMessage
            defaultMessage="Select all that apply"
            id="gSenzY"
          />
        </Typography>
      )}
      <RadioField
        isMulti={isMulti}
        name={question.name}
        onChange={answers => {
          const updatedAnswer = {
            answers,
            profile_question_id: question.id,
          }
          onUpdateAnswer(updatedAnswer)
        }}
        options={question.answers.map(answer => ({
          label: answer.answer,
          value: answer.id,
        }))}
        userAnswers={userAnswers}
      />
    </Box>
  )
}

RadioQuestionInput.propTypes = {
  onUpdateAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    name: PropTypes.string,
    question: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default memo(RadioQuestionInput)
