import { Button, Divider, Stack, Typography } from '@mui/material'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import useCurrentDomain from 'hooks/useCurrentDomain'
import { subdomainsFromUserDomains } from 'utils/helpers'
import { isEmpty, prop } from 'ramda'
import { useGetPublicUserDomainsQuery } from 'api/publicApi'

const RecommendedSubdomains = ({ subdomainData }) => {
  const { currentDomain } = useCurrentDomain()
  const { data: userDomains } = useGetPublicUserDomainsQuery(currentDomain)
  const related = prop('relatedDomains', subdomainData)

  if (isEmpty(related)) return null

  const relatedSubdomains = () => {
    const result = []
    const relatedDomains = Object.keys(related)
    subdomainsFromUserDomains(userDomains).map(subdomain => {
      if (relatedDomains.includes(subdomain.frontend_url_slug)) {
        result.push({
          link: subdomain.link,
          name: related[subdomain.frontend_url_slug]
        })
      }
    })
    return result
  }

  return (
    <>
      <Divider />
      <Stack
        spacing={2}
        sx={{
          mb: 7.5,
          mt: 7.5
        }}
      >
        <Typography color="primary" textAlign="center" variant="h3">
          <FormattedMessage
            defaultMessage="It's all connected..."
            id="mxJvGX"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="Sometimes it's helpful to zoom out and look at the big picture. Explore other topics below."
            id="d7JSSE"
          />
        </Typography>
        <Stack
          // eslint-disable-next-line sort-keys
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          {relatedSubdomains().map(({ link, name }) => (
            <Button
              endIcon={<ArrowForwardRoundedIcon />}
              key={name}
              LinkComponent={Link}
              sx={{
                p: 0,
                whiteSpace: 'nowrap'
              }}
              to={`/public${link}`}
              variant="text"
            >
              {name}
            </Button>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

RecommendedSubdomains.propTypes = {
  subdomainData: PropTypes.shape({}).isRequired
}

export default RecommendedSubdomains
