import { useEffect, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  Grow,
  // LinearProgress,
  Skeleton,
  Typography
} from '@mui/material'

import EqualizerIcon from '@mui/icons-material/Equalizer'

import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { prop, any, equals, isNil, not, has, inc, assoc } from 'ramda'
import { useGetRealityScoresQuery } from 'api/statsApi'
// import { Link } from "react-router-dom"
import PollQuestions from './PollQuestions'
import PollResults from './PollResults'

const PollCard = ({ content }) => {
  const [selected, setSelected] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [pollAnswers, setPollAnswers] = useState({})

  const { data: answers, isFetching } = useGetRealityScoresQuery(
    prop('id', content),
    { refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    if (not(isNil(answers))) setPollAnswers(answers)
  }, [answers])

  useEffect(() => {
    if (not(has(inc(selected), answers))) {
      setPollAnswers(assoc(inc(selected), 1, answers))
      return
    }
    setPollAnswers(
      assoc(inc(selected), inc(prop(inc(selected), answers)), answers)
    )
  }, [selected])

  if (any(equals(true))([isNil(content), isNil(answers), isFetching]))
    return <Skeleton height={500} variant="rectangular" />

  const renderContent = () => {
    if (isSubmitted)
      return (
        <PollResults
          answers={pollAnswers}
          content={content}
          selected={selected}
        />
      )
    return (
      <PollQuestions
        content={content}
        selected={selected}
        setIsSubmitted={setIsSubmitted}
        setSelected={setSelected}
      />
    )
  }

  return (
    <Grow in>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 16
            }}
          >
            <Box
              sx={{
                alignItems: 'flex-end',
                color: 'secondary.dark',
                display: 'flex',
                fill: 'school.accent',
                gridGap: 4
              }}
            >
              <EqualizerIcon sx={{ color: 'school.secondary' }} />
              <Typography color="secondary.dark" mb={0} variant="overline">
                <FormattedMessage defaultMessage="poll" id="6X88wy" />
              </Typography>
            </Box>
            <Typography variant="h4">
              <FormattedMessage
                defaultMessage="{title}"
                id="2YgLVn"
                values={{ title: prop('prompt', content) }}
              />
            </Typography>
            {renderContent()}
          </Box>
        </CardContent>
      </Card>
    </Grow>
  )
}

PollCard.propTypes = {
  content: PropTypes.shape({}).isRequired
}

export default PollCard
