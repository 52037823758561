import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SocialBadge from 'components/common/SocialBadge'
import { equals, inc, length } from 'ramda'
import { Box, Button, Chip, Typography } from '@mui/material'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import { Link } from 'react-router-dom'

// HACK ALERT:
// I have called this hackImage cause that's what it is.
// content hasn't worked out that they are going to use different illustrations
// for different appTypes but i am 150% confident they will.
// WHEN THAT HAPPENS
// we need to save that image on the CMS and return it in the BE payload as part
// of the user_domains in initData(). Then we can just use the domain.illustration
// or whatever we call it and pass it through as a prop. We will also need to delete
// the images used below since they will now be on the CMS.

// for now we are using default images supplied by Alex (our designer)
// Stan 4/26/2022
const TakeQuizCard = ({
  completed = false,
  domainUrlSlug,
  image,
  socialCount,
  subdomains,
  title
}) => (
  <Box
    sx={{
      alignItems: 'flex-start',
      backgroundColor: 'grey.light',
      borderRadius: 2,
      boxShadow: '0px 2px 4px rgba(79, 79, 79, 0.25)',
      display: 'grid',
      gridGap: '16px',
      justifyItems: 'center',
      px: 2,
      py: 4
    }}
  >
    <Typography variant="h2">{title}</Typography>
    <Chip
      icon={<WatchLaterRoundedIcon fontSize="small" />}
      label={<FormattedMessage defaultMessage="2-5 minutes" id="rBWPEU" />}
    />
    <Box
      component="img"
      role="presentation"
      src={`/images/reality-self-checks/${image}.svg`}
    />
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      sx={{
        gridGap: 4
      }}
    >
      <Typography variant="captionBold">
        <FormattedMessage defaultMessage="taken by:" id="QLK3To" />
      </Typography>
      <SocialBadge aria-hidden socialCount={socialCount} />
    </Box>
    {completed ? (
      <>
        <Button
          LinkComponent={Link}
          to={`/self-checks/results/${domainUrlSlug}`}
        >
          <FormattedMessage defaultMessage="View my results" id="3RYwDf" />
        </Button>
        <Button
          LinkComponent={Link}
          sx={{ textAlign: 'center' }}
          to={`/self-checks/${domainUrlSlug}`}
          variant="outlined"
        >
          <FormattedMessage
            defaultMessage="Retake this assessment"
            id="dzy4Z0"
          />
        </Button>
      </>
    ) : (
      <Button LinkComponent={Link} to={`/self-checks/${domainUrlSlug}`}>
        <FormattedMessage defaultMessage="take this assessment" id="lU9cvh" />
      </Button>
    )}

    <Box width="100%">
      <Typography
        component="span"
        display="block"
        fontSize="12px"
        fontWeight={600}
        mb="8px"
        textTransform="none"
        variant="overline"
      >
        <FormattedMessage
          defaultMessage="What topics are covered?"
          id="dgwKdP"
        />
      </Typography>
      {subdomains.map(({ title }, idx) => (
        <Typography component="span" key={title} variant="caption">
          <FormattedMessage
            defaultMessage="{title}"
            id="2YgLVn"
            values={{ title }}
          />
          {equals(inc(idx), length(subdomains)) ? '' : ' ∙ '}
        </Typography>
      ))}
    </Box>
  </Box>
)

TakeQuizCard.propTypes = {
  completed: PropTypes.bool,
  domainUrlSlug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  socialCount: PropTypes.number.isRequired,
  subdomains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
}

export default TakeQuizCard
