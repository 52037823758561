import PropTypes from 'prop-types'
import PhoneCardImage from './PhoneCardImage'

export const options = [
  { label: 'work-life balance', value: 'work-life-balance' },
  { label: 'stress & anxiety', value: 'stress-anxiety' },
  { label: 'family', value: 'family' },
  { label: 'finances', value: 'finances' }
]

const BusinessCards = ({ role }) => {
  switch (role) {
    default:
    case options[0].label:
      return (
        <PhoneCardImage
          numberOfCards={3}
          roleUrlPath="/images/welcome/card-images-business/work-life/work-life"
        />
      )
    case options[1].label:
      return (
        <PhoneCardImage
          numberOfCards={3}
          roleUrlPath="/images/welcome/card-images-business/stress/stress"
        />
      )
    case options[2].label:
      return (
        <PhoneCardImage
          numberOfCards={3}
          roleUrlPath="/images/welcome/card-images-business/family/family"
        />
      )
    case options[3].label:
      return (
        <PhoneCardImage
          numberOfCards={3}
          roleUrlPath="/images/welcome/card-images-business/finances/finances"
        />
      )
  }
}

BusinessCards.propTypes = {
  role: PropTypes.string.isRequired
}
export default BusinessCards
