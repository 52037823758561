/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import WelcomeHeroImage from './WelcomeHeroImage'
import { useAnalytics } from 'hooks'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

const WelcomeSectionAbout = () => {
  const { sendEvent } = useAnalytics()

  const imageUrl = () =>
    '/images/welcome/about-images/collective_about_image.png'

  const headline = () => (
    <FormattedMessage
      defaultMessage="Created by young people for young people."
      id="+dfJLp"
    />
  )

  const ctaLink = () => (
    <FormattedMessage defaultMessage="Sign up to get started" id="nh/w4j" />
  )

  const content = () => (
    <FormattedMessage
      defaultMessage="Real and useful resources to help you balance your mental health. "
      id="Lzj4jK"
    />
  )

  const HashCircle = () => (
    <Box
      sx={{
        left: { xs: 0, lg: '-3%' },
        position: 'absolute',
        top: { xs: 0, lg: '8%' },
        transform: 'rotate(180deg)',
        width: { xs: '95%', md: '85%', lg: '50%' }
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/images/welcome/hash-circle.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          pt: '100%',
          width: 1
        }}
      />
    </Box>
  )

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        pb: { xs: '6em', lg: '10%', xl: 0 },
        position: 'relative',
        pt: { xs: '5%', md: 0, lg: '10%' }
      }}
    >
      <HashCircle />

      <Box
        sx={{
          flex: { xs: '0 0 100%', lg: '0 0 40%' },
          order: 1,
          p: { xs: 0, lg: '2% 0 15%' },
          position: 'relative'
        }}
      >
        <Box
          position="relative"
          sx={{
            width: 1
          }}
        >
          <WelcomeHeroImage imageUrl={imageUrl()} position="left" />
        </Box>
      </Box>

      <Box
        sx={{
          flex: { xs: '0 0 100%', lg: '0 0 55%' },
          order: 2,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            p: { xs: '10% 3% 5%', lg: '0 10% 5%' },
            position: { xs: 'relative', lg: 'absolute' },
            top: { lg: '50%' },
            transform: { lg: 'translateY(-50%)' },
            width: 1
          }}
        >
          <Typography color="primary" variant="h2">
            {headline()}
          </Typography>
          <Typography component="p" variant="bodyLarge">
            {content()}
          </Typography>
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            LinkComponent={Link}
            onClick={() =>
              sendEvent('welcome_page', {
                element_title: 'Learn more about operation veteran strong',
                section: 'about block'
              })
            }
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              px: 0
            }}
            to="/sign-up"
            variant="text"
          >
            {ctaLink()}
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}

export default WelcomeSectionAbout
