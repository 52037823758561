/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import GA from 'services/gaService'
import { FormattedMessage } from 'react-intl'
import BackgroundImage from 'components/common/BackgroundImage'
import parse from 'html-react-parser'
import {
  isEmpty,
  path,
  compose,
  length,
  gte,
  prop,
  not,
  defaultTo,
  and,
  isNil,
  find
} from 'ramda'
import {
  Alert,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSearchQuery } from 'api/searchApi'
import CloseIcon from '@mui/icons-material/Close'
import { useAnalytics } from 'hooks'
import { useSelector } from 'react-redux'

const truncateCopy = ({
  facts_tips_copy,
  video_description,
  photo_description,
  page_content
}) => {
  const itemCopy = find(
    item => and(not(isNil(item)), not(isEmpty(item))),
    [facts_tips_copy, video_description, photo_description, page_content]
  )
  return parse(itemCopy.substring(0, 95))
}

const Search = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { searchQuery } = useParams()
  const { sendEvent } = useAnalytics()

  const [searchTerm, setSearchTerm] = useState(searchQuery)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => setOpen(false), 1500)
  }, [open])

  const { flagIcon } = useSelector(prop('school'))

  const { data = { cards: [] }, isFetching } = useSearchQuery(searchTerm, {
    skip: isEmpty(searchTerm)
  })

  const searchResults = prop('cards', data)

  const handleAnalytics = () => {
    // tracking
    GA.search(searchTerm)
    sendEvent('search_query', {
      searchTerm
    })
  }

  return (
    <Stack
      spacing={4}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        pb: 'calc(100vh - 415px)'
      }}
    >
      <Fade in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setOpen(false)}
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="info"
          sx={{ alignItems: 'center', boxShadow: 2, width: 300 }}
          variant="outlined"
        >
          <Typography variant="legal">
            <FormattedMessage
              defaultMessage="Copied link to clipboard!"
              id="rGf6cq"
            />
          </Typography>
        </Alert>
      </Fade>
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ChevronLeftRoundedIcon color="primary" />}
        sx={{
          alignSelf: 'flex-start'
        }}
        variant="tertiary"
      >
        <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
      </Button>
      <Stack alignItems="center" spacing={3} sx={{ width: 1 }}>
        <Typography component="h1" mx="auto" variant="h3">
          What can we help you find?
        </Typography>
        <Box sx={{ maxWidth: 544, width: 1 }}>
          <TextField
            defaultValue={searchQuery}
            fullWidth
            onChange={compose(
              handleAnalytics,
              setSearchTerm,
              path(['target', 'value'])
            )}
            placeholder="How can YOU help you?"
            value={searchTerm}
          />
        </Box>
      </Stack>
      {isFetching && (
        <Stack spacing={1} sx={{ width: 1 }}>
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
          <Skeleton height={120} variant="rectangular" />
        </Stack>
      )}

      {not(isEmpty(searchResults)) && (
        <>
          <Button
            onClick={() => {
              setOpen(true)
              navigator.clipboard.writeText(
                window.location.origin + pathname + '?searchQuery=' + searchTerm
              )
              sendEvent('card_share', {
                path: pathname,
                search_term: searchTerm
              })
            }}
            startIcon={<ShareRoundedIcon />}
            variant="tertiary"
          >
            <FormattedMessage defaultMessage="Copy Sharable URL" id="m3WvDh" />
          </Button>
          <Typography variant="cardTitle">
            <FormattedMessage
              defaultMessage="Search Results: {number}"
              id="itEqZE"
              values={{ number: length(searchResults) }}
            />
          </Typography>
        </>
      )}

      {gte(length(searchResults), 0) &&
        searchResults.map(
          ({
            id,
            title,
            campus_resource,
            video_thumbnail_url,
            photo,
            ...rest
          }) => (
            <Box
              key={id}
              sx={{
                px: { xs: 2, md: 3 },
                width: { xs: 'calc(100% - 32px)', md: 'calc(100% - 48px)' }
              }}
            >
              <Box
                component={Link}
                sx={{ display: 'flex' }}
                to={`/article/${id}/${title}`}
              >
                <Box sx={{ mr: 2.5 }}>
                  <BackgroundImage
                    display="table-cell"
                    flagIcon={campus_resource ? flagIcon : null}
                    height="75px"
                    imageUrl={defaultTo(
                      video_thumbnail_url,
                      prop('thumb_url', photo)
                    )}
                    verticalAlign="center"
                    width="75px"
                  />
                </Box>
                <Box>
                  <Typography
                    color="primary.main"
                    component="h2"
                    mb="0.5em"
                    variant="h4"
                  >
                    {title}
                  </Typography>
                  <Typography color="primary.main" variant="body2">
                    {truncateCopy(rest)}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Box>
          )
        )}
    </Stack>
  )
}

export default Search
