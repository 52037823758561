import { Stack } from '@mui/material'
import { useState } from 'react'
import { or } from 'ramda'

import ProfileAlert from './ProfileAlert'
import StaffFacultyAlert from './StaffFacultyAlert'

const AlertWrapper = () => {
  const [showProfile, setShowProfile] = useState(false)
  const [showStaff, setShowStaff] = useState(false)

  return (
    <Stack
      sx={{
        bottom: 12,
        maxWidth: 675,
        position: 'fixed',
        right: 8,
        width: 1,
        zIndex: or(showProfile, showStaff) ? 10 : -1
      }}
    >
      <ProfileAlert setShow={setShowProfile} show={showProfile} />
      <StaffFacultyAlert setShow={setShowStaff} show={showStaff} />
    </Stack>
  )
}

export default AlertWrapper
