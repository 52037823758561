import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material'
import { add, and, isNil, not, prop } from 'ramda'
import GA from 'services/gaService'
import { useSignInAzureMutation } from 'api/authApi'
import { useAnalytics, useLocalStorage, useSession } from 'hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AzureError from './AzureError'

const AzureLoginRedirect = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const code = search.get('code')
  const id_token = search.get('id_token')
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { clear, get, save } = useLocalStorage()

  const [progress, setProgress] = useState(25)

  const [azureLogin, { data, isError }] = useSignInAzureMutation()

  useEffect(() => {
    if (and(not(isNil(code)), not(isNil(id_token)))) {
      setProgress(50)
      clear()
      azureLogin({
        code: code,
        id_token: id_token,
        org_domain: process.env.REACT_APP_PUBLIC_ORG_DOMAIN
      })
    }
  }, [code, id_token])

  useEffect(() => {
    if (not(isNil(data))) {
      setProgress(75)
      save(prop('x-session-id', data))
    }
  }, [data])

  const handleAnalytics = () => {
    const promises = [
      sessionEvent('login-wellpower', {
        session_id: prop('x-session-id', data)
      }),
      sendEvent('login', {
        auth_type: 'SSO',
        validation_errors: [],
        validation_success: true
      }),
      sendEvent('sign-in', {
        auth_type: 'SSO',
        validation_errors: [],
        validation_success: true
      })
    ]
    return Promise.allSettled(promises)
  }

  const sessionToken = get()

  useEffect(() => {
    if (not(isNil(sessionToken))) {
      setProgress(100)
      // Tracking
      GA.click('Login', 'Click')
      handleAnalytics().finally(() => navigate('/home'))
    }
  }, [sessionToken])

  return isError ? (
    <AzureError />
  ) : (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle component="h1" id="dialog-title" tabIndex={-1}>
        <FormattedMessage defaultMessage="Logging You In..." id="S4ohHi" />
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          color: 'school.secondary',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box width={1}>
          <LinearProgress
            color="inherit"
            value={progress}
            valueBuffer={add(progress, 10)}
            variant="buffer"
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AzureLoginRedirect
