const MuiInputBase = {
  styleOverrides: {
    root: {
      fontSize: 16,
      height: 48,
    },
  },
}

export default MuiInputBase
