import PropTypes from 'prop-types'
import ScreenReaderMessage from './ScreenReaderMessage'
import BackgroundImage from './BackgroundImage'
import { FormattedMessage } from 'react-intl'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const MiniResourceCard = ({
  flagIcon = null,
  handleAnalytics,
  headline,
  imageUrl,
  resourceUrl,
  subtitle
}) => {
  return (
    <Card>
      <CardActionArea
        component={Link}
        onClick={handleAnalytics}
        to={resourceUrl}
      >
        <Stack direction="row" spacing={0.5}>
          <Stack>
            <BackgroundImage
              flagIcon={flagIcon}
              height={150}
              imageUrl={imageUrl}
              pt={0}
              smallFlag
              width={110}
            />
            <ScreenReaderMessage>
              <FormattedMessage
                defaultMessage="Link to Learn How Too... {headline}"
                id="34IZX5"
                values={{ headline }}
              />
            </ScreenReaderMessage>
          </Stack>
          <Stack sx={{ p: 1 }}>
            <Typography data-testid="headline" variant="body2">
              {headline}
            </Typography>
            <Typography
              color="secondary.dark"
              fontSize="10px"
              textTransform="uppercase"
              variant="subtitleBold"
            >
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

MiniResourceCard.propTypes = {
  flagIcon: PropTypes.string,
  handleAnalytics: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  resourceUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default MiniResourceCard
