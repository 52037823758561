import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Box, Card, CardActionArea, Typography } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useIntl } from 'react-intl'

const CheckboxCard = ({ disabled, image, title, handleClick, selected }) => {
  const intl = useIntl()

  return (
    <Card>
      <CardActionArea
        aria-label={
          selected
            ? intl.formatMessage(
                { defaultMessage: 'selected {title}', id: 'GMW7io' },
                { title }
              )
            : intl.formatMessage(
                { defaultMessage: 'not selected {title}', id: 'O3KVhz' },
                { title }
              )
        }
        disabled={disabled}
        onClick={handleClick}
      >
        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            color: 'school.primary',
            height: 20,
            left: 8,
            position: 'absolute',
            top: 8,
            width: 20,
            zIndex: 1,
          }}
        >
          {selected && <CheckRoundedIcon color="inherit" fontSize="small" />}
        </Box>
        <Box
          sx={{
            background: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 160,
            width: 1,
          }}
        />
        {isEmpty(image) && (
          <Box
            bgcolor="school.secondary"
            sx={{
              borderRadius: 1,
              height: 160,
              mb: 1,
              objectFit: 'cover',
              width: 1,
            }}
          />
        )}
        <Box display="flex" justifyContent="center" p={1}>
          <Typography
            sx={{
              color: 'primary.main',
              fontFamily: 'mark-ot-bold',
              mb: '0',
              pb: '0',
              textAlign: 'center',
            }}
            variant="body2"
          >
            {title}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

CheckboxCard.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default CheckboxCard
