import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

const ArrowAnimationButton = ({
  handleClick,
  showContent,
  buttonText,
  disabled,
}) => (
  <Button
    aria-label={buttonText}
    disabled={disabled}
    endIcon={
      showContent ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
    }
    onClick={handleClick}
    sx={{
      color: 'primary.main',
      fontFamily: 'mark-ot',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
    }}
    variant="text"
  >
    {buttonText}
  </Button>
)

ArrowAnimationButton.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
}

ArrowAnimationButton.defaultProps = {
  disabled: false,
}

export default ArrowAnimationButton
