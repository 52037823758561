/* eslint-disable sort-keys */
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const AboutGrit = () => (
  <Stack
    direction="row"
    sx={{
      alignSelf: 'center',
      justifyContent: 'center',
      px: 0
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
        justifyContent: 'space-between',
        justifyItems: 'center'
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/images/about-grit.jpg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 250, lg: '100%' },
          maxHeight: 375,
          maxWidth: 485,
          width: 1
        }}
      />
      <Stack
        spacing={2}
        sx={{
          px: { xs: 1.5, md: 0 },
          textAlign: 'center'
        }}
      >
        <Typography color="secondary.dark" variant="h2">
          <FormattedMessage
            defaultMessage="About Grit Digital Health"
            id="mQmbi1"
          />
        </Typography>
        <Box
          sx={{
            alignSelf: 'center',
            borderTop: '4px solid',
            borderTopColor: 'school.secondary',
            width: 167
          }}
        />
        <Typography fontSize="22px" variant="body1">
          <FormattedMessage
            defaultMessage="Creating Solutions that Personalize Well-Being"
            id="eGBvPy"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="{link} develops behavioral health and well-being solutions through design and technology that envision a new way to approach mental health and well-being."
            id="X6wuv3"
            values={{
              link: (
                <Link
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline'
                  }}
                  target="_blank"
                  to="https://gritdigitalhealth.com"
                >
                  Grit Digital Health
                </Link>
              )
            }}
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="The compaby solves complex health problems through innovation and creativity, including products that address veteran transitions to civilian life, student lonliness and well-being, employee satisfaction, community mental health and men’s mental health."
            id="8an2o1"
          />
        </Typography>
      </Stack>
    </Box>
  </Stack>
)

export default AboutGrit
