import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'

const Hero = ({ image }) => {
  if (isNil(image)) return null

  return (
    <Box
      data-testid="hero"
      sx={{
        background: `url(${image})`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 370,
        width: 1,
      }}
    />
  )
}

Hero.propTypes = {
  image: PropTypes.string,
}

Hero.defaultProps = {
  image: null,
}

export default Hero
