import { FormattedMessage } from 'react-intl'
import introImg from 'assets/images/onboarding/intro.svg'

const steps = [
  {
    heading: <FormattedMessage defaultMessage="Welcome to YOU" id="4QohQh" />,
    id: 0,
    image: introImg,
    name: 'intro',
    title: <FormattedMessage defaultMessage="Get Started" id="9QXm1E" />,
    url: 'introduction'
  },
  {
    emoji: '📝',
    heading: (
      <FormattedMessage
        defaultMessage="What do you want to prioritize?"
        id="PclCbi"
      />
    ),
    id: 1,
    name: 'interests',
    subtitle: (
      <FormattedMessage
        defaultMessage="Scroll to see all topics! And don't worry, you can change them anytime."
        id="gBQlYK"
      />
    ),
    title: <FormattedMessage defaultMessage="My Priorities" id="dm4opd" />,
    url: 'interests'
  },
  {
    emoji: '✅',
    heading: <FormattedMessage defaultMessage="Check-In" id="X7IMRK" />,
    id: 2,
    name: 'self check',
    title: <FormattedMessage defaultMessage="Self Check" id="HFuVrT" />,
    url: 'self-check'
  },
  {
    emoji: '🎯',
    heading: <FormattedMessage defaultMessage="Set goals" id="7kfEkR" />,
    id: 3,
    name: 'goals',
    subtitle: (
      <FormattedMessage
        defaultMessage="Write your own or choose from 100+ ready-made ideas."
        id="NxAz7r"
      />
    ),
    title: <FormattedMessage defaultMessage="My Goals" id="TenbOz" />,
    url: 'goals'
  },
  {
    id: 4,
    name: 'email',
    title: <FormattedMessage defaultMessage="Tips & Reminders" id="wKuQ+W" />,
    url: 'email'
  },
  {
    emoji: '📨',
    heading: (
      <FormattedMessage defaultMessage="Know where to go." id="aZp4SF" />
    ),
    id: 5,
    name: 'wellpower',
    subtitle: (
      <FormattedMessage
        defaultMessage="When you need help, we can point you there. Enter your address below and we'll connect you with local resources near you. (You can edit this one later, too.)"
        id="8nxHO4"
      />
    ),
    title: <FormattedMessage defaultMessage="Location" id="rvirM2" />,
    url: 'location'
  }
]

export default steps
