const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      '& > div > span, & > span, & > p': {
        color: 'white',
      },
    },
  },
}

export default MuiTooltip
