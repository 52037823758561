import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useCurrentAppType } from 'hooks'

const imageStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: 60,
  width: 1
}

const SidebarFeature = ({ sidebarLogo }) => {
  const { isWellpower } = useCurrentAppType()

  const WellpowerContent = () => (
    <>
      <Typography variant="overline">
        <FormattedMessage defaultMessage="Powered by" id="U8QBHO" />
      </Typography>
      <Box
        component={Link}
        sx={{
          background: `url(${sidebarLogo})`,
          ...imageStyle
        }}
        target="_blank"
        title="Wellpower"
        to="https://wellpower.org"
      />
    </>
  )

  const NLCContent = () => (
    <>
      <Typography variant="overline">
        <FormattedMessage defaultMessage="Powered by" id="U8QBHO" />
      </Typography>
      <Box
        component={Link}
        sx={{
          background: `url(${sidebarLogo})`,
          ...imageStyle
        }}
        target="_blank"
        title="NLC RISC"
        to="https://risc.nlc.org/"
      />
    </>
  )

  const content = () => {
    if (isWellpower) return <WellpowerContent />
    return <NLCContent />
  }

  return (
    <Stack
      sx={{
        mb: 0,
        mt: 'auto',
        pb: 3,
        px: 2,
        width: 1
      }}
    >
      {content()}
    </Stack>
  )
}

SidebarFeature.propTypes = {
  sidebarLogo: PropTypes.string
}

SidebarFeature.defaultProps = {
  sidebarLogo: null
}

export default SidebarFeature
