import { useRef, useState } from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'

import { useGetAddressQuery } from 'api/addressApi'
import getParams from 'utils/getParams'
import {
  and,
  compose,
  defaultTo,
  equals,
  gte,
  inc,
  isEmpty,
  not,
  path,
  prop,
  tap,
  length
} from 'ramda'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

const LocationInput = ({ selected, setSelected }) => {
  const containerRef = useRef(null)
  const [value, setValue] = useState('')

  const { data } = useGetAddressQuery(
    getParams({
      include_only_states: 'co',
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      search: value
    }),
    {
      skip: isEmpty(value)
    }
  )

  const options = defaultTo([], prop('suggestions', data))

  return (
    <Box ref={containerRef} sx={{ display: 'grid', gridGap: 8, width: 360 }}>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: not(isEmpty(selected)) && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  setValue('')
                  setSelected({})
                }}
              >
                <ClearRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnRoundedIcon />
            </InputAdornment>
          )
        }}
        label={
          <FormattedMessage defaultMessage="Enter an address" id="BKdjS1" />
        }
        onChange={compose(
          () => setSelected({}),
          tap(setValue),
          path(['target', 'value'])
        )}
        value={value}
      />
      {and(gte(length(options), 1), isEmpty(selected)) && (
        <Box
          role="listbox"
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            boxShadow: 2,
            display: 'grid',
            gridGap: 8,
            maxHeight: 250,
            mt: -1,
            overflow: 'auto',
            py: 1
          }}
        >
          {options.map((option, idx) => (
            <Box
              key={prop('street_line', option)}
              onClick={() => {
                setValue(prop('street_line', option))
                setSelected(option)
              }}
              role="option"
              sx={{
                ':hover': {
                  bgcolor: 'secondary.light'
                },
                alignItems: 'center',
                borderBottom: equals(length(options), inc(idx))
                  ? 'unset'
                  : '1px solid',
                borderBottomColor: 'secondary.light',
                cursor: 'pointer',
                display: 'flex',
                gridGap: 8,
                pb: 1,
                px: 2
              }}
              tabIndex={0}
            >
              <LocationOnRoundedIcon />
              <Box>
                <Typography variant="body2">
                  <FormattedMessage
                    defaultMessage="{street_line}"
                    id="359sJT"
                    values={{ street_line: prop('street_line', option) }}
                  />
                </Typography>
                <Typography variant="caption">
                  <FormattedMessage
                    defaultMessage="{city}, {zipcode}"
                    id="GbmSf4"
                    values={{
                      city: prop('city', option),
                      zipcode: prop('zipcode', option)
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

LocationInput.propTypes = {
  selected: PropTypes.shape({}).isRequired,
  setSelected: PropTypes.func.isRequired
}

export default LocationInput
