import { useEffect, useState } from 'react'

import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

import {
  not,
  isNil,
  prop,
  split,
  isEmpty,
  defaultTo,
  gte,
  equals,
  inc,
  length,
  compose,
  path,
  assoc,
  tap,
  and
} from 'ramda'
import { FormattedMessage } from 'react-intl'
import {
  useGetAddressQuery,
  useGetAddressCoordinatesQuery
} from 'api/addressApi'
import { useUpdateHomeLocationMutation } from 'api/profileApi'
import getParams from 'utils/getParams'
import { useSelector } from 'react-redux'

const LocationSelection = () => {
  const homeLocation = useSelector(path(['user', 'home_location', 'address']))
  const [selected, setSelected] = useState({})
  const [value, setValue] = useState('')

  const formattedAddress = isEmpty(selected)
    ? null
    : `${prop('street_line', selected)}, ${prop('city', selected)} ${prop(
        'state',
        selected
      )} ${prop('zipcode', selected)}`

  useEffect(() => {
    if (not(isNil(homeLocation))) {
      setValue(prop(0, split(',', homeLocation)))
      const rest = split(' ', prop(1, split(',', homeLocation)))
      setSelected({
        city: prop(1, rest),
        state: 'CO',
        street_line: prop(0, split(',', homeLocation)),
        zipcode: prop(3, rest)
      })
    }
  }, [homeLocation])

  const { data } = useGetAddressQuery(
    getParams({
      include_only_states: 'co',
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      search: value
    }),
    {
      skip: isEmpty(value)
    }
  )
  const options = defaultTo([], prop('suggestions', data))

  const formatted = assoc('street', prop('street_line', selected), selected)
  const { data: coordinates } = useGetAddressCoordinatesQuery(
    getParams({
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      ...formatted
    }),
    { skip: isEmpty(selected) }
  )

  const [updateHomeLocation] = useUpdateHomeLocationMutation()

  const body = {
    address: formattedAddress,
    lat: path(['metadata', 'latitude'], prop(0, coordinates)),
    lng: path(['metadata', 'longitude'], prop(0, coordinates))
  }

  useEffect(() => {
    if (not(isNil(coordinates))) {
      updateHomeLocation(body)
      setValue(prop('address', body))
    }
  }, [coordinates])

  return (
    <Stack spacing={1} sx={{ maxWidth: 434 }}>
      <Typography variant="subtitle4">
        <FormattedMessage defaultMessage="Home Location" id="B2o748" />
      </Typography>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: not(isEmpty(value)) && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  setValue('')
                  setSelected({})
                }}
              >
                <ClearRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnRoundedIcon />
            </InputAdornment>
          )
        }}
        onChange={compose(
          () => setSelected({}),
          tap(setValue),
          path(['target', 'value'])
        )}
        value={value}
      />
      {and(gte(length(options), 1), isEmpty(selected)) && (
        <Box
          role="listbox"
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            boxShadow: 2,
            display: 'grid',
            gridGap: 8,
            maxHeight: 250,
            overflow: 'auto',
            py: 1
          }}
        >
          {options.map((option, idx) => (
            <Box
              key={prop('street_line', option)}
              onClick={() => {
                setValue(prop('street_line', option))
                setSelected(option)
              }}
              role="option"
              sx={{
                ':hover': {
                  bgcolor: 'secondary.light'
                },
                alignItems: 'center',
                borderBottom: equals(length(options), inc(idx))
                  ? 'unset'
                  : '1px solid',
                borderBottomColor: 'secondary.light',
                cursor: 'pointer',
                display: 'flex',
                gridGap: 8,
                pb: 1,
                px: 2
              }}
              tabIndex={0}
            >
              <LocationOnRoundedIcon />
              <Box>
                <Typography variant="body2">
                  <FormattedMessage
                    defaultMessage="{street_line}"
                    id="359sJT"
                    values={{ street_line: prop('street_line', option) }}
                  />
                </Typography>
                <Typography variant="caption">
                  <FormattedMessage
                    defaultMessage="{city}, {zipcode}"
                    id="GbmSf4"
                    values={{
                      city: prop('city', option),
                      zipcode: prop('zipcode', option)
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Stack>
  )
}

export default LocationSelection
