import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { makeStore } from './store'
import { useRef } from 'react'
import { not } from 'ramda'

const ReduxProvider = ({ children }) => {
  const storeRef = useRef()
  if (not(storeRef.current)) storeRef.current = makeStore()

  return <Provider store={storeRef.current}>{children}</Provider>
}

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ReduxProvider
