/* eslint-disable sort-keys */
import { Box, Button, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useAnalytics, useCurrentAppType } from 'hooks'

const About = () => {
  const { isWellpower } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  // Default object to fill in unspecified fields and all text if no different app types given
  const defaultText = {
    aboutDescription: (
      <FormattedMessage
        defaultMessage="A free and confidential space to find resources, learn skills, and take action to support your well-being."
        id="uS5RhI"
      />
    ),
    aboutHeader: (
      <FormattedMessage defaultMessage="About YOU at Your Best" id="AHs81m" />
    ),
    communityDescription: (
      <FormattedMessage
        defaultMessage="Hear stories from community members on how they navigate topics like finances, stress management, relationships, and everything between"
        id="p9GOi1"
      />
    ),
    communityHeader: (
      <FormattedMessage
        defaultMessage="Stories from your Community"
        id="qvltaL"
      />
    ),
    connectingDescription: (
      <FormattedMessage
        defaultMessage="Whether it's for you, or a someone in your life, we can help point to the best resources to help."
        id="Gor7C2"
      />
    ),
    connectingHeader: (
      <FormattedMessage
        defaultMessage="Connect to the right resources"
        id="z3WwBV"
      />
    ),
    faqButton: <FormattedMessage defaultMessage="FAQ" id="W8nHSd" />,
    whoDescription: (
      <FormattedMessage
        defaultMessage="Well-being is unique and personal to each person. YOU at Your Best personalizes your experience based on your interests, priorities, and unique identities."
        id="dOERbT"
      />
    ),
    whoHeader: (
      <FormattedMessage defaultMessage="Personalized for You" id="/xU3F1" />
    )
  }

  /**
   * Function to get text objects for specific app types
   * @input string: app type currently in use
   * @return object: object of key value pairs. Keys are identifiers and values are Components to render for app type
   */
  const getDynamicText = () => {
    if (isWellpower)
      return {
        ...defaultText,
        aboutDescription: (
          <FormattedMessage
            defaultMessage="A free and confidential space to find local resources, get tips, and take action to support your well-being."
            id="//C5QB"
          />
        ),
        communityDescription: (
          <FormattedMessage
            defaultMessage="Hear stories from community members on how they thrive in areas like finances, stress management, relationships, and everything between."
            id="yCLSJa"
          />
        ),
        communityHeader: (
          <FormattedMessage
            defaultMessage="Hear from others in your Community"
            id="Q62qv7"
          />
        ),
        connectingDescription: (
          <FormattedMessage
            defaultMessage="Whether it's for you or someone, YOU at Your Best helps connect you to the right supports to thrive in all aspects of life."
            id="3u+6EF"
          />
        ),
        connectingHeader: (
          <FormattedMessage
            defaultMessage="Connect to local resources"
            id="2Wme+0"
          />
        ),
        faqButton: <FormattedMessage defaultMessage="FAQs" id="2zAY1d" />,
        whoDescription: (
          <FormattedMessage
            defaultMessage="Well-being is unique to everyone. YOU at Your Best provides a personalized experience based on your interests, priorities, and unique identities."
            id="bmJQpj"
          />
        )
      }
    return {
      ...defaultText,
      aboutDescription: (
        <FormattedMessage
          defaultMessage="A free and confidential place for Emergency Responders and their loved ones to hear stories from other responders, connect to supports, and take action to be at their best."
          id="IfsEtI"
        />
      ),
      aboutHeader: (
        <FormattedMessage defaultMessage="About ResponderStrong" id="rNM5fv" />
      ),
      communityDescription: (
        <FormattedMessage
          defaultMessage="Hear from other Emergency Responders on how they navigate topics like work-life balance, sleep, relationships, and everything in between."
          id="N2o7Re"
        />
      ),
      communityHeader: (
        <FormattedMessage
          defaultMessage="Stories from the Emergency Responder Community"
          id="sXGQoJ"
        />
      ),
      connectingDescription: (
        <FormattedMessage
          defaultMessage="Whether it's for you, or a Emergency Responders in your life, we have local and national resources on hand to help."
          id="JM8xVw"
        />
      ),
      connectingHeader: (
        <FormattedMessage
          defaultMessage="Connecting Emergency Responders to the right resources"
          id="Tjvnsu"
        />
      ),
      whoDescription: (
        <FormattedMessage
          defaultMessage="Sometimes only Emergency Responders understand the full picture of serving our community. That's why RS was created by responders, for responders."
          id="9hEf9h"
        />
      ),
      whoHeader: (
        <FormattedMessage
          defaultMessage="By Responder, For Responders"
          id="jAbUYM"
        />
      )
    }
  }

  const {
    aboutHeader,
    aboutDescription,
    faqButton,
    communityDescription,
    communityHeader,
    whoDescription,
    whoHeader,
    connectingDescription,
    connectingHeader
  } = getDynamicText()

  const getImage = () => {
    if (isWellpower) return 'url(/images/wellpower/explore/wellpower-about.jpg)'
    return 'url(/images/responder/About-Responder-Strong.png)'
  }

  return (
    <Box sx={{ pb: 5 }}>
      <Box
        sx={{ margin: '0 auto', maxWidth: 1024, textAlign: 'center', width: 1 }}
      >
        <Box
          alignItems="center"
          spacing={3}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 24
          }}
        >
          <Typography variant="h2">{aboutHeader}</Typography>
          <Typography variant="body1">{aboutDescription}</Typography>
          <Box
            sx={{
              display: 'flex',
              gridGap: 24
            }}
          >
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('block_interactions', {
                  block_title: 'OFD Explore About YOU',
                  click_element: 'Link',
                  element_title: 'Sign Up CTA',
                  path: window.location.pathname
                })
              }
              to="/sign-up"
            >
              <FormattedMessage defaultMessage="sign up" id="yv/kr9" />
            </Button>
            <Button LinkComponent={Link} to="/about-you" variant="outlined">
              {faqButton}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: { xs: 4, md: 7.5 },
          mx: 'auto',
          maxWidth: 1024,
          textAlign: 'center',
          width: 1
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridGap: 48,
            gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' }
          }}
        >
          <Box
            sx={{
              background: getImage(),
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'fill',
              minHeight: 345,
              width: 1
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 16
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{communityHeader}</Typography>
              <Typography variant="body2">{communityDescription}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{whoHeader}</Typography>
              <Typography variant="body2">{whoDescription}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{connectingHeader}</Typography>
              <Typography variant="body2">{connectingDescription}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default About
