import { Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { isNil, path } from 'ramda'
import { useUpdateUserMutation } from 'api/userApi'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const Birthday = () => {
  const birthdate = useSelector(
    path(['user', 'public_user', 'birthdate', 'date'])
  )

  const selected = isNil(birthdate) ? '' : new Date(birthdate)
  const [updateUser] = useUpdateUserMutation()

  return (
    <Stack
      spacing={1}
      sx={{
        maxWidth: 434,
        width: 1
      }}
    >
      <Typography variant="subtitle4">
        <FormattedMessage defaultMessage="Birthdate" id="WMQYUt" />
      </Typography>
      <DatePicker
        labelId="birthdate"
        maxDate={new Date()}
        onChange={date => {
          const body = new FormData()
          // we have to rip off the timezone information for the BE since the field
          // is marked "no timezone" in the DB and will throw a 500 SQL error.
          body.append('birthdate', date.toISOString())
          updateUser(body)
        }}
        renderInput={params => <TextField {...params} />}
        value={selected}
      />
    </Stack>
  )
}

export default Birthday
