import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TYPE_CAMPUS_GOAL,
  TYPE_GEO_RESOURCE,
  TYPE_LIFE_LESSON,
  TYPE_TIP,
} from 'utils/card-functions'
import {
  path,
  equals,
  or,
  not,
  prop,
  length,
  lt,
  cond,
  always,
  evolve,
  assoc,
  propEq,
  and,
  gt,
  inc,
  dissoc,
} from 'ramda'
import { useGetCardsQuery } from 'api/cardsApi'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import { Box, Divider, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import PreparedCard from 'components/common/PreparedCardConnector'
import FilterSortMenu from 'components/common/FilterSortMenu'
import LoadMoreButton from 'components/common/LoadMoreButton'
import MasonryWrapper from 'components/common/MasonryWrapper'

const ExploreResourcesBlock = ({ subdomain }) => {
  const { formatMessage } = useIntl()
  const [filterId, setFilterId] = useState(0)
  const [page, setPage] = useState(1)
  const [cardParams, setCardParams] = useState({
    skip: 0,
    subdomain: [path(['subdomain', 'id'], subdomain)],
    take: page * 6,
    type: '',
  })

  const {
    enable_geolocated_resources,
    enable_goals,
    enable_tips,
    hide_resources,
    letters,
    tips_data,
  } = useSelector(prop('school'))
  const { app_type, is_secondary_user } = useSelector(prop('user'))
  const enableTips = prop('enable_tips', app_type)

  const buildFilterOption = (label, id, hide_filter) => ({
    hide_filter,
    id,
    name: formatMessage({ defaultMessage: '{label}', id: 'j8y+qc' }, { label }),
  })

  const equals0 = equals(0)

  const cardFilters = [
    buildFilterOption(
      prop('tips_category_title', tips_data),
      1,
      or(
        not(and(enable_tips, enableTips)),
        equals0(prop('tips_cards', subdomain))
      )
    ),
    buildFilterOption(
      `${letters} Resources`,
      2,
      or(hide_resources, equals0(prop('campus_cards', subdomain)))
    ),
    buildFilterOption('Expert Info', 3, lt(prop('expert_cards', subdomain), 0)),
    buildFilterOption(
      'Goals',
      4,
      or(
        or(not(enable_goals), is_secondary_user),
        equals0(prop('goal_cards', subdomain))
      )
    ),
    buildFilterOption('Near me', 5, not(enable_geolocated_resources)),
  ]

  const { data = [], isFetching } = useGetCardsQuery(cardParams)

  useEffect(() => {
    const fetchObj = cond([
      [
        equals(0),
        always(
          evolve({ type: always('') }, dissoc('campus_resource', cardParams))
        ),
      ],
      [
        equals(1),
        always(
          evolve(
            { type: always(TYPE_TIP) },
            dissoc('campus_resource', cardParams)
          )
        ),
      ],
      [
        equals(2),
        always(
          evolve({ type: always('') }, { campus_resource: 1, ...cardParams })
        ),
      ],
      [
        equals(3),
        always(
          evolve(
            {
              type: always(TYPE_LIFE_LESSON),
            },
            dissoc('campus_resource', cardParams)
          )
        ),
      ],
      [
        equals(4),
        always(
          evolve(
            {
              type: always(TYPE_CAMPUS_GOAL),
            },
            dissoc('campus_resource', cardParams)
          )
        ),
      ],
      [
        equals(5),
        always(
          evolve(
            {
              type: always(TYPE_GEO_RESOURCE),
            },
            dissoc('campus_resource', cardParams)
          )
        ),
      ],
    ])
    setCardParams(fetchObj(filterId))
  }, [filterId])

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        // eslint-disable-next-line sort-keys
        mt: { xs: 3, md: 4 },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          width: 1,
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <TrendingUpRoundedIcon />
          <Typography color="secondary.dark" ml="10px" variant="subtitle1">
            <FormattedMessage
              defaultMessage="Explore {subdomain}"
              id="hH8CUp"
              values={{
                subdomain: path(['subdomain', 'frontend_name'], subdomain),
              }}
            />
          </Typography>
        </Box>
        <Divider />
      </Box>
      <FilterSortMenu
        activeId={filterId}
        handleChange={id => {
          setFilterId(id)
          setCardParams(assoc('take', 6, cardParams))
          setPage(1)
        }}
        keenEventTitle="YOU_subdomain_filter"
        options={cardFilters.filter(propEq(false, 'hide_filter'))}
      />
      {equals(length(data), 0) && (
        <Typography variant="body1">
          <FormattedMessage defaultMessage="Nothing found..." id="WaVGEG" />
        </Typography>
      )}
      <MasonryWrapper>
        {data.map(card => (
          <CardAnimationWrapper key={prop('id', card)}>
            <PreparedCard card={card} />
          </CardAnimationWrapper>
        ))}
      </MasonryWrapper>
      {and(equals(length(data), 6 * page), gt(length(data), 0)) && (
        <LoadMoreButton
          handleClick={() => {
            setCardParams(assoc('take', inc(page) * 6, cardParams))
            setPage(inc(page))
          }}
          loading={isFetching}
        />
      )}
    </Box>
  )
}

ExploreResourcesBlock.propTypes = {
  subdomain: PropTypes.shape({}).isRequired,
}

export default ExploreResourcesBlock
