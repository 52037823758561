import { publicBaseQuery } from './publicBaseQuery'

export const publicApi = publicBaseQuery.injectEndpoints({
  endpoints: ({ query }) => ({
    getPublicCard: query({
      providesTags: ['card'],
      query: ({ id, org }) => `/v3/card/${id}?org_domain=${org}`
    }),
    getPublicCards: query({
      providesTags: ['cards'],
      query: qp => `/v3/cards?${qp}`
    }),
    getPublicFeaturedContent: query({
      providesTags: ['featured'],
      query: org => `/public/featured-content?org_domain=${org}`
    }),
    getPublicSubdomain: query({
      providesTags: ['public subdomain'],
      query: id => `/public/subdomains/${id}`
    }),
    getPublicUserDomains: query({
      providesTags: ['public user domains'],
      query: org => `/public/domains?org_domain=${org}`
    })
  }),
  reducerPath: 'publicApi'
})

export const {
  useGetPublicCardQuery,
  useGetPublicCardsQuery,
  useGetPublicFeaturedContentQuery,
  useGetPublicSubdomainQuery,
  useGetPublicUserDomainsQuery
} = publicApi
