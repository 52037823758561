import ScreenReaderMessage from './ScreenReaderMessage'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

const MiniFactTipCard = ({
  resourceUrl,
  handleAnalytics,
  headline,
  subtitle
}) => (
  <Card>
    <CardActionArea component={Link} onClick={handleAnalytics} to={resourceUrl}>
      <Stack direction="row" spacing={0.5}>
        <Box sx={{ flex: '0 0 110px' }}>
          <Stack
            direction="row"
            height={150}
            justifyContent="center"
            position="relative"
          >
            <Box
              sx={{
                background: 'url(/images/fact-tips-bg.jpg)',
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: 1
              }}
            />
            <Stack
              alignItems="center"
              bgcolor="school.accent"
              borderRadius="50%"
              height={45}
              justifyContent="center"
              mt={6.5}
              position="absolute"
              width={45}
            >
              <LightbulbIcon sx={{ fill: 'white' }} />
            </Stack>
          </Stack>
          <ScreenReaderMessage>
            <FormattedMessage
              defaultMessage="Link to Learn How Too... {headline}"
              id="34IZX5"
              values={{ headline }}
            />
          </ScreenReaderMessage>
        </Box>

        <Stack sx={{ p: 1.5 }}>
          <Typography color="primary.main" variant="body2">
            {headline}
          </Typography>
          <Typography
            color="secondary.dark"
            fontSize="10px"
            variant="overlineBold"
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </CardActionArea>
  </Card>
)

MiniFactTipCard.propTypes = {
  handleAnalytics: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
  resourceUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default MiniFactTipCard
