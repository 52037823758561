import { useSendKeenEventMutation } from 'api/analyticsApi'

const useSession = () => {
  const [fireAnalytics] = useSendKeenEventMutation()

  const clearStorage = () => sessionStorage.removeItem('@you:keenSessionId')

  const sessionEvent = (session_endpoint, params) =>
    fireAnalytics({
      dev_environment: process.env.REACT_APP_PUBLIC_KEENIO_ENV,
      is_new: global.thesession_wasnull,
      keen_session_id: sessionStorage.getItem('@you:keenSessionId'),
      name: 'session',
      org_domain: sessionStorage.getItem('org_domain'),
      session_endpoint,
      ...params,
      // adding in referrer tracking
      page: document.location.href,
      referrer: document.referrer
    })

  return {
    clearStorage,
    sessionEvent
  }
}

export default useSession
