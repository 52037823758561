/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography
} from '@mui/material'

import GoalStatus from './GoalStatus'
import ProgressBar from '../../../components/common/ProgressBar'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

const WelcomeGoalCard = ({
  activeGoal,
  animate,
  goalTitle,
  stepsCompleted,
  totalSteps,
  stepDetail
}) => {
  const { gradient_start_color, gradient_stop_color } = useSelector(
    prop('school')
  )

  const ActiveGoalContent = () => (
    <Box display="flex" flexDirection="column">
      <ProgressBar
        barColor="secondary"
        completed={stepsCompleted}
        label={`${stepsCompleted} / ${totalSteps} Steps`}
        total={totalSteps}
      />
      <GoalStatus dueDate="" startDate="" />
    </Box>
  )

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 1,
        boxShadow: 4,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        left: '35%',
        p: 1,
        position: 'absolute',
        top: { xs: '40%', md: '35%', lg: '20%', xl: '25%' },
        width: '60%',
        zIndex: 3
      }}
    >
      <CardContent>
        <Stack sapcing={2}>
          <Typography color="primary.main" variant="subtitleBold">
            <FormattedMessage defaultMessage="Suggested Goal" id="NTzXZv" />
          </Typography>
          <Typography variant="cardTitle">{goalTitle}</Typography>
          <ActiveGoalContent />
        </Stack>
        <Divider />
        <Typography variant="overline">
          <FormattedMessage defaultMessage="Next Step" id="8cv9D4" />
        </Typography>
        <Typography
          maxHeight="46px"
          minHeight="46px"
          my=".8em"
          overflow="hidden"
          textAlign="left"
          variant="body2"
        >
          {stepDetail}
        </Typography>
        <CardActions>
          <Box
            sx={{
              animation: `${animate ? 'pulseAnimation' : ''} 0.5s ease`,
              background: `linear-gradient(-180deg, ${gradient_start_color} 0%, ${gradient_stop_color} 99%)`,
              borderRadius: 1,
              color: 'school.buttonFontColor',
              fontFamily: 'mark-ot-bold',
              fontSize: 16,
              px: 2,
              py: 1.5,
              textAlign: 'center',
              textTransform: 'uppercase',
              width: 1
            }}
          >
            {activeGoal ? (
              <FormattedMessage defaultMessage="Visit your Goal" id="INaVF/" />
            ) : (
              <FormattedMessage defaultMessage="Set this Goal" id="4OUieF" />
            )}
          </Box>
        </CardActions>
      </CardContent>
    </Box>
  )
}

WelcomeGoalCard.propTypes = {
  activeGoal: PropTypes.bool.isRequired,
  animate: PropTypes.bool.isRequired,
  goalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  stepDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  stepsCompleted: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
}

export default WelcomeGoalCard
