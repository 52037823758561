import { useEffect } from 'react'
import BasicSignIn from './components/BasicSignInConnector'
import SocialSignIn from './components/SocialSignInConnector'
import SSOSignIn from './components/SSOSignIn'
import { FormattedMessage } from 'react-intl'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { and, equals, not, prop } from 'ramda'
import { useCurrentAppType } from 'hooks'
import { useSelector } from 'react-redux'

const SignInPage = () => {
  const { isWellpower } = useCurrentAppType()
  const { sso_enabled, enable_social_sso, name, azureLoginUrl } = useSelector(
    prop('school')
  )

  useEffect(() => {
    const isDev = equals(process.env.REACT_APP_PUBLIC_APP_ENV, 'development')
    if (and(not(isDev), isWellpower)) {
      window.location.href = azureLoginUrl
    }
  }, [isWellpower])

  const renderComponent = () => {
    if (enable_social_sso) return <SocialSignIn />
    if (equals('1', sso_enabled)) return <SSOSignIn />
    return <BasicSignIn />
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        height: 'calc(100vh - 74px)',
        maxWidth: 1074,
        mt: {
          xs: 'calc(50% - 74px)',
          // eslint-disable-next-line sort-keys
          sm: 'calc(30% - 74px)',
          // eslint-disable-next-line sort-keys
          md: 'calc(20% - 74px)'
        },
        mx: 'auto',
        px: 2
      }}
    >
      <Card
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent',
          maxWidth: 500,
          width: 1
        }}
      >
        <Stack spacing={0.5} sx={{ pt: 1, px: 2 }}>
          <Typography component="h1" variant="h4">
            <FormattedMessage
              defaultMessage="Log into your account"
              id="g/KaJY"
            />
          </Typography>
          <Typography fontSize={20} variant="subtitle1">
            <FormattedMessage
              defaultMessage="New to YOU at {school}?"
              id="9Iytmg"
              values={{ school: name }}
            />
          </Typography>
          <Typography
            color="primary.main"
            component={Link}
            sx={{
              textDecoration: 'underline',
              width: 'fit-content'
            }}
            to="/sign-up"
            variant="body2"
          >
            <FormattedMessage defaultMessage="Create an account" id="0vL5u1" />
          </Typography>
        </Stack>
        <Divider />
        <Box
          sx={{
            px: 2,
            py: 1
          }}
        >
          {renderComponent()}
        </Box>
      </Card>
    </Stack>
  )
}

export default SignInPage
