/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import VideoImage from '../../../components/common/VideoImage'
import WelcomeNumberNav from './WelcomeNumberNav'
import { prop } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useAnalytics } from 'hooks'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useSelector } from 'react-redux'

const WelcomeSectionStories = () => {
  const { cloudfront_domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const getResponderVideos = cloudfront_url => {
    return [
      {
        background:
          '/images/welcome/video-backgrounds/responder/911-Dispatcher-Trauma-&-Burnout-_-PTSD-&-Stress-for-First-Responders.jpg',
        caption:
          'A 911 dispatcher talks about the pace of the job and dealing with burnout.',
        name: 'Strength Through Community',
        url:
          'https://' +
          cloudfront_url +
          '/misc/welcome-videos/responder/911-Dispatcher-Trauma-&-Burnout-_-PTSD-&-Stress-for-First-Responders.mp4'
      },
      {
        background:
          '/images/welcome/video-backgrounds/responder/Rick-Balentine-Fire-Chief-Testimonial.jpg',
        caption:
          'A fire chief offers advice on positivity, disseminating information, and caring for yourself and others as a leader.',
        name: 'Rick Balentine Fire Chief Testimonial',
        url:
          'https://' +
          cloudfront_url +
          '/misc/welcome-videos/responder/Rick-Balentine-Fire-Chief-Testimonial.mp4'
      },
      {
        background:
          '/images/welcome/video-backgrounds/responder/David-Ray-Allen-EMT-Testimonial.jpg',
        caption:
          'An EMT talks about peer support, decompression, and finding work-life balance.',
        name: 'David Ray Allen EMT Testimonial',
        url:
          'https://' +
          cloudfront_url +
          '/misc/welcome-videos/responder/David-Ray-Allen-EMT-Testimonial.mp4'
      }
    ]
  }

  const getVideos = cloudfront_url => getResponderVideos(cloudfront_url)

  const videos = getVideos(cloudfront_domain)
  const [currentVideo, setCurrentVideo] = useState(0)

  const veteranHeadline =
    'Get exclusive access to Veteran Stories by Warrior Films.'
  const veteranLink = 'Sign up to see the full length films'

  const responderContent =
    'Sometimes, it takes another responder to truly understand the unique stresses of the job. Hear tips, tools, and life hacks from other responders. No responder should feel alone, on the job or off.'

  const handleNav = index => {
    if (index !== currentVideo) {
      setCurrentVideo(index)
    }
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'stories'
    })
  }

  const HashSquare = () => {
    const hash = '/images/welcome/hash-square.svg'

    return (
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '5%', md: 70 },
          width: { xs: 1, lg: '50%' }
        }}
      >
        <Box
          sx={{
            background: `url(${hash})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            pt: '100%',
            width: 1
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, auto)' },
        pb: { xs: '25%', lg: '5%' },
        position: 'relative',
        pt: { xs: 10, lg: 8, xl: 10 }
      }}
    >
      <Stack
        sx={{
          // gridColumn: isResponder ? { xs: 1, lg: 2 } : 1,
          gridRow: 1,
          p: 2
        }}
      >
        <HashSquare />
        <VideoImage
          background={prop(currentVideo, videos).background}
          handleGA={() =>
            GA.click(
              'Welcome-Stories-Video: ' + prop(currentVideo, videos).name,
              'Click'
            )
          }
          handleKeen={sendKeenIOEvent}
          videoUrl={prop(currentVideo, videos).url}
        />
        <Box>
          <WelcomeNumberNav
            caption={prop(currentVideo, videos).caption}
            currentItem={currentVideo}
            handleClick={(e, index) => {
              e.preventDefault()
              handleNav(index)
              sendKeenIOEvent('Video Nav ' + (index + 1), 'Button')
            }}
            items={videos}
            positionNumbersOnTop
          />
        </Box>
      </Stack>

      <Stack
        sx={{
          gridRow: { xs: 2, lg: 1 },
          p: { xs: 2, lg: '15% 7%' }
        }}
      >
        <Typography color="primary" variant="h2">
          <FormattedMessage
            defaultMessage="{headline}"
            id="AEeXEL"
            values={{
              headline: veteranHeadline
            }}
          />
        </Typography>
        <Typography mb={2} mt=".8em" variant="bodyLarge">
          <FormattedMessage
            defaultMessage="{content}"
            id="zb24ln"
            values={{ content: responderContent }}
          />
        </Typography>
        <Button
          endIcon={<ArrowForwardRoundedIcon />}
          LinkComponent={Link}
          onClick={() => sendKeenIOEvent('Create a new Account', 'Link')}
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            px: 0
          }}
          to="/sign-up"
          variant="text"
        >
          <FormattedMessage
            defaultMessage="{ctaLink}"
            id="QBkvSl"
            values={{
              ctaLink: veteranLink
            }}
          />
        </Button>
      </Stack>
    </Box>
  )
}

export default WelcomeSectionStories
