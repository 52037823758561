import PropTypes from 'prop-types'
import PreparedCard from 'components/common/PreparedCardConnector'
import { Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const PickUpWhereYouLeftOffItem = ({
  card,
  subtitle,
  linkText = null,
  href = null
}) => (
  <Stack
    spacing={2}
    sx={{
      width: 1
    }}
  >
    <Stack alignItems="center">
      <Typography
        component="span"
        fontSize={20}
        textAlign="center"
        variant="subtitle2"
      >
        {subtitle}...
      </Typography>
      {href && (
        <Typography
          color="primary.main"
          component={Link}
          fontFamily="mark-ot-bold"
          fontSize="14px"
          sx={{
            textDecoration: 'underline'
          }}
          to={href}
        >
          {linkText}
        </Typography>
      )}
    </Stack>
    <PreparedCard blockTitle="Pick Up Where You Left Off Block" card={card} />
  </Stack>
)

PickUpWhereYouLeftOffItem.propTypes = {
  card: PropTypes.shape({}).isRequired,
  href: PropTypes.any,
  linkText: PropTypes.any,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default PickUpWhereYouLeftOffItem
