import HeroContent from './HeroContent'
import { isNil, not, prop } from 'ramda'
import { connect } from 'react-redux'

const mapStateToProps = ({ history, user }) => ({
  history: prop('history', history),
  isLoggedIn: not(isNil(prop('id', user))),
})

export default connect(mapStateToProps)(HeroContent)
