import BasicSignIn from './BasicSignIn'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { resetState } from 'store/authenticationSlice'
import { setUserData } from 'store/userSlice'

const mapStateToProps = ({ authentication }) => ({
  email: prop('email', authentication),
  password: prop('password', authentication),
})

export default connect(mapStateToProps, {
  resetState,
  setUserData,
})(BasicSignIn)
