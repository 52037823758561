import { Box, Button, Typography } from '@mui/material'

import { prop, length, not } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useGetPublicCardsQuery } from 'api/publicApi'

import StoryCard from '../cards/StoryCard'
import { useAnalytics } from 'hooks'
import useCurrentDomain from 'hooks/useCurrentDomain'

const Stories = () => {
  const { currentDomain } = useCurrentDomain()
  const { sendEvent } = useAnalytics()

  const { data = [], isFetching } = useGetPublicCardsQuery(
    `org_domain=${currentDomain}&filter[types]=${12}&page[take]=${3}`
  )

  const getCopy = () => (
    <FormattedMessage defaultMessage="Stories" id="Ldxym4" />
  )

  return (
    <Box>
      <Box
        sx={{ margin: '0 auto', maxWidth: 1024, textAlign: 'center', width: 1 }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gridGap: 24
          }}
        >
          <Typography color="primary" variant="h2">
            {getCopy()}
          </Typography>
          {not(isFetching) && (
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'grid',
                gridGap: 48,
                gridTemplateColumns: {
                  xs: '1fr',
                  // eslint-disable-next-line sort-keys
                  lg: `repeat(${length(data)}, 1fr)`
                }
              }}
            >
              {data.map(card => (
                <StoryCard key={prop('id', card)} {...card} />
              ))}
            </Box>
          )}
          <Button
            LinkComponent={Link}
            onClick={() =>
              sendEvent('block_interactions', {
                block_title: 'OFD Explore Stories',
                click_element: 'Link',
                element_title: 'See All Stories CTA',
                path: window.location.pathname
              })
            }
            to="/public/stories"
          >
            <FormattedMessage defaultMessage="hear more stories" id="Vmy+ho" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Stories
