import MuiAlert from './MuiAlert'
import MuiAlertTitle from './MuiAlertTitle'
import MuiAutocomplete from './MuiAutocomplete'
import MuiAvatar from './MuiAvatar'
import MuiButton from './MuiButton'
import MuiCard from './MuiCard'
import MuiCardActions from './MuiCardActions'
import MuiCardContent from './MuiCardContent'
import MuiChip from './MuiChip'
import MuiCssBaseline from './MuiCssBaseline'
import MuiDialogTitle from './MuiDialogTitle'
import MuiDivider from './MuiDivider'
import MuiDrawer from './MuiDrawer'
import MuiFormLabel from './MuiFormLabel'
import MuiInputBase from './MuiInputBase'
import MuiLinearProgress from './MuiLinearProgress'
import MuiMasonry from './MuiMasonry'
import MuiSkeleton from './MuiSkeleton'
import MuiTabs from './MuiTabs'
import MuiTooltip from './MuiTooltip'
import MuiUseMediaQuery from './MuiUseMediaQuery'

const components = {
  MuiAlert,
  MuiAlertTitle,
  MuiAutocomplete,
  MuiAvatar,
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiChip,
  MuiCssBaseline,
  MuiDialogTitle,
  MuiDivider,
  MuiDrawer,
  MuiFormLabel,
  MuiInputBase,
  MuiLinearProgress,
  MuiMasonry,
  MuiSkeleton,
  MuiTabs,
  MuiTooltip,
  MuiUseMediaQuery,
}

export default components
