import { useEffect } from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FormattedMessage } from 'react-intl'
import { facebook } from '../../icons'
import { Button } from '@mui/material'
import { and, equals, isNil, not, path, prop, reject } from 'ramda'
import { useSignInFacebookMutation } from 'api/authApi'
import { useSelector } from 'react-redux'
import { getSSOBodyCopy } from 'store/authenticationSlice'
import GA from 'services/gaService'
import { useAnalytics, useLocalStorage } from 'hooks'
import { useNavigate } from 'react-router-dom'

const FacebookButton = ({
  accessToken,
  isSignUp,
  setAccessToken,
  setIsOpen,
  setModalType,
  setSSOError,
  setSSOErrorCode,
  setUserData
}) => {
  const navigate = useNavigate()
  const { domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()
  const { save } = useLocalStorage()

  // Redux Selector
  const bodyCopy = useSelector(({ authentication }) =>
    getSSOBodyCopy(authentication)
  )

  const [signInFacebook, { data, error, isError }] = useSignInFacebookMutation()

  const handleFacebookCallback = ({ accessToken, error }) => {
    if (error) {
      setSSOError(true)
      setSSOErrorCode(prop('code', error))
    }
    setAccessToken(accessToken)
    if (isSignUp) {
      const body = {
        ...reject(isNil, bodyCopy),
        access_token: accessToken,
        org_domain: domain
      }
      return signInFacebook(body)
    }
    signInFacebook({ access_token: accessToken, org_domain: domain })
  }

  const handleRedirect = () => {
    if (isSignUp) return navigate('/onboarding/introduction')
    navigate('/home')
  }

  useEffect(() => {
    if (and(not(isNil(data)), path(['user', 'active_consent_given'], data))) {
      if (not(isNil(accessToken))) setAccessToken(null)
      save(prop('x-session-id', data))
      if (isSignUp) {
        sendEvent('sign-up', {
          auth_type: 'social_sso',
          validation_errors: [],
          validation_success: true
        })
        GA.click('Social SSO Signup', 'Click')
      }
      if (not(isSignUp)) {
        sendEvent('sign-in', {
          auth_type: 'social_sso',
          validation_errors: [],
          validation_success: true
        })
        GA.click('Social SSO Signin', 'Click')
      }
      setUserData(prop('user', data))
      handleRedirect()
    }
  }, [data])

  useEffect(() => {
    if (and(isError, equals(428, prop('status', error)))) {
      setModalType('facebook')
      setIsOpen(true)
    }
  }, [isError])

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_PUBLIC_SOCIAL_SSO_FACEBOOK_APPID}
      callback={handleFacebookCallback}
      disableMobileRedirect
      render={({ onClick }) => (
        <Button
          fullWidth
          onClick={() => onClick()}
          startIcon={facebook}
          sx={{
            bgcolor: 'grey.socialSSO'
          }}
          variant="sso"
        >
          <FormattedMessage
            defaultMessage="Continue with Facebook"
            id="currUk"
          />
        </Button>
      )}
    />
  )
}

FacebookButton.propTypes = {
  accessToken: PropTypes.string,
  isSignUp: PropTypes.bool.isRequired,
  setAccessToken: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setModalType: PropTypes.func.isRequired,
  setSSOError: PropTypes.func.isRequired,
  setSSOErrorCode: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired
}

FacebookButton.defaultProps = {
  accessToken: null
}

export default FacebookButton
