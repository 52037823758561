import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { equals, inc, length, not } from 'ramda'
import { Fragment } from 'react'

const BreadCrumbs = ({ links }) => (
  <Box
    aria-label="breadcrumb"
    sx={{
      alignItems: 'center',
      alignSelf: 'flex-start',
      display: 'flex',
      gridGap: 8
    }}
  >
    {links.map(({ href, label }, idx) => (
      <Fragment key={href}>
        <Link
          style={{
            color: 'inherit',
            fontSize: 12,
            textDecoration: equals(inc(idx), length(links))
              ? 'none'
              : 'underline',
            textTransform: 'uppercase'
          }}
          to={href}
        >
          {label}
        </Link>
        {not(equals(inc(idx), length(links))) && (
          <Typography mb="4px">&#8226;</Typography>
        )}
      </Fragment>
    ))}
  </Box>
)

BreadCrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default BreadCrumbs
