import { Box, Button, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'

const LocalResources = () => {
  const { sendEvent } = useAnalytics()

  return (
    <Box
      sx={{
        maxWidth: 1024,
        mb: 8,
        mt: 0,
        mx: 'auto',
        textAlign: 'center',
        width: 1
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gridGap: 24
        }}
      >
        <Box
          sx={{
            background: 'url(/images/wellpower/explore/local-resources.jpg)',
            backgroundPosition: 'center -2px',
            backgroundRepeat: 'no-repeat',
            borderRadius: 2,
            height: 313,
            position: 'relative',
            width: 1
          }}
        />
        <Typography color="primary" variant="h2">
          <FormattedMessage
            defaultMessage="Want to get connected?"
            id="SqBvOZ"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="Find support, right in your neighborhood."
            id="AAyUgX"
          />
        </Typography>
        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'OFD Explore Geolocal Resources',
              click_element: 'Link',
              element_title: 'Geolocal Resources CTA',
              path: window.location.pathname
            })
          }
          to="/public/resources-near-me"
        >
          <FormattedMessage
            defaultMessage="Explore community resources"
            id="1nutn2"
          />
        </Button>
      </Box>
    </Box>
  )
}

export default LocalResources
