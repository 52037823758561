import PropTypes from 'prop-types'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { FormattedMessage } from 'react-intl'
import { metersToMiles } from 'utils/helpers'
import { isNil, defaultTo, prop, not, equals } from 'ramda'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grow,
  Stack,
  Typography,
} from '@mui/material'
import parse from 'html-react-parser'
import { TYPE_FACT_TIP, TYPE_SKILL } from 'utils/card-functions'
import { useSelector } from 'react-redux'

import BackgroundImage from 'components/common/BackgroundImage'
import ContactInfo from './ContactInfo'

const GeoLocated = ({ article }) => {
  const { flagImage } = useSelector(prop('school'))
  const { home_location, temp_location } = useSelector(prop('user'))

  const {
    campus_resource,
    cta,
    cta_url,
    distance,
    facts_tips_copy,
    geo_located_resource,
    page_content,
    photo,
    photo_description,
    type,
  } = article

  const articleToRender = equals(type, TYPE_FACT_TIP)
    ? facts_tips_copy
    : page_content

  const articleHtml = defaultTo('', articleToRender)

  return (
    <Container maxWidth="md">
      <Stack spacing={3}>
        <Grow in>
          <Card sx={{ alignSelf: 'center', width: 'fit-content' }}>
            <CardContent
              sx={{
                ':last-child': {
                  pb: 1.5,
                },
                p: 1.5,
              }}
            >
              <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                spacing={0.5}
              >
                <LocationOnRoundedIcon sx={{ color: 'secondary.dark' }} />
                <Typography
                  color="secondary.dark"
                  display="inline"
                  fontFamily="mark-ot-bold"
                  variant="caption"
                >
                  <FormattedMessage
                    defaultMessage="{distance} miles from"
                    id="5uDQrt"
                    values={{ distance: metersToMiles(parseInt(distance)) }}
                  />
                </Typography>
                <Typography
                  color="secondary.dark"
                  display="inline"
                  variant="caption"
                >
                  <FormattedMessage
                    defaultMessage="{address}"
                    id="CXfoaJ"
                    values={{
                      address: defaultTo(
                        prop('address', temp_location),
                        prop('address', home_location)
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grow>

        <Grow in>
          <Box>
            {not(isNil(prop('large_url', photo))) && (
              <BackgroundImage
                bgcolor={equals(type, TYPE_SKILL) ? 'white' : 'transparent'}
                flagIcon={campus_resource ? flagImage : null}
                imageUrl={prop('large_url', photo)}
                objectFit={equals(type, TYPE_SKILL) ? 'contain' : 'cover'}
              />
            )}
            {not(isNil(photo_description)) && (
              <Box
                data-testid="photo-description"
                sx={{
                  fontSize: 22,
                  fontWeight: 600,
                  letterSpacing: 0.25,
                  lineHeight: '38px',
                }}
              >
                {parse(photo_description)}
              </Box>
            )}
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'grid',
                // eslint-disable-next-line sort-keys
                gridTemplateColumns: { xs: '1fr', md: '.56fr .4fr' },
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  fontSize: 14,
                  'p:first-of-type': {
                    mt: 1,
                  },
                }}
              >
                <Typography variant="h6">
                  <FormattedMessage defaultMessage="About" id="g5pX+a" />
                </Typography>
                {parse(articleHtml)}
              </Box>
              <ContactInfo
                ctaLink={cta_url}
                ctaText={cta}
                geoLocatedResource={geo_located_resource}
              />
            </Box>
          </Box>
        </Grow>

        <Stack spacing={2}>
          <Typography component="h3" textAlign="left" variant="cardTitle">
            <FormattedMessage defaultMessage="Services Offered" id="nbUxqB" />
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ gridColumnGap: 16, gridRowGap: 16 }}
          >
            {prop('services_offered', geo_located_resource).map(
              ({ display_value }) => (
                <Chip
                  key={display_value}
                  label={display_value}
                  variant="outlined"
                />
              )
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

GeoLocated.propTypes = {
  article: PropTypes.shape({
    campus_resource: PropTypes.bool,
    cta: PropTypes.string,
    cta_url: PropTypes.string,
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    facts_tips_copy: PropTypes.string,
    geo_located_resource: PropTypes.shape({
      services_offered: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
      ),
    }),
    page_content: PropTypes.string,
    photo: PropTypes.shape({}),
    photo_description: PropTypes.string,
    type: PropTypes.number,
  }).isRequired,
}

export default GeoLocated
