/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { Stack, Typography } from '@mui/material'
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded'
import SpaRoundedIcon from '@mui/icons-material/SpaRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'

import RegionBlock from 'components/common/RegionBlock'
import AllTopicsBlock from './components/AllTopicsBlock'
import ExploreBlockWrapper from './components/ExploreBlockWrapper'
import SearchBlock from './components/SearchBlock'
import TrendingBlock from './components/TrendingBlock'

const Explore = () => (
  <Stack
    spacing={4}
    sx={{
      backgroundImage: 'url("/images/dashboard_bg.png")',
      backgroundSize: 'cover',
      mx: 'auto',
      my: 0,
      pb: 5
    }}
  >
    <Typography textAlign="center" variant="h1">
      <FormattedMessage
        defaultMessage="What do you want to explore?"
        id="AGq6u+"
      />
    </Typography>
    <SearchBlock id="main" />
    <ExploreBlockWrapper
      icon={<StarOutlineRoundedIcon />}
      title={<FormattedMessage defaultMessage="Featured Topics" id="VCoOFY" />}
    >
      <RegionBlock />
    </ExploreBlockWrapper>

    <ExploreBlockWrapper
      icon={<SpaRoundedIcon />}
      title={
        <FormattedMessage defaultMessage="All Well-Being Topics" id="MXtbhk" />
      }
    >
      <AllTopicsBlock />
    </ExploreBlockWrapper>

    <ExploreBlockWrapper
      icon={<TrendingUpRoundedIcon />}
      title={<FormattedMessage defaultMessage="New & Trending" id="/CJs/f" />}
    >
      <TrendingBlock />
    </ExploreBlockWrapper>
  </Stack>
)

export default Explore
