import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { not, path, prop } from 'ramda'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography
} from '@mui/material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import useAnalytics from 'hooks/useAnalytics'

import AnswerOptions from './AnswerOptions'
import Results from './Results'

const PollOfMonth = ({ pollingQuestion }) => {
  const { sendEvent } = useAnalytics()

  const [selected, setSelected] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = () => {
    sendEvent('YOU_Subdomain_Poll', {
      poll_question_answer: path(['answers', selected], pollingQuestion),
      poll_question_title: prop('prompt', pollingQuestion),
      subdomain_name: prop('group', pollingQuestion)
    })
    setIsSubmitted(true)
  }

  const renderContent = () => {
    if (isSubmitted) {
      return (
        <Results
          answers={prop('answers', pollingQuestion)}
          id={prop('id', pollingQuestion)}
          selected={selected}
        />
      )
    }
    return (
      <AnswerOptions
        answers={prop('answers', pollingQuestion)}
        selected={selected}
        setSelected={setSelected}
      />
    )
  }

  return (
    <Card
      sx={{
        alignSelf: 'center',
        maxWidth: 375,
        width: 1
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'flex-end',
          pt: 2,
          px: 2
        }}
      >
        <LeaderboardIcon sx={{ color: 'school.accent' }} />
        <Typography variant="overline">
          <FormattedMessage defaultMessage="Poll" id="h1gtUi" />
        </Typography>
      </Stack>
      <CardHeader
        subheader={
          not(isSubmitted) && (
            <FormattedMessage
              defaultMessage="Select an answer to see what others said."
              id="fkOonE"
            />
          )
        }
        subheaderTypographyProps={{
          variant: 'subtitle1'
        }}
        sx={{ pb: 1, pt: 0 }}
        title={
          <Typography
            aria-label={`Poll: ${prop('prompt', pollingQuestion)}`}
            tabIndex={0}
            variant="h5"
          >
            {prop('prompt', pollingQuestion)}
          </Typography>
        }
      />
      <CardContent sx={{ pb: 1 }}>{renderContent()}</CardContent>
      <CardActions sx={{ pb: 2, pt: 0 }} variant="center">
        {isSubmitted ? (
          <Button
            onClick={() => {
              setIsSubmitted(false)
              setSelected(null)
            }}
            startIcon={<RefreshRoundedIcon />}
            variant="text"
          >
            <FormattedMessage defaultMessage="Reset my Answer" id="41P9py" />
          </Button>
        ) : (
          <Button onClick={handleSubmit}>
            <FormattedMessage defaultMessage="Submit my Answer" id="nlkQVA" />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

PollOfMonth.propTypes = {
  pollingQuestion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    group: PropTypes.string,
    id: PropTypes.number,
    prompt: PropTypes.string,
    questionSummary: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
}

export default PollOfMonth
