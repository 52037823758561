import { combineReducers, configureStore } from '@reduxjs/toolkit'

// API
import { baseQuery } from 'api/baseQuery'
import { addressApi } from 'api/addressApi'
import { analyticsApi } from 'api/analyticsApi'
import { publicBaseQuery } from 'api/publicBaseQuery'

// Slices
import authentication from 'store/authenticationSlice'
import history from './historySlice'
import modal from 'components/modals/modalSlice'
import onboarding from 'pages/onboarding/components/onboardingSlice'
import school from 'components/school/schoolSlice'
import user from './userSlice'

export const rootReducer = combineReducers({
  [baseQuery.reducerPath]: baseQuery.reducer,
  [addressApi.reducerPath]: addressApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [publicBaseQuery.reducerPath]: publicBaseQuery.reducer,
  authentication,
  history,
  modal,
  onboarding,
  school,
  user
})

export const middleware = getDefaultMiddleware =>
  getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
    .concat(baseQuery.middleware)
    .concat(addressApi.middleware)
    .concat(analyticsApi.middleware)
    .concat(publicBaseQuery.middleware)

export const makeStore = () =>
  configureStore({
    middleware,
    reducer: rootReducer
  })
