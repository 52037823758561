import { baseQuery } from './baseQuery'

export const searchApi = baseQuery.injectEndpoints({
  endpoints: ({ query }) => ({
    search: query({
      query: query => `/search?query=${query}`,
    }),
  }),
  reducerPath: 'searchApi',
})

export const { useSearchQuery } = searchApi
