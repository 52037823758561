const MuiDrawer = {
  styleOverrides: {
    paper: {
      '@media (min-width: 1024px)': {
        top: 68,
      },
      top: 0,
      width: 260,
      zIndex: 4,
    },
  },
}

export default MuiDrawer
