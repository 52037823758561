import AzureForm from './AzureForm'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setEmail } from 'store/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  consent: prop('consent', authentication),
  email: prop('email', authentication)
})

const mapDispatchToProps = {
  setEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(AzureForm)
