import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material'
import { add } from 'date-fns'
import { useAnalytics, useSession } from 'hooks'
import { isNil, not, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'
import GA from 'services/gaService'

const Modal = ({ data }) => {
  const navigate = useNavigate()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()

  const [progress, setProgress] = useState(50)

  const handleAnalytics = () => {
    const promises = [
      sessionEvent('signup-wellpower', {
        session_id: prop('x-session-id', data)
      }),
      sendEvent('sign-up', {
        auth_type: 'wellpower',
        validation_errors: [],
        validation_success: true
      })
    ]
    return Promise.allSettled(promises)
  }

  useEffect(() => {
    if (not(isNil(prop('x-session-id', data)))) {
      setProgress(100)
      // Tracking
      GA.click('MHCD-Signup', 'Click')
      handleAnalytics().finally(() => navigate('/onboarding/introduction'))
    }
  }, [data])

  return (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle component="h1" id="dialog-title" tabIndex={-1}>
        <FormattedMessage
          defaultMessage="Finalizing your account...."
          id="y/1FyK"
        />
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          color: 'school.secondary',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box width={1}>
          <LinearProgress
            color="inherit"
            value={progress}
            valueBuffer={add(progress, 10)}
            variant="buffer"
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

Modal.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default Modal
