import { useState } from 'react'
import { Box, Button, Collapse, Stack, Typography } from '@mui/material'

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { prop, not, isNil } from 'ramda'
import parse from 'html-react-parser'

const VideoArticle = ({ article }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack spacing={4}>
      <Typography alignSelf="center" variant="h1">
        <FormattedMessage
          defaultMessage="{title}"
          id="2YgLVn"
          values={{ title: prop('title', article) }}
        />
      </Typography>
      <Box
        sx={{
          height: 360,
          // eslint-disable-next-line sort-keys
          mb: { xs: 4, sm: 8.5 },
          position: 'relative',
          width: 1,
        }}
      >
        <Box
          component="iframe"
          src={prop('video_embed_url', article)}
          sx={{
            border: 'none',
            height: '100%',
            left: 0,
            margin: 'auto',
            postion: 'absolute',
            right: 0,
            top: 0,
            width: 1,
          }}
        />
      </Box>
      {not(isNil(prop('video_transcript', article))) && (
        <>
          <Button
            endIcon={
              isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
            }
            onClick={() => setIsOpen(not(isOpen))}
            variant="text"
          >
            Video Audio Transcript
          </Button>
          <Collapse in={isOpen}>
            {parse(prop('video_transcript', article))}
          </Collapse>
        </>
      )}
      <Box>{parse(prop('video_description', article))}</Box>
    </Stack>
  )
}

VideoArticle.propTypes = {
  article: PropTypes.shape({}).isRequired,
}

export default VideoArticle
