import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { not } from 'ramda'

const SuccessMessage = ({ hasSuccess, message }) => {
  if (not(hasSuccess)) return null

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        alignItems: 'center',
        bgcolor: 'success.light',
        borderRadius: 1,
        color: 'success',
        mt: 1,
        p: '1% 2%',
      }}
    >
      <CheckCircleRoundedIcon aria-hidden color="success" />
      <Typography color="success" fontFamily="mark-ot-bold" variant="subtitle1">
        {message}
      </Typography>
    </Stack>
  )
}

SuccessMessage.propTypes = {
  hasSuccess: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

SuccessMessage.defaultProps = {
  hasSuccess: false,
}

export default SuccessMessage
