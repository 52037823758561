import { prop } from 'ramda'
import { baseQuery } from './baseQuery'

export const schoolApi = baseQuery.injectEndpoints({
  endpoints: ({ query }) => ({
    getAboutPageData: query({
      providesTags: ['about'],
      query: org => `/about-page-data?org_domain=${org}`,
    }),
    getFeaturedContent: query({
      providesTags: ['featured content'],
      query: () => '/featured-content',
    }),
    getPanicCard: query({
      providesTags: ['panic card'],
      query: org => `/panic-card?org_domain=${org}`,
    }),
    getReferralLinks: query({
      providesTags: ['referral links'],
      query: params =>
        `/referral-links?org_domain=${prop(
          'domain',
          params
        )}&is_staff_faculty=${prop('isStaffFaculty', params) ? 1 : 0}`,
    }),
    getSchoolData: query({
      providesTags: ['school'],
      query: org => `/school-data?org_domain=${org}`,
    }),
    getSubdomain: query({
      providesTags: ['subdomain'],
      query: id => `/subdomain/${id}`,
    }),
  }),
  overrideExisting: module.hot?.status() === 'apply',
  reducerPath: 'schoolApi',
})

export const {
  useGetAboutPageDataQuery,
  useGetFeaturedContentQuery,
  useGetPanicCardQuery,
  useGetReferralLinksQuery,
  useGetSchoolDataQuery,
  useGetSubdomainQuery,
} = schoolApi
